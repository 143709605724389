import React from 'react';
import { createIcon } from '@chakra-ui/react';

const DocumentLoupe = createIcon({
  displayName: 'DocumentLoupe',
  viewBox: '0 0 78 100',
  path: (
    <>
      <path
        d='M71.4314 86.147C71.4314 66.4241 71.4314 46.9124 71.4314 27.2364C70.9737 27.2364 70.5846 27.2364 70.1726 27.2364C65.2519 27.2364 60.354 27.2364 55.4332 27.2364C52.7325 27.2364 51.5423 25.992 51.5423 23.2214C51.5423 18.1733 51.5423 13.1486 51.5423 8.1005C51.5423 7.67786 51.5423 7.25523 51.5423 6.76215C39.023 6.76215 26.6409 6.76215 14.0758 6.76215C14.0758 7.23175 14.0758 7.70134 14.0758 8.14746C14.0758 15.8488 14.0758 23.5501 14.0758 31.228C14.0758 33.8108 12.1075 35.4543 9.88742 34.7499C8.60573 34.3508 7.82756 33.4351 7.64446 32.0733C7.5758 31.6506 7.5758 31.228 7.5758 30.7819C7.5758 21.9066 7.62157 13.0312 7.55291 4.17939C7.53002 1.6201 8.8346 -0.0234755 11.6497 4.14733e-06C25.7712 0.0939229 39.9156 0.0469635 54.0371 0.0234838C55.479 0.0234838 56.5776 0.469598 57.5846 1.52618C63.8786 8.05354 70.1955 14.5574 76.5582 21.0143C77.5423 22.024 78.0001 23.1275 77.9772 24.5598C77.9543 45.9732 77.9543 67.3867 77.9543 88.8002C77.9543 91.6647 76.7871 92.8622 73.9948 92.8622C56.0283 92.8622 38.0388 92.8622 20.0723 92.8622C17.7835 92.8622 16.3188 91.5708 16.273 89.575C16.2501 87.8141 17.5089 86.3818 19.2712 86.194C19.6832 86.147 20.118 86.1705 20.53 86.1705C37.0318 86.1705 53.5107 86.1705 70.0124 86.1705C70.4473 86.147 70.905 86.147 71.4314 86.147ZM58.0423 20.4273C60.9719 20.4273 63.7642 20.4273 66.1216 20.4273C63.5124 17.7272 60.6973 14.8392 58.0423 12.0686C58.0423 14.6279 58.0423 17.4689 58.0423 20.4273Z'
        fill='#A8B9F0'
      />
      <path
        d='M18.2412 78.5161C17.5317 79.6666 16.868 80.7467 16.2043 81.8267C12.8856 87.2505 9.54407 92.6509 6.24829 98.0747C5.65322 99.0373 4.92083 99.7887 3.77646 99.9296C2.40322 100.117 1.28174 99.6478 0.549347 98.4269C-0.205935 97.1824 -0.0914982 95.9145 0.663784 94.6701C4.30287 88.7532 7.91907 82.8363 11.5353 76.9195C11.9243 76.2855 12.2905 75.6516 12.7254 74.9472C7.00357 69.8051 3.95956 63.3482 4.18843 55.506C4.37153 49.4013 6.5916 44.1183 10.7113 39.7042C18.8363 30.9932 31.8134 29.7018 41.4261 36.6283C50.9244 43.4609 54.1286 56.2808 49.0705 66.9406C44.1268 77.3186 31.4472 84.0338 18.2412 78.5161ZM10.6427 56.3043C10.6198 66.0249 18.2412 73.8436 27.7853 73.8671C37.2377 73.8906 44.9279 66.0953 44.9736 56.4687C44.9965 46.7011 37.4666 38.9528 27.8997 38.8589C18.4015 38.765 10.6655 46.6072 10.6427 56.3043Z'
        fill='#A8B9F0'
      />
      <path
        d='M28.2201 43.8601C34.5599 44.0244 39.7095 49.2134 40.0986 55.7877C40.2131 57.9714 38.9772 59.5915 37.0546 59.7089C35.1092 59.8262 33.736 58.4409 33.5757 56.2104C33.3469 52.8763 31.1268 50.5987 27.9455 50.4813C25.7712 50.3874 24.3522 49.0256 24.3979 47.0768C24.4437 45.0575 25.9543 43.7896 28.2201 43.8601Z'
        fill='#A8B9F0'
      />
    </>
  ),
});

export default DocumentLoupe;
