import PropTypes from 'prop-types';
import { Box, Flex } from '@chakra-ui/react';

const DrawerBody = ({ children, ...styles }) => {
  return (
    <Box pt={{ base: 0, md: 16 }} {...styles}>
      <Flex h='full' flexDirection='column'>
        {children}
      </Flex>
    </Box>
  );
};

DrawerBody.defaultProps = {
  styles: {},
};

DrawerBody.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.shape({}),
};

export default DrawerBody;
