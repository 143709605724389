import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';
import { Box, Button, Flex, Text, Grid } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import {
  getPaymentMethods,
  attachPaymentMethod,
  getPaymentMethodCreated,
  getIsCreating,
  resetCreating,
  getCreatingError,
  clearCreatingError,
} from '@/stores/payment';
import { getUser } from '@/stores/user';

import { DrawerHeader, DrawerBody } from '@/components/Drawer';
import { StripeCard } from '@/components/Stripe';
import { InputForm, CheckboxForm, SelectForm } from '@/components/Form';
import Error from '@/components/Error';
import { paymentMethodSchema } from './validationSchemas';
import { Stripe } from '../illustrations';

const AddPaymentMethod = ({ onBack }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const [isFormValid, setIsFormValid] = useState(false);
  const [isCardNumberValid, setIsCardNumberValid] = useState(false);
  const [isCardExpiryDateValid, setIsCardExpiryDateValid] = useState(false);
  const [isCardCVCValid, setIsCardCVCValid] = useState(false);
  const [creatingError, setCreatingError] = useState(false);

  const { id: userId } = useSelector(state => getUser(state));
  const paymentMethodCreated = useSelector(state =>
    getPaymentMethodCreated(state),
  );
  const isCreating = useSelector(state => getIsCreating(state));
  const creatingPaymentMethodError = useSelector(state =>
    getCreatingError(state),
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'all', resolver: yupResolver(paymentMethodSchema) });

  const countriesOptions = [{ value: 'us', name: 'United States' }];

  useEffect(() => {
    return () => {
      dispatch(resetCreating());
      dispatch(clearCreatingError());
    };
  }, [dispatch]);

  useEffect(() => {
    if (paymentMethodCreated) {
      dispatch(getPaymentMethods({ id: userId }));
      onBack();
    }
  }, [dispatch, paymentMethodCreated, onBack, userId]);

  const handleFormValidation = useCallback(() => {
    if (
      isValid &&
      isCardNumberValid &&
      isCardExpiryDateValid &&
      isCardCVCValid
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [isValid, isCardNumberValid, isCardExpiryDateValid, isCardCVCValid]);

  useEffect(() => {
    handleFormValidation();
  }, [handleFormValidation]);

  const onSubmit = async data => {
    const cardNumber = elements.getElement(CardNumberElement);
    const cardExpiryDate = elements.getElement(CardExpiryElement);
    const cardCVC = elements.getElement(CardCvcElement);

    const { name, country, zipCode, isDefault } = data;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
      card: cardExpiryDate,
      card: cardCVC,
      billing_details: {
        name,
        address: { country, postal_code: zipCode },
      },
    });

    if (error) {
      setCreatingError(true);
    } else {
      dispatch(
        attachPaymentMethod({
          userId,
          paymentMethod: paymentMethod.id,
          defaultPaymentMethod: isDefault,
        }),
      );
    }
  };

  const renderForm = () => {
    if (creatingError || creatingPaymentMethodError)
      return (
        <Error
          clearError={() => {
            setCreatingError(false);
            dispatch(clearCreatingError());
          }}
        />
      );

    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text fontSize='lg' fontWeight='bold'>
            {t('payment:addPayment.description')}
          </Text>
          <Box fontSize='sm' mb={6}>
            {t('payment:addPayment.powered_by_stripe')} <Stripe w={10} h={10} />
          </Box>
          <InputForm
            id='name'
            label={t('payment:addPayment.name')}
            placeholder={t('payment:addPayment.namePlaceholder')}
            register={register}
            errors={errors}
          />
          <StripeCard
            id='cardNumber'
            type='card'
            label={t('payment:addPayment.cardNumber')}
            isValid={setIsCardNumberValid}
          />
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <StripeCard
              id='cardExpiryDate'
              type='expiry'
              label={t('payment:addPayment.expiryDate')}
              isValid={setIsCardExpiryDateValid}
            />
            <StripeCard
              id='cardCVC'
              type='cvc'
              label={t('payment:addPayment.cvc')}
              isValid={setIsCardCVCValid}
            />
          </Grid>
          <SelectForm
            id='country'
            options={countriesOptions}
            label={t('payment:addPayment.country')}
            placeholder={t('payment:addPayment.countryPlaceholder')}
            register={register}
            errors={errors}
            mb={4}
          />
          <InputForm
            id='zipCode'
            type='number'
            label={t('payment:addPayment.zipCode')}
            placeholder={t('payment:addPayment.zipCode')}
            register={register}
            errors={errors}
          />
          <CheckboxForm
            id='isDefault'
            label={t('payment:addPayment.setDefault')}
            register={register}
          />
          <Flex justifyContent='flex-end' py={6}>
            <Button
              w={{ base: 'full', md: 'fit-content' }}
              type='submit'
              bg='alpha.400'
              color='white'
              disabled={!isFormValid || isCreating}
              data-testid='add-payment-button'
              isLoading={isCreating}
              loadingText='Creating'
            >
              {t('payment:addPayment.add')}
            </Button>
          </Flex>
        </form>
      </>
    );
  };

  return (
    <Box data-testid='add-payment-method-form'>
      <DrawerHeader onBack={onBack} title={t('payment:addPayment.title')} />
      <DrawerBody px={{ base: 6, md: 8 }} pb={{ base: 4, md: 0 }}>
        {renderForm()}
      </DrawerBody>
    </Box>
  );
};

AddPaymentMethod.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default AddPaymentMethod;
