import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

const SuccessfulUpload = ({ children }) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      w={{ base: '80%', md: '60%' }}
      m='0 auto'
      h='full'
      justifyContent={{ base: 'none', md: 'center' }}
      alignItems={{ base: 'center', md: 'start' }}
      pt={{ base: '0', md: '12px' }}
    >
      {children}
    </Box>
  );
};

SuccessfulUpload.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SuccessfulUpload;
