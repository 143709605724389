import { object, string } from 'yup';

const professorsValidationSchema = object({
  professorSuggestion: string().required('Professor name is required'),
}).required();

const coursesValidationSchema = object({
  courseSuggestion: string().required('Course name is required'),
}).required();

export { professorsValidationSchema, coursesValidationSchema };
