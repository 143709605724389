export const pages = {
  rootPath: '/',
  adminPath: '/admin',
  adminOutlinesPath: '/admin/outlines',
  adminProfessorsPath: '/admin/professors',
  adminCoursesPath: '/admin/courses',
  adminSchoolsPath: '/admin/schools',
  adminUsersPath: '/admin/users',
  adminProfessorsSuggestionsPath: '/admin/professorsSuggestions',
  adminCoursesSuggestionsPath: '/admin/coursesSuggestions',
  adminPurchasesPath: '/admin/purchases',
  welcomePath: '/welcome',
  outlinesPath: '/outlines',
  outlinePath: id => `/outlines/${id}`,
  uploadOutline: '/outlines/upload',
  uploadSuccessfulPath: id => `/outlines/upload/${id}`,
  tosPath: '/tos',
  privacyPath: '/privacy',
  faqPath: '/faq',

  // Error pages
  notFound: '/404',
};
