import getConfig from 'next/config';
import useTranslation from 'next-translate/useTranslation';
import { Box, Text, Link } from '@chakra-ui/react';

const { publicRuntimeConfig } = getConfig();

const FAQ = () => {
  const { t } = useTranslation();

  const { SUPPORT_EMAIL, SUGGEST_EMAIL } = publicRuntimeConfig;
  const NUMBER_OF_QUESTIONS = 10;

  let questions = [];

  for (let i = 1; i < NUMBER_OF_QUESTIONS + 1; i++) {
    questions.push({ Q: t(`faq:q${i}.Q`), A: t(`faq:q${i}.A`) });
  }

  const addLink = text => {
    const findEmail = email => new RegExp(email).test(text);
    const renderLink = email => (
      <>
        {text.replace(email, '')}
        <Link color='alpha.500' isExternal href={`mailto:${email}`}>
          {email}
        </Link>
      </>
    );

    if (findEmail(SUPPORT_EMAIL)) return renderLink(SUPPORT_EMAIL);
    if (findEmail(SUGGEST_EMAIL)) return renderLink(SUGGEST_EMAIL);

    return text;
  };

  return (
    <Box
      pt={2}
      mx={{ base: 6, md: 8 }}
      my={{ md: 4 }}
      borderRadius='xl'
      bg={'none'}
    >
      {questions.map((q, idx) => (
        <Box key={idx} fontSize='sm' mb={6}>
          <Text fontWeight='bold'>{q.Q}</Text>
          <Text color='gray.500'>{addLink(q.A)}</Text>
        </Box>
      ))}
    </Box>
  );
};

export default FAQ;
