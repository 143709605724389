import React, { useState, useEffect, useCallback } from 'react';
import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, Flex, Stack, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useStripe } from '@stripe/react-stripe-js';

import {
  addBankAccount,
  getIsCreating,
  resetCreating,
  getCreatingError,
  clearCreatingError,
  getBankAccountCreated,
} from '@/stores/payment';
import { getUser } from '@/stores/user';
import { createFinancialConnectionSession } from '@/api/user';

import { DrawerHeader, DrawerBody } from '@/components/Drawer';
import { InputForm } from '@/components/Form';
import { BankCheck, Stripe } from '@/components/illustrations';
import Error from '@/components/Error';
import { bankAccountSchema } from './validationSchemas';
import BankAdded from './BankAdded';
import { CardIcon, DocumentTextIcon, PencilIcon } from 'chakra-ui-ionicons';

const AddBank = ({ onBack }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stripe = useStripe();

  const [creatingError, setCreatingError] = useState(false);
  const [created, setCreated] = useState(false);
  const [creatingFCSession, setCreatingFCSession] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const { id: userId } = useSelector(state => getUser(state));
  const isCreating = useSelector(state => getIsCreating(state));
  const bankAccountCreated = useSelector(state => getBankAccountCreated(state));
  const creatingBankAccountError = useSelector(state =>
    getCreatingError(state),
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'all', resolver: yupResolver(bankAccountSchema) });

  useEffect(() => {
    return () => {
      dispatch(resetCreating());
      dispatch(clearCreatingError());
      setCreated(false);
    };
  }, [dispatch]);

  useEffect(() => {
    if (bankAccountCreated) {
      setCreated(true);
    }
  }, [dispatch, bankAccountCreated]);

  const onSubmit = async data => {
    const { holderName, routingNumber, accountNumber } = data;

    const { token, error } = await stripe.createToken('bank_account', {
      country: 'US',
      currency: 'usd',
      account_holder_name: holderName,
      account_holder_type: 'individual',
      routing_number: routingNumber,
      account_number: accountNumber,
    });

    if (error) {
      setCreatingError(true);
    } else {
      dispatch(addBankAccount({ userId, token: token.id }));
    }
  };

  const handleAddBankWithStripe = async () => {
    setCreatingFCSession(true);

    const { data } = await createFinancialConnectionSession(userId);

    const { token, error } = await stripe.collectBankAccountToken({
      clientSecret: data.client_secret,
    });

    if (error) {
      setCreatingError(true);
    } else if (token) {
      dispatch(addBankAccount({ userId, token: token.id }));
    }

    setCreatingFCSession(false);
  };

  const handleOnBack = () => {
    if (showForm) return setShowForm(false);

    return onBack();
  };

  const renderAddBankOptions = () => {
    if (creatingError || creatingBankAccountError)
      return (
        <Error
          clearError={() => {
            setCreatingError(false);
            dispatch(clearCreatingError());
          }}
        />
      );

    if (showForm)
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box fontSize='sm' mb={{ base: 6, md: 12 }}>
            <Text fontSize='lg' fontWeight='bold'>
              {t('payment:bank.descriptionFormTitle')}
            </Text>
            {t('payment:bank.description')} <Stripe w={10} h={10} />
          </Box>
          <Box>
            <InputForm
              id='holderName'
              label={t('payment:bank.holderName')}
              placeholder={t('payment:bank.holderNamePlaceholder')}
              register={register}
              errors={errors}
            />
            <BankCheck w='full' h='full' mt={2} mb={6} />
            <InputForm
              id='routingNumber'
              type='number'
              label={t('payment:bank.routingNumber')}
              placeholder={t('payment:bank.routingNumberPlaceholder')}
              register={register}
              errors={errors}
            />
            <InputForm
              id='accountNumber'
              type='number'
              label={t('payment:bank.accountNumber')}
              placeholder={t('payment:bank.accountNumberPlaceholder')}
              register={register}
              errors={errors}
            />
            <InputForm
              id='confirmAccountNumber'
              type='number'
              label={t('payment:bank.confirmAccountNumber')}
              placeholder={t('payment:bank.confirmAccountNumberPlaceholder')}
              register={register}
              errors={errors}
            />
            <Flex justifyContent='flex-end' py={6}>
              <Button
                w={{ base: 'full', md: 'fit-content' }}
                type='submit'
                bg='alpha.400'
                color='white'
                disabled={!isValid || isCreating}
                isLoading={isCreating}
                loadingText='Adding'
                data-testid='add-bank-button'
              >
                {t('payment:bank.addBtn')}
              </Button>
            </Flex>
          </Box>
        </form>
      );

    return (
      <Box>
        <Box fontSize='sm' mb={{ base: 6, md: 12 }}>
          <Text fontWeight='bold'>{t('payment:bank.descriptionTitle')}</Text>
          {t('payment:bank.description')} <Stripe w={10} h={10} />
        </Box>
        <Box fontSize='sm' mb={6} mt={6}>
          <Text fontWeight='bold'>
            <CardIcon /> {t('payment:bank.stripeTitle')}
          </Text>
          {t('payment:bank.stripeDescription')}
        </Box>
        <Flex justifyContent='center'>
          <Button
            w={{ base: 'full', md: 'fit-content' }}
            bg='alpha.400'
            color='white'
            isLoading={isCreating || creatingFCSession}
            loadingText='Adding'
            onClick={handleAddBankWithStripe}
            data-testid='add-bank-with-stripe-button'
          >
            {t('payment:bank.stripeBtn')}
          </Button>
        </Flex>
        <Box fontSize='sm' mb={6} mt={{ base: 6, md: 12 }}>
          <Text fontWeight='bold'>
            <DocumentTextIcon /> {t('payment:bank.manuallyTitle')}
          </Text>
          {t('payment:bank.manuallyDescription')}
        </Box>
        <Flex justifyContent='center'>
          <Button
            w={{ base: 'full', md: 'fit-content' }}
            bg='alpha.400'
            color='white'
            isDisabled={isCreating || creatingFCSession}
            loadingText='Adding'
            onClick={() => setShowForm(true)}
            data-testid='add-bank-manually-button'
          >
            {t('payment:bank.manuallyBtn')}
          </Button>
        </Flex>
      </Box>
    );
  };

  if (created) return <BankAdded onBack={onBack} />;

  return (
    <Box data-testid='add-bank-form'>
      <DrawerHeader onBack={handleOnBack} title={t('payment:bank.title')} />
      <DrawerBody px={{ base: 6, md: 8 }} pb={{ base: 4, md: 0 }} pt={4}>
        {renderAddBankOptions()}
      </DrawerBody>
    </Box>
  );
};

AddBank.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default AddBank;
