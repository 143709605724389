import { useSelector } from 'react-redux';
import { Flex } from '@chakra-ui/react';

import {
  getPurchasedOutlinesHistory,
  getIsLoadingPurchasedOutlines,
} from '@/stores/purchasedOutlines';

import LoadingSpinner from '@/components/LoadingSpinner';
import HistoryItem from './HistoryItem';
import EmptyHistory from './EmptyHistory';

const History = () => {
  const purchasedOutlines = useSelector(state =>
    getPurchasedOutlinesHistory(state),
  );
  const isLoading = useSelector(state => getIsLoadingPurchasedOutlines(state));

  if (isLoading) return <LoadingSpinner mt={4} />;

  if (!purchasedOutlines?.buyersOutlines?.length) return <EmptyHistory />;

  return (
    <Flex flexDirection='column' mt={2}>
      {purchasedOutlines.buyersOutlines.map(el => (
        <HistoryItem key={el.id} purchasedOutline={el} />
      ))}
    </Flex>
  );
};

export default History;
