import React from 'react';
import { createIcon } from '@chakra-ui/react';

const DressedAndPlant = createIcon({
  displayName: 'Dresssed & Plant',
  viewBox: '0 0 298 211',
  path: (
    <>
      <path
        d='M243.36 96C239.01 33.8197 234.187 29.7207 234 29.5663L234.304 29C234.517 29.0965 239.559 32.1724 244 95.9807L243.36 96Z'
        fill='#414D58'
      />
      <path
        d='M247.868 96L247.265 95.9612C249.935 50.2587 247.03 25.3301 247 25.0841L247.603 25C247.633 25.2524 250.544 50.2393 247.874 96'
        fill='#414D58'
      />
      <path
        d='M251.824 98C248.282 69.2049 257.302 47.2225 257.394 47L258 47.2552C257.908 47.4712 248.96 69.2769 252.476 97.9215L251.824 98Z'
        fill='#414D58'
      />
      <path
        d='M255.54 101C254.781 90.7129 254.822 80.2635 255.662 70L256 70.1178C255.169 80.3041 255.129 90.6737 255.882 100.882L255.54 101Z'
        fill='#414D58'
      />
      <path
        d='M258 48L260.752 47.7622C262.708 47.593 264.607 46.9866 266.318 45.985C267.152 45.4756 268.015 45.0231 268.905 44.6307C272.309 43.2037 276.113 43.4613 279.778 43.2697C285.777 42.9453 291.654 41.373 297.061 38.6451C297.385 38.5084 297.658 38.2679 297.841 37.9574C298.405 36.8805 297.359 35.9226 296.452 35.3478C291.811 32.3682 287.088 29.3497 281.832 27.8698C276.576 26.39 270.598 26.641 266.109 29.8518C260.834 33.6308 258.913 41.3934 258.025 48'
        fill='#5172E1'
      />
      <path
        opacity='0.3'
        d='M298 37.6754C297.836 37.6179 297.678 37.5442 297.529 37.4557L296.217 36.7574C295.083 36.1497 293.446 35.2576 291.376 34.249C288.967 33.0485 286.458 32.0658 283.879 31.3139C280.723 30.3985 277.419 30.1282 274.159 30.5187C270.894 30.8918 267.812 32.2416 265.306 34.3977C263.342 36.248 261.771 38.4849 260.688 40.9726C259.72 43.0802 259.121 44.8516 258.682 46.0928C258.478 46.6811 258.318 47.1531 258.191 47.5087C258.141 47.6774 258.077 47.8416 258 48C258.02 47.8254 258.061 47.6541 258.121 47.4892C258.229 47.1207 258.369 46.6423 258.548 46.0476C259.083 44.2865 259.721 42.5597 260.459 40.8756C261.527 38.3349 263.101 36.0456 265.083 34.152C267.636 31.941 270.785 30.5582 274.121 30.1826C277.417 29.7766 280.759 30.0494 283.949 30.9842C286.543 31.7555 289.061 32.7685 291.471 34.0098C293.535 35.0507 295.153 35.9881 296.268 36.6346L297.541 37.391C297.692 37.4744 297.838 37.5694 297.975 37.6754'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M290.999 40.9978C290.942 41.0824 287.314 38.6882 282.895 35.6563C278.475 32.6245 274.943 30.0936 275.001 30.0025C275.058 29.9114 278.679 32.3122 283.099 35.344C287.519 38.3759 291.057 40.9132 290.999 40.9978Z'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M287 30.0016C286.203 30.5275 285.368 30.8143 284.524 30.8517C283.685 31.0705 282.835 31.0467 282 30.7837C282.824 30.5105 283.658 30.3519 284.494 30.3077C285.324 30.0889 286.162 29.9857 287 30.0016Z'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M271 28C270.781 28.7607 270.483 29.465 270.118 30.0857C269.806 30.7978 269.429 31.4421 269 32C269.216 31.2386 269.514 30.5364 269.882 29.9214C270.192 29.2036 270.568 28.5564 271 28Z'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M275 42.9804C274.39 42.7231 273.808 42.3984 273.266 42.0108C272.225 41.3561 270.815 40.4075 269.309 39.2815C267.804 38.1555 266.477 37.0884 265.499 36.3093C264.961 35.9218 264.459 35.4831 264 35C264.583 35.3169 265.136 35.6893 265.651 36.1123L269.5 38.9934C270.999 40.1129 272.377 41.1008 273.38 41.8079C273.943 42.1601 274.476 42.5581 274.975 43'
        fill='#D3DCF8'
      />
      <path d='M264.847 137H237.153L232 99H270L264.847 137Z' fill='#576675' />
      <path d='M273.352 105H228.648L228 96H274L273.352 105Z' fill='#576675' />
      <path
        opacity='0.2'
        d='M233 105H270L265.558 137L261.116 136.706L263.596 107.56L233.281 107.061L233 105Z'
        fill='#161A1D'
      />
      <path
        opacity='0.2'
        d='M264.238 96.0623L263 105H273.381L274 96L264.238 96.0623Z'
        fill='#161A1D'
      />
      <path
        d='M234.626 29.3354C231.98 27.2895 229.18 26.892 225.862 27.0224C222.539 27.2504 219.288 28.1059 216.272 29.544C211.088 31.8636 206.21 35.2713 203.109 40.106C200.009 44.9407 198.928 51.3327 201.249 56.5974C201.43 57.15 201.806 57.6146 202.304 57.9006C202.878 58.0687 203.492 58.0225 204.036 57.7703C209.937 55.8156 215.882 53.8608 221.297 50.7528C226.712 47.6448 231.66 43.4095 234.575 37.8255C235.323 36.5067 235.797 35.0452 235.969 33.5316C236.05 32.7758 235.972 32.0108 235.739 31.2882C235.506 30.5656 235.124 29.9023 234.62 29.342'
        fill='#5172E1'
      />
      <path
        opacity='0.3'
        d='M235 29C234.862 29.0717 234.717 29.1299 234.567 29.1744L233.321 29.6009L232.417 29.9046C232.087 30.0209 231.739 30.1695 231.358 30.3182C230.609 30.6219 229.73 30.9256 228.775 31.3779L227.315 32.0241C226.811 32.2567 226.301 32.541 225.765 32.8188C224.687 33.3487 223.595 34.0272 222.426 34.7121C219.947 36.2778 217.589 38.0296 215.374 39.9525C213.183 41.9059 211.144 44.0247 209.278 46.2914C208.451 47.3576 207.638 48.3527 206.973 49.3607C206.637 49.8583 206.288 50.3235 205.991 50.7952C205.694 51.2669 205.416 51.7257 205.145 52.1586C204.583 53.018 204.17 53.8451 203.769 54.5559C203.576 54.9178 203.388 55.2408 203.227 55.551L202.814 56.4104C202.575 56.9015 202.381 57.2892 202.226 57.5994C202.166 57.7409 202.09 57.8753 202 58C202.037 57.8501 202.092 57.7047 202.161 57.567C202.297 57.2504 202.465 56.8498 202.685 56.3522L203.066 55.4735C203.221 55.1568 203.401 54.8273 203.589 54.459C203.97 53.7417 204.37 52.8953 204.919 52.023L205.746 50.6401C206.043 50.162 206.391 49.6903 206.721 49.1863C207.367 48.1653 208.187 47.1508 209.013 46.0717C212.72 41.4568 217.199 37.521 222.252 34.4407C223.427 33.7558 224.532 33.0838 225.623 32.5604C226.166 32.2955 226.682 32.0111 227.192 31.7785L228.671 31.1323C229.608 30.6994 230.492 30.4086 231.254 30.1243C231.642 29.9822 231.997 29.8464 232.326 29.7366L233.243 29.4652L234.535 29.1034C234.682 29.0536 234.833 29.0187 234.987 29'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M214 40.0096C211.89 40.8574 209.739 41.5849 207.554 42.1898C205.402 42.9166 203.214 43.5209 201 44C203.11 43.1528 205.261 42.4247 207.446 41.8197C211.04 40.7161 213.974 39.9019 214 40.0096Z'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M225.972 48C225.882 48 225.601 44.4284 225.337 40.0099C225.073 35.5913 224.938 32.0066 225.028 32C225.118 31.9934 225.399 35.5651 225.663 39.9836C225.927 44.4022 226.062 47.9934 225.972 48Z'
        fill='#D3DCF8'
      />
      <path
        d='M230 15.6377L226.376 21.6859C226.295 21.8235 226.179 21.9798 226.01 21.9986C225.84 22.0174 225.677 21.8423 225.569 21.6984L216 8.15709L218.206 7L230 15.6377Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M230 15.6377L226.376 21.6859C226.295 21.8235 226.179 21.9798 226.01 21.9986C225.84 22.0174 225.677 21.8423 225.569 21.6984L216 8.15709L218.206 7L230 15.6377Z'
        fill='#07102C'
      />
      <path
        d='M248.363 27L216.793 8.59457C216.448 8.44551 216.173 8.17203 216.026 7.82984C215.901 7.36971 216.249 6.92254 216.59 6.57906C218.758 4.3529 221.388 2.61603 224.298 1.48643C227.209 0.356826 230.332 -0.138958 233.454 0.0334315C236.577 0.251834 239.6 1.21942 242.26 2.85193C244.92 4.48509 247.136 6.73394 248.717 9.40467C251.767 14.7772 251.866 21.8931 248.376 27'
        fill='#5172E1'
      />
      <path
        opacity='0.3'
        d='M221 3.11067C221.133 3.0719 221.271 3.05473 221.409 3.05981C221.676 3.05981 222.059 2.99624 222.592 3.0026C224.045 2.98099 225.497 3.09413 226.928 3.33945C231.699 4.12432 236.14 6.22728 239.728 9.40109C243.316 12.5743 245.899 16.6855 247.175 21.2485C247.567 22.6193 247.826 24.0238 247.948 25.443C247.982 25.7378 247.999 26.0353 248 26.3327C247.975 26.5704 247.893 26.7992 247.76 27C247.852 26.4903 247.868 25.9698 247.805 25.4557C247.178 20.0995 244.806 15.0826 241.038 11.1468C237.27 7.21104 232.307 4.56472 226.882 3.59999C225.467 3.33689 224.033 3.18566 222.592 3.1488C221.565 3.1488 221 3.1488 221 3.1488'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M244 14.0217C243.514 14.353 242.996 14.6199 242.457 14.8174C241.485 15.2586 240.12 15.8156 238.602 16.3653C237.084 16.915 235.68 17.3562 234.664 17.6383C234.123 17.8286 233.565 17.9501 233 18C233.511 17.7222 234.045 17.5038 234.594 17.349L238.487 15.9675L242.368 14.5208C242.894 14.2799 243.441 14.1049 244 14'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M217 8C217.738 7.65888 218.501 7.38072 219.282 7.16752C220.703 6.71405 222.692 6.13197 224.902 5.58374C227.112 5.03551 229.14 4.60237 230.607 4.33841C231.393 4.15228 232.194 4.03926 233 4C232.235 4.26125 231.453 4.46428 230.659 4.60912C229.205 4.94076 227.204 5.40777 224.993 5.96276C222.783 6.51775 220.795 7.04566 219.354 7.43145C218.584 7.68255 217.797 7.87276 217 8Z'
        fill='#D3DCF8'
      />
      <path
        d='M260.619 61.2826C257.92 60.5698 254.707 63.2052 254.154 65.9517C253.601 68.6983 254.623 71.5037 255.819 74.0344C258.479 79.6321 262.141 84.8244 266.967 88.6892C271.792 92.554 277.834 95.0324 284 94.9997C282.862 89.6766 284.306 84.1182 283.668 78.7166C283.11 73.9553 280.96 69.5248 277.572 66.1512C274.183 62.7775 269.757 60.6601 265.016 60.1447C263.292 59.9551 261.302 59.687 260.619 61.2826Z'
        fill='#5172E1'
      />
      <path
        opacity='0.3'
        d='M284 94.968C283.868 94.9239 283.741 94.8638 283.623 94.7889C283.274 94.6054 282.942 94.3912 282.631 94.1494C281.518 93.1844 280.626 91.991 280.014 90.6512C279.592 89.8326 279.118 88.9436 278.658 87.9523C278.197 86.961 277.737 85.9122 277.231 84.8058C276.717 83.6483 276.135 82.5227 275.485 81.4355C274.764 80.2849 273.957 79.1907 273.073 78.161C271.256 76.025 269.311 74.1831 267.622 72.4052C266.095 70.8652 264.704 69.1961 263.463 67.4169C262.632 66.212 261.951 64.9105 261.435 63.5413C261.297 63.171 261.179 62.793 261.083 62.4093C261.037 62.2776 261.01 62.1394 261 62C261.032 62 261.179 62.5436 261.569 63.4901C262.134 64.8229 262.843 66.0899 263.681 67.2698C264.939 69.0144 266.338 70.6529 267.865 72.1686C269.56 73.9081 271.518 75.75 273.354 77.9244C274.253 78.9694 275.068 80.0835 275.792 81.2564C276.449 82.3564 277.034 83.4967 277.545 84.6715C278.031 85.7843 278.496 86.8376 278.939 87.8308C279.381 88.8157 279.841 89.7494 280.219 90.536C280.796 91.8631 281.639 93.0577 282.695 94.0471C283.469 94.6866 283.974 94.9616 283.974 95'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M270 90C270.011 89.4375 270.109 88.8779 270.293 88.3339C270.54 87.3127 270.956 85.9153 271.503 84.3925C272.05 82.8697 272.651 81.5081 273.129 80.5347C273.343 79.999 273.635 79.4837 274 79C273.875 79.5524 273.682 80.0946 273.422 80.6183C273.029 81.6037 272.489 82.9653 271.934 84.4821C271.38 85.9989 270.925 87.3844 270.601 88.3876C270.462 88.9376 270.261 89.4769 270 90Z'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M283.98 78C283.334 77.9064 282.697 77.7566 282.076 77.552C280.908 77.2377 279.304 76.8097 277.51 76.3951C275.716 75.9806 274.085 75.6663 272.905 75.4322C272.259 75.3473 271.622 75.2042 271 75.0043C271.656 74.9836 272.312 75.0377 272.957 75.1648C274.157 75.3253 275.807 75.6061 277.601 76.0207C279.395 76.4353 281.006 76.91 282.167 77.2912C282.802 77.4584 283.416 77.6957 284 78'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M260 80.9697C260.028 80.3113 260.133 79.6558 260.314 79.0149C260.579 77.8045 260.993 76.1704 261.543 74.3729C262.093 72.5754 262.686 70.9716 263.15 69.8156C263.362 69.188 263.646 68.5798 264 68C263.868 68.6494 263.677 69.2885 263.429 69.9125C263.036 71.0806 262.493 72.6904 261.943 74.4818C261.393 76.2733 260.914 77.8892 260.6 79.0996C260.463 79.746 260.262 80.3821 260 81'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M278 65.9878C278 66.0482 274.621 65.8789 270.48 65.6088C267.972 65.4846 265.475 65.2814 263 65C265.516 65.048 268.025 65.1746 270.52 65.379C273.028 65.5032 275.525 65.7063 278 65.9878Z'
        fill='#D3DCF8'
      />
      <path
        d='M247.881 35.3128C247.649 32.8717 245.123 31.0262 242.611 31.0003C240.098 30.9744 237.758 32.4443 236.22 34.3868C234.682 36.3294 233.84 38.6993 233.144 41.0562C231.453 46.8126 230.525 52.8604 231.248 58.8046C231.97 64.7487 234.443 70.6023 238.812 74.8046C239.521 75.4522 240.443 76.1709 241.417 75.9637C241.94 75.8083 242.414 75.5227 242.79 75.1349C247.068 71.312 250.46 66.6388 252.736 61.4302C255.013 56.221 256.122 50.5967 255.989 44.9348C255.916 42.675 255.618 40.3245 254.332 38.4597C253.046 36.5949 250.062 34.5746 247.881 35.3322'
        fill='#5172E1'
      />
      <path
        opacity='0.3'
        d='M240.146 74.9936C240.114 74.8584 240.097 74.7199 240.097 74.5815C240.097 74.266 240.063 73.8733 240.042 73.3905C239.993 72.3539 239.979 70.8538 240.042 68.9997C240.104 67.1455 240.215 64.9436 240.458 62.5036C240.7 60.0636 241.089 57.3531 241.629 54.604C242.17 51.8549 242.842 49.1896 243.529 46.8139C244.215 44.4383 244.915 42.3073 245.608 40.5883C246.302 38.8693 246.856 37.4336 247.293 36.4743C247.501 36.0301 247.667 35.6631 247.799 35.3734C247.859 35.2459 247.926 35.121 248 35C247.979 35.1397 247.937 35.2762 247.875 35.4056L247.418 36.5323C247.023 37.5044 246.482 38.9144 245.858 40.6784C245.234 42.4425 244.541 44.5413 243.861 46.9105C243.182 49.2797 242.551 51.8936 242.004 54.6749C241.456 57.4561 241.082 60.1086 240.804 62.5422C240.527 64.9758 240.375 67.1712 240.305 69.0125C240.236 70.8538 240.194 72.3604 240.194 73.3905C240.194 73.8798 240.194 74.2725 240.194 74.5879C240.202 74.7264 240.191 74.8648 240.16 75'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M241.981 55C241.638 55.4853 241.241 55.9282 240.796 56.3203C240.031 57.0959 238.944 58.1403 237.71 59.2474C236.475 60.3544 235.302 61.3113 234.444 61.9805C234.001 62.3727 233.517 62.7142 233 63C233.388 62.5541 233.819 62.1482 234.284 61.7866L237.475 58.9784L240.642 56.132C241.061 55.7149 241.515 55.3359 242 55'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M251.974 63C251.486 62.4066 251.043 61.7772 250.649 61.1172L247.497 56.5L244.285 51.9294C243.804 51.3233 243.374 50.6779 243 50C243.552 50.5442 244.055 51.1363 244.503 51.7697C245.384 52.894 246.57 54.4841 247.815 56.2805C249.06 58.0768 250.132 59.7467 250.874 60.9708C251.31 61.5995 251.687 62.2682 252 62.9667'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M246.999 38.003C247.058 38.0912 243.726 40.4049 239.601 43.1597C235.477 45.9144 232.06 48.0856 232.001 47.9974C231.942 47.9092 235.274 45.5955 239.399 42.834C243.523 40.0724 246.947 37.908 246.999 38.003Z'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M256 48C252.492 45.169 249.154 42.1642 246 39C247.799 40.3661 249.513 41.8255 251.134 43.3702C252.85 44.8271 254.474 46.3731 256 48Z'
        fill='#D3DCF8'
      />
      <path
        d='M272.056 116V136.076H251.972V116H250.028V136.076H229.944V116H228V184H229.944V141.905H250.028V184H251.972V141.905H272.056V184H274V141.905V136.076V116H272.056Z'
        fill='#2B333B'
      />
      <path
        d='M10.2671 134.517C6.78337 138.761 4.33455 143.757 3.1168 149.112C2.5153 151.785 2.46667 154.552 2.97382 157.245C3.48097 159.937 4.5321 162.497 6.06535 164.769C9.01389 168.734 13.5251 171.167 18.1101 172.966C39.8114 181.428 64.0336 177.978 87.3124 178.597C115.324 179.349 143.983 186.087 170.653 177.713C176.845 175.767 183.066 172.789 186.723 167.393C189.636 162.703 190.859 157.157 190.187 151.677C189.494 146.239 188.183 140.896 186.28 135.755L175.488 102.82C146.799 100.49 148.952 98.8688 120.218 101.11C105.991 105.046 83.3614 97.9547 51.6056 106.196C37.541 109.926 19.2012 122.914 10.2671 134.517Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.3'
        d='M173.411 155.451C173.411 155.451 163.356 172.022 140.977 158.4L159.538 179.703L173.057 176.548L179.072 174.027L173.411 155.451Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M110.017 162.823C110.017 162.823 113.452 178.361 118.863 180.514L132.367 181.192L110.017 162.823Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M175.55 102.863C160.41 101.639 153.849 100.607 146.817 100.224C138.356 100.967 129.863 101.247 121.371 101.064L120.28 101.152C118.217 101.696 116.111 102.052 113.985 102.214C112.334 102.347 110.063 114.008 113.985 119.463L116.476 122.131L179.649 115.482L175.55 102.863Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M31.0542 153.475C36.6123 158.93 43.9246 162.232 51.3844 164.459C58.8442 166.685 66.5694 168.174 73.7344 171.225C83.1255 175.221 113.068 178.11 115.014 180.528C105.756 179.747 96.4971 178.847 87.3124 178.597C64.0336 177.978 39.8114 181.427 18.1101 172.965C13.5251 171.166 8.9844 168.734 6.06535 164.768C4.5321 162.496 3.48097 159.936 2.97382 157.243C2.46667 154.551 2.5153 151.784 3.1168 149.111C4.33455 143.757 6.78337 138.761 10.2671 134.516C16.6772 126.72 24.3522 120.058 32.9708 114.805C32.9708 114.805 16.9013 139.647 31.0542 153.475Z'
        fill='black'
      />
      <path
        d='M114.208 165.404C114.96 168.809 126.592 178.805 129.688 180.383C132.875 181.776 136.18 182.88 139.565 183.683C143.288 185.025 147.082 186.16 150.932 187.089C154.825 188.018 158.925 187.487 162.461 185.615C165.851 183.58 168.063 179.292 166.883 175.532C162.506 174.015 158.405 171.799 154.735 168.972C151.072 166.15 147.088 163.769 142.867 161.88C138.563 160.2 124.734 152.312 120.532 154.229C116.331 156.146 113.19 160.893 114.208 165.404Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.3'
        d='M115.15 122.133C115.15 122.133 149.25 152.914 182.716 124.726L179.074 113.095L115.15 122.133Z'
        fill='black'
      />
      <path
        d='M193.255 180.278C194.141 179.908 194.906 179.3 195.467 178.523C196.277 177.123 195.629 175.368 195.275 173.776C194.67 171.019 192.164 162.247 192.326 159.417C192.489 156.586 189.938 145.957 188.198 143.716C185.795 140.62 184.734 149.126 181.21 150.851C179.493 151.807 177.986 153.101 176.78 154.653C175.574 156.206 174.694 157.987 174.193 159.889C173.117 163.383 174.429 171.58 175.181 175.162C175.601 177.987 177.122 180.531 179.412 182.239C181.576 183.459 184.134 183.787 186.532 183.138C188.887 182.477 191.147 181.516 193.255 180.278Z'
        fill='#4D61FC'
      />
      <path
        d='M90.7946 210.427C83.6886 210.176 76.4499 208.864 70.6266 205.37C66.9556 203.173 64.0071 200.195 60.7047 197.586C53.8199 192.116 45.4608 188.298 37.2491 184.435C29.0375 180.571 15.5478 179.348 9.00207 173.583C5.95033 171.099 3.54429 167.915 1.98893 164.3C0.433577 160.686 -0.22539 156.75 0.0679898 152.826C0.539756 144.688 10.152 126.289 19.4104 124.918C16.9631 128.544 15.1646 138.805 14.0146 142.874C12.7276 146.927 13.0121 151.316 14.8107 155.17C16.6978 158.914 20.2508 161.804 24.0544 164.192C35.6569 171.475 49.8246 174.807 63.2847 179.068C76.7448 183.328 90.4113 189.138 98.2987 199.503C100.141 201.935 101.616 205.149 99.8761 207.655C98.1365 210.161 94.1559 210.545 90.7946 210.427Z'
        fill='#3F4249'
      />
      <path
        d='M151.857 181.767C151.051 182.824 150.672 184.141 150.796 185.468C150.957 186.072 151.25 186.618 151.657 187.089C152.062 187.561 152.571 187.93 153.14 188.166C154.284 188.637 155.503 188.888 156.737 188.918C162.425 189.33 168.142 188.77 173.632 187.222C174.871 186.883 176.316 181.664 174.974 179.851C172.898 177.075 169.81 175.232 166.379 174.72C163.544 174.434 160.684 174.941 158.12 176.186C155.555 177.43 153.388 179.363 151.857 181.767Z'
        fill='#DADDDF'
      />
      <path
        d='M198.565 175.914C197.044 174.919 195.335 174.248 193.544 173.94C191.754 173.634 189.919 173.698 188.154 174.129C186.388 174.561 184.731 175.35 183.283 176.448C181.835 177.546 180.629 178.931 179.739 180.514C179.4 180.999 179.167 181.552 179.058 182.133C178.949 182.715 178.965 183.314 179.105 183.89C179.465 184.539 179.976 185.099 180.594 185.512C181.212 185.925 181.918 186.19 182.658 186.278C186.914 187.34 191.343 187.531 195.676 186.839C198.182 186.441 200.968 185.453 201.897 183.094C202.929 180.514 200.954 177.477 198.565 175.914Z'
        fill='#DADDDF'
      />
      <path
        d='M176.904 118.049C178.714 116.703 180.221 114.99 181.326 113.022C181.875 111.589 182.099 110.051 181.978 108.521C181.859 106.991 181.399 105.506 180.632 104.176C179.276 101.39 177.243 98.9132 175.68 96.2301C172.319 90.4951 170.754 83.4776 165.049 79.5265C160.464 76.3569 154.272 75.9441 148.478 76.0325C140.93 76.1505 132.954 77.035 126.895 81.1188C122.624 84.1514 119.379 88.4149 117.592 93.3405C115.94 98.0847 114.803 102.992 114.201 107.98C113.73 110.928 113.289 114.054 114.601 116.826C115.72 118.775 117.225 120.476 119.024 121.823C121.511 124.151 124.262 126.18 127.221 127.868C134.489 131.657 143.436 131.45 151.854 130.816C155.904 130.744 159.905 129.914 163.649 128.369C165.919 127.163 168.029 125.677 169.929 123.946L176.904 118.049Z'
        fill='#4D61FC'
      />
      <path
        d='M174.094 91.7187C166.177 80.2046 157.287 78.7156 152.938 76.976C148.589 75.2363 169.7 59.6238 174.359 61.098C177.131 61.8942 175.774 94.1512 174.094 91.7187Z'
        fill='#61656D'
      />
      <path
        d='M117.731 69.8552C116.448 69.5751 111.834 67.7617 111.495 71.0051C111.17 75.133 114.045 107.213 115.682 102.923C118.026 96.8196 125.869 82.5782 135.393 80.47C138.592 79.7771 120.34 70.4449 117.731 69.8552Z'
        fill='#61656D'
      />
      <path
        d='M173.178 64.7233C176.746 60.7575 175.375 83.3286 173.178 90.4641C168.092 84.7144 162.328 80.5422 159.364 79.3923C156.401 78.2424 169.596 68.6891 173.178 64.7233Z'
        fill='#DADDDF'
      />
      <path
        d='M112.54 72.0367C112.54 76.0025 115.165 104.471 116.477 101.522C119.543 94.3719 124.6 88.5191 127.843 84.7302C129.745 82.4451 112.585 68.0709 112.54 72.0367Z'
        fill='#DADDDF'
      />
      <path
        d='M166.72 109.278C179.31 98.0141 167.516 93.5913 155.279 84.8194C153.584 83.5958 152.449 81.6792 150.532 80.8241C144.635 78.1852 140.625 82.2983 137.264 87.1487C134.548 90.942 131.284 94.3107 127.578 97.1443C119.926 103.248 124.054 115.735 135.273 116.501C145.24 111.061 158.287 116.015 166.72 109.278Z'
        fill='#3F4249'
      />
      <path
        d='M141.314 103.292C141.314 103.292 140.665 99.4587 135.711 99.4292C130.758 99.3997 130.537 102.378 130.537 102.378C130.537 102.378 131.701 106.697 135.343 106.801C136.575 106.883 137.805 106.593 138.869 105.968C139.935 105.341 140.786 104.409 141.314 103.292Z'
        fill='white'
      />
      <path
        d='M135.714 99.43C134.71 99.4034 133.709 99.5686 132.766 99.9165C132.506 100.468 132.385 101.075 132.412 101.686C132.429 102.199 132.547 102.703 132.76 103.17C132.972 103.638 133.276 104.059 133.65 104.409C134.025 104.759 134.465 105.032 134.946 105.212C135.427 105.393 135.938 105.477 136.451 105.46C136.964 105.442 137.469 105.324 137.936 105.112C138.403 104.898 138.825 104.596 139.176 104.221C139.525 103.845 139.799 103.406 139.979 102.925C140.159 102.445 140.243 101.933 140.225 101.42C140.225 101.317 140.225 101.229 140.225 101.14C139.64 100.548 138.934 100.089 138.156 99.7941C137.377 99.4993 136.546 99.3754 135.714 99.43Z'
        fill='black'
      />
      <path
        d='M136.555 101.966C136.571 102.093 136.561 102.221 136.524 102.343C136.485 102.466 136.423 102.579 136.338 102.675C136.254 102.771 136.151 102.847 136.034 102.9C135.918 102.952 135.791 102.98 135.663 102.98C135.536 102.98 135.409 102.952 135.293 102.9C135.176 102.847 135.072 102.771 134.987 102.675C134.903 102.579 134.84 102.466 134.803 102.343C134.766 102.221 134.755 102.093 134.771 101.966C134.759 101.723 134.84 101.484 135.001 101.299C135.16 101.115 135.384 101 135.626 100.978C135.754 100.978 135.879 101.003 135.996 101.053C136.112 101.103 136.217 101.177 136.306 101.27C136.393 101.363 136.459 101.472 136.502 101.591C136.546 101.712 136.563 101.839 136.555 101.966Z'
        fill='white'
      />
      <path
        d='M155.559 101.391C155.559 101.391 155.943 97.5285 160.881 97.1599C165.82 96.7913 166.248 99.7694 166.248 99.7694C166.248 99.7694 165.378 104.192 161.825 104.458C160.592 104.642 159.333 104.446 158.214 103.899C157.095 103.352 156.17 102.476 155.559 101.391Z'
        fill='white'
      />
      <path
        d='M164.772 99.3274C164.748 98.723 164.588 98.1332 164.3 97.6025C163.215 97.1705 162.039 97.0187 160.88 97.1602C159.466 97.1971 158.108 97.7175 157.032 98.6345C156.995 98.947 156.995 99.264 157.032 99.578C157.05 100.091 157.168 100.595 157.38 101.063C157.592 101.53 157.896 101.95 158.27 102.301C158.645 102.652 159.086 102.925 159.566 103.104C160.047 103.286 160.558 103.37 161.072 103.352C161.585 103.334 162.089 103.217 162.556 103.004C163.023 102.79 163.445 102.488 163.796 102.114C164.145 101.739 164.42 101.299 164.599 100.818C164.779 100.337 164.863 99.8257 164.846 99.3127L164.772 99.3274Z'
        fill='black'
      />
      <path
        d='M161.147 99.8711C161.141 100.047 161.083 100.216 160.983 100.359C160.881 100.502 160.741 100.613 160.578 100.678C160.416 100.742 160.237 100.757 160.065 100.722C159.894 100.686 159.736 100.601 159.612 100.477C159.488 100.353 159.403 100.195 159.367 100.024C159.332 99.852 159.347 99.6736 159.412 99.5115C159.475 99.3478 159.586 99.2077 159.73 99.106C159.873 99.0057 160.043 98.9482 160.218 98.9423C160.464 98.9423 160.7 99.0397 160.874 99.2136C161.048 99.3891 161.147 99.6249 161.147 99.8711Z'
        fill='white'
      />
      <path
        d='M142.469 123.268C143.391 124.492 144.585 125.485 145.956 126.169C147.327 126.853 148.838 127.209 150.372 127.209C151.903 127.209 153.416 126.853 154.787 126.169C156.158 125.485 157.352 124.492 158.274 123.268L153.851 120.85C153.015 120.358 152.117 119.976 151.182 119.715C149.57 119.438 147.911 119.718 146.48 120.511C145.041 121.273 143.696 122.198 142.469 123.268Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M158.274 123.193C153.158 119.581 147.113 117.871 142.469 123.193C142.792 123.613 143.146 124.008 143.531 124.372C148.249 123.724 152.627 121.984 157.168 124.372C157.565 124.007 157.935 123.613 158.274 123.193Z'
        fill='black'
      />
      <path
        d='M145.797 90.5837C144.042 118.108 137.526 108.024 134.268 117.283C133.905 118.241 133.838 119.286 134.07 120.284C134.305 121.282 134.83 122.188 135.58 122.885C140.268 126.983 148.347 118.683 157.09 122.487C158.24 123.055 159.518 123.312 160.798 123.234C162.077 123.158 163.314 122.746 164.387 122.045C170.461 117.209 162.913 110.693 156.043 108.452C151.296 106.801 151.178 83.2124 145.797 90.5837Z'
        fill='white'
      />
      <path
        d='M149.587 118.196C150.206 118.196 153.7 115.793 153.848 114.584C153.995 113.375 153.111 112.078 149.543 112.181C145.975 112.284 144.928 113.523 145.223 115.13C145.518 116.737 148.879 118.24 149.587 118.196Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M151.445 112.698C150.332 112.617 149.213 112.617 148.098 112.698C147.755 112.727 147.414 112.786 147.081 112.875C146.933 112.875 146.757 113.037 146.771 113.184C146.786 113.332 146.992 113.405 147.155 113.42C149.056 113.612 151.017 113.096 152.875 113.582C152.862 113.426 152.807 113.274 152.716 113.144C152.626 113.016 152.503 112.913 152.359 112.845C152.065 112.745 151.756 112.695 151.445 112.698Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M203.74 112.419L202.266 112.242L201.47 112.168H200.629L197.076 111.903H196.162H195.72H195.263H193.509L190.368 111.8H187.243H184.294C183.321 111.8 182.348 111.8 181.346 111.918L178.206 112.08C177.1 112.08 175.862 112.271 174.55 112.404L172.559 112.611L171.527 112.714H171.011L170.51 112.787L166.471 113.377C165.822 113.48 165.188 113.554 164.583 113.672L162.859 113.996L159.025 114.719C158.126 114.896 159.689 114.719 162.726 114.173L164.2 113.923L164.967 113.79L165.792 113.687L169.522 113.186L170.539 113.038L171.041 112.964H171.542L173.606 112.743L175.744 112.522C176.481 112.448 177.218 112.36 178.014 112.33L181.199 112.168C182.275 112.168 183.366 112.036 184.442 112.05H187.641C188.673 112.05 189.676 112.05 190.59 112.05L195.528 112.183C197.298 112.271 199.126 112.419 200.836 112.522C201.249 112.522 201.676 112.522 202.074 112.611L203.254 112.728L205.318 112.95L207.824 113.23C208.664 113.112 207.426 112.876 203.74 112.419Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M203.988 120.806L202.514 120.437C202.257 120.362 201.996 120.303 201.732 120.26L200.907 120.083L197.383 119.346L196.499 119.155L196.056 119.066L195.599 118.993L193.86 118.698L190.749 118.182L189.938 118.064L189.157 117.96L187.683 117.769L184.734 117.415C183.761 117.282 182.773 117.238 181.785 117.15L178.63 116.914C177.525 116.796 176.272 116.796 174.945 116.752H172.925H170.846H166.763C166.099 116.752 165.451 116.752 164.831 116.752L163.077 116.84L159.17 117.076C158.241 117.076 159.819 117.076 162.915 117.076H164.389H165.185H171.701H173.78H175.947C176.684 117.076 177.422 117.076 178.232 117.179L181.432 117.43C182.508 117.533 183.599 117.577 184.675 117.739L187.859 118.137C188.906 118.255 189.909 118.373 190.808 118.535L195.717 119.317C197.472 119.641 199.27 120.01 200.966 120.334L202.189 120.585L203.339 120.865L205.374 121.351L207.836 121.956C208.824 122.133 207.6 121.735 203.988 120.806Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M204.359 130.006L203.003 129.417C202.767 129.299 202.516 129.21 202.266 129.107L201.484 128.812L198.197 127.529L197.356 127.205L196.929 127.058L196.516 126.91L194.865 126.35L191.916 125.362L191.165 125.112L190.413 124.891L188.938 124.463L186.122 123.652C185.194 123.357 184.25 123.151 183.277 122.915L180.329 122.178C179.252 121.898 178.044 121.706 176.761 121.441L174.8 121.072L173.798 120.881L173.296 120.792H172.795L168.756 120.07L166.883 119.804L165.158 119.613L161.311 119.229C160.411 119.126 161.93 119.406 164.982 119.76L166.382 119.922L167.149 120.011L167.959 120.143L171.63 120.733L172.633 120.881H173.134L173.635 120.984L175.64 121.367L177.734 121.765C178.456 121.913 179.208 122.03 179.945 122.222L183.012 122.974C184.044 123.239 185.105 123.475 186.122 123.8L189.159 124.684C190.147 124.979 191.106 125.23 192.005 125.554L196.649 127.028C198.3 127.618 199.995 128.267 201.588 128.856L202.738 129.284L203.814 129.741L205.716 130.537L208.015 131.51C208.826 132.056 207.721 131.481 204.359 130.006Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M188.11 135.769C187.594 135.312 186.989 134.781 186.311 134.294L185.294 133.469L184.218 132.658C182.744 131.567 181.269 130.55 180.09 129.798C178.704 128.913 177.451 128.176 176.168 127.468C175.534 127.1 174.886 126.776 174.208 126.436C173.529 126.097 172.851 125.758 172.099 125.405C171.347 125.051 170.492 124.682 169.593 124.299C169.136 124.092 168.664 123.93 168.119 123.739C167.573 123.547 167.161 123.37 166.645 123.208C165.657 122.869 164.684 122.515 163.696 122.235C163.224 122.087 162.782 121.94 162.34 121.822L161.072 121.468C160.453 121.276 158.86 120.923 158.241 120.761C157.902 120.687 158.02 120.761 158.492 120.878C158.728 120.967 159.052 121.055 159.465 121.173C159.878 121.291 160.364 121.409 160.939 121.586L161.956 121.881L162.517 122.043L163.106 122.25C163.917 122.515 164.816 122.795 165.775 123.164C167.78 123.854 169.748 124.645 171.672 125.537C174.516 126.879 177.27 128.405 179.913 130.107C180.37 130.402 180.871 130.712 181.387 131.066L182.98 132.157C184.056 132.923 185.176 133.734 186.164 134.53L186.901 135.105L187.579 135.665C188.021 136.034 188.434 136.358 188.773 136.668L190.248 137.921C190.498 138.142 190.469 138.083 190.115 137.729L189.348 136.978L188.773 136.432L188.11 135.769Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M103 117.224C104.651 116.929 106.833 116.678 109 116.369C110.091 116.221 111.182 116.103 112.214 116L115.03 115.69L115.752 115.602H116.475L117.846 115.484L120.514 115.263C121.399 115.174 122.269 115.13 123.183 115.071L126.013 114.894C127.016 114.821 128.136 114.806 129.33 114.747L133.001 114.585H136.687H138.427H140.004H143.528C144.368 114.585 142.953 114.467 140.152 114.408H138.869H133.989C133.37 114.408 132.765 114.408 132.146 114.408H130.259L128.313 114.496L126.249 114.585L123.374 114.762C122.401 114.762 121.414 114.865 120.426 114.953L117.477 115.189C116.534 115.263 115.62 115.337 114.765 115.44L110.342 115.956L105.565 116.649L104.445 116.826L103.398 117.017L101.54 117.342L99.2848 117.725C98.5477 117.843 99.6976 117.74 103 117.224Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M95.7909 123.489L97.2651 123.047C97.5261 122.955 97.7914 122.882 98.0612 122.826L98.8868 122.605L102.425 121.676L103.324 121.44L103.767 121.322L104.224 121.219L105.978 120.835L109.118 120.142L109.929 119.966L110.725 119.833L112.199 119.553L115.148 119.037C116.121 118.845 117.124 118.742 118.097 118.595L121.281 118.182C122.401 118.005 123.669 117.916 125.011 117.784L127.06 117.577L128.107 117.474H128.638H129.168L133.34 117.238C134.019 117.238 134.667 117.15 135.301 117.15H137.1H141.095C142.039 117.15 140.447 117.032 137.262 117.047H135.139H134.284L130.421 117.12H129.375H128.859H128.328L126.205 117.327L124.008 117.533C123.257 117.533 122.534 117.666 121.679 117.784L118.436 118.211C117.345 118.359 116.224 118.477 115.133 118.683L111.905 119.243C110.843 119.42 109.826 119.582 108.882 119.818L103.929 120.924C102.16 121.381 100.346 121.897 98.6509 122.398L97.4125 122.737L96.2626 123.121L94.2281 123.769L91.7661 124.565C90.8816 124.904 92.1642 124.61 95.7909 123.489Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M111.949 126.292C113.202 125.79 114.898 125.274 116.593 124.699C117.433 124.405 118.288 124.169 119.085 123.933L121.281 123.284L121.856 123.122L122.416 122.975L123.507 122.694L125.601 122.164C126.294 121.972 127.001 121.839 127.709 121.677L129.965 121.191C130.746 121.014 131.645 120.866 132.589 120.689L134.063 120.409L134.8 120.277L135.552 120.144L138.501 119.702C138.972 119.702 139.444 119.554 139.886 119.51L141.169 119.377L144 119.053C144.678 119.053 143.528 119.053 141.272 119.2L140.226 119.304H139.651L139.046 119.392L136.304 119.746L134.83 119.967L133.355 120.247L131.807 120.542C131.277 120.645 130.717 120.734 130.156 120.866L127.871 121.353C127.09 121.515 126.308 121.662 125.542 121.869L123.257 122.444C122.52 122.621 121.782 122.798 121.134 123.004L117.655 124.066C116.416 124.478 115.148 124.935 113.969 125.348L113.099 125.658L112.303 125.982L110.829 126.542L109.118 127.22C108.499 127.442 109.399 127.25 111.949 126.292Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M108.307 136.287C109.649 135.476 111.492 134.532 113.305 133.545C114.205 133.043 115.133 132.601 116.003 132.159L118.392 130.965L118.996 130.655L119.615 130.375L120.795 129.815L123.065 128.753C123.817 128.4 124.539 128.09 125.365 127.736L127.827 126.689C128.682 126.291 129.67 125.923 130.775 125.495C131.881 125.068 132.898 124.611 133.989 124.198L137.247 122.974L138.722 122.414L140.196 121.942L143.336 120.881C144.088 120.63 142.791 120.881 140.284 121.751L139.12 122.134L138.486 122.326L137.822 122.561L134.8 123.638L133.164 124.257L131.527 124.92L129.832 125.613C129.242 125.849 128.638 126.085 128.033 126.35L125.542 127.427C124.687 127.795 123.832 128.134 122.991 128.532L120.529 129.682C119.718 130.051 118.937 130.405 118.215 130.773L114.47 132.69C113.128 133.412 111.772 134.164 110.489 134.886L109.561 135.417L108.706 135.933L107.231 136.847L105.388 137.938C104.607 138.366 105.595 137.894 108.307 136.287Z'
        fill='white'
      />
    </>
  ),
});

export default DressedAndPlant;
