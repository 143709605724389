import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, Box, List, useOutsideClick } from '@chakra-ui/react';

const ListOptions = ({
  options,
  input,
  onSelectOption,
  handleDisplayOption,
  inputList,
}) => {
  useOutsideClick({
    ref: inputList,
    handler: () => handleDisplayOption(input),
  });

  return (
    <List
      position='absolute'
      bg='white'
      w='100%'
      borderRadius='md'
      boxShadow='md'
      mt={2}
      px={6}
      pt={4}
      pb={2}
      ref={inputList}
      zIndex={1}
    >
      <Box mb={2} textAlign='left'>
        {options.map(item => (
          <ListItem
            fontSize='sm'
            key={item.id}
            onClick={() => onSelectOption(item, input)}
            cursor='pointer'
            borderRadius='md'
            mb={1}
            _hover={{ color: 'alpha.400' }}
          >
            {item.name}
          </ListItem>
        ))}
      </Box>
    </List>
  );
};

ListOptions.defaultProps = {
  options: [],
};

ListOptions.propTypes = {
  options: PropTypes.array,
  input: PropTypes.string.isRequired,
  onSelectOption: PropTypes.func.isRequired,
  handleDisplayOption: PropTypes.func.isRequired,
  inputList: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any,
    }),
  ]).isRequired,
};

export default ListOptions;
