import React from 'react';
import getConfig from 'next/config';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { Flex, Box, Text, Heading, Link } from '@chakra-ui/react';
import { DocumentLoupe } from '@/components/illustrations';
import { DocumentIcon } from 'chakra-ui-ionicons';

const { publicRuntimeConfig } = getConfig();

const Preview = ({ uuid, course, term, year, professor }) => {
  const { t } = useTranslation();
  const { URL_GOOGLE_PREVIEW, URL_OUTLINE_PREVIEW } = publicRuntimeConfig;

  return (
    <Link
      h='fit-content'
      href={`${URL_GOOGLE_PREVIEW}?url=${URL_OUTLINE_PREVIEW}/${uuid}`}
      isExternal
    >
      <Flex flexDirection='column' display={{ base: 'none', md: 'flex' }}>
        <Flex
          direction='column'
          bg='alpha.100'
          roundedTop='lg'
          w={{ md: 180, lg: 320 }}
          minH={{ md: 32, lg: 200 }}
          justifyContent='center'
          alignItems='center'
        >
          <DocumentLoupe w={{ md: 20, lg: 118 }} h={{ md: 16, lg: 118 }} />
          <Text color='alpha.300' fontWeight={600} as='u'>
            {t('outlines:preview.button')}
          </Text>
        </Flex>
        <Box
          bg='white'
          w={{ md: 180, lg: 320 }}
          minH={{ md: 10, lg: 20 }}
          py={6}
          px={4}
        >
          <Flex>
            <DocumentIcon w={4} h={4} color='alpha.500' />
            <Heading fontSize='sm' fontWeight={600} color='black' mx={2}>
              {course}
            </Heading>
          </Flex>
          <Text
            fontSize='xs'
            pt={2}
            fontWeight={400}
            display={{ md: 'none', lg: 'block' }}
          >
            {t('outlines:courseDictatedOn', { term, year })} - {professor}
          </Text>
        </Box>
      </Flex>
      <Flex display={{ base: 'flex', md: 'none' }} color='alpha.500'>
        <DocumentIcon w={4} h={4} />
        <Heading fontSize='sm' fontWeight={600} mx={2}>
          {t('outlines:preview.title')}
        </Heading>
      </Flex>
    </Link>
  );
};

Preview.propTypes = {
  course: PropTypes.string.isRequired,
  term: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  professor: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default Preview;
