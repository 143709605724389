import PropTypes from 'prop-types';
import { FormControl, FormLabel, Checkbox, Flex } from '@chakra-ui/react';

const CheckboxForm = ({ id, label, register }) => {
  return (
    <FormControl id={id}>
      <Flex alignItems='center' mb={4}>
        <Checkbox borderColor='gray.400' {...register(id)} mr={2} />
        <FormLabel htmlFor={id} color='gray.800' fontSize='md' m={0}>
          {label}
        </FormLabel>
      </Flex>
    </FormControl>
  );
};

CheckboxForm.defaultProps = {
  label: '',
};

CheckboxForm.propTypes = {
  id: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default CheckboxForm;
