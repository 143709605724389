import React from 'react';
import { createIcon } from '@chakra-ui/react';

const SlideWelcome = createIcon({
  displayName: 'SlideWelcome',
  viewBox: '0 0 704 419',
  defaultProps: {
    w: '704px',
    h: '419px',
    fill: 'none',
  },
  path: (
    <>
      <path
        opacity='0.2'
        d='M310.891 49.6878C607.804 -68.0744 657.7 49.6876 680.886 132.95C700.609 203.779 716.783 322.241 689.64 418H18C-31.9425 342.887 9.43976 169.25 310.891 49.6878Z'
        fill='#163083'
      />
      <path
        d='M592.152 225.865H335.014C330.129 225.865 326.168 229.825 326.168 234.711V406.557C326.168 411.444 330.129 415.403 335.014 415.403H592.152C597.039 415.403 600.998 411.444 600.998 406.557V234.711C600.998 229.825 597.039 225.865 592.152 225.865Z'
        fill='#414D58'
      />
      <path
        d='M315.058 401.384H612.812V407.164C612.812 410.029 611.67 412.78 609.649 414.807C607.621 416.829 604.871 417.97 602.005 417.97H325.865C322.999 417.97 320.25 416.829 318.224 414.807C316.197 412.78 315.058 410.029 315.058 407.164V401.384Z'
        fill='#414D58'
      />
      <path
        d='M593.239 233.393H333.933V393.455H593.239V233.393Z'
        fill='white'
      />
      <path
        opacity='0.3'
        d='M600.998 402.064C600.998 402.155 539.468 402.234 463.592 402.234C387.716 402.234 326.168 402.155 326.168 402.064C326.168 401.973 387.686 401.924 463.592 401.924C539.498 401.924 600.998 401.973 600.998 402.064Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M434.469 402.531H493.407L490.28 406.757H437.723L434.469 402.531Z'
        fill='white'
      />
      <path
        d='M593.251 233.35H333.763V239.464H593.251V233.35Z'
        fill='#868A91'
      />
      <path
        d='M339.713 236.41C339.724 236.652 339.662 236.892 339.535 237.099C339.409 237.306 339.223 237.47 339.002 237.57C338.781 237.671 338.536 237.703 338.297 237.662C338.058 237.621 337.836 237.51 337.661 237.343C337.486 237.176 337.364 236.96 337.313 236.723C337.262 236.485 337.282 236.239 337.372 236.014C337.462 235.788 337.618 235.596 337.819 235.46C338.019 235.324 338.256 235.251 338.498 235.251C338.655 235.245 338.811 235.271 338.957 235.326C339.103 235.382 339.237 235.466 339.35 235.574C339.463 235.682 339.554 235.812 339.616 235.955C339.678 236.099 339.711 236.254 339.713 236.41Z'
        fill='#4D61FC'
      />
      <path
        d='M344.6 236.41C344.611 236.652 344.549 236.892 344.423 237.099C344.296 237.306 344.111 237.47 343.89 237.57C343.669 237.671 343.424 237.703 343.185 237.662C342.945 237.621 342.725 237.51 342.549 237.343C342.374 237.176 342.252 236.96 342.201 236.723C342.15 236.485 342.17 236.239 342.26 236.014C342.351 235.788 342.506 235.596 342.706 235.46C342.907 235.324 343.144 235.251 343.386 235.251C343.543 235.245 343.699 235.271 343.845 235.326C343.991 235.382 344.125 235.466 344.238 235.574C344.352 235.682 344.441 235.812 344.504 235.955C344.566 236.099 344.599 236.254 344.6 236.41Z'
        fill='white'
      />
      <path
        opacity='0.3'
        d='M349.487 236.41C349.498 236.652 349.436 236.892 349.309 237.098C349.183 237.305 348.997 237.469 348.777 237.57C348.556 237.67 348.311 237.702 348.071 237.662C347.832 237.621 347.611 237.51 347.436 237.343C347.26 237.175 347.139 236.959 347.088 236.722C347.036 236.485 347.057 236.238 347.147 236.014C347.237 235.788 347.392 235.595 347.593 235.459C347.794 235.323 348.031 235.25 348.273 235.25C348.429 235.246 348.584 235.272 348.731 235.328C348.876 235.383 349.01 235.468 349.123 235.576C349.236 235.684 349.326 235.813 349.389 235.956C349.451 236.1 349.485 236.254 349.487 236.41Z'
        fill='black'
      />
      <path
        d='M593.16 239.464H588.704V393.425H593.16V239.464Z'
        fill='#DADDDF'
      />
      <path
        d='M590.926 292.841C590.774 292.841 590.622 292.811 590.482 292.752C590.343 292.694 590.215 292.609 590.106 292.501C590.003 292.393 589.918 292.265 589.857 292.125C589.802 291.984 589.772 291.833 589.772 291.681V241.529C589.772 241.376 589.802 241.226 589.857 241.085C589.918 240.945 590.003 240.817 590.106 240.709C590.215 240.601 590.343 240.516 590.482 240.457C590.622 240.399 590.774 240.369 590.926 240.369C591.077 240.369 591.229 240.399 591.369 240.457C591.508 240.516 591.636 240.602 591.739 240.709C591.848 240.817 591.933 240.945 591.988 241.086C592.049 241.226 592.073 241.377 592.073 241.529V291.681C592.073 291.833 592.049 291.983 591.988 292.124C591.933 292.265 591.848 292.392 591.739 292.5C591.636 292.609 591.508 292.694 591.369 292.752C591.229 292.811 591.077 292.841 590.926 292.841Z'
        fill='#868A91'
      />
      <path
        d='M445.288 309.851L444.074 305.322C444.055 305.24 444.007 305.167 443.94 305.115C443.873 305.064 443.788 305.036 443.703 305.037H428.89C428.787 305.037 428.69 305.078 428.617 305.151C428.544 305.224 428.501 305.322 428.501 305.426V358.978C428.501 359.081 428.544 359.18 428.617 359.253C428.69 359.326 428.787 359.367 428.89 359.367H504.25C504.353 359.367 504.45 359.326 504.523 359.253C504.596 359.18 504.638 359.081 504.638 358.978V310.519C504.638 310.417 504.596 310.32 504.523 310.248C504.45 310.177 504.353 310.137 504.25 310.137H445.664C445.579 310.136 445.494 310.108 445.427 310.057C445.361 310.006 445.312 309.933 445.288 309.851Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M445.288 309.851L444.074 305.322C444.055 305.24 444.007 305.167 443.94 305.115C443.873 305.064 443.788 305.036 443.703 305.037H428.89C428.787 305.037 428.69 305.078 428.617 305.151C428.544 305.224 428.501 305.322 428.501 305.426V358.978C428.501 359.081 428.544 359.18 428.617 359.253C428.69 359.326 428.787 359.367 428.89 359.367H504.25C504.353 359.367 504.45 359.326 504.523 359.253C504.596 359.18 504.638 359.081 504.638 358.978V310.519C504.638 310.417 504.596 310.32 504.523 310.248C504.45 310.177 504.353 310.137 504.25 310.137H445.664C445.579 310.136 445.494 310.108 445.427 310.057C445.361 310.006 445.312 309.933 445.288 309.851Z'
        fill='black'
      />
      <path
        d='M428.75 324.307L475.995 339.157L495.064 278.513L459.98 267.482L444.183 275.217L428.75 324.307Z'
        fill='#DADDDF'
      />
      <path
        opacity='0.2'
        d='M459.98 267.482L444.183 275.217L456.349 279.041L459.98 267.482Z'
        fill='black'
      />
      <g opacity='0.2'>
        <path
          opacity='0.2'
          d='M487.269 287.225L461 278.969L460.514 280.526L486.777 288.782L487.269 287.225Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M486.03 291.153L459.767 282.897L459.275 284.455L485.538 292.711L486.03 291.153Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M482.017 293.219L455.857 284.959L455.372 286.51L481.526 294.77L482.017 293.219Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M483.559 299.019L445.075 286.921L444.584 288.48L483.068 300.577L483.559 299.019Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M482.321 302.948L443.837 290.85L443.345 292.408L481.835 304.505L482.321 302.948Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M481.089 306.877L442.605 294.779L442.113 296.337L480.603 308.434L481.089 306.877Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M479.856 310.805L441.366 298.707L440.88 300.266L479.364 312.363L479.856 310.805Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M478.624 314.741L440.134 302.643L439.648 304.201L478.132 316.299L478.624 314.741Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M477.379 318.669L438.895 306.572L438.403 308.13L476.887 320.227L477.379 318.669Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M476.147 322.604L437.662 310.507L437.171 312.065L475.655 324.163L476.147 322.604Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M474.908 326.533L436.424 314.436L435.938 315.993L474.423 328.091L474.908 326.533Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M473.676 330.469L435.192 318.371L434.706 319.929L473.19 332.026L473.676 330.469Z'
          fill='black'
        />
      </g>
      <path
        d='M428.653 359.367H504.007C504.061 359.367 504.116 359.355 504.164 359.333C504.213 359.31 504.255 359.277 504.292 359.237C504.328 359.196 504.359 359.148 504.371 359.096C504.389 359.045 504.395 358.99 504.389 358.936L499.241 314.563C499.229 314.47 499.186 314.384 499.113 314.321C499.04 314.259 498.949 314.224 498.858 314.223H438.974C438.895 314.222 438.822 314.198 438.761 314.155C438.694 314.112 438.646 314.051 438.616 313.98L436.891 309.785C436.861 309.713 436.812 309.652 436.752 309.608C436.685 309.565 436.612 309.542 436.533 309.542H422.946C422.892 309.543 422.837 309.555 422.788 309.578C422.74 309.601 422.691 309.634 422.655 309.675C422.618 309.717 422.594 309.765 422.576 309.817C422.564 309.87 422.558 309.925 422.564 309.979L428.252 359.027C428.264 359.124 428.313 359.212 428.386 359.276C428.459 359.338 428.556 359.371 428.653 359.367Z'
        fill='#4D61FC'
      />
      <path
        d='M356.481 148.118C337.248 148.87 318.774 151.323 298.575 154.693C299.964 172.792 301.38 190.686 302.825 208.373C324.347 204.833 348.236 198.981 370.28 201.579C369.096 186.219 367.9 172.238 366.692 157.163L356.481 148.118Z'
        fill='#DADDDF'
      />
      <g opacity='0.2'>
        <path
          opacity='0.2'
          d='M308.8 159.447C309.595 169.257 311.61 191.775 312.442 201.501L314.172 201.349C313.365 191.635 311.349 169.172 310.53 159.38L308.8 159.447Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M313.152 158.609C313.96 168.323 315.981 190.785 316.795 200.463L318.531 200.25C317.718 190.603 315.696 168.183 314.889 158.469C314.306 158.524 313.729 158.566 313.152 158.609Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M317.523 158.207C318.331 167.878 320.359 190.268 321.166 199.885C321.773 199.806 322.326 199.721 322.908 199.63C322.089 190.025 320.061 167.654 319.266 158.001L317.523 158.207Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M321.907 157.656C323.121 171.759 324.293 185.2 325.495 199.218L327.237 198.914C326.023 184.909 324.809 171.486 323.643 157.407L321.907 157.656Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M326.296 156.994C327.51 171.042 328.724 184.447 329.896 198.446L331.638 198.131C330.424 184.131 329.21 170.738 328.038 156.708L326.296 156.994Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M330.698 156.265C331.912 170.283 333.126 183.664 334.298 197.663L336.046 197.354C334.832 183.348 333.618 169.967 332.44 155.961L330.698 156.265Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M335.099 156.083C336.314 170.046 337.528 183.463 338.705 197.481L340.448 197.184C339.234 183.154 338.02 169.761 336.842 155.767L335.099 156.083Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M339.483 155.288C340.697 169.288 341.911 182.699 343.083 196.753L344.825 196.48C343.611 182.407 342.397 168.99 341.231 154.979L339.483 155.288Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M343.902 155.13C345.117 169.154 346.331 182.601 347.49 196.71C348.097 196.631 348.65 196.552 349.233 196.485C348.018 182.346 346.847 168.874 345.645 154.838L343.902 155.13Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M348.911 161.729C350.125 175.795 350.69 182.012 351.874 196.2L353.604 196.03C352.426 181.806 351.843 175.559 350.647 161.468L348.911 161.729Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M353.294 161.692C354.508 175.82 355.061 182.121 356.238 196.4L357.963 196.297C356.791 181.976 356.22 175.656 355.03 161.48C354.454 161.547 353.877 161.613 353.294 161.692Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M357.696 161.195C358.879 175.407 359.45 181.836 360.616 196.2C361.186 196.2 361.757 196.2 362.334 196.2C361.168 181.751 360.604 175.31 359.426 161.061L357.696 161.195Z'
          fill='black'
        />
      </g>
      <path
        opacity='0.2'
        d='M356.457 148.118L357.088 157.479L366.65 157.163L356.457 148.118Z'
        fill='black'
      />
      <path
        d='M423.87 168.476C414.563 183.241 405.139 198.216 395.603 213.401C419.62 206.596 419.802 251.042 446.059 244.661L464.133 216.473L470.811 206.553C447.352 210.918 449.034 164.208 423.87 168.476Z'
        fill='#DADDDF'
      />
      <g opacity='0.2'>
        <path
          opacity='0.2'
          d='M438.167 239.738C444.881 229.302 450.345 220.122 457.035 209.723C456.574 209.518 456.119 209.287 455.681 209.031C449.003 219.4 443.539 228.561 436.861 238.979C437.28 239.256 437.717 239.51 438.167 239.738Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M434.979 237.564C441.658 227.159 447.122 218.022 453.8 207.677C453.399 207.343 453.016 206.991 452.646 206.614C445.968 216.935 440.553 226.078 433.862 236.478C434.19 236.86 434.591 237.218 434.979 237.564Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M432.223 234.625C438.901 224.22 444.365 215.089 451.043 204.756C450.703 204.325 450.375 203.87 450.06 203.408C443.382 213.729 437.96 222.878 431.239 233.296C431.555 233.757 431.883 234.2 432.223 234.625Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M429.788 231.129C436.515 220.662 441.985 211.483 448.663 201.114C448.366 200.604 448.056 200.081 447.795 199.547C441.117 209.935 435.653 219.138 428.884 229.635C429.181 230.115 429.479 230.643 429.788 231.129Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M426.935 227.887C433.711 217.341 439.836 207.476 446.545 197.052C446.278 196.493 446.011 195.923 445.749 195.352C439.023 205.812 432.879 215.714 426.085 226.314C426.365 226.83 426.65 227.358 426.935 227.887Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M424.798 223.861C431.634 213.201 437.821 203.22 444.578 192.711C444.323 192.104 444.074 191.545 443.819 190.957C437.031 201.526 430.821 211.549 423.948 222.259C424.234 222.793 424.519 223.327 424.798 223.861Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M421.404 221.1C426.389 213.317 433.231 202.644 442.046 188.923C441.785 188.316 441.53 187.764 441.269 187.193C432.423 200.987 425.545 211.732 420.524 219.582C420.834 220.068 421.113 220.584 421.404 221.1Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M419.122 217.366C424.21 209.401 430.536 199.153 439.442 185.263C439.169 184.71 438.889 184.17 438.61 183.636C429.661 197.599 423.293 207.92 418.151 215.982C418.485 216.401 418.806 216.862 419.122 217.366Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M416.584 213.996C421.799 205.812 428.253 195.977 437.268 181.868C436.965 181.371 436.661 180.891 436.333 180.423C427.269 194.624 420.767 204.556 415.491 212.837C415.868 213.207 416.232 213.596 416.584 213.996Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M413.706 211.295C419.073 202.856 425.666 192.766 434.815 178.414C434.463 177.995 434.105 177.6 433.735 177.2C424.525 191.661 417.871 201.86 412.443 210.408C412.875 210.687 413.3 210.979 413.706 211.295Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M410.975 208.781C416.511 200.051 422.649 190.289 431.95 175.658C431.543 175.351 431.118 175.068 430.681 174.808C421.313 189.566 415.103 199.45 409.493 208.308C409.997 208.439 410.489 208.597 410.975 208.781Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M407.047 207.92C412.778 198.862 419.097 188.772 428.574 173.825C428.083 173.646 427.579 173.5 427.069 173.388C417.519 188.48 411.114 198.71 405.304 207.908C405.885 207.878 406.466 207.882 407.047 207.92Z'
          fill='black'
        />
      </g>
      <path
        d='M223.047 386.161L238.21 386.117L238.612 394.844C238.66 395.969 238.964 397.068 239.5 398.058C240.036 399.049 240.79 399.904 241.706 400.559L252.826 408.505L224.952 407.815L223.047 386.161Z'
        fill='#FC9D9D'
      />
      <path
        opacity='0.15'
        d='M233.011 404.304L237.006 405.894L224.854 407.224L223.519 392.451H238.379L238.466 394.21L229.576 395.501C229.113 395.567 228.694 395.81 228.405 396.178C228.117 396.546 227.982 397.012 228.029 397.477V397.645C228.164 399.106 228.7 400.502 229.58 401.678C230.459 402.853 231.647 403.762 233.011 404.304Z'
        fill='black'
      />
      <path
        d='M176.922 386.161L191.738 386.117L189.42 407.327H176.878L176.922 386.161Z'
        fill='#FC9D9D'
      />
      <path
        opacity='0.15'
        d='M176.921 407.441L176.965 392.407L191.075 392.364L190.858 394.166L181.838 395.544C181.426 395.599 181.046 395.796 180.764 396.101C180.481 396.407 180.314 396.801 180.292 397.216L179.993 403.728L176.921 407.441Z'
        fill='black'
      />
      <path
        d='M176.65 219.004H232.827L238.851 392.473H223.362L207.731 251.024C207.731 250.574 207.552 250.142 207.234 249.823C206.915 249.505 206.483 249.326 206.033 249.326C205.582 249.326 205.15 249.505 204.832 249.823C204.513 250.142 204.334 250.574 204.334 251.024L192.139 392.451H176.65V219.004Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M223.28 391.718L207.731 251.068C207.707 250.636 207.52 250.228 207.21 249.926C206.9 249.625 206.487 249.451 206.054 249.44H209.262C213.348 249.44 216.822 252.658 217.315 256.956L232.598 390.465L223.28 391.718Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M185.746 219.286L183.255 388.842L176.59 388.798L176.742 219.286H185.746Z'
        fill='black'
      />
      <path
        d='M176.623 402.584L176.58 403.833C176.573 404.887 176.39 405.932 176.037 406.926L174.49 411.267C174.067 412.488 173.849 413.775 173.844 415.066V415.283C173.866 415.712 174.053 416.108 174.367 416.401C174.68 416.689 175.095 416.846 175.521 416.83H190.94C191.367 416.846 191.781 416.689 192.095 416.401C192.409 416.108 192.596 415.712 192.617 415.283V414.741C192.608 413.617 192.425 412.505 192.074 411.436L190.739 407.012C190.449 406.11 190.304 405.167 190.31 404.218V402.59C190.314 401.567 189.926 400.583 189.225 399.838L189.013 399.583C187.598 398.143 185.67 397.321 183.651 397.296C181.632 397.272 179.685 398.047 178.235 399.453L177.844 399.838C177.123 400.579 176.69 401.553 176.623 402.584Z'
        fill='#21252B'
      />
      <path
        d='M175.657 388.875H193.306L192.964 392.44H175.657V388.875Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.15'
        d='M175.657 388.875H183.043L182.869 392.44H175.657V388.875Z'
        fill='black'
      />
      <path
        d='M224.637 401.682L230.564 403.528C230.991 403.654 231.443 403.671 231.879 403.576C232.314 403.481 232.719 403.277 233.055 402.985L237.738 398.991C238.248 398.551 238.895 398.302 239.568 398.287C240.241 398.271 240.899 398.489 241.429 398.904L251.995 407.104L260.326 409.856C261.524 410.257 262.566 411.023 263.306 412.048C264.045 413.074 264.446 414.301 264.451 415.565C264.45 415.918 264.309 416.254 264.06 416.504C263.812 416.754 263.474 416.895 263.121 416.9H226.184C225.766 416.9 225.352 416.819 224.966 416.661C224.58 416.499 224.229 416.265 223.934 415.972C223.638 415.674 223.404 415.326 223.244 414.936C223.085 414.55 223.003 414.138 223.004 413.72V402.866C223.011 402.671 223.062 402.481 223.154 402.31C223.246 402.138 223.375 401.99 223.532 401.876C223.69 401.762 223.871 401.686 224.062 401.652C224.254 401.618 224.45 401.629 224.637 401.682Z'
        fill='#21252B'
      />
      <path
        opacity='0.6'
        d='M173.661 414.15H192.39C192.426 414.361 192.441 414.578 192.434 414.795V415.338C192.412 415.761 192.225 416.163 191.912 416.456C191.598 416.744 191.183 416.896 190.757 416.885H175.338C174.911 416.896 174.497 416.744 174.183 416.456C173.869 416.163 173.682 415.761 173.661 415.338V414.133V414.15Z'
        fill='black'
      />
      <path
        opacity='0.6'
        d='M222.88 414.323H264.126C264.251 414.758 264.309 415.203 264.3 415.653C264.299 416.006 264.158 416.342 263.909 416.592C263.66 416.842 263.323 416.983 262.971 416.988H226.017C225.264 416.983 224.535 416.717 223.961 416.228C223.387 415.74 223.004 415.067 222.88 414.323Z'
        fill='black'
      />
      <path
        d='M241.305 403.241L243.541 400.837C243.602 400.774 243.635 400.689 243.635 400.601C243.635 400.513 243.602 400.428 243.541 400.365C243.478 400.303 243.393 400.268 243.305 400.268C243.216 400.268 243.132 400.303 243.069 400.365L240.833 402.769C240.792 402.814 240.765 402.87 240.754 402.93C240.743 402.99 240.75 403.051 240.772 403.108C240.795 403.164 240.833 403.214 240.882 403.249C240.931 403.286 240.989 403.307 241.05 403.312C241.139 403.307 241.226 403.283 241.305 403.241Z'
        fill='white'
      />
      <path
        d='M243.069 404.614L245.299 402.21C245.361 402.147 245.396 402.062 245.396 401.974C245.396 401.885 245.361 401.801 245.299 401.738C245.236 401.677 245.151 401.643 245.063 401.643C244.975 401.643 244.891 401.677 244.827 401.738L242.597 404.126C242.535 404.189 242.5 404.273 242.5 404.362C242.5 404.45 242.535 404.535 242.597 404.598C242.653 404.653 242.729 404.685 242.808 404.685C242.899 404.681 242.988 404.657 243.069 404.614Z'
        fill='white'
      />
      <path
        d='M244.827 405.992L247.063 403.587C247.124 403.524 247.158 403.439 247.158 403.351C247.158 403.263 247.124 403.179 247.063 403.115C247 403.053 246.916 403.018 246.827 403.018C246.739 403.018 246.654 403.053 246.591 403.115L244.355 405.519C244.314 405.564 244.287 405.62 244.276 405.68C244.266 405.74 244.272 405.802 244.295 405.859C244.317 405.915 244.355 405.964 244.404 406C244.453 406.036 244.511 406.058 244.572 406.062C244.663 406.071 244.754 406.046 244.827 405.992Z'
        fill='white'
      />
      <path
        d='M186.632 403.328C186.671 403.236 186.675 403.133 186.644 403.039C186.613 402.944 186.549 402.864 186.464 402.813C185.467 402.324 184.371 402.069 183.262 402.069C182.151 402.069 181.056 402.324 180.06 402.813C179.968 402.859 179.899 402.939 179.867 403.036C179.835 403.133 179.842 403.239 179.889 403.331C179.935 403.422 180.015 403.492 180.112 403.524C180.209 403.556 180.315 403.548 180.407 403.502C181.292 403.081 182.26 402.863 183.24 402.863C184.22 402.863 185.188 403.081 186.073 403.502C186.124 403.536 186.185 403.552 186.247 403.545C186.324 403.546 186.401 403.526 186.468 403.488C186.536 403.45 186.592 403.395 186.632 403.328Z'
        fill='white'
      />
      <path
        d='M186.632 405.302C186.671 405.211 186.675 405.108 186.644 405.013C186.613 404.919 186.549 404.838 186.464 404.787C185.467 404.298 184.371 404.044 183.262 404.044C182.151 404.044 181.056 404.298 180.06 404.787C179.968 404.833 179.899 404.914 179.867 405.011C179.835 405.108 179.842 405.214 179.889 405.305C179.935 405.397 180.015 405.466 180.112 405.498C180.209 405.53 180.315 405.522 180.407 405.477C181.292 405.054 182.259 404.835 183.24 404.835C184.22 404.835 185.188 405.054 186.073 405.477C186.124 405.511 186.185 405.526 186.247 405.52C186.324 405.521 186.401 405.5 186.468 405.462C186.536 405.425 186.592 405.369 186.632 405.302Z'
        fill='white'
      />
      <path
        d='M186.632 407.235C186.678 407.137 186.685 407.029 186.654 406.931C186.623 406.828 186.555 406.746 186.464 406.692C185.467 406.204 184.372 405.95 183.262 405.95C182.152 405.95 181.057 406.204 180.06 406.692C180.014 406.714 179.974 406.746 179.941 406.784C179.908 406.822 179.883 406.866 179.867 406.915C179.851 406.964 179.845 407.012 179.849 407.067C179.852 407.115 179.866 407.164 179.889 407.208C179.911 407.257 179.943 407.295 179.982 407.327C180.02 407.36 180.064 407.387 180.112 407.403C180.161 407.419 180.211 407.425 180.262 407.419C180.313 407.419 180.361 407.403 180.407 407.381C181.292 406.958 182.26 406.741 183.24 406.741C184.22 406.741 185.188 406.958 186.073 407.381C186.124 407.414 186.185 407.43 186.247 407.425C186.322 407.43 186.396 407.414 186.464 407.381C186.531 407.343 186.589 407.295 186.632 407.235Z'
        fill='white'
      />
      <path
        d='M221.788 388.875H239.182L239.437 392.44H222.146L221.788 388.875Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.15'
        d='M175.657 388.875H193.306L192.964 392.44H175.657V388.875Z'
        fill='black'
      />
      <path
        opacity='0.15'
        d='M221.788 388.875H239.182L239.437 392.44H222.146L221.788 388.875Z'
        fill='black'
      />
      <path
        opacity='0.15'
        d='M221.788 388.875H232.051L232.436 392.44H222.124L221.788 388.875Z'
        fill='black'
      />
      <path
        d='M234.775 152.597C235.063 145.813 231.519 139.626 226.748 138.085L215.129 133.629C215.129 133.596 211.948 132.744 211.948 132.712L213.115 133.059L195.206 132.457L194.261 132.723L172.504 137.878C166.794 139.165 162.735 144.538 162.854 150.632L163.185 166.371L176.813 185.437L174.794 268.901L236.284 269.08L234.775 152.597Z'
        fill='#FFE2A8'
      />
      <path
        d='M230.943 140.717C230.943 140.717 237.651 144.625 238.699 157.476L244.158 196.975L227.958 196.297L230.943 140.717Z'
        fill='#21252B'
      />
      <path
        opacity='0.4'
        d='M188.183 192.58L180.791 191.597L182.712 157.998C182.712 157.922 182.831 157.862 183 157.819C183.224 157.766 183.458 157.765 183.682 157.816C183.907 157.866 184.118 157.966 184.299 158.109C184.48 158.252 184.626 158.433 184.727 158.641C184.828 158.848 184.881 159.075 184.883 159.306L185.382 189.475L216.268 194.202C216.42 194.227 216.558 194.305 216.658 194.422C216.757 194.54 216.811 194.689 216.811 194.843C216.811 194.934 216.791 195.026 216.753 195.109C216.715 195.193 216.659 195.268 216.59 195.328C216.521 195.388 216.439 195.433 216.35 195.459C216.262 195.484 216.169 195.491 216.078 195.478L188.183 192.58Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M233.483 143.262L227.958 196.296L238.699 196.747L236.49 166.941L236.332 160.683C236.324 160.344 236.236 160.012 236.076 159.714C235.915 159.415 235.687 159.158 235.41 158.963L233.483 143.262Z'
        fill='black'
      />
      <path
        opacity='0.4'
        d='M185.881 206.277L185.686 269.173L174.805 268.907L176.237 206.158L185.881 206.277Z'
        fill='black'
      />
      <path
        d='M222.748 173.498L221.788 169.455C221.774 169.384 221.737 169.321 221.683 169.274C221.629 169.228 221.561 169.202 221.489 169.199H209.653C209.61 169.201 209.568 169.212 209.529 169.23C209.49 169.248 209.456 169.274 209.427 169.306C209.398 169.338 209.376 169.375 209.362 169.415C209.348 169.456 209.341 169.499 209.343 169.541V217.42C209.339 217.507 209.369 217.592 209.427 217.657C209.485 217.722 209.566 217.761 209.653 217.767H269.78C269.867 217.761 269.948 217.722 270.006 217.657C270.064 217.592 270.094 217.507 270.09 217.42V174.1C270.092 174.057 270.086 174.015 270.071 173.974C270.057 173.934 270.035 173.897 270.006 173.865C269.977 173.833 269.942 173.807 269.904 173.789C269.865 173.771 269.823 173.76 269.78 173.758H223.047C222.975 173.756 222.906 173.728 222.851 173.681C222.797 173.633 222.761 173.568 222.748 173.498Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M222.748 173.498L221.788 169.454C221.774 169.384 221.737 169.321 221.683 169.274C221.629 169.228 221.561 169.201 221.489 169.199H209.653C209.61 169.201 209.568 169.212 209.529 169.23C209.49 169.248 209.456 169.274 209.427 169.306C209.398 169.338 209.376 169.374 209.362 169.415C209.348 169.455 209.341 169.498 209.343 169.541V217.42C209.339 217.506 209.369 217.592 209.427 217.656C209.485 217.721 209.566 217.761 209.653 217.766H269.78C269.867 217.761 269.948 217.721 270.006 217.656C270.064 217.592 270.094 217.506 270.09 217.42V174.099C270.092 174.057 270.086 174.014 270.071 173.974C270.057 173.933 270.035 173.896 270.006 173.864C269.977 173.833 269.942 173.807 269.904 173.788C269.865 173.771 269.823 173.76 269.78 173.757H223.047C222.975 173.755 222.906 173.728 222.851 173.68C222.797 173.633 222.761 173.568 222.748 173.498Z'
        fill='black'
      />
      <path
        d='M210.147 186.424L252.381 199.699L269.428 145.481L238.069 135.626L223.943 142.54L210.147 186.424Z'
        fill='#DADDDF'
      />
      <path
        opacity='0.2'
        d='M238.069 135.626L223.942 142.54L234.818 145.959L238.069 135.626Z'
        fill='black'
      />
      <g opacity='0.2'>
        <path
          opacity='0.2'
          d='M262.452 153.277L238.972 145.897L238.534 147.29L262.014 154.67L262.452 153.277Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M261.351 156.79L237.872 149.41L237.434 150.803L260.914 158.183L261.351 156.79Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M260.245 160.307L236.765 152.927L236.327 154.319L259.807 161.7L260.245 160.307Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M257.493 162.698L223.232 151.879L222.795 153.267L257.055 164.086L257.493 162.698Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M258.037 167.334L223.633 156.519L223.195 157.912L257.599 168.726L258.037 167.334Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M255.294 169.7L221.033 158.881L220.595 160.268L254.856 171.087L255.294 169.7Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M255.83 174.364L221.426 163.549L220.988 164.942L255.392 175.756L255.83 174.364Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M254.723 177.876L220.319 167.061L219.882 168.453L254.286 179.268L254.723 177.876Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M253.618 181.388L219.214 170.574L218.776 171.966L253.18 182.781L253.618 181.388Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M250.891 183.698L216.631 172.879L216.193 174.266L250.453 185.085L250.891 183.698Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M251.412 188.419L217.008 177.605L216.57 178.997L250.974 189.812L251.412 188.419Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M250.304 191.935L215.901 181.12L215.463 182.513L249.867 193.328L250.304 191.935Z'
          fill='black'
        />
      </g>
      <path
        d='M209.479 217.766H269.601C269.647 217.763 269.692 217.75 269.733 217.728C269.774 217.707 269.81 217.677 269.839 217.641C269.867 217.605 269.887 217.563 269.899 217.518C269.911 217.473 269.912 217.426 269.905 217.38L265.802 177.713C265.797 177.633 265.763 177.558 265.706 177.501C265.649 177.444 265.573 177.409 265.492 177.404H217.733C217.672 177.405 217.612 177.387 217.561 177.353C217.509 177.319 217.47 177.271 217.446 177.214L216.073 173.464C216.053 173.403 216.015 173.349 215.963 173.31C215.911 173.272 215.85 173.249 215.785 173.247H204.963C204.917 173.25 204.872 173.263 204.831 173.285C204.791 173.306 204.754 173.336 204.726 173.372C204.697 173.408 204.677 173.45 204.665 173.495C204.654 173.54 204.652 173.586 204.659 173.632L209.196 217.484C209.206 217.556 209.238 217.622 209.289 217.673C209.34 217.725 209.407 217.757 209.479 217.766Z'
        fill='#4D61FC'
      />
      <path
        d='M186.728 192.584L219.22 195.564L218.678 204.953C218.678 204.953 190.418 207.417 176.14 205.702L179.157 197.36L186.728 192.584Z'
        fill='#FC9D9D'
      />
      <path
        d='M214.434 204.035L222.146 206.358C224.381 206.851 226.643 207.213 228.919 207.443L235.801 208.909C236.001 208.928 236.199 208.867 236.354 208.74C236.509 208.613 236.606 208.429 236.626 208.23C236.645 208.031 236.585 207.832 236.458 207.677C236.331 207.523 236.147 207.425 235.948 207.405L229.809 204.952L229.69 204.686H238.813C239.025 204.67 239.224 204.576 239.371 204.423C239.518 204.268 239.603 204.066 239.61 203.853C239.617 203.641 239.545 203.433 239.408 203.27C239.271 203.108 239.078 203.001 238.867 202.971L236.403 202.684L240.056 202.608C240.313 202.602 240.559 202.501 240.747 202.325C240.934 202.149 241.05 201.909 241.071 201.653C241.094 201.38 241.009 201.109 240.834 200.898C240.66 200.687 240.41 200.552 240.137 200.524L229.972 199.558L229.88 199.156L237.88 198.95C238.072 198.943 238.256 198.866 238.396 198.733C238.535 198.6 238.621 198.421 238.637 198.229C238.653 198.037 238.598 197.845 238.483 197.691C238.367 197.537 238.199 197.431 238.01 197.392L233.918 196.915L228.708 196.372L229.196 195.965C233.755 195.276 234.01 194.375 234.205 193.669C234.221 193.607 234.222 193.542 234.208 193.48C234.193 193.417 234.164 193.359 234.123 193.31C234.081 193.262 234.028 193.223 233.969 193.199C233.91 193.174 233.846 193.164 233.782 193.17L225.332 193.892L215.682 195.84L215.46 197.012'
        fill='#FC9D9D'
      />
      <g opacity='0.15'>
        <path
          opacity='0.15'
          d='M230.058 202.452C230.05 202.408 230.05 202.364 230.058 202.321C230.064 202.247 230.097 202.179 230.152 202.13C230.207 202.08 230.278 202.054 230.351 202.055L237.852 202.419C237.926 202.424 237.996 202.459 238.045 202.515C238.095 202.57 238.121 202.643 238.118 202.717C238.113 202.791 238.08 202.86 238.025 202.91C237.971 202.96 237.898 202.986 237.825 202.983L230.324 202.614C230.269 202.618 230.213 202.604 230.166 202.575C230.118 202.545 230.081 202.503 230.058 202.452Z'
          fill='black'
        />
      </g>
      <path
        d='M182.582 169.074L181.496 191.455L196.823 193.496L187.765 206.174L173.214 206.445C171.979 206.518 170.741 206.338 169.578 205.916C168.414 205.494 167.349 204.838 166.448 203.989C165.547 203.141 164.829 202.117 164.338 200.981C163.847 199.844 163.593 198.62 163.592 197.382V169.66'
        fill='#FC9D9D'
      />
      <path
        opacity='0.15'
        d='M181.475 191.451L178.533 191.217C178.492 191.207 178.455 191.184 178.429 191.15C178.403 191.116 178.388 191.075 178.388 191.033C178.388 190.99 178.403 190.949 178.429 190.916C178.455 190.882 178.492 190.859 178.533 190.848L181.616 189.926L181.475 191.451Z'
        fill='black'
      />
      <path
        d='M163.049 169.66V149.58L184.183 148.766L183.097 170.154L163.049 169.66Z'
        fill='#FFE2A8'
      />
      <path
        opacity='0.3'
        d='M167.961 169.644L168.249 148.934C168.547 144.049 170.007 140.25 174.045 137.64L173.144 137.857C170.167 138.491 167.514 140.168 165.663 142.585C163.813 145.002 162.886 148 163.05 151.039V169.66L167.961 169.644Z'
        fill='black'
      />
      <path
        opacity='0.15'
        d='M189.941 206.309C201.599 206.271 214.249 205.316 217.744 205.034L222.146 206.358C224.381 206.851 226.642 207.213 228.919 207.444L235.801 208.909C235.899 208.914 235.999 208.9 236.092 208.866C236.185 208.832 236.271 208.781 236.343 208.714C235.356 209.017 228.534 206.646 228.534 206.646C224.808 205.956 221.169 204.865 217.679 203.39C217.647 203.248 217.614 203.21 217.582 203.346C217.489 203.802 202.771 203.579 195.26 203.438L174.886 202.353C171.326 202.478 168.189 199.276 168.189 194.853L168.547 169.633H163.636V197.311C163.632 198.552 163.884 199.781 164.377 200.92C164.87 202.059 165.593 203.084 166.5 203.931C167.407 204.778 168.48 205.428 169.65 205.841C170.821 206.254 172.063 206.421 173.301 206.331L189.941 206.309Z'
        fill='black'
      />
      <path
        opacity='0.5'
        d='M174.881 265.396H236.262'
        stroke='white'
        strokeWidth='1.08095'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M163.028 167.196L183.125 167.663'
        stroke='white'
        strokeWidth='1.08095'
        strokeMiterlimit='10'
      />
      <path
        d='M215.362 120.062C215.271 121.176 215.062 122.276 214.738 123.345L214.987 131.036L215.047 132.865C215.09 133.432 214.909 133.993 214.542 134.428C212.279 136.161 209.609 137.286 206.787 137.695C203.966 138.105 201.087 137.785 198.424 136.767C197.177 136.304 195.987 135.703 194.874 134.976C194.691 134.863 194.531 134.718 194.402 134.547L194.364 134.509V134.466C194.203 134.264 194.086 134.033 194.019 133.784C193.952 133.536 193.936 133.277 193.973 133.022L194.017 132.637V132.593L194.201 131.302L196.671 114.287L197.175 110.543C197.3 109.245 197.668 107.982 198.261 106.82C202.754 101.935 213.191 107.466 216.762 109.593C216.599 110.635 215.872 115.145 215.362 120.062Z'
        fill='#FC9D9D'
      />
      <path
        d='M214.809 96.4964C216.383 97.4478 217.701 98.7704 218.647 100.348C219.593 101.926 220.139 103.712 220.236 105.549C220.681 113.939 219.905 128.039 209.761 128.614C196.107 130.617 196.047 104.903 196.047 104.903C196.47 101.413 198.185 98.9712 200.741 96.9306C204.372 94.027 210.803 94.0976 214.787 96.4964H214.809Z'
        fill='#FC9D9D'
      />
      <path
        opacity='0.15'
        d='M196.513 115.225C197.138 115.334 197.781 115.239 198.347 114.954L196.122 117.917L196.513 115.225Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M215.053 131.268L214.895 126.742C213.609 127.914 210.011 129.455 206.212 128.229C204.299 127.601 202.634 126.386 201.452 124.756C201.434 124.72 201.409 124.688 201.378 124.663C201.347 124.637 201.311 124.619 201.272 124.608C201.234 124.598 201.193 124.596 201.154 124.603C201.114 124.608 201.076 124.624 201.043 124.645C201.01 124.668 200.982 124.697 200.96 124.731C200.939 124.765 200.925 124.803 200.92 124.842C200.914 124.882 200.917 124.922 200.928 124.961C200.94 124.999 200.959 125.035 200.985 125.065C202.517 127.148 204.772 128.582 207.308 129.086C208.385 129.954 209.626 130.594 210.957 130.969C212.288 131.344 213.681 131.446 215.053 131.268Z'
        fill='black'
      />
      <path
        d='M207.292 113.863C207.918 113.863 208.426 113.356 208.426 112.729C208.426 112.103 207.918 111.595 207.292 111.595C206.665 111.595 206.157 112.103 206.157 112.729C206.157 113.356 206.665 113.863 207.292 113.863Z'
        fill='black'
      />
      <path
        d='M207.45 113.239C207.578 113.239 207.683 113.134 207.683 113.006C207.683 112.877 207.578 112.772 207.45 112.772C207.32 112.772 207.216 112.877 207.216 113.006C207.216 113.134 207.32 113.239 207.45 113.239Z'
        fill='white'
      />
      <path
        d='M216.344 113.863C216.971 113.863 217.479 113.356 217.479 112.729C217.479 112.103 216.971 111.595 216.344 111.595C215.718 111.595 215.21 112.103 215.21 112.729C215.21 113.356 215.718 113.863 216.344 113.863Z'
        fill='black'
      />
      <path
        d='M216.502 113.196C216.631 113.196 216.735 113.091 216.735 112.963C216.735 112.834 216.631 112.729 216.502 112.729C216.373 112.729 216.268 112.834 216.268 112.963C216.268 113.091 216.373 113.196 216.502 113.196Z'
        fill='white'
      />
      <path
        opacity='0.3'
        d='M212.518 118.156H210.934C210.888 118.156 210.842 118.147 210.8 118.129C210.757 118.112 210.718 118.086 210.686 118.053C210.654 118.02 210.628 117.981 210.611 117.938C210.594 117.895 210.586 117.85 210.586 117.804C210.586 117.758 210.594 117.713 210.611 117.67C210.629 117.627 210.654 117.589 210.686 117.556C210.719 117.524 210.757 117.499 210.8 117.481C210.843 117.464 210.888 117.456 210.934 117.456H212.497C212.673 117.441 212.836 117.36 212.955 117.23C213.074 117.1 213.14 116.93 213.14 116.754C213.14 116.577 213.074 116.407 212.955 116.277C212.836 116.147 212.673 116.066 212.497 116.051C212.403 116.051 212.313 116.014 212.247 115.948C212.181 115.881 212.144 115.791 212.144 115.698C212.144 115.605 212.181 115.515 212.247 115.448C212.313 115.382 212.403 115.345 212.497 115.345C212.87 115.345 213.227 115.493 213.49 115.757C213.754 116.02 213.902 116.378 213.902 116.751C213.902 117.124 213.754 117.481 213.49 117.745C213.227 118.008 212.87 118.156 212.497 118.156H212.518Z'
        fill='black'
      />
      <path
        d='M209.056 121.282L213.539 121.857C213.566 121.863 213.592 121.874 213.615 121.89C213.64 121.905 213.659 121.928 213.669 121.955C213.677 121.98 213.677 122.006 213.669 122.031C213.675 122.058 213.675 122.086 213.669 122.112C213.435 122.587 213.059 122.977 212.594 123.229C212.129 123.481 211.597 123.581 211.072 123.518C210.547 123.456 210.055 123.231 209.662 122.876C209.27 122.522 208.997 122.054 208.882 121.537V121.504C208.877 121.478 208.877 121.45 208.882 121.423C208.887 121.398 208.899 121.374 208.915 121.353C208.932 121.331 208.954 121.314 208.98 121.304C209.005 121.298 209.031 121.298 209.056 121.304V121.282Z'
        fill='white'
      />
      <path
        d='M204.866 109.532C207.737 107.839 209.153 109.028 209.153 109.028L209.246 109.413C209.246 109.413 206.559 108.984 205.126 109.766L204.866 109.532Z'
        fill='black'
      />
      <path
        d='M219.829 99.1016V99.145C219.771 98.1681 219.44 97.227 218.872 96.4292C218.305 95.632 217.525 95.01 216.621 94.635C215.037 93.9837 212.795 93.7124 209.691 94.4776C209.105 94.6187 208.491 94.6078 207.911 94.445C206.424 94.038 203.314 93.4736 201.007 95.0203C200.401 95.4463 199.681 95.6824 198.94 95.6987C196.714 95.6987 192.221 96.9035 194.223 107.014L197.029 111.541H199.537L200.465 107.09C200.51 106.805 200.644 106.542 200.848 106.337C201.051 106.132 201.314 105.997 201.599 105.951L203.135 105.696C203.363 105.658 203.578 105.563 203.759 105.421C203.941 105.279 204.085 105.093 204.177 104.881L205.295 102.26C205.369 102.089 205.477 101.934 205.612 101.805C205.747 101.677 205.907 101.576 206.082 101.511C207.401 101.023 211.107 100.138 218.521 101.658C218.687 101.691 218.84 101.77 218.963 101.886C219.085 102.002 219.173 102.151 219.215 102.314C219.492 103.373 220.089 105.755 220.355 107.313L219.829 99.1016Z'
        fill='#414D58'
      />
      <path
        d='M218.83 109.603C215.954 107.92 214.537 109.12 214.537 109.12L214.451 109.505C214.451 109.505 217.137 109.065 218.57 109.842L218.83 109.603Z'
        fill='black'
      />
      <path
        d='M197.762 108.691C197.668 108.329 197.495 107.991 197.256 107.703C197.018 107.414 196.718 107.182 196.379 107.022C196.041 106.862 195.671 106.778 195.297 106.777C194.922 106.776 194.552 106.857 194.212 107.014C191.01 108.143 191.086 115.285 196.356 116.067C199.129 116.496 198.657 114.857 198.348 112.669L197.762 108.691Z'
        fill='#FC9D9D'
      />
      <path
        opacity='0.3'
        d='M193.935 108.832C194.162 108.68 194.419 108.578 194.688 108.531C194.957 108.484 195.233 108.494 195.498 108.561C195.747 108.652 195.975 108.793 196.169 108.973C196.363 109.154 196.519 109.372 196.627 109.614C196.921 110.221 197.055 110.894 197.018 111.567V112.192C197.017 112.236 197.008 112.278 196.99 112.319C196.972 112.358 196.945 112.394 196.913 112.423C196.88 112.452 196.842 112.474 196.8 112.487C196.758 112.501 196.714 112.505 196.671 112.501C196.627 112.501 196.583 112.492 196.542 112.474C196.502 112.456 196.465 112.43 196.436 112.398C196.406 112.365 196.383 112.326 196.37 112.284C196.356 112.242 196.351 112.198 196.356 112.154V111.529C196.371 110.971 196.266 110.415 196.046 109.901C195.978 109.735 195.877 109.583 195.749 109.455C195.621 109.328 195.47 109.227 195.303 109.158C194.935 109.099 194.559 109.182 194.25 109.391C194.178 109.433 194.093 109.444 194.012 109.423C193.932 109.401 193.863 109.349 193.821 109.277C193.779 109.203 193.768 109.115 193.789 109.032C193.81 108.949 193.863 108.877 193.935 108.832Z'
        fill='black'
      />
      <path
        d='M85.3093 314.117C80.1614 240.537 74.4551 235.687 74.2333 235.504L74.5928 234.834C74.8452 234.948 80.8116 238.588 86.0666 314.094L85.3093 314.117Z'
        fill='#414D58'
      />
      <path
        d='M90.6435 314.117L89.9304 314.071C93.0895 259.99 89.6522 230.491 89.6166 230.2L90.3297 230.101C90.3654 230.399 93.8097 259.967 90.6506 314.117'
        fill='#414D58'
      />
      <path
        d='M95.3249 316.484C91.1332 282.41 101.807 256.397 101.916 256.134L102.633 256.436C102.524 256.691 91.9357 282.495 96.0963 316.391L95.3249 316.484Z'
        fill='#414D58'
      />
      <path
        d='M99.7227 320.034C98.8241 307.861 98.8729 295.496 99.8667 283.351L100.267 283.49C99.2836 295.544 99.2364 307.814 100.127 319.894L99.7227 320.034Z'
        fill='#414D58'
      />
      <path
        d='M102.633 257.317L105.889 257.036C108.204 256.836 110.451 256.118 112.476 254.933C113.463 254.33 114.485 253.795 115.537 253.33C119.566 251.642 124.067 251.946 128.404 251.72C135.503 251.336 142.457 249.475 148.855 246.247C149.238 246.086 149.562 245.801 149.778 245.434C150.446 244.159 149.208 243.026 148.135 242.345C142.643 238.82 137.054 235.248 130.835 233.497C124.615 231.745 117.54 232.043 112.229 235.842C105.987 240.314 103.714 249.5 102.663 257.317'
        fill='#5172E1'
      />
      <path
        opacity='0.3'
        d='M149.966 245.1C149.773 245.032 149.586 244.945 149.409 244.84L147.856 244.014C146.514 243.294 144.577 242.239 142.128 241.045C139.277 239.625 136.308 238.462 133.257 237.572C129.522 236.489 125.612 236.169 121.755 236.631C117.891 237.073 114.244 238.67 111.278 241.221C108.955 243.411 107.095 246.058 105.814 249.001C104.668 251.495 103.96 253.592 103.44 255.06C103.198 255.757 103.01 256.315 102.859 256.736C102.8 256.935 102.724 257.13 102.633 257.317C102.657 257.111 102.706 256.908 102.776 256.713C102.905 256.277 103.07 255.711 103.281 255.007C103.915 252.923 104.67 250.88 105.543 248.887C106.807 245.88 108.67 243.171 111.015 240.93C114.036 238.314 117.762 236.678 121.71 236.233C125.609 235.753 129.565 236.076 133.34 237.182C136.409 238.095 139.388 239.293 142.241 240.762C144.683 241.994 146.597 243.103 147.916 243.868L149.424 244.763C149.602 244.862 149.774 244.974 149.936 245.1'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M141.682 249.031C141.614 249.131 137.322 246.298 132.092 242.711C126.862 239.123 122.683 236.128 122.751 236.02C122.819 235.912 127.104 238.753 132.334 242.341C137.563 245.929 141.75 248.931 141.682 249.031Z'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M136.95 236.019C136.006 236.642 135.018 236.981 134.02 237.025C133.028 237.284 132.021 237.256 131.033 236.945C132.008 236.621 132.995 236.434 133.984 236.381C134.967 236.122 135.958 236 136.95 236.019Z'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M118.017 233.651C117.757 234.551 117.405 235.384 116.973 236.119C116.603 236.961 116.158 237.724 115.65 238.384C115.906 237.483 116.258 236.652 116.693 235.924C117.06 235.075 117.506 234.309 118.017 233.651Z'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M122.75 251.377C122.028 251.073 121.339 250.689 120.698 250.23C119.466 249.455 117.797 248.333 116.016 247C114.235 245.668 112.664 244.405 111.507 243.483C110.87 243.025 110.277 242.506 109.733 241.934C110.423 242.309 111.077 242.75 111.687 243.25L116.242 246.66C118.015 247.984 119.646 249.153 120.833 249.99C121.499 250.407 122.13 250.878 122.72 251.401'
        fill='#D3DCF8'
      />
      <path
        d='M110.736 362.634H77.9638L71.8666 317.667H116.833L110.736 362.634Z'
        fill='#576675'
      />
      <path
        d='M120.8 324.767H67.9L67.1333 314.117H121.567L120.8 324.767Z'
        fill='#576675'
      />
      <path
        opacity='0.2'
        d='M73.0499 324.767H116.833L111.577 362.634L106.321 362.285L109.255 327.796L73.3828 327.206L73.0499 324.767Z'
        fill='#161A1D'
      />
      <path
        opacity='0.2'
        d='M110.015 314.191L108.55 324.767H120.834L121.567 314.117L110.015 314.191Z'
        fill='#161A1D'
      />
      <path
        d='M74.9746 235.231C71.8427 232.81 68.5293 232.34 64.6031 232.494C60.6716 232.764 56.824 233.776 53.2556 235.478C47.1205 238.223 41.3484 242.255 37.6794 247.976C34.0104 253.697 32.732 261.261 35.478 267.491C35.6921 268.145 36.137 268.694 36.7263 269.033C37.4056 269.232 38.1326 269.177 38.7763 268.879C45.7588 266.566 52.7942 264.253 59.2017 260.575C65.6092 256.897 71.4644 251.885 74.914 245.277C75.7984 243.717 76.3597 241.987 76.5632 240.196C76.6593 239.302 76.5662 238.397 76.2909 237.542C76.0155 236.687 75.5639 235.902 74.967 235.239'
        fill='#5172E1'
      />
      <path
        opacity='0.3'
        d='M75.4166 234.834C75.2531 234.919 75.0811 234.988 74.9046 235.04L73.4297 235.545L72.3599 235.904C71.9701 236.042 71.5575 236.218 71.1066 236.394C70.2201 236.753 69.1809 237.113 68.0499 237.648L66.3228 238.412C65.7267 238.688 65.123 239.024 64.4887 239.353C63.2126 239.98 61.9211 240.783 60.5379 241.593C57.6034 243.446 54.8134 245.519 52.193 247.794C49.5993 250.106 47.1875 252.613 44.979 255.295C44.0009 256.557 43.038 257.735 42.2509 258.927C41.8535 259.516 41.4409 260.067 41.0893 260.625C40.7378 261.183 40.4092 261.726 40.0883 262.238C39.4234 263.255 38.9343 264.234 38.4605 265.075C38.2313 265.503 38.0097 265.886 37.8186 266.253L37.3295 267.27C37.0468 267.851 36.8175 268.309 36.6341 268.676C36.563 268.844 36.4737 269.003 36.3667 269.151C36.411 268.973 36.4752 268.801 36.5577 268.638C36.7182 268.264 36.9169 267.789 37.1767 267.201L37.6276 266.161C37.811 265.786 38.0249 265.396 38.2466 264.96C38.6974 264.112 39.1712 263.11 39.8208 262.078L40.7989 260.441C41.1505 259.876 41.5631 259.317 41.9529 258.721C42.7171 257.513 43.6876 256.312 44.6657 255.035C49.0514 249.574 54.3526 244.917 60.3316 241.272C61.7224 240.462 63.0291 239.666 64.3206 239.047C64.9625 238.734 65.5739 238.397 66.1776 238.122L67.9276 237.357C69.0357 236.845 70.0826 236.501 70.9843 236.164C71.4428 235.996 71.8632 235.836 72.2529 235.706L73.338 235.384L74.8664 234.956C75.0399 234.897 75.2195 234.856 75.4013 234.834'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M50.5666 247.862C48.07 248.865 45.524 249.726 42.9394 250.442C40.3927 251.302 37.8036 252.017 35.1833 252.584C37.68 251.581 40.2259 250.72 42.8105 250.004C47.0639 248.698 50.5363 247.734 50.5666 247.862Z'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M64.7337 257.317C64.6273 257.317 64.2947 253.091 63.9821 247.862C63.6694 242.634 63.5097 238.392 63.6162 238.384C63.7226 238.376 64.0552 242.603 64.3679 247.831C64.6805 253.06 64.8401 257.309 64.7337 257.317Z'
        fill='#D3DCF8'
      />
      <path
        d='M69.4999 219.022L65.2117 226.179C65.1154 226.342 64.9788 226.527 64.7781 226.549C64.5773 226.571 64.3846 226.364 64.2561 226.194L52.9333 210.17L55.5432 208.801L69.4999 219.022Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M69.4999 219.022L65.2117 226.179C65.1154 226.342 64.9788 226.527 64.7781 226.549C64.5773 226.571 64.3846 226.364 64.2561 226.194L52.9333 210.17L55.5432 208.801L69.4999 219.022Z'
        fill='#07102C'
      />
      <path
        d='M91.2296 232.467L53.8721 210.688C53.4638 210.511 53.1386 210.188 52.9639 209.783C52.8165 209.238 53.2278 208.709 53.6314 208.303C56.1975 205.668 59.3092 203.613 62.7531 202.276C66.197 200.94 69.8924 200.353 73.5871 200.557C77.2832 200.815 80.8599 201.96 84.0073 203.892C87.1547 205.825 89.7774 208.486 91.6488 211.646C95.258 218.004 95.3744 226.424 91.2452 232.467'
        fill='#5172E1'
      />
      <path
        opacity='0.3'
        d='M58.85 204.198C59.0069 204.152 59.1707 204.132 59.3345 204.138C59.6499 204.138 60.1037 204.063 60.7344 204.07C62.4534 204.045 64.1709 204.179 65.8645 204.469C71.51 205.398 76.7655 207.886 81.0111 211.642C85.2568 215.397 88.3141 220.262 89.8231 225.661C90.2877 227.284 90.5938 228.945 90.7384 230.625C90.7784 230.974 90.7991 231.326 90.7999 231.678C90.7699 231.959 90.673 232.23 90.5153 232.467C90.6253 231.864 90.6438 231.248 90.5692 230.64C89.8277 224.302 87.0204 218.365 82.5617 213.708C78.103 209.05 72.2299 205.919 65.8107 204.777C64.1363 204.466 62.4388 204.287 60.7344 204.243C59.5191 204.243 58.85 204.243 58.85 204.243'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M86.0666 217.11C85.4917 217.502 84.8784 217.818 84.2404 218.051C83.0905 218.573 81.4747 219.232 79.6785 219.883C77.8823 220.533 76.2214 221.056 75.019 221.389C74.3787 221.614 73.7181 221.758 73.0499 221.817C73.6542 221.489 74.2862 221.23 74.9363 221.047L79.5432 219.412L84.1351 217.7C84.7574 217.415 85.4052 217.208 86.0666 217.084'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M54.1166 209.984C54.99 209.58 55.8927 209.251 56.817 208.999C58.4989 208.462 60.8521 207.773 63.4676 207.125C66.083 206.476 68.4825 205.963 70.2184 205.651C71.1489 205.431 72.0963 205.297 73.0499 205.251C72.1449 205.56 71.2191 205.8 70.2801 205.971C68.5596 206.364 66.1911 206.917 63.5756 207.573C60.9601 208.23 58.6069 208.855 56.9018 209.311C55.9914 209.608 55.0602 209.833 54.1166 209.984Z'
        fill='#D3DCF8'
      />
      <path
        d='M105.732 273.035C102.538 272.191 98.7366 275.31 98.0824 278.56C97.4283 281.81 98.6366 285.13 100.053 288.125C103.2 294.749 107.533 300.893 113.244 305.466C118.954 310.039 126.104 312.972 133.4 312.933C132.053 306.634 133.762 300.057 133.007 293.665C132.346 288.031 129.803 282.788 125.793 278.796C121.783 274.804 116.545 272.298 110.935 271.688C108.895 271.464 106.54 271.147 105.732 273.035Z'
        fill='#5172E1'
      />
      <path
        opacity='0.3'
        d='M133.4 312.896C133.243 312.844 133.093 312.773 132.953 312.684C132.541 312.467 132.148 312.213 131.78 311.927C130.463 310.785 129.407 309.373 128.683 307.788C128.184 306.819 127.623 305.767 127.078 304.594C126.533 303.421 125.988 302.18 125.39 300.871C124.782 299.501 124.092 298.169 123.323 296.883C122.471 295.521 121.516 294.226 120.469 293.008C118.319 290.48 116.018 288.301 114.019 286.197C112.212 284.374 110.566 282.399 109.098 280.294C108.115 278.868 107.309 277.328 106.698 275.708C106.535 275.27 106.395 274.822 106.282 274.368C106.227 274.212 106.195 274.049 106.183 273.884C106.221 273.884 106.395 274.527 106.857 275.647C107.526 277.224 108.364 278.724 109.355 280.12C110.845 282.184 112.5 284.123 114.307 285.917C116.313 287.975 118.629 290.155 120.802 292.728C121.866 293.964 122.83 295.283 123.687 296.671C124.464 297.972 125.157 299.322 125.761 300.712C126.336 302.029 126.887 303.275 127.411 304.45C127.934 305.616 128.479 306.721 128.926 307.651C129.608 309.222 130.606 310.636 131.855 311.806C132.771 312.563 133.37 312.888 133.37 312.934'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M116.833 307.017C116.846 306.351 116.962 305.689 117.18 305.046C117.472 303.837 117.964 302.184 118.612 300.382C119.259 298.58 119.971 296.968 120.536 295.817C120.789 295.183 121.134 294.573 121.567 294C121.419 294.654 121.19 295.296 120.883 295.916C120.417 297.081 119.779 298.693 119.122 300.488C118.466 302.283 117.928 303.922 117.545 305.109C117.379 305.76 117.141 306.398 116.833 307.017Z'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M133.377 292.817C132.612 292.706 131.858 292.529 131.123 292.287C129.741 291.915 127.842 291.409 125.72 290.918C123.597 290.428 121.667 290.056 120.27 289.779C119.506 289.678 118.752 289.509 118.017 289.272C118.793 289.248 119.57 289.312 120.332 289.462C121.752 289.652 123.705 289.984 125.828 290.475C127.95 290.966 129.857 291.527 131.231 291.978C131.982 292.176 132.709 292.457 133.4 292.817'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M105 296.331C105.033 295.552 105.157 294.777 105.372 294.018C105.685 292.586 106.175 290.652 106.826 288.525C107.476 286.398 108.178 284.5 108.727 283.132C108.978 282.39 109.315 281.67 109.733 280.984C109.577 281.752 109.351 282.509 109.057 283.247C108.592 284.629 107.95 286.534 107.299 288.654C106.648 290.774 106.082 292.686 105.71 294.118C105.548 294.883 105.31 295.636 105 296.367'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M126.3 278.603C126.3 278.674 122.301 278.474 117.402 278.154C114.433 278.007 111.479 277.767 108.55 277.434C111.527 277.491 114.496 277.64 117.448 277.882C120.416 278.029 123.371 278.27 126.3 278.603Z'
        fill='#D3DCF8'
      />
      <path
        d='M90.6595 242.304C90.3849 239.415 87.3959 237.232 84.4226 237.201C81.4493 237.17 78.68 238.91 76.86 241.208C75.0399 243.507 74.0436 246.311 73.2198 249.1C71.2193 255.912 70.121 263.069 70.9762 270.103C71.8313 277.137 74.7575 284.063 79.9274 289.036C80.7668 289.802 81.8573 290.653 83.0105 290.408C83.6295 290.224 84.1896 289.886 84.6344 289.427C89.6969 284.903 93.7104 279.373 96.4044 273.21C99.0984 267.045 100.411 260.39 100.254 253.69C100.168 251.016 99.8147 248.235 98.2927 246.028C96.7708 243.821 93.2405 241.431 90.6595 242.327'
        fill='#5172E1'
      />
      <path
        opacity='0.3'
        d='M81.5057 289.26C81.468 289.1 81.4483 288.936 81.4483 288.772C81.4483 288.399 81.4073 287.934 81.3827 287.363C81.3252 286.136 81.3088 284.361 81.3827 282.167C81.4565 279.973 81.5877 277.367 81.8749 274.48C82.162 271.592 82.6213 268.385 83.2612 265.132C83.901 261.879 84.6968 258.725 85.5089 255.914C86.321 253.103 87.1495 250.581 87.9698 248.547C88.7901 246.513 89.4464 244.814 89.9632 243.679C90.2093 243.153 90.4061 242.719 90.562 242.376C90.6326 242.225 90.7121 242.077 90.7999 241.934C90.7753 242.099 90.7253 242.261 90.6523 242.414L90.1108 243.747C89.6433 244.898 89.0034 246.566 88.2651 248.653C87.5268 250.741 86.7065 253.224 85.9026 256.028C85.0987 258.832 84.3522 261.925 83.7042 265.216C83.0561 268.507 82.6131 271.646 82.285 274.526C81.9569 277.405 81.7764 280.003 81.6944 282.182C81.6124 284.361 81.5631 286.144 81.5631 287.363C81.5631 287.942 81.5631 288.406 81.5631 288.78C81.5722 288.943 81.559 289.107 81.5221 289.267'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M83.678 265.601C83.2719 266.175 82.8015 266.699 82.2756 267.163C81.3698 268.081 80.0842 269.317 78.6233 270.627C77.1624 271.937 75.7745 273.069 74.7592 273.861C74.2347 274.325 73.6613 274.729 73.0499 275.067C73.5094 274.54 74.0185 274.059 74.5693 273.631L78.3457 270.308L82.0929 266.94C82.5882 266.447 83.1258 265.998 83.6999 265.601'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M95.5019 275.067C94.9251 274.365 94.4008 273.62 93.9346 272.839L90.2043 267.376L86.4036 261.967C85.8346 261.25 85.326 260.486 84.8833 259.684C85.5368 260.328 86.1316 261.029 86.6622 261.778C87.7044 263.109 89.1072 264.99 90.5805 267.116C92.0538 269.241 93.3233 271.217 94.201 272.666C94.7167 273.41 95.1626 274.201 95.5332 275.028'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M89.6157 245.488C89.6854 245.592 85.7422 248.33 80.8617 251.59C75.9811 254.849 71.9372 257.419 71.8675 257.314C71.7978 257.21 75.741 254.472 80.6215 251.204C85.5021 247.936 89.5537 245.375 89.6157 245.488Z'
        fill='#D3DCF8'
      />
      <path
        opacity='0.3'
        d='M100.267 257.317C96.1154 253.967 92.1651 250.412 88.4333 246.667C90.5625 248.284 92.5908 250.011 94.5087 251.839C96.5387 253.563 98.4614 255.392 100.267 257.317Z'
        fill='#D3DCF8'
      />
      <path
        d='M119.267 337.784V361.541H95.4999V337.784H93.1999V361.541H69.4333V337.784H67.1333V418.25H69.4333V368.438H93.1999V418.25H95.4999V368.438H119.267V418.25H121.567V368.438V361.541V337.784H119.267Z'
        fill='#2B333B'
      />
    </>
  ),
});

export default SlideWelcome;
