import React from 'react';
import { createIcon } from '@chakra-ui/react';

const WomanWriting = createIcon({
  displayName: 'OutlineUpload',
  viewBox: '0 0 517 458',
  defaultProps: {
    fill: 'transparent'
  },
  path: (
    <>
      <path
        d='M317.904 417.074H161.741V458H317.904C323.2 457.808 328.212 455.569 331.892 451.752C335.571 447.938 337.627 442.838 337.627 437.537C337.627 432.233 335.571 427.134 331.892 423.319C328.212 419.502 323.2 417.263 317.904 417.074Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M317.904 417.074H161.741V458H317.904C323.2 457.808 328.212 455.569 331.892 451.752C335.571 447.938 337.627 442.838 337.627 437.537C337.627 432.233 335.571 427.134 331.892 423.319C328.212 419.502 323.2 417.263 317.904 417.074Z'
        fill='white'
      />
      <path
        d='M174.754 458H95.7168V452.153H120.203V423.157H95.7168V417.074H174.754C180.05 417.263 185.065 419.502 188.742 423.319C192.421 427.134 194.477 432.233 194.477 437.537C194.477 442.838 192.421 447.938 188.742 451.752C185.065 455.569 180.05 457.808 174.754 458Z'
        fill='#4D61FC'
      />
      <path
        d='M101.56 451.917H174.547C176.436 451.923 178.306 451.555 180.052 450.835C181.799 450.114 183.383 449.054 184.719 447.715C186.055 446.378 187.11 444.786 187.828 443.037C188.545 441.289 188.909 439.414 188.899 437.524C188.899 433.712 187.385 430.06 184.696 427.365C182.003 424.669 178.353 423.157 174.547 423.157H101.56V451.917Z'
        fill='white'
      />
      <path
        d='M341.474 385.612H210.897V417.073H341.474C342.184 417.084 342.888 416.95 343.546 416.68C344.203 416.41 344.8 416.009 345.298 415.5C346.288 414.483 346.84 413.12 346.843 411.699V391.013C346.864 390.3 346.741 389.592 346.479 388.929C346.217 388.265 345.822 387.663 345.319 387.157C344.816 386.651 344.216 386.252 343.556 385.987C342.894 385.722 342.187 385.594 341.474 385.612Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M267.276 396.181H312.163C312.761 396.181 313.331 396.417 313.756 396.842C314.177 397.264 314.416 397.838 314.416 398.436V405.383C314.408 405.976 314.167 406.542 313.745 406.962C313.324 407.379 312.755 407.612 312.163 407.612H267.276C266.98 407.614 266.689 407.559 266.414 407.449C266.142 407.339 265.893 407.177 265.684 406.967C265.474 406.76 265.309 406.513 265.194 406.241C265.081 405.971 265.024 405.677 265.024 405.383V398.436C265.019 398.139 265.076 397.843 265.186 397.568C265.299 397.293 265.464 397.043 265.676 396.834C265.885 396.624 266.134 396.456 266.409 396.346C266.684 396.233 266.977 396.176 267.276 396.181Z'
        fill='black'
      />
      <path
        d='M160.382 417.073H231.484C232.194 417.084 232.898 416.95 233.556 416.68C234.213 416.41 234.81 416.009 235.308 415.5C236.298 414.483 236.85 413.12 236.853 411.699V391.013C236.874 390.3 236.751 389.592 236.489 388.929C236.227 388.265 235.831 387.663 235.329 387.157C234.826 386.651 234.226 386.252 233.566 385.987C232.904 385.722 232.197 385.594 231.484 385.612H160.513L160.382 417.073Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M160.382 417.073H231.484C232.194 417.084 232.898 416.95 233.556 416.68C234.213 416.41 234.81 416.009 235.308 415.5C236.298 414.483 236.85 413.12 236.853 411.699V391.013C236.874 390.3 236.751 389.592 236.489 388.929C236.227 388.265 235.831 387.663 235.329 387.157C234.826 386.651 234.226 386.252 233.566 385.987C232.904 385.722 232.197 385.594 231.484 385.612H160.513L160.382 417.073Z'
        fill='black'
      />
      <path
        d='M160.382 412.357H229.337C230.031 412.357 230.696 412.079 231.188 411.589C231.678 411.096 231.956 410.43 231.956 409.735V392.773C231.948 392.07 231.678 391.394 231.196 390.885C230.947 390.631 230.649 390.429 230.319 390.295C229.991 390.159 229.638 390.091 229.284 390.099H160.382V412.357Z'
        fill='white'
      />
      <path
        d='M120.203 385.613H274.454V354.152H120.203C119.228 354.147 118.262 354.338 117.361 354.708C116.46 355.08 115.643 355.625 114.954 356.315C114.266 357.007 113.718 357.825 113.346 358.727C112.977 359.629 112.786 360.594 112.791 361.571V378.429C112.812 379.384 113.022 380.325 113.409 381.195C113.794 382.068 114.349 382.857 115.043 383.516C116.444 384.874 118.327 385.629 120.281 385.613'
        fill='#4D61FC'
      />
      <path
        opacity='0.3'
        d='M120.203 385.613H274.454V354.152H120.203C119.228 354.147 118.262 354.338 117.361 354.708C116.46 355.08 115.643 355.625 114.954 356.315C114.266 357.007 113.718 357.825 113.346 358.727C112.977 359.629 112.786 360.594 112.791 361.571V378.429C112.812 379.384 113.022 380.325 113.409 381.195C113.794 382.068 114.349 382.857 115.043 383.516C116.444 384.874 118.327 385.629 120.281 385.613'
        fill='white'
      />
      <path
        d='M327.227 358.871H256.256C255.562 358.871 254.894 359.149 254.404 359.639C253.912 360.132 253.637 360.798 253.637 361.493V378.351C253.637 378.705 253.71 379.054 253.849 379.379C253.99 379.704 254.192 379.995 254.449 380.239C254.695 380.49 254.988 380.69 255.31 380.823C255.635 380.96 255.983 381.028 256.334 381.025H327.306L327.227 358.871Z'
        fill='white'
      />
      <path
        d='M254.242 354.154H332.389V358.874H256.258C255.928 358.808 255.59 358.808 255.263 358.874C254.936 358.989 254.634 359.17 254.378 359.403C254.124 359.639 253.914 359.922 253.77 360.237C253.634 360.552 253.563 360.89 253.561 361.233V378.327C253.563 378.668 253.634 379.006 253.77 379.323C253.906 379.643 254.111 379.929 254.373 380.162C254.632 380.385 254.936 380.553 255.263 380.66C255.58 380.781 255.92 380.826 256.258 380.792H332.389V385.511H254.242C253.529 385.529 252.82 385.401 252.16 385.136C251.497 384.868 250.897 384.473 250.394 383.967C249.892 383.461 249.499 382.857 249.237 382.194C248.975 381.531 248.849 380.82 248.873 380.11V359.424C248.865 358.716 248.999 358.016 249.268 357.361C249.538 356.708 249.939 356.116 250.444 355.623C250.937 355.117 251.528 354.718 252.181 354.446C252.833 354.175 253.534 354.042 254.242 354.05'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M254.242 354.154H332.389V358.874H256.258C255.928 358.808 255.59 358.808 255.263 358.874C254.936 358.989 254.634 359.17 254.378 359.403C254.124 359.639 253.914 359.922 253.77 360.237C253.634 360.552 253.563 360.89 253.561 361.233V378.327C253.563 378.668 253.634 379.006 253.77 379.323C253.906 379.643 254.111 379.929 254.373 380.162C254.632 380.385 254.936 380.553 255.263 380.66C255.58 380.781 255.92 380.826 256.258 380.792H332.389V385.511H254.242C253.529 385.529 252.82 385.401 252.16 385.136C251.497 384.868 250.897 384.473 250.394 383.967C249.892 383.461 249.499 382.857 249.237 382.194C248.975 381.531 248.849 380.82 248.873 380.11V359.424C248.865 358.716 248.999 358.016 249.268 357.361C249.538 356.708 249.939 356.116 250.444 355.623C250.937 355.117 251.528 354.718 252.181 354.446C252.833 354.175 253.534 354.042 254.242 354.05'
        fill='black'
      />
      <path
        opacity='0.1'
        d='M11.3213 391.938L60.0056 392.878L11.3213 393.45V391.938Z'
        fill='black'
      />
      <path
        d='M16.7859 391.574L43.1465 409.829C43.629 410.174 44.1779 410.413 44.7588 410.532C45.3391 410.651 45.9384 410.651 46.517 410.52C47.0949 410.389 47.6402 410.139 48.1168 409.787C48.5928 409.436 48.99 408.984 49.2816 408.466C50.0327 407.096 50.6403 405.649 51.0955 404.155C51.4269 403.113 51.9906 402.159 52.7434 401.368L80.8645 371.64L92.0216 359.137L79.8746 345.055L42.8916 392.628C42.7001 392.889 42.4357 393.088 42.1322 393.199C41.8287 393.31 41.4992 393.329 41.1844 393.253L22.684 388.383C21.0444 387.929 19.3034 388.008 17.7106 388.609C16.3354 389.169 15.3218 390.098 16.7859 391.574Z'
        fill='#FC9D9D'
      />
      <path
        opacity='0.15'
        d='M52.7208 401.368C51.968 402.16 51.4042 403.113 51.0729 404.155C50.6111 405.673 49.9911 407.132 49.2234 408.519C48.9241 409.025 48.5228 409.466 48.045 409.811C47.5672 410.156 47.0237 410.395 46.4487 410.52C45.8737 410.639 45.2797 410.639 44.7041 410.52C44.1286 410.4 43.5844 410.162 43.106 409.817L16.7633 391.574C16.5327 391.414 16.362 391.182 16.2784 390.913C16.1942 390.644 16.2025 390.355 16.3009 390.091L42.454 406.894C45.2697 408.846 47.1962 406.03 48.2454 403.762C49.1725 401.737 50.3693 399.849 51.802 398.147L88.1798 354.76L91.9735 359.154L80.8181 371.658L52.7208 401.368Z'
        fill='black'
      />
      <path
        d='M27.2848 389.609L22.6849 388.418C21.0447 387.965 19.3037 388.044 17.7115 388.644C16.3363 389.186 15.3404 390.103 16.7868 391.621L43.1474 409.877C43.6293 410.222 44.1788 410.46 44.7591 410.579C45.3394 410.698 45.9387 410.698 46.5173 410.567C47.0958 410.436 47.6406 410.186 48.1171 409.835C48.5937 409.484 48.9903 409.031 49.2825 408.513C50.033 407.144 50.6406 405.697 51.0964 404.203C51.2897 403.601 51.5605 403.036 51.9026 402.505C25.5539 394.402 27.2848 389.609 27.2848 389.609Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.3'
        d='M16.0998 389.979C15.9931 390.42 16.1651 390.95 16.7875 391.575L43.148 409.829C43.7058 410.222 44.349 410.478 45.0241 410.574C45.6999 410.663 46.3875 410.591 47.0306 410.365L16.0998 389.979Z'
        fill='black'
      />
      <path
        d='M131.352 315.375H106.455C101.488 315.375 97.1785 318.054 91.6954 325.574L43.2363 390.098L55.0918 399.624L112.383 344.847L131.352 315.375Z'
        fill='#FFCE6B'
      />
      <path
        d='M170.978 226.661L156.272 228.941C152.223 229.733 148.4 235.181 147.38 239.194L129.479 279.681L142.519 314.214L207.724 315.405C207.724 315.405 208.317 289.207 211.281 277.895L230.937 275.335L220.267 235.502C219.716 233.43 218.554 231.573 216.93 230.173C215.312 228.774 213.314 227.896 211.192 227.655L192.306 225.5L170.978 226.661Z'
        fill='#CCADEB'
      />
      <path
        opacity='0.2'
        d='M151.192 252.292C151.631 251.538 151.797 250.653 151.655 249.791C151.506 248.993 151.062 248.6 149.936 250.774C147.772 254.888 141.986 269.422 139.266 275.185C139.266 275.185 141.637 290.07 141.044 296.024H149.882C149.882 296.024 150.973 280.323 146.711 274.441C145.211 272.314 144.595 269.682 144.998 267.105C145.436 264.111 147.962 258.097 151.192 252.292Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M124.624 315.375H109.722C109.722 315.375 95.744 319.543 97.7713 334.66C98.6723 341.364 99.4904 348.17 96.0464 353.981L52.1279 396.647H52.1694L55.1333 399.624L111.239 346.038L127.754 320.555L124.624 315.375Z'
        fill='black'
      />
      <path
        d='M199.503 178.832C199.503 178.832 219.871 178.689 220.985 198.277C221.062 199.653 221.027 201.034 221.139 202.409C221.554 207.518 222.918 217.64 227.067 221.653L224.305 222.469C224.305 222.469 220.155 217.283 219.995 212.817C219.995 212.817 218.501 219.277 220.505 223.582C220.505 223.582 214.654 227.94 200.185 228.428C200.185 228.428 194.482 218.491 199.319 207.792C199.545 207.286 199.811 206.798 200.084 206.309C200.801 205.023 202.811 200.57 200.949 193.413L199.503 178.832Z'
        fill='black'
      />
      <path
        d='M205.033 187.25C205.229 181.917 202.075 177.468 197.979 177.313C193.883 177.157 190.398 181.355 190.196 186.688C190.001 192.021 193.154 196.47 197.25 196.626C201.346 196.78 204.832 192.583 205.033 187.25Z'
        fill='#4D61FC'
      />
      <path
        d='M185.655 213.227C189.01 212.532 192.098 210.897 194.564 208.512C197.03 206.126 198.779 203.087 199.597 199.747C202.656 188.435 200.528 182.832 197.641 176.437C195.471 171.621 186.485 164.291 176.443 165.94C166.307 167.607 160.634 173.002 159.366 182.225C156.995 199.485 169.188 221.468 185.655 213.227Z'
        fill='black'
      />
      <path
        d='M117.955 434.24L148.038 423.231C148.595 423.035 149.111 422.725 149.544 422.315C149.977 421.91 150.314 421.415 150.546 420.868C150.777 420.32 150.895 419.73 150.884 419.135C150.872 418.54 150.735 417.956 150.486 417.414C149.805 416.009 148.987 414.676 148.038 413.431C147.38 412.55 146.936 411.532 146.734 410.454L137.102 370.204L133.438 353.843L116.758 355.629L133.948 413.467C134.055 413.776 134.067 414.116 133.978 414.431C133.889 414.753 133.711 415.039 133.462 415.253L118.791 427.584C117.475 428.667 116.532 430.138 116.1 431.787C115.744 433.216 115.898 434.58 117.955 434.24Z'
        fill='#FC9D9D'
      />
      <path
        opacity='0.15'
        d='M146.728 410.473C146.936 411.55 147.38 412.568 148.038 413.45C149.011 414.706 149.84 416.057 150.528 417.486C150.771 418.028 150.896 418.612 150.896 419.201C150.901 419.791 150.777 420.374 150.546 420.916C150.309 421.458 149.965 421.946 149.538 422.351C149.105 422.75 148.596 423.059 148.038 423.25L117.973 434.253C117.712 434.348 117.422 434.354 117.155 434.265C116.888 434.175 116.663 434.003 116.503 433.771L145.288 422.089C148.501 420.898 147.345 417.695 146.1 415.539C144.997 413.598 144.162 411.52 143.616 409.353L129.473 354.439L133.456 353.88L137.12 370.241L146.728 410.473Z'
        fill='black'
      />
      <path
        d='M189.479 198.795L194.511 225.439L192.514 226.796C184.908 231.72 173.646 233.507 170.99 226.665L171.826 208.541L189.479 198.795Z'
        fill='#FC9D9D'
      />
      <path
        opacity='0.15'
        d='M171.477 215.18C173.794 216.097 173.539 216.549 175.988 216.258L177.671 216.014C177.873 215.993 178.074 215.943 178.264 215.865H178.382C178.685 215.758 178.975 215.716 179.349 215.591C179.467 215.591 179.586 215.496 179.704 215.478C181.352 214.957 182.887 214.135 184.239 213.054C184.275 213.017 184.322 212.988 184.375 212.971C184.429 212.959 184.482 212.959 184.535 212.971C184.589 212.975 184.636 212.989 184.684 213.013C184.731 213.037 184.772 213.071 184.802 213.114C184.873 213.2 184.909 213.307 184.909 213.418C184.897 213.528 184.85 213.63 184.767 213.703C183.024 215.112 181.002 216.127 178.839 216.68C177.019 218.583 174.63 219.837 172.034 220.253C171.008 220.408 172.704 220.3 171.666 220.193L171.477 215.18Z'
        fill='black'
      />
      <path
        d='M167.386 309.974V310.017V309.974Z'
        fill='white'
        stroke='black'
        strokeWidth='0.946876'
        strokeMiterlimit='10'
      />
      <path
        d='M182.371 299.221L176.58 300.632L167.125 299.596C166.04 299.733 165.051 300.288 164.363 301.144L154.191 310.135C153.978 310.373 153.865 310.684 153.877 311.003C153.889 311.322 154.019 311.625 154.244 311.85C154.476 312.072 154.784 312.196 155.104 312.196C155.424 312.196 155.732 312.072 155.963 311.85L164.262 304.895L158.572 311.826C158.459 311.985 158.382 312.17 158.358 312.365C158.329 312.559 158.352 312.758 158.423 312.942C158.489 313.126 158.601 313.289 158.749 313.419C158.898 313.548 159.075 313.639 159.265 313.683C159.478 313.732 159.704 313.722 159.911 313.655C160.119 313.588 160.302 313.467 160.451 313.302L166.165 306.36L161.826 311.647C161.707 311.792 161.624 311.966 161.589 312.151C161.553 312.336 161.565 312.527 161.624 312.706C161.684 312.885 161.79 313.046 161.927 313.173C162.069 313.3 162.235 313.39 162.419 313.433C162.602 313.491 162.792 313.498 162.982 313.457C163.166 313.415 163.337 313.325 163.48 313.195L169.739 307.497L165.507 311.391C165.394 311.494 165.305 311.623 165.246 311.768C165.193 311.913 165.169 312.069 165.187 312.223C165.205 312.377 165.258 312.525 165.341 312.655C165.424 312.785 165.537 312.892 165.673 312.969C165.821 313.049 165.987 313.092 166.153 313.092C166.319 313.092 166.485 313.049 166.633 312.969L172.525 309.742C172.81 309.585 173.118 309.49 173.444 309.463C173.764 309.436 174.09 309.479 174.393 309.587L176.841 310.218C178.323 310.736 180.279 310.385 182.389 310.165L187.208 309.694C185.946 306.533 182.371 299.221 182.371 299.221Z'
        fill='#FC9D9D'
      />
      <path
        opacity='0.15'
        d='M171.173 309.017C169.78 309.91 167.451 311.398 166.639 311.994C165.501 312.815 165.062 311.94 165.062 311.94C165.038 312.144 165.074 312.351 165.163 312.536C165.251 312.721 165.394 312.876 165.566 312.982C165.72 313.068 165.892 313.113 166.07 313.113C166.247 313.113 166.419 313.068 166.573 312.982L172.726 309.618C173.023 309.451 173.349 309.351 173.681 309.323C174.019 309.295 174.356 309.34 174.671 309.457L173.343 308.862C172.999 308.712 172.62 308.647 172.24 308.674C171.861 308.702 171.499 308.819 171.173 309.017Z'
        fill='black'
      />
      <g opacity='0.15'>
        <path
          opacity='0.15'
          d='M166.354 311.117C166.378 311.12 166.402 311.116 166.42 311.106C166.443 311.097 166.461 311.083 166.479 311.064L170.545 307.342C170.622 307.247 170.652 307.116 170.545 307.039C170.504 306.998 170.45 306.976 170.391 306.976C170.332 306.976 170.279 306.998 170.237 307.039L166.171 310.754C166.129 310.796 166.105 310.851 166.105 310.909C166.105 310.967 166.129 311.023 166.171 311.064C166.224 311.099 166.289 311.117 166.354 311.117Z'
          fill='black'
        />
      </g>
      <g opacity='0.15'>
        <path
          opacity='0.15'
          d='M162.597 311.224C162.638 311.224 162.727 311.224 162.721 311.171L166.983 305.985C167.001 305.963 167.013 305.938 167.025 305.91C167.031 305.884 167.037 305.855 167.031 305.827C167.031 305.798 167.019 305.771 167.007 305.746C166.995 305.721 166.978 305.699 166.954 305.681C166.93 305.663 166.906 305.65 166.883 305.641C166.853 305.633 166.823 305.631 166.8 305.633C166.77 305.636 166.74 305.644 166.717 305.657C166.693 305.671 166.669 305.689 166.651 305.711L162.384 310.909C162.366 310.928 162.348 310.952 162.336 310.978C162.324 311.004 162.318 311.032 162.318 311.061C162.318 311.089 162.324 311.117 162.336 311.143C162.348 311.17 162.366 311.193 162.384 311.212C162.419 311.228 162.455 311.238 162.49 311.24C162.526 311.242 162.561 311.237 162.597 311.224Z'
          fill='black'
        />
      </g>
      <path
        opacity='0.2'
        d='M204.363 256.656L199.526 290.594L178.98 298.405L181.944 309.123L200.557 307.932L201.097 315.225L207.736 315.041C207.813 312.1 208.512 288.432 211.292 277.834L219.923 276.709L220.777 255.209L204.363 256.656Z'
        fill='black'
      />
      <path
        d='M160.942 203.077C160.426 203.216 159.958 203.48 159.567 203.845C159.176 204.217 158.879 204.678 158.701 205.19C158.524 205.708 158.476 206.261 158.553 206.804C158.63 207.343 158.838 207.857 159.146 208.304C159.454 208.742 159.857 209.099 160.331 209.346C160.806 209.584 161.333 209.699 161.867 209.679C162.4 209.659 162.916 209.506 163.372 209.233C163.835 208.956 164.22 208.569 164.493 208.108'
        stroke='white'
        strokeWidth='1.50265'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M165.656 212.936C167.037 214.115 168.644 215.005 170.375 215.555C172.105 216.105 173.925 216.304 175.733 216.139L176.776 216.05C178.573 215.879 180.309 215.34 181.892 214.466C183.493 213.596 184.903 212.41 186.041 210.977C187.18 209.533 188.033 207.882 188.549 206.113C189.053 204.328 189.219 202.464 189.035 200.617L187.073 180.832C186.948 179.591 186.35 178.446 185.401 177.641C184.939 177.249 184.4 176.955 183.825 176.775C183.244 176.595 182.639 176.533 182.034 176.593L164.091 178.337C163.315 178.421 162.556 178.659 161.874 179.038C161.187 179.417 160.582 179.93 160.096 180.546C159.1 181.817 158.638 183.427 158.804 185.036L160.659 203.392C161.044 207.099 162.834 210.516 165.656 212.936Z'
        fill='#FC9D9D'
      />
      <path
        d='M207.731 315.375C207.731 315.375 212.177 329.409 210.819 341.46C209.669 351.7 200.618 355.267 190.019 355.154L175.946 354.939L132.449 344.55L147.268 408.258L131.856 412.425L101.423 335.619C100.522 333.384 100.178 330.96 100.433 328.561C100.682 326.163 101.512 323.863 102.858 321.864C104.197 319.864 106.011 318.227 108.128 317.096C110.25 315.964 112.615 315.373 115.016 315.375H207.731Z'
        fill='#FFCE6B'
      />
      <path
        d='M172.68 196.407C172.65 196.115 172.704 195.82 172.834 195.559C172.964 195.297 173.172 195.081 173.427 194.936C173.682 194.792 173.972 194.726 174.268 194.747C174.559 194.768 174.838 194.875 175.069 195.055C175.3 195.234 175.472 195.478 175.567 195.756C175.661 196.034 175.673 196.335 175.596 196.619C175.525 196.903 175.365 197.158 175.152 197.354C174.932 197.549 174.66 197.675 174.369 197.717C174.174 197.743 173.972 197.73 173.782 197.678C173.593 197.626 173.409 197.537 173.255 197.415C173.095 197.293 172.964 197.141 172.87 196.969C172.769 196.795 172.704 196.605 172.68 196.407Z'
        fill='black'
      />
      <path
        d='M173.172 196.515C173.166 196.475 173.166 196.433 173.178 196.393C173.184 196.353 173.202 196.315 173.225 196.282C173.249 196.248 173.279 196.22 173.314 196.199C173.35 196.177 173.391 196.164 173.433 196.158C173.492 196.148 173.557 196.157 173.611 196.183C173.67 196.209 173.717 196.251 173.747 196.305C173.782 196.359 173.8 196.42 173.794 196.483C173.794 196.545 173.771 196.606 173.735 196.656C173.699 196.707 173.646 196.745 173.587 196.766C173.528 196.788 173.468 196.791 173.409 196.776C173.344 196.761 173.29 196.728 173.249 196.682C173.207 196.635 173.178 196.577 173.172 196.515Z'
        fill='white'
      />
      <path
        d='M161.228 198.058C161.198 197.765 161.251 197.47 161.382 197.208C161.512 196.947 161.72 196.73 161.975 196.586C162.229 196.442 162.52 196.376 162.816 196.398C163.107 196.418 163.385 196.526 163.617 196.705C163.848 196.885 164.02 197.129 164.114 197.407C164.209 197.685 164.221 197.984 164.144 198.268C164.073 198.552 163.913 198.808 163.694 199.004C163.48 199.199 163.208 199.326 162.917 199.367C162.721 199.393 162.52 199.38 162.33 199.328C162.141 199.276 161.957 199.186 161.803 199.065C161.643 198.943 161.512 198.792 161.417 198.618C161.317 198.446 161.251 198.255 161.228 198.058Z'
        fill='black'
      />
      <path
        d='M161.713 198.093C161.708 198.053 161.708 198.011 161.713 197.971C161.725 197.931 161.737 197.893 161.761 197.859C161.79 197.826 161.82 197.798 161.856 197.777C161.885 197.756 161.927 197.742 161.968 197.736C162.028 197.725 162.093 197.732 162.152 197.758C162.205 197.783 162.253 197.825 162.288 197.878C162.324 197.931 162.342 197.993 162.336 198.056C162.336 198.118 162.312 198.179 162.277 198.23C162.241 198.282 162.194 198.32 162.134 198.342C162.075 198.364 162.01 198.368 161.951 198.353C161.891 198.339 161.832 198.306 161.79 198.26C161.749 198.213 161.719 198.155 161.713 198.093Z'
        fill='white'
      />
      <path
        opacity='0.15'
        d='M189.556 198.896L187.476 199.194L190.398 203.147L189.556 198.896Z'
        fill='black'
      />
      <g opacity='0.15'>
        <path
          opacity='0.15'
          d='M167.636 203.041C167.719 203.265 167.849 203.469 168.021 203.636C168.187 203.807 168.389 203.936 168.614 204.012C168.833 204.089 169.064 204.118 169.295 204.095L171.287 204.006C171.352 204.017 171.412 204.017 171.477 204.006C171.536 203.988 171.589 203.957 171.631 203.916C171.678 203.873 171.714 203.823 171.744 203.767C171.767 203.707 171.785 203.642 171.785 203.577C171.773 203.449 171.72 203.329 171.631 203.238C171.584 203.196 171.524 203.165 171.465 203.148C171.406 203.133 171.34 203.133 171.281 203.148L169.29 203.238C169.171 203.245 169.058 203.224 168.952 203.178C168.839 203.137 168.738 203.072 168.649 202.987C168.56 202.907 168.489 202.808 168.442 202.696C168.394 202.584 168.365 202.465 168.359 202.344C168.353 202.097 168.442 201.857 168.608 201.672C168.756 201.49 168.969 201.37 169.201 201.332C169.301 201.309 169.384 201.252 169.45 201.172C169.509 201.092 169.538 200.993 169.538 200.892C169.544 200.79 169.509 200.692 169.45 200.612C169.39 200.529 169.301 200.47 169.201 200.445C168.744 200.483 168.317 200.696 168.015 201.04C167.695 201.402 167.535 201.875 167.559 202.356C167.535 202.587 167.559 202.821 167.636 203.041Z'
          fill='black'
        />
      </g>
      <path
        d='M194.393 225.767L210.831 227.416C212.97 227.637 214.992 228.501 216.634 229.896C218.276 231.291 219.461 233.151 220.03 235.234L236.444 295.524C236.741 296.613 236.8 297.753 236.61 298.867C236.426 299.98 236 301.04 235.371 301.972C234.737 302.904 233.907 303.687 232.941 304.265C231.975 304.845 230.902 305.206 229.781 305.325L181.945 310.909L179.277 299.299L214.844 289.177L194.393 225.767Z'
        fill='#CCADEB'
      />
      <path
        d='M188.222 199.516C188.222 199.516 194.79 199.724 195.78 192.966C196.77 186.209 188.667 185.405 187.944 192.222L187.505 199.647L188.222 199.516Z'
        fill='#FC9D9D'
      />
      <path
        d='M190.356 196.896C190.943 196.877 191.524 197.017 192.034 197.301C192.55 197.595 192.976 198.018 193.279 198.528C193.581 199.045 193.741 199.631 193.753 200.231C193.765 200.828 193.617 201.418 193.326 201.94C193.048 202.453 192.639 202.883 192.141 203.184C191.637 203.476 191.068 203.626 190.487 203.619C189.9 203.604 189.331 203.432 188.833 203.118C188.335 202.798 187.926 202.356 187.647 201.832'
        stroke='white'
        strokeWidth='1.50265'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        opacity='0.15'
        d='M193.8 190.043C193.835 189.94 193.835 189.829 193.8 189.727C193.764 189.628 193.699 189.543 193.61 189.483C193.325 189.275 192.993 189.131 192.65 189.06C192.306 188.989 191.956 188.989 191.612 189.06C191.274 189.161 190.966 189.338 190.705 189.578C190.45 189.82 190.249 190.115 190.112 190.442C189.686 191.259 189.49 192.181 189.555 193.103V193.812C189.555 194.324 190.45 194.461 190.415 193.812V193.038C190.385 192.283 190.539 191.531 190.853 190.847C190.948 190.626 191.085 190.424 191.251 190.251C191.422 190.085 191.624 189.953 191.843 189.864C192.323 189.787 192.81 189.9 193.207 190.18C193.308 190.236 193.42 190.253 193.533 190.226C193.64 190.2 193.74 190.132 193.8 190.037V190.043Z'
        fill='black'
      />
      <path
        d='M180.712 191.919C181.163 192.709 181.423 193.593 181.483 194.503C181.512 194.936 181.489 195.372 181.418 195.801C181.252 196.889 180.783 197.908 180.06 198.736C179.349 199.552 178.412 200.14 177.369 200.427C177.007 200.523 176.64 200.575 176.272 200.582C175.531 200.623 174.796 200.517 174.097 200.268C173.403 200.02 172.763 199.635 172.218 199.135C171.097 198.109 170.416 196.692 170.303 195.175V195.08C170.285 194.479 170.35 193.877 170.499 193.294C169.568 193.115 168.602 193.229 167.742 193.621C168.329 194.491 168.673 195.502 168.744 196.551C168.845 198.066 168.353 199.562 167.375 200.719C166.895 201.283 166.314 201.746 165.656 202.08C164.998 202.415 164.281 202.614 163.545 202.665C162.994 202.694 162.443 202.636 161.915 202.493C160.754 202.186 159.716 201.52 158.952 200.588C158.175 199.637 157.713 198.468 157.624 197.241C157.517 195.726 158.009 194.23 158.993 193.074C159.977 191.919 161.37 191.196 162.876 191.061C163.463 191.042 164.049 191.11 164.618 191.264C165.668 191.554 166.616 192.141 167.351 192.955C168.406 192.402 169.627 192.246 170.789 192.514C171.08 191.817 171.501 191.184 172.034 190.65C172.562 190.133 173.19 189.728 173.883 189.46C174.061 189.386 174.245 189.322 174.435 189.269C174.802 189.18 175.182 189.127 175.561 189.108C176.741 189.03 177.908 189.342 178.898 189.995C179.639 190.487 180.262 191.145 180.712 191.919Z'
        stroke='white'
        strokeWidth='1.49236'
        strokeMiterlimit='10'
      />
      <path
        d='M168.548 206.714C168.506 206.721 168.465 206.737 168.429 206.761C168.399 206.792 168.37 206.826 168.346 206.862C168.328 206.903 168.316 206.948 168.316 206.993C168.316 207.039 168.328 207.083 168.346 207.124C168.797 207.806 169.425 208.348 170.16 208.69C170.889 209.028 171.695 209.147 172.49 209.033C173.284 208.919 174.025 208.577 174.629 208.047C175.24 207.51 175.69 206.814 175.928 206.035C175.957 205.832 175.874 205.66 175.625 205.695L168.548 206.714Z'
        fill='white'
      />
      <path
        d='M188.4 183.66C188.4 183.66 192.129 167.644 168.441 173.378C154.808 176.682 153.77 182.779 153.403 185.929C153.035 189.078 160.516 183.773 160.516 183.773C160.516 183.773 160.516 190.108 163.012 188.257C166.07 185.988 169.597 180.969 169.597 180.969C169.597 180.969 177.345 189.263 185.098 187.423C189.503 186.381 188.4 183.66 188.4 183.66Z'
        fill='black'
      />
      <path
        d='M183.219 180.707C183.219 180.707 187.137 192.764 187.285 195.169C187.487 198.45 192.205 187.429 188.234 177.688L183.219 180.707Z'
        fill='black'
      />
      <path
        d='M111.025 316.911H196.763V312.696H111.025L110.978 312.779C110.64 313.414 110.468 314.125 110.474 314.845C110.48 315.566 110.669 316.272 111.025 316.899V316.911Z'
        fill='#21252B'
      />
      <path
        opacity='0.3'
        d='M111.026 316.911H196.764V312.696H111.026C110.623 313.322 110.409 314.052 110.409 314.798C110.409 315.544 110.623 316.273 111.026 316.899V316.911Z'
        fill='black'
      />
      <path
        d='M110.836 312.797H161.103L142.377 275.34H92.1104L110.836 312.797Z'
        fill='#21252B'
      />
      <path
        opacity='0.3'
        d='M110.836 312.797H161.103L142.377 275.34H92.1104L110.836 312.797Z'
        fill='black'
      />
      <path
        d='M196.58 316.785H161.998C161.446 316.785 160.913 316.564 160.522 316.169C160.125 315.775 159.905 315.241 159.905 314.683C159.905 314.126 160.125 313.591 160.522 313.197C160.913 312.803 161.446 312.582 161.998 312.582H196.58V316.785Z'
        fill='#21252B'
      />
      <path
        d='M160.878 313.255L162.52 312.582L143.794 275.34H141.921L160.878 313.255Z'
        fill='#21252B'
      />
      <path
        opacity='0.2'
        d='M127.7 298.514C129.04 297.296 128.749 294.786 127.06 292.909C125.371 291.032 122.911 290.498 121.571 291.717C120.231 292.935 120.516 295.444 122.211 297.322C123.9 299.198 126.36 299.733 127.7 298.514Z'
        fill='white'
      />
      <path
        opacity='0.3'
        d='M160.587 312.666L110.954 312.695L110.682 312.159H160.332L160.587 312.666Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M196.953 346.253C195.382 347.616 193.545 348.637 191.565 349.251C189.579 349.866 187.492 350.062 185.43 349.825L128.002 342.466L132.483 344.705L132.448 344.55L175.945 354.97L190.018 355.184C200.616 355.291 209.668 351.725 210.818 341.49C212.175 329.439 207.73 315.405 207.73 315.405H199.875C199.875 315.405 207.854 336.512 196.953 346.253Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M128.002 342.466L143.319 409.002L147.563 407.96L132.151 344.252L128.002 342.466Z'
        fill='black'
      />
      <path
        d='M122.442 424.506L118.791 427.572C117.475 428.656 116.532 430.126 116.1 431.776C115.744 433.217 115.898 434.58 117.955 434.241L148.038 423.232C148.595 423.035 149.111 422.726 149.544 422.315C149.977 421.91 150.314 421.416 150.546 420.868C150.777 420.32 150.895 419.731 150.884 419.135C150.872 418.54 150.735 417.956 150.486 417.415C149.805 416.009 148.987 414.676 148.038 413.431C147.659 412.925 147.357 412.378 147.125 411.788C125.329 428.703 122.442 424.506 122.442 424.506Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.3'
        d='M116.266 433.877C116.562 434.223 117.09 434.383 117.955 434.241L148.038 423.232C148.678 423.005 149.259 422.624 149.728 422.124C150.196 421.624 150.534 421.017 150.723 420.356L116.266 433.877Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M229.781 305.324C230.901 305.205 231.974 304.844 232.94 304.265C233.907 303.686 234.737 302.903 235.371 301.971C235.999 301.039 236.426 299.979 236.61 298.866C236.799 297.753 236.74 296.612 236.444 295.523L234.926 289.933C234.553 300.519 226.402 300.787 226.402 300.787L181.352 308.432L181.944 310.909L229.781 305.324Z'
        fill='black'
      />
      <path
        d='M172.626 272.827L171.293 272.148L169.977 272.869L170.208 271.38L169.123 270.35L170.599 270.112L171.239 268.754L171.921 270.094L173.409 270.285L172.354 271.35L172.626 272.827Z'
        fill='white'
      />
      <path
        d='M195.744 271.041L194.41 270.362L193.094 271.082L193.325 269.594L192.24 268.564L193.716 268.326L194.356 266.968L195.038 268.308L196.526 268.498L195.471 269.564L195.744 271.041Z'
        fill='white'
      />
      <path
        d='M186.26 291.88L184.926 291.201L183.61 291.921L183.842 290.433L182.757 289.403L184.233 289.165L184.873 287.807L185.555 289.147L187.043 289.337L185.987 290.403L186.26 291.88Z'
        fill='white'
      />
      <path
        d='M213.527 296.048L212.193 295.369L210.877 296.089L211.108 294.601L210.023 293.571L211.499 293.333L212.14 291.975L212.821 293.315L214.309 293.505L213.254 294.571L213.527 296.048Z'
        fill='white'
      />
      <path
        d='M190.409 309.146L189.075 308.468L187.759 309.188L187.99 307.699L186.905 306.669L188.381 306.431L189.021 305.074L189.703 306.413L191.191 306.604L190.136 307.67L190.409 309.146Z'
        fill='white'
      />
      <path
        d='M139.431 272.232L138.097 271.553L136.781 272.273L137.012 270.785L135.928 269.755L137.404 269.516L138.044 268.159L138.726 269.499L140.213 269.689L139.158 270.755L139.431 272.232Z'
        fill='white'
      />
      <path
        d='M159.586 291.88L158.253 291.201L156.937 291.921L157.168 290.433L156.083 289.403L157.559 289.165L158.199 287.807L158.881 289.147L160.369 289.337L159.314 290.403L159.586 291.88Z'
        fill='white'
      />
      <path
        d='M209.722 284.402L208.246 284.64L209.331 285.67L209.1 287.159L209.692 286.837C209.841 285.646 210.001 284.545 210.167 283.467L209.722 284.402Z'
        fill='white'
      />
      <path
        d='M205.229 253.774L203.895 253.095L202.579 253.816L202.81 252.327L201.726 251.297L203.202 251.059L203.842 249.701L204.523 251.041L206.011 251.232L204.956 252.297L205.229 253.774Z'
        fill='white'
      />
      <path
        d='M201.079 232.34L199.746 231.661L198.43 232.381L198.661 230.893L197.576 229.863L199.052 229.625L199.692 228.267L200.374 229.607L201.862 229.797L200.807 230.863L201.079 232.34Z'
        fill='white'
      />
      <path
        d='M176.183 251.988L174.849 251.309L173.533 252.03L173.764 250.541L172.68 249.511L174.156 249.273L174.796 247.915L175.478 249.255L176.965 249.445L175.91 250.511L176.183 251.988Z'
        fill='white'
      />
      <path
        d='M159.586 238.294L158.253 237.615L156.937 238.335L157.168 236.847L156.083 235.817L157.559 235.578L158.199 234.221L158.881 235.561L160.369 235.751L159.314 236.817L159.586 238.294Z'
        fill='white'
      />
      <path
        d='M158.4 261.514L157.066 260.835L155.75 261.556L155.981 260.067L154.896 259.037L156.372 258.799L157.013 257.442L157.694 258.781L159.182 258.972L158.127 260.038L158.4 261.514Z'
        fill='white'
      />
      <path
        d='M378.073 311.108C371.365 306.915 363.79 304.311 355.929 303.499C352.313 303.123 348.354 303.225 345.456 305.413C345.114 305.599 344.817 305.876 344.623 306.213C344.418 306.551 344.315 306.934 344.315 307.326C344.475 307.878 344.84 308.345 345.342 308.636C354.355 315.471 367.977 316.36 378.016 311.108'
        fill='#4D61FC'
      />
      <path
        d='M377.503 310.438C372.38 306.689 367.588 302.495 363.208 297.907C358.804 293.274 355.701 287.559 354.229 281.344C353.852 279.453 353.727 277.3 354.948 275.796C366.356 284.693 375.301 296.198 377.503 310.472'
        fill='#4D61FC'
      />
      <path
        d='M377.96 311.02C377.104 304.789 376.819 298.398 378.222 292.269C379.626 286.141 382.991 280.285 388.228 276.8C390.201 281.356 389.369 286.687 387.965 291.495C385.809 298.939 382.455 305.984 378.04 312.353'
        fill='#4D61FC'
      />
      <path
        d='M389.14 331.022C382.295 331.842 376.442 335.419 371.377 339.999C366.289 344.626 362.741 350.701 361.212 357.405C367.875 356.995 373.522 352.313 377.606 347.062C381.69 341.81 384.543 336.035 389.015 331.113'
        fill='#4D61FC'
      />
      <path
        d='M391.559 334.224C388.889 327.451 387.953 320.12 388.832 312.896C389.722 305.67 392.403 298.781 396.624 292.85C400.491 306.577 399.065 322.081 391.559 334.224Z'
        fill='#4D61FC'
      />
      <path
        d='M406.333 351.776C400.127 351.394 393.955 352.839 388.57 355.934C383.185 359.029 378.827 363.636 376.043 369.183C375.667 369.934 375.336 370.88 375.838 371.552C376.101 371.804 376.42 371.99 376.774 372.092C377.116 372.196 377.492 372.213 377.846 372.144C383.813 371.757 389.106 368.169 393.476 364.091C397.845 360.012 401.644 355.285 406.47 351.754'
        fill='#4D61FC'
      />
      <path
        d='M405.865 350.82C402.682 340.6 402.488 329.686 405.294 319.357C406.435 315.313 408.272 311.041 412.025 309.105C412.174 309.004 412.345 308.936 412.516 308.904C412.698 308.872 412.881 308.879 413.052 308.922C413.36 309.08 413.588 309.35 413.702 309.674C414.957 312.507 415.505 315.605 415.288 318.696C415.06 321.767 414.592 324.816 413.873 327.81C412.733 333.403 412.059 339.395 408.991 344.213C408.26 345.376 407.61 346.582 407.04 347.825C406.458 349.043 406.458 349.476 405.899 350.445'
        fill='#4D61FC'
      />
      <path
        d='M444.95 356.642C442.052 345.637 445.133 332.298 452.069 323.287C452.582 322.615 453.267 321.909 454.1 321.989C455.378 322.103 455.708 323.8 455.651 325.087C455.127 336.4 451.419 347.337 444.95 356.642Z'
        fill='#4D61FC'
      />
      <path
        d='M448.476 359.045C461.858 362.52 477.214 359.136 488.406 350.946C481.732 347.596 474.099 346.663 466.821 348.303C459.61 349.943 453.028 353.167 448.567 359.045'
        fill='#4D61FC'
      />
      <path
        d='M451.407 351.72C458.903 339.508 473.277 331.546 487.595 330.828C485.941 336.638 481.161 341.16 475.753 343.894C470.345 346.628 464.345 347.813 458.389 348.963C456.108 349.408 452.902 349.909 451.407 351.72Z'
        fill='#4D61FC'
      />
      <path
        d='M445.705 316.134C447.735 309.186 449.778 302.18 450.337 294.969C450.896 287.758 449.869 280.228 446.115 274.043C439.11 287.564 438.403 303.376 445.499 316.852'
        fill='#4D61FC'
      />
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M445.705 316.134C447.735 309.186 449.778 302.18 450.337 294.969C450.896 287.758 449.869 280.228 446.115 274.043C439.11 287.564 438.403 303.376 445.499 316.852'
          fill='white'
        />
      </g>
      <path
        d='M469.274 304.275C477.466 289.466 494.168 277.55 511.076 275.887C504.55 292.097 486.764 304.628 469.274 304.275Z'
        fill='#4D61FC'
      />
      <g opacity='0.2'>
        <path
          opacity='0.2'
          d='M469.274 304.275C477.466 289.466 494.168 277.55 511.076 275.887C504.55 292.097 486.764 304.628 469.274 304.275Z'
          fill='black'
        />
      </g>
      <path
        d='M464.117 307.191C476.336 309.048 486.74 316.84 498.149 321.556C500.545 322.707 503.203 323.214 505.85 323.025C508.462 322.684 511.052 320.929 511.634 318.355C511.817 317.27 511.725 316.158 511.372 315.118C511.018 314.077 510.413 313.139 509.615 312.386C508.006 310.883 506.101 309.721 504.024 308.968C491.6 303.808 478.264 303.671 465.167 306.804'
        fill='#4D61FC'
      />
      <path
        d='M457.112 315.745C459.873 316.531 460.82 318.023 463.011 319.823C470.472 326.164 479.302 330.694 488.817 333.06C489.798 333.299 491.099 333.402 491.578 332.513C492.057 331.625 491.475 330.793 490.916 330.098C486.946 325.143 481.287 321.76 475.332 319.538C469.377 317.317 464.22 314.663 457.078 315.232'
        fill='#4D61FC'
      />
      <path
        d='M449.126 264.085C454.134 268.699 460.055 272.213 466.501 274.405C472.947 276.597 479.781 277.416 486.569 276.81C487.196 276.821 487.801 276.602 488.269 276.195C489.216 275.169 488.086 273.597 486.98 272.777C475.982 264.211 462.645 260.656 449.126 264.131'
        fill='#4D61FC'
      />
      <path
        d='M458.539 260.634C465.167 255.753 472.868 252.51 481.003 251.172C489.125 249.833 497.465 250.437 505.314 252.933C499.427 258.72 491.27 261.841 483.045 262.695C474.842 263.442 466.559 262.744 458.596 260.634'
        fill='#4D61FC'
      />
      <path
        d='M454.831 236.895C453.759 225.925 454.454 217.267 453.69 206.24C453.69 205.363 453.439 204.304 452.618 203.962C451.477 203.518 450.507 204.929 450.131 206.102C448.533 211.212 448.123 216.619 448.933 221.91C449.743 227.202 451.762 232.237 454.82 236.633'
        fill='#4D61FC'
      />
      <path
        d='M453.986 238.114C461.436 233.557 467.015 227.508 472.24 220.445C475.834 215.535 478.868 209.646 478.047 203.62C473.004 203.517 468.201 206.297 464.927 210.136C461.653 213.975 459.691 218.748 458.23 223.578C456.77 228.408 455.755 233.363 454.043 238.114'
        fill='#4D61FC'
      />
      <g opacity='0.2'>
        <path
          opacity='0.2'
          d='M453.986 238.114C461.436 233.557 467.015 227.508 472.24 220.445C475.834 215.535 478.868 209.646 478.047 203.62C473.004 203.517 468.201 206.297 464.927 210.136C461.653 213.975 459.691 218.748 458.23 223.578C456.77 228.408 455.755 233.363 454.043 238.114'
          fill='black'
        />
      </g>
      <path
        d='M471.863 233.146C488.246 227.087 503.419 218.157 516.664 206.775C511.314 204.622 505.187 205.909 499.837 208.062C487.607 212.994 477.624 221.31 471.635 233.032'
        fill='#4D61FC'
      />
      <path
        d='M459.017 244.105C467.277 245.016 475.685 245.597 483.888 244.23C492.091 242.863 499.563 239.673 507.104 236.529C507.755 236.356 508.314 235.95 508.679 235.39C508.804 235.043 508.816 234.666 508.713 234.311C508.61 233.957 508.405 233.641 508.12 233.408C507.538 232.949 506.865 232.63 506.135 232.474C497.943 230.195 489.102 231.255 481.036 233.977C473.358 236.595 465.977 240.008 459.006 244.162'
        fill='#4D61FC'
      />
      <path
        d='M411.797 264.462C406.161 253.071 406.526 239.822 411.398 228.101C414.82 233.329 415.242 239.971 414.615 246.179C413.988 252.387 412.094 258.789 411.98 265.032'
        fill='#4D61FC'
      />
      <path
        d='M389.265 253.115C382.203 253.021 375.198 254.399 368.695 257.163C362.204 259.928 356.351 264.015 351.525 269.166C358.553 270.305 365.798 268.46 372.186 265.339C378.575 262.217 384.268 257.843 389.904 253.503'
        fill='#4D61FC'
      />
      <g opacity='0.2'>
        <path
          opacity='0.2'
          d='M389.265 253.115C382.203 253.021 375.198 254.399 368.695 257.163C362.204 259.928 356.351 264.015 351.525 269.166C358.553 270.305 365.798 268.46 372.186 265.339C378.575 262.217 384.268 257.843 389.904 253.503'
          fill='black'
        />
      </g>
      <path
        d='M374.115 248.057C367.27 247.498 360.596 246.644 353.967 248.672C347.339 250.699 341.315 255.302 338.611 261.669C345.057 262.398 351.594 260.781 357.504 258.104C363.334 255.256 368.89 251.893 374.115 248.057Z'
        fill='#4D61FC'
      />
      <path
        d='M396.544 248.286C390.35 244.231 383.995 240.096 376.762 238.421C369.529 236.747 361.189 237.976 356.146 243.41C361.988 246.827 369.084 247.238 375.861 247.238C382.637 247.238 390.931 247.158 397.32 249.413'
        fill='#4D61FC'
      />
      <path
        d='M396.578 248.616C385.991 238.226 381.964 219.556 386.231 205.328C394.605 218.123 398.255 233.425 396.578 248.616Z'
        fill='#4D61FC'
      />
      <path
        d='M425.728 219.167C427.724 214.531 429.15 209.667 430.919 204.939C432.55 200.146 435.071 195.696 438.334 191.816C441.757 188.069 446.4 185.278 451.454 184.981C449.823 192.133 446.708 198.865 442.316 204.749C437.935 210.634 432.367 215.54 425.978 219.156'
        fill='#4D61FC'
      />
      <g opacity='0.2'>
        <path
          opacity='0.2'
          d='M425.728 219.167C427.724 214.531 429.15 209.667 430.919 204.939C432.55 200.146 435.071 195.696 438.334 191.816C441.757 188.069 446.4 185.278 451.454 184.981C449.823 192.133 446.708 198.865 442.316 204.749C437.935 210.634 432.367 215.54 425.978 219.156'
          fill='black'
        />
      </g>
      <path
        d='M420.445 225.307C417.137 220.751 413.327 214.588 408.832 211.193C404.337 207.799 398.564 205.748 393.042 207.058C395.871 212.01 399.819 216.236 404.576 219.4C409.333 222.563 414.753 224.578 420.423 225.285'
        fill='#4D61FC'
      />
      <path
        d='M421.506 219.555C419.338 214.052 417.148 208.493 416.281 202.65C415.414 196.806 415.927 190.552 418.928 185.46C424.746 196.43 426.617 208.243 421.803 219.634'
        fill='#4D61FC'
      />
      <path
        d='M425.772 185.461C429.07 174.57 439.052 166.562 449.822 162.883C451.157 162.336 452.606 162.117 454.043 162.245C454.762 162.329 455.435 162.62 455.994 163.081C456.541 163.541 456.952 164.154 457.158 164.842C457.545 166.688 456.017 168.351 454.568 169.581C446.148 176.763 436.348 182.166 425.772 185.461Z'
        fill='#4D61FC'
      />
      <path
        d='M425.899 176.551C426.309 169.534 426.754 162.414 429.036 155.773C431.318 149.132 435.733 142.878 442.145 139.973C443.228 147.059 440.102 154.11 436.326 160.205C432.55 166.299 427.986 172.063 425.728 178.864'
        fill='#4D61FC'
      />
      <path
        d='M425.728 181.384C423.001 173.41 422.716 163.374 417.958 156.47C414.639 151.64 409.311 148.348 406.447 143.233C404.542 148.006 405.124 153.486 407.12 158.259C409.288 162.924 412.266 167.167 415.916 170.79C419.43 174.549 422.453 177.374 425.728 181.338'
        fill='#4D61FC'
      />
      <path
        d='M425.773 414.988C425.568 414.988 425.385 362.063 425.362 296.812C425.34 231.562 425.511 178.625 425.727 178.625C425.944 178.625 426.115 231.527 426.127 296.812C426.138 362.097 425.99 414.988 425.773 414.988Z'
        fill='#61656D'
      />
      <path
        d='M472.093 256.477C471.454 256.793 470.792 257.045 470.096 257.229L464.609 259.074C460.045 260.646 453.634 262.924 446.743 265.738C439.852 268.552 433.474 271.035 429.812 274.213C428.341 275.427 427.177 276.963 426.39 278.702C425.865 279.943 425.774 280.707 425.705 280.695C425.637 280.684 425.705 280.502 425.705 280.137C425.785 279.613 425.911 279.097 426.093 278.599C426.789 276.748 427.93 275.1 429.413 273.803C431.626 271.939 434.102 270.404 436.749 269.247C439.692 267.88 442.944 266.456 446.412 265.032C453.325 262.207 459.726 259.997 464.403 258.561C466.685 257.832 468.636 257.297 469.959 256.944C470.655 256.719 471.374 256.563 472.093 256.477Z'
        fill='#61656D'
      />
      <path
        d='M487.266 219.305C487.266 219.305 487.095 219.522 486.707 219.898L485.007 221.492L478.664 227.256C473.176 232.121 465.749 238.739 457.535 246.041C449.321 253.343 442.156 260.372 437.216 265.646C434.741 268.277 432.79 270.465 431.512 271.991L429.972 273.734C429.618 274.133 429.424 274.338 429.401 274.315C429.527 274.072 429.675 273.847 429.858 273.643C430.177 273.21 430.645 272.583 431.284 271.797C432.539 270.214 434.433 267.97 436.863 265.281C443.24 258.323 449.982 251.711 457.067 245.472C465.338 238.158 472.88 231.585 478.344 226.846L484.859 221.264L486.65 219.784C486.844 219.602 487.049 219.443 487.266 219.305Z'
        fill='#61656D'
      />
      <path
        d='M448.624 253.481C448.407 253.39 450.13 249.414 451.853 244.368C453.575 239.321 454.397 235.106 454.614 235.141C454.83 235.175 454.328 239.526 452.571 244.607C451.841 246.776 450.974 248.902 450.004 250.975C449.674 251.874 449.206 252.719 448.624 253.481Z'
        fill='#61656D'
      />
      <path
        d='M425.819 285.468C425.351 285.013 424.929 284.517 424.552 283.988L421.301 279.818C418.563 276.287 414.775 271.423 410.543 266.148C406.31 260.874 402.408 256.021 399.579 252.57C398.255 250.918 397.137 249.539 396.259 248.457C395.825 247.97 395.449 247.443 395.118 246.885C395.62 247.295 396.088 247.757 396.499 248.264C397.343 249.175 398.541 250.542 400.012 252.216C402.944 255.634 406.858 260.327 411.159 265.67C415.46 271.013 419.145 275.922 421.746 279.556C423.058 281.367 424.096 282.837 424.803 283.874C425.203 284.366 425.545 284.9 425.819 285.468Z'
        fill='#61656D'
      />
      <path
        d='M415.665 272.447C415.54 272.584 412.836 269.371 411.421 264.473C410.622 261.893 410.291 259.193 410.451 256.499H410.794C410.634 257.102 410.531 257.433 410.463 257.421C410.394 257.41 410.463 257.068 410.463 256.442H410.805C410.976 259.083 411.432 261.7 412.163 264.245C413.52 269.007 415.87 272.322 415.665 272.447Z'
        fill='#4D61FC'
      />
      <path
        d='M402.557 255.986C401.644 255.286 400.652 254.703 399.591 254.255C396.921 253.462 394.103 253.241 391.342 253.605C388.547 253.822 385.763 254.267 383.037 254.938C381.919 255.298 380.778 255.571 379.614 255.758C380.63 255.15 381.736 254.701 382.889 254.425C385.615 253.577 388.433 253.046 391.274 252.842C394.149 252.459 397.058 252.771 399.785 253.753C400.937 254.15 401.918 254.94 402.557 255.986Z'
        fill='#61656D'
      />
      <path
        d='M399.453 251.989C399.453 252.114 397.719 251.294 394.89 250.337C387.52 247.813 379.625 247.213 371.959 248.594C369.027 249.118 367.247 249.665 367.213 249.54C367.179 249.414 367.624 249.312 368.422 249.027C369.54 248.629 370.681 248.306 371.845 248.059C379.591 246.364 387.668 246.976 395.061 249.824C396.179 250.25 397.263 250.745 398.313 251.305C398.723 251.476 399.111 251.706 399.453 251.989Z'
        fill='#61656D'
      />
      <path
        d='M425.727 235.117C425.522 235.117 424.028 230.013 422.408 223.6C420.788 217.186 419.624 211.958 419.829 211.912C420.035 211.867 421.518 217.004 423.149 223.418C424.781 229.831 425.933 235.06 425.727 235.117Z'
        fill='#61656D'
      />
      <path
        d='M425.933 235.117C425.705 235.185 424.792 231.699 422.259 228.282C419.726 224.864 416.726 222.882 416.84 222.7C417.616 222.908 418.335 223.299 418.928 223.839C420.468 224.958 421.803 226.329 422.887 227.894C425.476 231.448 426.115 235.105 425.933 235.117Z'
        fill='#61656D'
      />
      <path
        d='M425.772 386.76C425.692 386.76 425.533 385.495 424.939 383.24C424.061 380.094 422.874 377.043 421.403 374.126C417.74 366.654 411.135 357.187 402.613 347.698C398.414 342.914 394.341 338.402 390.885 334.028C387.793 330.226 384.986 326.193 382.511 321.965C380.845 319.108 379.43 316.113 378.278 313.011C377.89 311.872 377.605 311.04 377.445 310.448C377.343 310.149 377.263 309.84 377.229 309.525C377.297 309.525 377.685 310.744 378.552 312.942C379.807 315.979 381.29 318.92 382.978 321.737C385.511 325.9 388.34 329.875 391.444 333.629C394.866 337.913 398.985 342.401 403.183 347.197C411.671 356.709 418.322 366.3 421.882 373.899C423.331 376.874 424.46 379.999 425.225 383.217C425.43 384.093 425.59 384.978 425.692 385.871C425.761 386.163 425.784 386.461 425.772 386.76Z'
        fill='#61656D'
      />
      <path
        d='M467.62 339.577C466.924 340.04 466.194 340.451 465.429 340.807C463.444 341.924 461.539 343.179 459.725 344.567C457.067 346.649 454.568 348.934 452.264 351.402C449.64 354.17 446.936 357.382 444.175 360.822C438.665 367.726 433.508 373.843 430.428 378.718C428.853 381.134 427.712 383.173 427.005 384.608C426.651 385.369 426.252 386.104 425.796 386.806C425.83 386.592 425.898 386.385 426.001 386.191C426.161 385.793 426.389 385.2 426.743 384.471C427.701 382.389 428.796 380.369 430.006 378.422C432.995 373.433 438.117 367.236 443.616 360.344C446.377 356.926 449.115 353.68 451.785 350.912C454.146 348.455 456.702 346.198 459.44 344.168C461.288 342.804 463.262 341.604 465.315 340.58C466.045 340.215 466.639 339.987 467.026 339.828C467.209 339.713 467.414 339.628 467.62 339.577Z'
        fill='#61656D'
      />
      <path
        d='M468.442 354.863C466.925 355.205 465.373 355.429 463.81 355.535C460.091 355.927 456.406 356.652 452.812 357.7C449.264 358.855 445.933 360.599 442.967 362.86C440.685 364.614 439.635 366.061 439.544 365.97C439.715 365.58 439.955 365.221 440.24 364.91C440.959 364.008 441.769 363.18 442.647 362.438C445.59 359.996 448.968 358.14 452.607 356.971C456.246 355.894 459.988 355.232 463.776 355C465.327 354.821 466.89 354.775 468.442 354.863Z'
        fill='#61656D'
      />
      <path
        d='M440.878 364.32C440.639 364.183 442.726 360.993 444.301 356.71C445.875 352.427 446.172 348.588 446.446 348.633C446.571 349.458 446.548 350.299 446.377 351.117C446.149 353.114 445.693 355.078 445.02 356.972C444.312 358.849 443.411 360.648 442.327 362.337C441.951 363.07 441.46 363.739 440.878 364.32Z'
        fill='#61656D'
      />
      <path
        d='M481.172 298.477C480.944 298.638 480.693 298.768 480.431 298.864L478.252 299.855C477.305 300.265 476.141 300.778 474.829 301.473C473.517 302.167 471.989 302.84 470.403 303.751L467.87 305.186C466.992 305.687 466.125 306.325 465.2 306.861C463.341 308 461.47 309.378 459.496 310.802C450.962 317.156 443.513 324.85 437.443 333.585C436.097 335.624 434.762 337.538 433.701 339.441C433.154 340.375 432.56 341.263 432.115 342.152C431.671 343.04 431.191 343.906 430.758 344.726C429.857 346.344 429.218 347.893 428.59 349.283C427.963 350.673 427.449 351.778 427.084 352.7C426.719 353.623 426.411 354.329 426.172 354.91C426.08 355.175 425.955 355.426 425.807 355.662C425.841 355.388 425.909 355.12 426.023 354.865C426.229 354.261 426.491 353.509 426.81 352.586C427.21 351.387 427.666 350.208 428.191 349.055C428.784 347.699 429.4 346.127 430.279 344.498L431.591 341.89C432.058 340.978 432.618 340.078 433.154 339.122C434.203 337.196 435.515 335.26 436.862 333.198C442.908 324.348 450.426 316.587 459.074 310.244C461.093 308.831 462.987 307.453 464.881 306.337C465.817 305.767 466.706 305.198 467.596 304.685L470.163 303.284C471.783 302.361 473.335 301.689 474.727 301.005C476.118 300.322 477.236 299.866 478.149 299.502L480.431 298.647C480.67 298.562 480.921 298.505 481.172 298.477Z'
        fill='#61656D'
      />
      <path
        d='M448.054 320.69C448.362 319.619 449.001 318.671 449.879 317.979C451.921 316.212 454.511 315.216 457.215 315.166C459.862 315.156 462.486 315.671 464.938 316.68C465.977 317.004 466.958 317.48 467.859 318.093C466.798 317.878 465.76 317.573 464.756 317.181C462.337 316.359 459.793 315.935 457.238 315.928C454.693 315.986 452.241 316.849 450.233 318.389C449.445 319.102 448.715 319.871 448.054 320.69Z'
        fill='#4D61FC'
      />
      <g opacity='0.2'>
        <path
          opacity='0.2'
          d='M378.073 311.108C371.365 306.915 363.79 304.311 355.929 303.499C352.313 303.123 348.354 303.225 345.456 305.413C345.114 305.599 344.817 305.876 344.623 306.213C344.418 306.551 344.315 306.934 344.315 307.326C344.475 307.878 344.84 308.345 345.342 308.636C354.355 315.471 367.977 316.36 378.016 311.108'
          fill='black'
        />
      </g>
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M377.96 311.02C377.104 304.789 376.819 298.398 378.222 292.269C379.626 286.141 382.991 280.285 388.228 276.8C390.201 281.356 389.369 286.687 387.965 291.495C385.809 298.939 382.455 305.984 378.04 312.353'
          fill='white'
        />
      </g>
      <g opacity='0.2'>
        <path
          opacity='0.2'
          d='M391.559 334.224C388.889 327.451 387.953 320.12 388.832 312.896C389.722 305.67 392.403 298.781 396.624 292.85C400.491 306.577 399.065 322.081 391.559 334.224Z'
          fill='black'
        />
      </g>
      <g opacity='0.2'>
        <path
          opacity='0.2'
          d='M406.333 351.776C400.127 351.394 393.955 352.839 388.57 355.934C383.185 359.029 378.827 363.636 376.043 369.183C375.667 369.934 375.336 370.88 375.838 371.552C376.101 371.804 376.42 371.99 376.774 372.092C377.116 372.196 377.492 372.213 377.846 372.144C383.813 371.757 389.106 368.169 393.476 364.091C397.845 360.012 401.644 355.285 406.47 351.754'
          fill='black'
        />
      </g>
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M448.476 359.045C461.858 362.52 477.214 359.136 488.406 350.946C481.732 347.596 474.099 346.663 466.821 348.303C459.61 349.943 453.028 353.167 448.567 359.045'
          fill='white'
        />
      </g>
      <g opacity='0.2'>
        <path
          opacity='0.2'
          d='M451.407 351.72C458.903 339.508 473.277 331.546 487.595 330.828C485.941 336.638 481.161 341.16 475.753 343.894C470.345 346.628 464.345 347.813 458.389 348.963C456.108 349.408 452.902 349.909 451.407 351.72Z'
          fill='black'
        />
      </g>
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M464.117 307.191C476.336 309.048 486.74 316.84 498.149 321.556C500.545 322.707 503.203 323.214 505.85 323.025C508.462 322.684 511.052 320.929 511.634 318.355C511.817 317.27 511.725 316.158 511.372 315.118C511.018 314.077 510.413 313.139 509.615 312.386C508.006 310.883 506.101 309.721 504.024 308.968C491.6 303.808 478.264 303.671 465.167 306.804'
          fill='white'
        />
      </g>
      <g opacity='0.2'>
        <path
          opacity='0.2'
          d='M458.539 260.634C465.167 255.753 472.868 252.51 481.003 251.172C489.125 249.833 497.465 250.437 505.314 252.933C499.427 258.72 491.27 261.841 483.045 262.695C474.842 263.442 466.559 262.744 458.596 260.634'
          fill='black'
        />
      </g>
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M454.831 236.895C453.759 225.925 454.454 217.267 453.69 206.24C453.69 205.363 453.439 204.304 452.618 203.962C451.477 203.518 450.507 204.929 450.131 206.102C448.533 211.212 448.123 216.619 448.933 221.91C449.743 227.202 451.762 232.237 454.82 236.633'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M471.863 233.146C488.246 227.087 503.419 218.157 516.664 206.775C511.314 204.622 505.187 205.909 499.837 208.062C487.607 212.994 477.624 221.31 471.635 233.032'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M411.797 264.462C406.161 253.071 406.526 239.822 411.398 228.101C414.82 233.329 415.242 239.971 414.615 246.179C413.988 252.387 412.094 258.789 411.98 265.032'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M374.115 248.057C367.27 247.498 360.596 246.644 353.967 248.672C347.339 250.699 341.315 255.302 338.611 261.669C345.057 262.398 351.594 260.781 357.504 258.104C363.334 255.256 368.89 251.893 374.115 248.057Z'
          fill='white'
        />
      </g>
      <g opacity='0.2'>
        <path
          opacity='0.2'
          d='M396.578 248.616C385.991 238.226 381.964 219.556 386.231 205.328C394.605 218.123 398.255 233.425 396.578 248.616Z'
          fill='black'
        />
      </g>
      <g opacity='0.2'>
        <path
          opacity='0.2'
          d='M420.445 225.307C417.137 220.751 413.327 214.588 408.832 211.193C404.337 207.799 398.564 205.748 393.042 207.058C395.871 212.01 399.819 216.236 404.576 219.4C409.333 222.563 414.753 224.578 420.423 225.285'
          fill='black'
        />
      </g>
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M425.772 185.461C429.07 174.57 439.052 166.562 449.822 162.883C451.157 162.336 452.606 162.117 454.043 162.245C454.762 162.329 455.435 162.62 455.994 163.081C456.541 163.541 456.952 164.154 457.158 164.842C457.545 166.688 456.017 168.351 454.568 169.581C446.148 176.763 436.348 182.166 425.772 185.461Z'
          fill='white'
        />
      </g>
      <g opacity='0.2'>
        <path
          opacity='0.2'
          d='M425.728 181.384C423.001 173.41 422.716 163.374 417.958 156.47C414.639 151.64 409.311 148.348 406.447 143.233C404.542 148.006 405.124 153.486 407.12 158.259C409.288 162.924 412.266 167.167 415.916 170.79C419.43 174.549 422.453 177.374 425.728 181.338'
          fill='black'
        />
      </g>
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M415.665 272.447C415.54 272.584 412.836 269.371 411.421 264.473C410.622 261.893 410.291 259.193 410.451 256.499H410.794C410.634 257.102 410.531 257.433 410.463 257.421C410.394 257.41 410.463 257.068 410.463 256.442H410.805C410.976 259.083 411.432 261.7 412.163 264.245C413.52 269.007 415.87 272.322 415.665 272.447Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M448.054 320.69C448.362 319.619 449.001 318.671 449.879 317.979C451.921 316.212 454.511 315.216 457.215 315.166C459.862 315.156 462.486 315.671 464.938 316.68C465.977 317.004 466.958 317.48 467.859 318.093C466.798 317.878 465.76 317.573 464.756 317.181C462.337 316.359 459.793 315.935 457.238 315.928C454.693 315.986 452.241 316.849 450.233 318.389C449.445 319.102 448.715 319.871 448.054 320.69Z'
          fill='white'
        />
      </g>
      <path
        d='M443.103 325.735C442.897 325.61 444.768 322.876 445.19 318.957C445.613 315.039 444.495 311.963 444.723 311.895C445.168 312.473 445.453 313.149 445.578 313.865C446.035 315.555 446.16 317.312 445.955 319.048C445.749 320.782 445.225 322.461 444.403 324.004C443.753 325.177 443.182 325.781 443.103 325.735Z'
        fill='#61656D'
      />
      <path
        d='M389.175 394.359C390.316 414.579 396.419 435.152 405.809 455.942H446.629C455.584 435.357 461.266 414.818 462.27 394.359H389.175Z'
        fill='#B2B6BB'
      />
      <path
        d='M465.669 387.912H386.254V394.61H465.669V387.912Z'
        fill='#B2B6BB'
      />
      <path
        opacity='0.2'
        d='M389.175 394.359C389.175 395.384 389.312 396.409 389.392 397.435H446.651C446.651 397.435 447.005 432.384 435.083 455.942H446.629C455.539 435.437 461.209 414.932 462.258 394.61L389.175 394.359Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M446.697 387.912V394.553L465.67 394.61V387.912H446.697Z'
        fill='black'
      />
      <path
        d='M25.4331 194.192C30.0433 212.459 36.1281 229.657 43.4396 248.32C60.4933 243.33 77.3443 238.355 93.9913 233.395C86.2494 213.498 75.8134 191.802 73.8443 170.165C59.3732 174.404 46.2245 178.377 32.0316 182.582L25.4331 194.192Z'
        fill='white'
      />
      <g opacity='0.5'>
        <path
          opacity='0.2'
          d='M45.9253 237.567C55.1606 234.822 76.3186 228.338 85.4651 225.575L84.9699 223.948C75.8301 226.686 54.7244 233.159 45.5114 235.924L45.9253 237.567Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M44.2422 233.568C53.3819 230.83 74.4865 224.352 83.5901 221.616L83.0355 219.996C73.9609 222.726 52.8971 229.196 43.7573 231.933C43.9275 232.48 44.0848 233.024 44.2422 233.568Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M42.9731 229.463C52.0721 226.734 73.1057 220.264 82.1524 217.546C81.9541 216.981 81.761 216.469 81.5559 215.929C72.5232 218.656 51.5071 225.122 42.4231 227.836L42.9731 229.463Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M41.5587 225.376C54.8201 221.361 67.4562 217.521 80.6386 213.535L79.9956 211.928C66.8272 215.923 54.2171 219.8 40.9692 223.764L41.5587 225.376Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M40.0385 221.306C53.2469 217.303 65.8396 213.429 79.0107 209.476L78.3566 207.871C65.1941 211.865 52.6125 215.736 39.4123 219.695L40.0385 221.306Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M38.4489 217.238C51.6289 213.241 64.1983 209.372 77.3694 205.419L76.7193 203.807C63.551 207.802 50.9815 211.671 37.8059 215.631L38.4489 217.238Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M37.3854 213.06C50.513 209.073 63.1173 205.197 76.3046 201.234L75.6674 199.626C62.4758 203.626 49.8947 207.497 36.7307 211.455L37.3854 213.06Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M35.737 209.022C48.8995 205.028 61.498 201.153 74.7214 197.189L74.1074 195.576C60.8752 199.584 48.2708 203.46 35.0869 207.41L35.737 209.022Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M34.6922 204.821C47.8779 200.822 60.5113 196.94 73.7895 192.977C73.5912 192.411 73.4039 191.898 73.2221 191.353C59.9258 195.375 47.2606 199.222 34.0607 203.211L34.6922 204.821Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M39.999 198.69C53.2255 194.683 59.0653 192.885 72.4142 188.882L71.9015 187.259C58.5165 191.264 52.6513 193.085 39.3979 197.08L39.999 198.69Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M39.0785 194.5C52.3631 190.48 58.2867 188.676 71.7241 184.661L71.2766 183.031C57.7973 187.049 51.8599 188.873 38.5239 192.88C38.7045 193.418 38.885 193.957 39.0785 194.5Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M37.7109 190.385C51.0831 186.377 57.1251 184.53 70.6463 180.509C70.5309 179.963 70.4155 179.416 70.2989 178.864C56.6963 182.902 50.6414 184.745 37.2331 188.755L37.7109 190.385Z'
          fill='#7C96E9'
        />
      </g>
      <path
        d='M25.4375 194.215L34.2756 191.717L32.0397 182.623L25.4375 194.215Z'
        fill='#D3DCF8'
      />
      <path
        d='M13.8056 67.1558C30.6436 71.8179 47.7173 76.5316 65.0246 81.2917C51.5726 60.2328 94.1251 47.2366 80.4342 23.9077L48.2002 14.7144L36.7646 11.1745C47.716 32.4021 2.45514 44.2649 13.8056 67.1558Z'
        fill='white'
      />
      <g opacity='0.5'>
        <path
          opacity='0.2'
          d='M77.9924 32.8934C66.0516 29.4675 55.6762 26.878 43.7773 23.4649C43.7143 23.9662 43.6239 24.4694 43.5044 24.9623C55.3707 28.3725 65.7287 30.9673 77.6415 34.3636C77.7866 33.882 77.9032 33.3899 77.9924 32.8934Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M76.8293 36.5758C64.9281 33.176 54.5934 30.5742 42.7504 27.157C42.5459 27.6374 42.3187 28.1056 42.0647 28.5692C53.8845 31.9934 64.2109 34.547 76.1098 37.9601C76.3819 37.5355 76.6096 37.048 76.8293 36.5758Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M74.8065 40.0657C62.9053 36.666 52.5764 34.0624 40.7451 30.6417C40.4299 31.092 40.0881 31.5375 39.7369 31.9733C51.5566 35.3975 61.8906 37.9551 73.8157 41.3921C74.1669 40.9563 74.4972 40.5142 74.8065 40.0657Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M72.1559 43.4082C60.1825 39.9794 49.8054 37.384 37.9391 33.9738C37.5361 34.4061 37.1249 34.8534 36.6881 35.2578C48.5719 38.6628 58.9705 41.2453 70.9853 44.7061C71.3592 44.2826 71.7797 43.8451 72.1559 43.4082Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M69.8716 47.0784C57.8085 43.6258 46.5846 40.5999 34.6571 37.1763C34.1988 37.5935 33.7288 38.0141 33.2571 38.429C45.2247 41.8595 56.4888 44.8925 68.6095 48.3467C69.0236 47.9302 69.4476 47.5042 69.8716 47.0784Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M66.6299 50.2882C54.4387 46.8107 43.0867 43.7598 31.0638 40.3142C30.5554 40.7338 30.0918 41.1335 29.6009 41.5478C41.6907 45.005 53.0904 48.0669 65.3387 51.5653C65.7685 51.1377 66.1983 50.71 66.6299 50.2882Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M64.9612 54.338C56.0627 51.8054 43.8581 48.3257 28.163 43.8337C27.6563 44.2591 27.2003 44.6628 26.7285 45.0777C42.5023 49.5777 54.7872 53.0714 63.7602 55.6197C64.1365 55.1828 64.5506 54.7662 64.9612 54.338Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M62.0416 57.6031C52.9387 55.024 41.2903 51.9163 25.406 47.3861C24.9552 47.8073 24.5178 48.2309 24.0863 48.6527C40.0527 53.2027 51.7832 56.3301 60.9949 58.9335C61.3002 58.4926 61.6496 58.0511 62.0416 57.6031Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M59.5427 61.008C50.1935 58.3698 38.9042 55.0209 22.7788 50.4489C22.3891 50.8835 22.017 51.3128 21.6634 51.7619C37.8901 56.3541 49.2866 59.7217 58.7464 62.3901C58.9928 61.9224 59.2602 61.4612 59.5427 61.008Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M57.7832 64.5455C48.1458 61.8354 36.5718 58.4261 20.1751 53.7962C19.8751 54.2545 19.6001 54.7118 19.3229 55.1823C35.8419 59.8389 47.5382 63.2749 57.2978 66.0117C57.4412 65.518 57.5979 65.0265 57.7832 64.5455Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M56.1624 67.8894C46.1966 65.1004 35.0681 62.0329 18.3599 57.3381C18.1834 57.8164 18.0342 58.3056 17.9113 58.7996C34.7609 63.5217 46.0268 66.6239 56.1358 69.446C56.1167 68.9249 56.1261 68.408 56.1624 67.8894Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M56.4692 71.9033C46.1331 69.0226 34.6379 65.8752 17.5762 61.1027C17.547 61.6255 17.5521 62.1508 17.5915 62.672C34.8138 67.4723 46.4675 70.661 56.9602 73.577C56.7642 73.0292 56.6005 72.4705 56.4692 71.9033Z'
          fill='#7C96E9'
        />
      </g>
      <path
        d='M138.375 95.3871C133.764 113.655 127.68 130.852 120.368 149.515C103.314 144.526 86.4633 139.551 69.8164 134.59C77.5582 114.694 87.9942 92.9977 89.9633 71.3601C104.434 75.5999 117.583 79.5727 131.776 83.778L138.375 95.3871Z'
        fill='white'
      />
      <g opacity='0.5'>
        <path
          opacity='0.2'
          d='M117.882 138.763C108.647 136.017 87.489 129.533 78.3425 126.77L78.8377 125.144C87.9775 127.881 109.083 134.354 118.296 137.119L117.882 138.763Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M119.565 134.764C110.426 132.026 89.3212 125.547 80.2175 122.811L80.7721 121.191C89.8467 123.921 110.911 130.391 120.05 133.129C119.88 133.676 119.723 134.22 119.565 134.764Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M120.835 130.658C111.735 127.929 90.7019 121.46 81.6552 118.742C81.8536 118.176 82.0467 117.664 82.2517 117.124C91.2844 119.852 112.3 126.318 121.385 129.031L120.835 130.658Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M122.249 126.572C108.988 122.557 96.3514 118.717 83.169 114.731L83.8121 113.123C96.9804 117.119 109.591 120.996 122.838 124.959L122.249 126.572Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M123.769 122.502C110.561 118.498 97.968 114.625 84.7969 110.671L85.451 109.066C98.6135 113.06 111.195 116.932 124.395 120.891L123.769 122.502Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M125.359 118.434C112.179 114.436 99.6093 110.567 86.4382 106.614L87.0883 105.002C100.257 108.997 112.826 112.866 126.002 116.826L125.359 118.434Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M126.422 114.255C113.295 110.269 100.69 106.393 87.503 102.43L88.1402 100.821C101.332 104.821 113.913 108.693 127.077 112.65L126.422 114.255Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M128.071 110.218C114.908 106.224 102.31 102.349 89.0862 98.3847L89.7002 96.7712C102.932 100.78 115.537 104.656 128.721 108.606L128.071 110.218Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M129.115 106.017C115.93 102.018 103.296 98.1358 90.0181 94.1721C90.2164 93.6067 90.4037 93.0935 90.5856 92.5488C103.882 96.571 116.547 100.417 129.747 104.407L129.115 106.017Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M123.809 99.8857C110.582 95.8783 104.742 94.0804 91.3934 90.0776L91.9061 88.4548C105.291 92.4593 111.156 94.2807 124.41 98.2756L123.809 99.8857Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M124.729 95.6951C111.445 91.6754 105.521 89.872 92.0836 85.8565L92.531 84.2261C106.01 88.2443 111.948 90.0689 125.284 94.0751C125.103 94.614 124.923 95.1528 124.729 95.6951Z'
          fill='#7C96E9'
        />
        <path
          opacity='0.2'
          d='M126.097 91.5802C112.725 87.5724 106.682 85.7257 93.1613 81.7047C93.2767 81.1581 93.3921 80.6116 93.5087 80.0592C107.111 84.0974 113.166 85.9407 126.574 89.9501L126.097 91.5802Z'
          fill='#7C96E9'
        />
      </g>
      <path
        d='M138.37 95.4106L129.532 92.9129L131.768 83.819L138.37 95.4106Z'
        fill='#D3DCF8'
      />
    </>
  ),
});

export default WomanWriting;
