import React from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { Box, Text, Input } from '@chakra-ui/react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import Dropzone from 'react-dropzone';
import { RemoveCircleIcon } from 'chakra-ui-ionicons';
import { DocumentIcon } from 'chakra-ui-ionicons';
import { DocumentTextOutlineIcon } from 'chakra-ui-ionicons';
import { constants } from '../../constants';

const DropZoneOutline = ({
  id,
  children,
  label,
  isValid,
  errorMessage,
  file,
  onRemoveFile,
  handleFile,
  handleIsDropzoneValid,
  ...style
}) => {
  const { t } = useTranslation();
  const { hasFile, name } = file;

  const fileExtensionValidator = file => {
    if (!constants.OUTLINE_VALID_EXTENSIONS.includes(file.type)) {
      const error = 'The file extension must be .doc or .docx';

      handleIsDropzoneValid(false, error);

      return {
        code: 'invalid-extension',
        message: error,
      };
    }

    handleIsDropzoneValid(true, null);

    return null;
  };

  const onSelectFile = (acceptedFiles, fileRejections) => {
    handleFile(acceptedFiles, fileRejections);
  };

  return (
    <FormControl isRequired isInvalid={!isValid} id={id}>
      <FormLabel
        htmlFor={id}
        fontSize='xs'
        color={!isValid ? 'customInput.error' : 'gray.800'}
      >
        {label}
      </FormLabel>
      <Box {...style} data-testid='dropzone-container'>
        {!hasFile && (
          <Dropzone
            validator={fileExtensionValidator}
            onDrop={(acceptedFiles, fileRejections) =>
              onSelectFile(acceptedFiles, fileRejections)
            }
          >
            {({ getRootProps, getInputProps }) => (
              <Box
                h={24}
                border='2px dashed'
                borderColor={!isValid ? 'customInput.error' : 'alpha.400'}
                borderRadius={10}
                cursor='pointer'
                bg='gray.50'
                p={3}
              >
                <Box {...getRootProps()} textAlign='center' h='full'>
                  <Input {...getInputProps()} />
                  <DocumentTextOutlineIcon
                    w={6}
                    h={6}
                    color={!isValid ? 'customInput.error' : 'alpha.400'}
                    mb={1}
                  />
                  <Box
                    fontWeight={600}
                    fontSize={{ base: 'md' }}
                    color={!isValid ? 'customInput.error' : 'alpha.400'}
                  >
                    <Text
                      display='inline'
                      color={!isValid ? 'customInput.error' : 'gray.600'}
                    >
                      {t('outlines:outlineUpload.placeholder.dragAndDrop')}{' '}
                    </Text>
                    <Text
                      display='inline'
                      color={!isValid ? 'customInput.error' : 'alpha.400'}
                      textDecoration='underline'
                    >
                      {t('outlines:outlineUpload.placeholder.browse')}
                    </Text>
                    <Text
                      color={!isValid ? 'customInput.error' : 'alpha.400'}
                      fontWeight={400}
                      fontSize={{ base: '10px' }}
                    >
                      {t(
                        'outlines:outlineUpload.placeholder.uploadDescription',
                      )}
                    </Text>
                  </Box>
                </Box>
              </Box>
            )}
          </Dropzone>
        )}
        {name && (
          <Box display='flex' alignContent='center'>
            <DocumentIcon h={5} color='alpha.200' mr={1} />
            <Text fontSize={{ base: 'sm' }} fontWeight={{ base: 'bold' }}>
              {name}
            </Text>
            <RemoveCircleIcon
              h={5}
              ml={4}
              color='alpha.200'
              onClick={() => onRemoveFile()}
              cursor='pointer'
            />
          </Box>
        )}
        {!isValid && (
          <Box mt={2}>
            <Text
              fontSize={{ base: 'xs' }}
              color={!isValid ? 'customInput.error' : 'alpha.800'}
              alignItems='center'
            >
              {errorMessage}
            </Text>
          </Box>
        )}
      </Box>
    </FormControl>
  );
};

DropZoneOutline.defaultProps = {
  label: '',
  styles: {},
  isValid: true,
  errorMessage: null,
  onRemoveFile: () => {},
};

DropZoneOutline.propTypes = {
  id: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  styles: PropTypes.shape({}),
  file: PropTypes.shape({}).isRequired,
  onRemoveFile: PropTypes.func,
  handleFile: PropTypes.func.isRequired,
  handleIsDropzoneValid: PropTypes.func.isRequired,
};

export default DropZoneOutline;
