import React from 'react';
import getConfig from 'next/config';
import useTranslation from 'next-translate/useTranslation';
import { Box, Heading, Link } from '@chakra-ui/react';
import { SupportCat } from '@/components/illustrations';

const { publicRuntimeConfig } = getConfig();

const Support = () => {
  const { t } = useTranslation();
  const { SUPPORT_EMAIL } = publicRuntimeConfig;

  return (
    <>
      <Box alignSelf='center'>
        <SupportCat w={{ base: 170, md: 217 }} h={{ base: 170, md: 200 }} />
      </Box>
      <Heading
        fontSize={{ base: 'lg', md: '2xl' }}
        fontWeight={600}
        color='gray.600'
        textAlign={{ base: 'center' }}
        pt={{ base: 0, md: 14 }}
      >
        {t('support:needHelp')}
      </Heading>
      <Box
        fontSize={{ base: 'md' }}
        fontWeight={400}
        color='alpha.500'
        mb={16}
        textAlign={{ base: 'center' }}
        pt={{ base: 10, md: 12 }}
      >
        <Link href={`mailto:${SUPPORT_EMAIL}`} isExternal>
          {SUPPORT_EMAIL}
        </Link>
      </Box>
    </>
  );
};

export default Support;
