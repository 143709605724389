import React from 'react';
import { createIcon } from '@chakra-ui/react';

const MakeSuggestion = createIcon({
  displayName: 'MakeSuggestion',
  viewBox: '0 0 25 25',
  path: (
    <>
      <path
        d='M21.7451 12.86C21.5851 12.02 21.1051 11.44 20.2951 11.2C19.4851 10.95 18.7551 11.16 18.1551 11.76C16.2651 13.65 14.3751 15.54 12.4851 17.43C12.3451 17.57 12.2151 17.72 12.1151 17.89C11.7351 18.59 11.3651 19.3 11.0051 20.02C10.7551 20.53 10.8751 21.14 11.2651 21.56C11.4751 21.79 11.7551 21.91 12.0451 22H12.5351C13.3351 21.6 14.1351 21.21 14.9251 20.8C15.1251 20.7 15.3051 20.55 15.4651 20.39C17.3551 18.51 19.2351 16.62 21.1251 14.73C21.4551 14.4 21.6851 14.01 21.7551 13.54C21.7551 13.52 21.7751 13.5 21.7851 13.48V12.92C21.7851 12.92 21.7551 12.88 21.7551 12.86H21.7451ZM20.2351 13.61C20.1951 13.67 20.1451 13.72 20.0951 13.76C18.2251 15.63 16.3651 17.5 14.4951 19.36C14.4251 19.43 14.3451 19.51 14.2551 19.56C13.6151 19.9 12.9851 20.23 12.3451 20.56C12.3451 20.56 12.3251 20.54 12.3151 20.53C12.5451 20.08 12.7951 19.63 13.0151 19.17C13.2151 18.74 13.4951 18.38 13.8351 18.04C15.6051 16.29 17.3551 14.52 19.1151 12.76C19.2751 12.6 19.4451 12.49 19.6751 12.49C19.9551 12.49 20.1651 12.63 20.2951 12.88C20.4251 13.13 20.3951 13.38 20.2251 13.6L20.2351 13.61Z'
        fill='#161A1C'
      />
      <path
        d='M14.1852 9.17999H6.6852C6.3552 9.17999 6.08521 9.44999 6.08521 9.77999C6.08521 10.11 6.3552 10.38 6.6852 10.38H14.1852C14.5152 10.38 14.7852 10.11 14.7852 9.77999C14.7852 9.44999 14.5152 9.17999 14.1852 9.17999Z'
        fill='#161A1C'
      />
      <path
        d='M14.7852 12.73C14.7852 12.4 14.5152 12.13 14.1852 12.13H6.6852C6.3552 12.13 6.08521 12.4 6.08521 12.73C6.08521 13.06 6.3552 13.33 6.6852 13.33H14.1852C14.5152 13.33 14.7852 13.06 14.7852 12.73Z'
        fill='#161A1C'
      />
      <path
        d='M6.6852 15.09C6.3552 15.09 6.08521 15.36 6.08521 15.69C6.08521 16.02 6.3552 16.29 6.6852 16.29H11.8752C12.2052 16.29 12.4752 16.02 12.4752 15.69C12.4752 15.36 12.2052 15.09 11.8752 15.09H6.6852Z'
        fill='#161A1C'
      />
      <path
        d='M9.87512 21.24H6.00512H5.62512C4.84512 21.24 4.20512 20.58 4.20512 19.78V4.45998C4.20512 3.65998 4.86512 2.99998 5.66512 2.98998H11.8651C12.0151 2.98998 12.1551 3.04998 12.2551 3.15998L17.3751 8.35998C17.4851 8.46998 17.5451 8.61998 17.5451 8.76998H17.5351V10.44C17.5351 10.44 17.5451 10.49 17.5451 10.51V10.57H17.5551C17.6151 10.83 17.8451 11.03 18.1251 11.03C18.4051 11.03 18.6351 10.83 18.6951 10.57H18.7351V8.75998C18.7351 8.28998 18.5551 7.83998 18.2251 7.50998L13.1051 2.30998C12.7751 1.97998 12.3251 1.78998 11.8551 1.78998H5.65512C4.19512 1.78998 3.00512 2.98998 2.99512 4.44998V19.77C2.99512 21.24 4.16512 22.43 5.61512 22.43H9.87512C10.2051 22.41 10.4651 22.15 10.4651 21.82C10.4651 21.49 10.1951 21.22 9.86512 21.22L9.87512 21.24Z'
        fill='#161A1C'
      />
    </>
  ),
});

export default MakeSuggestion;
