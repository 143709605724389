import PropTypes from 'prop-types';
import { Stack, Flex, Text } from '@chakra-ui/react';
import { PersonCircleIcon } from 'chakra-ui-ionicons';
import useTranslation from 'next-translate/useTranslation';

import { formatTime } from '@/utils/dateUtils';

import StarRating from '@/components/StarRating';

const ReviewCard = ({ review }) => {
  const { t } = useTranslation();

  const {
    title,
    content,
    rating,
    created_at,
    user: { nickname },
  } = review;

  const date = formatTime(created_at, 'MMMM yyyy');

  return (
    <Stack mb={8}>
      <Flex alignItems='center' color='gray.600'>
        <PersonCircleIcon h={5} w={5} mr={2} color='gray.400' />
        <Text fontSize='xs'>{nickname}</Text>
      </Flex>
      <StarRating average={rating} />
      <Text fontSize='xl' fontWeight='bold' color='gray.600'>
        {title}
      </Text>
      <Text fontSize='xs' color='gray.500'>
        {t('makeReview:reviews.reviewedDate', { date })}
      </Text>
      <Text fontSize='sm' color='gray.900'>
        {content}
      </Text>
    </Stack>
  );
};

ReviewCard.propTypes = {
  review: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    rating: PropTypes.number,
    created_at: PropTypes.string,
    user: PropTypes.shape({ name: PropTypes.string }),
  }).isRequired,
};

export default ReviewCard;
