import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Text, Heading } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';

const SchoolInformation = ({
  professorName,
  schoolName,
  grade,
  year,
  term,
  nickname,
  showAllField = true,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      fontSize='sm'
      color='gray.500'
      spacing={1}
      my={1}
      data-testid='school-information'
    >
      <Heading color='gray.600' fontSize='sm'>
        {schoolName}
      </Heading>
      <Text fontWeight={600}>
        {t('outlines:professor')} {professorName}
      </Text>
      {showAllField && (
        <Text>{t('outlines:courseDictatedOn', { term, year })}</Text>
      )}
      <Text>
        {t('outlines:grade_received')}{' '}
        {grade && `${grade}`.trim() !== '' ? grade : 'NA'}
      </Text>
      {showAllField && (
        <Text color='gray.400'>
          {t('outlines:by')} {nickname}
        </Text>
      )}
    </Stack>
  );
};

SchoolInformation.defaultProps = {
  professorName: '',
  schoolName: '',
  grade: '',
  year: '',
  term: '',
  nickname: '',
};

SchoolInformation.propTypes = {
  professorName: PropTypes.string,
  schoolName: PropTypes.string,
  grade: PropTypes.string,
  year: PropTypes.string,
  term: PropTypes.string,
  nickname: PropTypes.string,
};

export default SchoolInformation;
