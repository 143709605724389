import React from 'react';
import Head from 'next/head';
import { MetaPropType } from './metaPropType';
import { useTheme } from '@chakra-ui/system';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const Meta = props => {
  const {
    title,
    charSet,
    viewport,
    description,
    ogType,
    ogTitle,
    ogDescription,
    ogSiteName,
    ogUrl,
    ogImage,
    twitterCard,
    twitterDescription,
    twitterSite,
    twitterCreator,
    twitterImage,
    children,
  } = props;

  const {
    colors: { alpha, white },
  } = useTheme();

  return (
    <Head>
      <title>{title}</title>
      <meta charSet={charSet} />
      <meta name='viewport' content={viewport} />
      {description && <meta name='description' content={description} />}
      {ogType && <meta property='og:type' content={ogType} />}
      {ogTitle && (
        <meta name='og:title' property='og:title' content={ogTitle} />
      )}
      {ogDescription && (
        <meta
          name='og:description'
          property='og:description'
          content={ogDescription}
        />
      )}
      {ogSiteName && <meta property='og:site_name' content={ogSiteName} />}
      {ogUrl && <meta property='og:url' content={ogUrl} />}
      {ogImage && <meta property='og:image' content={ogImage} />}
      {twitterCard && <meta name='twitter:card' content={twitterCard} />}
      {twitterDescription && (
        <meta name='twitter:description' content={twitterDescription} />
      )}
      {twitterSite && <meta name='twitter:site' content={twitterSite} />}
      {twitterCreator && (
        <meta name='twitter:creator' content={twitterCreator} />
      )}
      {twitterImage && <meta name='twitter:image' content={twitterImage} />}
      {children}
      <link
        href='https://fonts.googleapis.com/css2?family=Krona+One&display=optional'
        rel='stylesheet'
      />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
      <link rel="manifest" href="/site.webmanifest"/>
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color={alpha[500]}/>
      <meta name="apple-mobile-web-app-title" content="Remy Outlines"/>
      <meta name="application-name" content="Remy Outlines"/>
      <meta name="msapplication-TileColor" content={white}/>
      <meta name="theme-color" content={alpha[500]}/>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>

      <script src='//app.mailmunch.com/app/v1/site.js'
        id='mailmunch-script'
        data-mailmunch-site-id={publicRuntimeConfig.MAILMUNCH_SITE_ID}
        async='async'>
      </script>
    </Head>
  );
};

Meta.defaultProps = {
  title: 'Remy Outlines',
  charSet: 'utf-8',
  viewport: 'width=device-width,minimum-scale=1,initial-scale=1',
};

Meta.propTypes = MetaPropType;

export default Meta;
