import { node, string, oneOf } from 'prop-types';

export const MetaPropType = {
  title: string,
  description: string,
  charSet: string,
  viewport: string,
  ogType: oneOf([
    'music.song',
    'music.album',
    'music.playlist',
    'music.radio_station',
    'video.movie',
    'video.episode',
    'video.tv_show',
    'video.other',
    'article',
    'book',
    'profile',
    'website',
  ]),
  ogTitle: string,
  ogDescription: string,
  ogSiteName: string,
  ogUrl: string,
  ogImage: string,
  twitterCard: oneOf([
    'summary',
    'summary_large_image',
    'player',
    'app',
    'og:type',
  ]),
  twitterDescription: string,
  twitterSite: string,
  twitterCreator: string,
  twitterImage: string,
  children: node,
};
