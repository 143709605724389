import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

const StarRating = ({ average }) => {
  const percent = (average / 5) * 100;

  return (
    <Box
      data-testid='rating'
      display='inline-block'
      fontSize={16}
      lineHeight={1}
      _before={{
        content: '"★★★★★"',
        bgGradient: `linear(90deg, zeta.500 ${percent}%, zeta.100 ${percent}%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }}
    ></Box>
  );
};

StarRating.defaultProps = {
  average: 0,
};

StarRating.propTypes = {
  average: PropTypes.number,
};

export default StarRating;
