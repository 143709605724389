import React from 'react';
import { createIcon } from '@chakra-ui/react';

const SupportCat = createIcon({
  displayName: 'Support Cat',
  viewBox: '0 0 161 148',
  path: (
    <>
      <path
        d='M122.072 34.23H34.0367C30.1466 34.2341 26.417 35.7813 23.6663 38.532C20.9156 41.2828 19.3684 45.0124 19.3643 48.9025V111.785C19.3684 115.675 20.9156 119.404 23.6663 122.155C26.417 124.906 30.1466 126.453 34.0367 126.457H122.072C125.962 126.453 129.691 124.906 132.442 122.155C135.193 119.404 136.74 115.675 136.744 111.785V48.9025C136.74 45.0124 135.193 41.2828 132.442 38.532C129.691 35.7813 125.962 34.2341 122.072 34.23ZM118.356 58.4998L80.6271 87.8448C79.8914 88.4168 78.9861 88.7273 78.0542 88.7273C77.1223 88.7273 76.217 88.4168 75.4813 87.8448L37.752 58.4998C37.3087 58.1651 36.9364 57.7456 36.6567 57.2657C36.3769 56.7858 36.1953 56.2551 36.1225 55.7044C36.0496 55.1537 36.0869 54.594 36.2322 54.0579C36.3775 53.5217 36.6279 53.0198 36.9689 52.5813C37.3098 52.1428 37.7346 51.7764 38.2183 51.5034C38.7021 51.2304 39.2354 51.0563 39.787 50.9912C40.3387 50.9261 40.8978 50.9713 41.4318 51.1241C41.9658 51.277 42.4642 51.5344 42.8979 51.8815L78.0542 79.2247L113.211 51.8815C114.09 51.2176 115.195 50.9265 116.287 51.071C117.379 51.2155 118.37 51.784 119.046 52.6537C119.723 53.5234 120.029 54.6242 119.9 55.7183C119.771 56.8123 119.216 57.8115 118.356 58.4998Z'
        fill='#A8B9F0'
      />
      <path
        d='M5.59778 65.5776C5.59778 65.5776 -0.598605 74.8838 0.671656 76.4969C1.94192 78.11 6.86805 76.6934 7.48769 76.4039C8.10733 76.1144 10.7511 69.9619 10.7511 69.6724C10.7511 69.3829 5.59778 65.5776 5.59778 65.5776Z'
        fill='#868A91'
      />
      <path
        opacity='0.3'
        d='M5.59991 65.5776C5.59991 65.5776 2.08862 69.4656 3.33823 71.0993C4.58783 72.7331 5.96138 76.5693 6.612 76.5693C8.79107 76.5693 10.7429 69.9619 10.7429 69.6724C10.7429 69.3829 5.59991 65.5776 5.59991 65.5776Z'
        fill='black'
      />
      <path
        d='M7.49047 76.404C7.49047 76.404 4.21671 97.1982 15.174 95.6368C26.1313 94.0754 19.212 74.0981 15.5561 68.5144C15.5561 68.5144 21.3188 57.1401 21.515 55.0721C24.9375 57.6344 28.9868 59.2278 33.2365 59.6838C39.9596 60.2628 49.7602 57.5123 55.9049 39.0033C55.9049 39.0033 92.2158 41.2472 95.8613 40.8543C95.5009 42.0744 95.4524 43.3649 95.7219 44.6088C95.9904 45.8517 96.5677 47.0067 97.4001 47.9683C100.281 51.6288 107.965 65.0918 109.122 67.9767C110.278 70.8616 105.084 82.7942 107.965 84.7175C110.846 86.6408 120.461 82.9803 121.029 73.0744C121.597 63.1685 111.042 46.438 111.239 43.9356C111.435 41.4333 118.003 38.0314 118.003 38.0314L154.851 37.5867C154.851 37.5867 163.113 31.7342 159.653 26.3883C156.194 21.0424 153.457 12.2015 146.589 12.005L145.34 11.9533V0.206817C145.34 0.206817 137.656 5.07707 136.499 8.41696C134.996 5.46379 133.236 2.6471 131.243 0C131.243 0 125.346 2.56438 120.988 13.4423C118.082 13.1156 115.229 12.4207 112.499 11.3743C108.12 9.64744 103.762 13.2975 98.2573 12.1498C92.7528 11.002 67.0068 10.8159 59.7054 10.8986C52.404 10.9813 31.2123 9.51304 20.317 16.7615C10.8159 23.0898 -0.554515 38.1244 2.38877 54.1414C2.43008 54.1518 6.91214 68.1318 7.49047 76.404Z'
        fill='#868A91'
      />
      <path
        d='M159.291 26.8848C159.291 26.8848 145.204 27.5362 150.698 37.5455H154.829C154.829 37.5455 163.422 29.9868 159.291 26.8848Z'
        fill='#C3C5C8'
      />
      <path
        opacity='0.5'
        d='M26.5127 28.9526C24.4473 30.7622 22.4747 32.6131 20.5745 34.5674C18.6485 36.4762 16.8701 38.5298 15.2559 40.7095C14.8656 41.2596 14.5041 41.8283 14.1716 42.4156C14.0167 42.7052 13.8204 42.9843 13.6965 43.2842L13.2937 44.1838C12.8868 45.4525 12.6576 46.7709 12.6122 48.1028C12.5605 48.7645 12.6121 49.4469 12.5399 50.1191C12.4676 50.7912 12.5398 51.4736 12.5915 52.1871C12.6431 52.9006 12.6534 53.5417 12.7154 54.2552C12.7774 54.9686 12.8497 55.5994 12.922 56.2715C13.1987 58.9682 13.6965 61.6381 14.4091 64.2542C14.753 65.556 15.1909 66.832 15.7207 68.0698L16.1544 68.9693L16.6191 69.8793C16.9186 70.4997 17.2078 71.1201 17.497 71.7405C18.6309 74.2408 19.5769 76.8227 20.3267 79.4646C20.6809 80.7892 20.9432 82.1366 21.1115 83.4974C21.3015 84.8695 21.3015 86.2613 21.1115 87.6335C21.2117 86.2758 21.1456 84.9109 20.9153 83.5698C20.6819 82.2338 20.3607 80.9144 19.9549 79.6198C19.1359 77.0306 18.1207 74.5065 16.9186 72.0714C16.6191 71.4613 16.3197 70.8513 15.9995 70.2515L15.5244 69.3519L15.0597 68.4109C14.5113 67.1412 14.0487 65.8352 13.6759 64.5024C12.9282 61.8553 12.3994 59.1502 12.0958 56.4163C12.0235 55.7338 11.9512 55.0514 11.9099 54.3482C11.8686 53.6451 11.8169 52.9833 11.7963 52.2802C11.7756 51.5771 11.7963 50.9049 11.7963 50.2122C11.7963 49.5194 11.7963 48.8369 11.8996 48.1441C11.9564 46.7368 12.2094 45.345 12.6534 44.008C13.2091 42.7238 13.9237 41.515 14.7809 40.4096C18.2095 36.1257 22.1494 32.2781 26.5127 28.9526Z'
        fill='black'
      />
      <path
        opacity='0.5'
        d='M58.5902 32.25C57.0101 36.3861 55.399 40.5222 53.7569 44.6583C52.9483 46.747 51.9084 48.7375 50.6587 50.5936C49.4133 52.4693 47.9313 54.1764 46.249 55.6706C44.5553 57.1844 42.5931 58.3663 40.4657 59.1552C38.3269 59.9266 36.0497 60.2327 33.7839 60.0548C31.5419 59.8656 29.3473 59.3073 27.2881 58.4004C25.2525 57.507 23.3203 56.3934 21.5254 55.0812C22.4858 55.6396 23.4359 56.1876 24.4274 56.6839C25.4126 57.173 26.4226 57.6115 27.4533 57.9971C29.494 58.7778 31.6431 59.238 33.8252 59.3621C35.9909 59.4572 38.1524 59.1056 40.1765 58.328C42.1976 57.5473 44.0648 56.413 45.6913 54.9778C47.3241 53.5343 48.774 51.8954 50.0082 50.0972C51.2671 48.3073 52.3359 46.3913 53.1993 44.3791C54.9684 40.2988 56.7653 36.2558 58.5902 32.25Z'
        fill='black'
      />
      <path
        opacity='0.5'
        d='M100.384 28.5083C99.7954 30.5763 99.1344 32.7064 98.4425 34.7745C97.7506 36.8425 97.0173 38.9106 96.2738 40.9786V41.0303C96.2211 41.1182 96.1798 41.2123 96.1498 41.3095C96.1085 41.4274 96.0775 41.5483 96.0569 41.6714C96.0032 41.9227 95.9753 42.1801 95.9743 42.4366C95.9722 42.9691 96.0311 43.5006 96.1498 44.0186C96.3936 45.093 96.7643 46.1353 97.2549 47.1207C98.2762 49.1298 99.4577 51.0521 100.787 52.8699C99.9916 52.0561 99.2429 51.1958 98.5458 50.2952C97.8425 49.3914 97.2115 48.4339 96.6559 47.4309C96.0817 46.4134 95.6417 45.3256 95.3443 44.1944C95.2049 43.6123 95.1316 43.0157 95.1274 42.4159C95.1274 42.1037 95.1595 41.7924 95.2204 41.4853C95.2441 41.3271 95.2823 41.1709 95.334 41.02C95.3918 40.8504 95.4682 40.6881 95.5612 40.534V40.627C96.3254 38.559 97.0896 36.4909 97.9055 34.5056C98.7213 32.5203 99.4546 30.4936 100.384 28.5083Z'
        fill='black'
      />
      <path
        d='M157.173 26.7717C157.062 26.7903 156.959 26.8379 156.874 26.9103C156.789 26.9837 156.725 27.0778 156.688 27.1832C156.652 27.2897 156.646 27.4034 156.67 27.513C156.693 27.6227 156.745 27.723 156.822 27.8057C157.854 28.9431 159.682 30.7217 160.385 30.1633C160.521 29.5088 160.527 28.8335 160.401 28.1759C160.275 27.5193 160.02 26.8947 159.651 26.3374L157.173 26.7717Z'
        fill='black'
      />
      <path
        d='M141.073 23.6802C141.648 23.5282 142.235 23.4279 142.828 23.3804C143.38 23.3276 143.938 23.3586 144.481 23.4734C145.023 23.5747 145.547 23.764 146.03 24.0318L146.216 24.1248L146.402 24.2386L146.773 24.4661C147.032 24.6212 147.259 24.8176 147.538 24.9727C147.403 24.6873 147.244 24.4134 147.063 24.1559C146.868 23.9036 146.647 23.674 146.402 23.4734C145.899 23.0432 145.295 22.7516 144.646 22.6255C143.997 22.4952 143.327 22.5273 142.694 22.7186C142.079 22.8913 141.52 23.2232 141.073 23.6802Z'
        fill='black'
      />
      <path
        d='M58.587 32.25C58.587 32.25 81.1625 29.334 96.478 37.9578L95.4452 40.6669L55.8916 38.9608L58.587 32.25Z'
        fill='#C3C5C8'
      />
      <path
        opacity='0.4'
        d='M145.338 11.9121V0.165527C145.338 0.165527 137.654 5.03578 136.498 8.37568L138.873 12.5118L145.338 11.9121Z'
        fill='black'
      />
      <path
        opacity='0.4'
        d='M131.533 2.21387L137.729 12.5541C135.747 13.2862 133.644 13.6378 131.533 13.5881C131.533 13.5881 129.426 10.1655 131.533 2.21387Z'
        fill='white'
      />
      <path
        opacity='0.3'
        d='M13.6963 43.2334C13.6963 43.2334 14.4812 54.132 18.1887 63.159L15.7205 67.9362C15.7205 67.9362 9.86489 50.3371 13.6963 43.2334Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M98.7412 32.25C98.7412 32.25 96.2937 39.2917 83.9009 40.3257L95.2609 41.0185L98.7412 32.25Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M59.1161 29.9868C59.1161 29.9868 60.1488 38.259 66.3452 39.293L55.9043 38.9208L59.1161 29.9868Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M118.001 37.9888L124.198 30.0269C124.198 30.0269 122.504 35.8484 129.362 37.265L118.001 37.9888Z'
        fill='black'
      />
      <path
        d='M121.026 13.5042C121.026 13.5042 118.713 28.6102 127.13 37.5855L123.061 37.9278C118.191 30.5759 116.29 21.6461 117.742 12.9458L121.026 13.5042Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M121.026 13.5042C121.026 13.5042 118.713 28.6102 127.13 37.5855L123.061 37.9278C118.191 30.5759 116.29 21.6461 117.742 12.9458L121.026 13.5042Z'
        fill='black'
      />
    </>
  ),
});

export default SupportCat;
