import { extendTheme } from '@chakra-ui/react';
import colors from './theme/colors';
import typography from './theme/typography';

// Look at https://chakra-ui.com/docs/getting-started#add-custom-theme-optional to
// extend the theme

const theme = extendTheme({
  styles: {
    global: props => ({
      html: {
        h: 'full',
      },
      body: {
        h: 'full',
        bg: colors.alpha[50],
      },
    }),
  },
  colors,
  ...typography,
});

export default theme;
