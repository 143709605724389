import { object, number, bool, string, ref } from 'yup';

const paymentMethodSchema = object({
  name: string().required('Name is required'),
  country: string().required('Country is required'),
  zipCode: number().required('Zip Code is required'),
  isDefault: bool(),
}).required();

const bankAccountSchema = object({
  holderName: string().required('Holder name is required'),
  routingNumber: string()
    .required('Routing number Code is required')
    .length(9, 'Routing mumber must be exactly 9 digits'),
  accountNumber: string()
    .required('Account number is required')
    .max(17, 'Account number must 17 digits at max'),
  confirmAccountNumber: string()
    .required('Confirm account number')
    .oneOf([ref('accountNumber')], 'Account number must match'),
}).required();

export { paymentMethodSchema, bankAccountSchema };
