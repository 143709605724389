import React from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { Text, Flex, Heading } from '@chakra-ui/react';
import { formatTime } from '@/utils/dateUtils';

const PurchasesInfo = ({ outline }) => {
  const { t } = useTranslation();
  const {
    purchases: { totalPurchases, totalCommissionEarned },
    created_at,
  } = outline;

  const createdAt = formatTime(created_at, 'MMMM dd, yyyy');

  return (
    <>
      <Text fontSize={{ base: 'md' }} fontWeight={400} color='gray.800' my={1}>
        {t('uploadedOutlines:totalPurchases', { totalPurchases })}
      </Text>
      <Text fontSize={{ base: 'xs' }} fontWeight={400} color='gray.600' my={1}>
        {t('uploadedOutlines:createdAt', { createdAt })}
      </Text>
      <Flex flexDirection='row' my={1}>
        <Text
          fontSize={{ base: 'sm' }}
          fontWeight={400}
          color='gray.800'
          mr={1}
        >
          {t('uploadedOutlines:totalReceived')}
        </Text>
        <Heading fontSize={{ base: 'md' }} fontWeight={600} color='gray.800'>
          ${totalCommissionEarned.toFixed(2)}
        </Heading>
      </Flex>
    </>
  );
};

PurchasesInfo.propTypes = {
  outline: PropTypes.shape({
    purchases: PropTypes.object,
    created_at: PropTypes.string,
  }).isRequired,
};

export default PurchasesInfo;
