import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { CardOutlineIcon, ChevronForwardIcon } from 'chakra-ui-ionicons';
import { Divider, Flex, Text } from '@chakra-ui/react';

import { Bank } from '@/components/illustrations';

const AddButton = ({ onClick, isBank }) => {
  const { t } = useTranslation();

  const iconStyle = {
    h: 6,
    w: 6,
    color: 'alpha.400',
    cursor: 'pointer',
    mr: 4,
  };

  const text = isBank ? t('payment:bank.add') : t('payment:newCard');
  const icon = isBank ? (
    <Bank {...iconStyle} />
  ) : (
    <CardOutlineIcon {...iconStyle} />
  );

  return (
    <>
      <Flex
        onClick={onClick}
        py={4}
        cursor='pointer'
        data-testid='payment-method-btn'
      >
        <Flex alignItems='center' w='full'>
          {icon}
          <Text w='full' fontSize={{ base: 'md', md: 'sm' }} color='alpha.400'>
            {text}
          </Text>
          <ChevronForwardIcon color='gray.300' />
        </Flex>
      </Flex>
      <Divider borderColor='alpha.200' />
    </>
  );
};

AddButton.defaultProps = {
  isBank: false,
};

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isBank: PropTypes.bool,
};

export default AddButton;
