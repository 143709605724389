import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';
import { Box, Flex } from '@chakra-ui/react';
import { DrawerHeader, DrawerBody } from '@/components/Drawer';
import Support from '@/components/Support';

const SupportContainer = ({ onBack }) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection='column' h='full' data-testid='support-container'>
      <DrawerHeader onBack={onBack} title={t('support:title')} />
      <DrawerBody pt={0} h='full'>
        <Box
          display='flex'
          flexDirection='column'
          pt={{ base: 12, md: 32 }}
          w='full'
          h='full'
        >
          <Support />
        </Box>
      </DrawerBody>
    </Flex>
  );
};

SupportContainer.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default SupportContainer;
