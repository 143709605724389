import React, { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useDispatch, useSelector } from 'react-redux';

import {
  getIsRemovingBankAccount,
  getIsUpdatingBankAccount,
  removeBankAccount,
} from '@/stores/payment';

import {
  Box,
  Button,
  Divider,
  Flex,
  Spinner,
  Tag,
  Text,
} from '@chakra-ui/react';
import { CheckmarkCircleIcon, TrashIcon } from 'chakra-ui-ionicons';
import { Bank } from '@/components/illustrations';
import Modal from '../Modal';
import { getUser } from '@/stores/user';

const BankItem = ({ bankAccount, onSelect }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    id: bankAccountId,
    bank_name,
    last4,
    default_for_currency,
  } = bankAccount;

  const isUpdatingBankAccount = useSelector(state =>
    getIsUpdatingBankAccount(state),
  );
  const isRemovingBankAccount = useSelector(state =>
    getIsRemovingBankAccount(state),
  );
  const { id: userId } = useSelector(state => getUser(state));

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const renderIcons = () => {
    if (isUpdatingBankAccount || isRemovingBankAccount)
      return <Spinner size='sm' color='alpha.100' />;

    if (default_for_currency)
      return <CheckmarkCircleIcon h={6} w={6} color='gamma.500' />;

    return (
      <TrashIcon
        w={6}
        h={6}
        cursor='pointer'
        color='epsilon.500'
        onClick={handleClickRemove}
        data-testid='remove-bank-account'
      />
    );
  };

  const handleClickCard = () => {
    if (
      !default_for_currency &&
      !isUpdatingBankAccount &&
      !isRemovingBankAccount
    ) {
      setModalIsOpen(true);
      setIsRemoving(false);
    }
  };

  const handleClickRemove = () => {
    if (!isRemovingBankAccount) {
      setModalIsOpen(true);
      setIsRemoving(true);
    }
  };

  const handlOnConfirm = async handleOnClose => {
    if (!isRemoving) {
      onSelect();
    } else {
      dispatch(removeBankAccount({ userId, bankAccountId }));
    }
    handleOnClose();
  };

  return (
    <Box data-testid='bank-item'>
      <Flex py={4} alignItems='flex-start' justifyContent='space-between'>
        <Flex
          alignItems='center'
          onClick={handleClickCard}
          w='full'
          cursor={
            !default_for_currency &&
            !isUpdatingBankAccount &&
            !isRemovingBankAccount
              ? 'pointer'
              : 'default'
          }
          data-testid='bank-item-card'
        >
          <Bank h={6} w={6} mr={4} />
          <Box w='full'>
            <Text fontSize={{ base: 'md', md: 'sm' }} color='gray.900'>
              {bank_name}
              {default_for_currency && (
                <>
                  {' '}
                  <Tag size='sm' bg='gamma.500' color='white'>
                    {t('common:default')}
                  </Tag>
                </>
              )}
            </Text>
            <Text fontSize={{ base: 'md', md: 'sm' }} color='gray.400'>
              {t('payment:bank.last4', { last4 })}
            </Text>
          </Box>
        </Flex>
        <Flex alignSelf='center'>{renderIcons()}</Flex>
      </Flex>
      <Divider borderColor='alpha.200' />
      {modalIsOpen && (
        <Modal
          open={modalIsOpen}
          onCloseModal={() => setModalIsOpen(false)}
          title={t(
            `payment:${
              isRemoving ? 'removeBank' : 'updateDefaultBank'
            }.modalTitle`,
          )}
          stylescancelbutton={{ bg: 'white', color: 'alpha.500' }}
          confirmButton={handleOnClose => (
            <Button
              data-testid='confirm-button'
              bg='epsilon.500'
              color='white'
              mr={3}
              onClick={() => handlOnConfirm(handleOnClose)}
            >
              {t(`common:${isRemoving ? 'remove' : 'update'}`)}
            </Button>
          )}
        >
          <Text
            fontSize={{ base: 'sm' }}
            fontWeight={400}
            color='gray.500'
            data-testid='bank-modal-message'
          >
            {t(
              `payment:${
                isRemoving ? 'removeBank' : 'updateDefaultBank'
              }.modalMessage`,
            )}
          </Text>
        </Modal>
      )}
    </Box>
  );
};

export default BankItem;
