import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Documents = createIcon({
  displayName: 'Documents',
  viewBox: '0 0 118 118',
  path: (
    <>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.8896 25.8098H46.587C47.8271 25.8117 49.0545 26.0604 50.1976 26.5414C51.3398 27.0221 52.375 27.7251 53.243 28.6096C53.2436 28.6102 53.2423 28.6089 53.243 28.6096L78.4043 54.1974C80.1484 55.9809 81.1247 58.3765 81.124 60.871C81.124 60.8716 81.124 60.8705 81.124 60.871V100.482C81.124 108.024 75.176 114.31 67.5379 114.31H24.6592C17.0123 114.31 11.0615 108.027 11.0615 100.482V39.6163C11.0829 35.9612 12.5445 32.4619 15.1291 29.8774C17.7136 27.2928 21.213 25.8313 24.868 25.8099L24.8896 25.8098ZM73.749 60.8694C73.7493 60.3043 73.5287 59.7614 73.1344 59.3567C73.1334 59.3557 73.1354 59.3577 73.1344 59.3567L47.983 33.779C47.7999 33.5923 47.5781 33.4405 47.3371 33.3391C47.0967 33.2379 46.8386 33.1855 46.5779 33.1848H24.9016C23.191 33.1974 21.5538 33.8824 20.344 35.0923C19.1341 36.3021 18.4491 37.9393 18.4365 39.6499V100.482C18.4365 104.137 21.2666 106.935 24.6592 106.935H67.5379C70.9161 106.935 73.749 104.14 73.749 100.482V60.8694Z'
        fill='#A8B9F0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46.0928 25.8098C48.1293 25.8098 49.7803 27.4608 49.7803 29.4973V54.3614C49.7936 55.0976 50.0919 55.8001 50.6128 56.321C51.1337 56.842 51.8363 57.1402 52.5724 57.1536H77.4365C79.4731 57.1536 81.124 58.8045 81.124 60.8411C81.124 62.8776 79.4731 64.5286 77.4365 64.5286H52.5019C49.8341 64.4967 47.2845 63.4225 45.3979 61.5359C43.5113 59.6494 42.4374 57.0998 42.4056 54.4319L42.405 54.3879L42.4053 29.4973C42.4053 27.4608 44.0562 25.8098 46.0928 25.8098Z'
        fill='#A8B9F0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50.7024 3.68579H72.3716C73.613 3.68752 74.8418 3.93486 75.9872 4.41358C77.1326 4.8923 78.172 5.59292 79.0454 6.47508L79.0543 6.48407L104.204 32.0602C104.205 32.0612 104.203 32.0592 104.204 32.0602C105.954 33.8329 106.937 36.2264 106.937 38.717C106.937 38.7174 106.937 38.7166 106.937 38.717V78.3577C106.937 85.8999 100.989 92.1858 93.3506 92.1858H79.2805V84.8108H93.3506C96.7289 84.8108 99.5618 82.0162 99.5618 78.3577V38.717C99.5619 38.1656 99.3447 37.6352 98.9572 37.243L98.9514 37.237L73.8046 11.664C73.8035 11.6629 73.8057 11.6651 73.8046 11.664C73.6164 11.4748 73.3895 11.3211 73.1432 11.2182C72.8961 11.1149 72.631 11.0614 72.3632 11.0608H50.7144C49.0037 11.0733 47.3665 11.7584 46.1567 12.9682C44.9469 14.1781 44.2618 15.8153 44.2493 17.5259V29.4983H36.8743V17.4923C36.8957 13.8372 38.3572 10.3379 40.9418 7.75333C43.5264 5.16874 47.0257 3.70726 50.6808 3.68585L50.7024 3.68579Z'
        fill='#A8B9F0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M71.9063 3.68579C73.9428 3.68579 75.5938 5.33674 75.5938 7.37329V32.2374C75.6071 32.9735 75.9054 33.6761 76.4263 34.197C76.9472 34.7179 77.6498 35.0162 78.3859 35.0295H103.25C105.287 35.0295 106.938 36.6805 106.938 38.717C106.938 40.7536 105.287 42.4045 103.25 42.4045H78.3154C75.6476 42.3727 73.098 41.2985 71.2114 39.4119C69.3248 37.5253 68.2508 34.9757 68.219 32.3079L68.2185 32.2639L68.2188 7.37329C68.2188 5.33674 69.8697 3.68579 71.9063 3.68579Z'
        fill='#A8B9F0'
      />
    </>
  ),
});

export default Documents;
