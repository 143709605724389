import React from 'react';
import PropTypes from 'prop-types';
import { Flex, ListItem, Text } from '@chakra-ui/react';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import {
  DocumentsOutlineIcon,
  DocumentOutlineIcon,
  CardOutlineIcon,
  ClipboardOutlineIcon,
  WalletOutlineIcon,
  HeadsetOutlineIcon,
  LogOutOutlineIcon,
  DocumentTextOutlineIcon,
} from 'chakra-ui-ionicons';
import { MakeSuggestion as MakeSuggestionIllustration } from '../illustrations';

import { navigationIds } from './navigationIds';

const SideMenuItem = ({ id, label, onClick }) => {
  let icon;

  const iconStyles = { w: 6, h: 6, mr: 4 };

  switch (id) {
    case navigationIds.adminConsole:
      icon = <ClipboardOutlineIcon {...iconStyles} />;
      break;

    case navigationIds.purchasedOutlines:
      icon = <DocumentsOutlineIcon {...iconStyles} />;
      break;

    case navigationIds.uploadedOutlines:
      icon = <DocumentOutlineIcon {...iconStyles} />;
      break;

    case navigationIds.paymentMethod:
      icon = <CardOutlineIcon {...iconStyles} />;
      break;

    case navigationIds.makeSuggestion:
      icon = <MakeSuggestionIllustration {...iconStyles} />;
      break;

    case navigationIds.myBalance:
      icon = <WalletOutlineIcon {...iconStyles} />;
      break;

    case navigationIds.faq:
      icon = <QuestionOutlineIcon {...iconStyles} />;
      break;

    case navigationIds.support:
      icon = <HeadsetOutlineIcon {...iconStyles} />;
      break;

    case navigationIds.tos:
      icon = <DocumentTextOutlineIcon {...iconStyles} />;
      break;

    case navigationIds.logout:
      icon = <LogOutOutlineIcon {...iconStyles} />;
      break;
  }

  return (
    <ListItem
      color='gray.900'
      mb={8}
      cursor='pointer'
      onClick={() => onClick(id)}
      _hover={{ color: 'alpha.400' }}
      transition='color ease-in 100ms'
      data-testid='side-menu-item'
    >
      <Flex alignItems='center'>
        {icon}
        <Text> {label}</Text>
      </Flex>
    </ListItem>
  );
};

SideMenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SideMenuItem;
