import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Text, Button } from '@chakra-ui/react';

const MessageComponent = ({
  title,
  message,
  renderIllustration,
  buttonTitle,
  onClick,
}) => {
  return (
    <>
      <Box alignSelf='center'>{renderIllustration()}</Box>
      <Heading
        fontSize={{ base: '2xl' }}
        fontWeight={600}
        color='gray.600'
        textAlign={{ base: 'center' }}
        mb={3}
        mt={12}
      >
        {title}
      </Heading>
      <Text
        fontSize={{ base: 'md' }}
        fontWeight={400}
        color='gray.600'
        mb={16}
        textAlign={{ base: 'center' }}
      >
        {message}
      </Text>
      <Button
        bg='alpha.500'
        color='white'
        w={{ base: 'full' }}
        _focus={{ boxShadow: 'none' }}
        onClick={onClick}
      >
        {buttonTitle}
      </Button>
    </>
  );
};

MessageComponent.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  renderIllustration: PropTypes.func.isRequired,
  buttonTitle: PropTypes.string.isRequired,
};

export default MessageComponent;
