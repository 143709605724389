import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { FormControl, FormLabel, Select } from '@chakra-ui/react';

import { OUTLINES_FILTERS } from '@/utils/constants';

const FilterInput = ({ id, label, options, placeholder, handleSelect }) => {
  const { query } = useRouter();

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const { SORTBY } = OUTLINES_FILTERS;

    const isSortby = query.hasOwnProperty('sortby');

    switch (query?.sortby) {
      case SORTBY.CREATEDAT:
        setInputValue(SORTBY.CREATEDAT);
        break;
      case SORTBY.RATING:
        setInputValue(SORTBY.RATING);
        break;
      case SORTBY.COURSENAME:
        setInputValue(
          query?.order === 'asc'
            ? SORTBY.COURSENAME_ASC
            : SORTBY.COURSENAME_DESC,
        );
        break;
      case SORTBY.GRADE:
        setInputValue(
          query?.order === 'asc' ? SORTBY.GRADE_ASC : SORTBY.GRADE_DESC,
        );
        break;
      default:
        break;
    }

    if (!isSortby) {
      setInputValue('');
    }
  }, [query]);

  const handleSelectOption = e => {
    const filter = e.target.value;

    handleSelect(filter);
    setInputValue(filter);
  };

  return (
    <FormControl id={id} mb={4}>
      <FormLabel htmlFor={id} color='gray.600' fontSize='sm' fontWeight='bold'>
        {label}
      </FormLabel>
      <Select
        onChange={handleSelectOption}
        value={inputValue}
        placeholder={placeholder}
        size='xs'
        borderRadius='md'
        mb={4}
        bg='white'
        _focus={{ boxShadow: 'none' }}
      >
        {options.map(({ value, name }) => (
          <option key={value} value={value}>
            {name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

FilterInput.defaultProps = {
  options: [],
  label: '',
  placeholder: '',
  handleSelect: () => {},
};

FilterInput.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  handleSelect: PropTypes.func,
};

export default FilterInput;
