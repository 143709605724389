import React from 'react';
import { createIcon } from '@chakra-ui/react';

const SlideDownload = createIcon({
  displayName: 'SlideDownload',
  viewBox: '0 0 484 308',
  defaultProps: {
    w: '484px',
    h: '308px',
    fill: 'transparent',
  },
  path: (
    <>
      <path d="M285.236 263.485H119.531v43.379h165.705a21.715 21.715 0 0 0 14.843-6.622 21.697 21.697 0 0 0 0-30.137 21.696 21.696 0 0 0-14.843-6.62Z" fill="#3B61DE"/>
      <path opacity=".2" d="M285.236 263.485H119.531v43.379h165.705a21.715 21.715 0 0 0 14.843-6.622 21.697 21.697 0 0 0 0-30.137 21.696 21.696 0 0 0-14.843-6.62Z" fill="#fff"/>
      <path d="M133.34 306.864H49.472v-6.197h25.983v-30.735H49.472v-6.447h83.868a21.69 21.69 0 0 1 14.842 6.62 21.697 21.697 0 0 1 0 30.137 21.709 21.709 0 0 1-14.842 6.622Z" fill="#3B61DE"/>
      <path d="M55.672 300.417h77.448a15.204 15.204 0 0 0 5.841-1.148 15.168 15.168 0 0 0 9.387-14.108 15.227 15.227 0 0 0-15.228-15.228H55.672v30.484Z" fill="#fff"/>
      <path d="M310.247 230.138H171.691v33.346h138.556a5.562 5.562 0 0 0 4.057-1.667 5.788 5.788 0 0 0 1.639-4.03v-21.925a5.56 5.56 0 0 0-5.696-5.724Z" fill="#A8B9F0"/>
      <path opacity=".2" d="M231.515 241.339h47.63c.634 0 1.24.25 1.69.7.447.448.7 1.056.7 1.69v7.364a2.39 2.39 0 0 1-2.39 2.362h-47.63a2.383 2.383 0 0 1-.914-.172 2.374 2.374 0 0 1-1.295-1.281 2.36 2.36 0 0 1-.181-.909v-7.364a2.337 2.337 0 0 1 .692-1.698c.222-.222.486-.4.778-.517.292-.119.603-.18.92-.175Z" fill="#1E3FAE"/>
      <path d="M118.089 263.484h75.447a5.562 5.562 0 0 0 4.057-1.667 5.79 5.79 0 0 0 1.64-4.03v-21.925a5.56 5.56 0 0 0-5.697-5.724h-75.308l-.139 33.346Z" fill="#3B61DE"/>
      <path d="M118.089 263.484h75.447a5.562 5.562 0 0 0 4.057-1.667 5.79 5.79 0 0 0 1.64-4.03v-21.925a5.56 5.56 0 0 0-5.697-5.724h-75.308l-.139 33.346Z" fill="#5172E1"/>
      <path d="M118.089 258.486h73.168c.737 0 1.442-.295 1.965-.814a2.791 2.791 0 0 0 .814-1.965v-17.979a2.956 2.956 0 0 0-.806-2.001 2.732 2.732 0 0 0-.931-.625 2.702 2.702 0 0 0-1.097-.209h-73.113v23.593Z" fill="#fff"/>
      <path d="M75.455 230.138h163.677v-33.347H75.455a7.853 7.853 0 0 0-3.015.589 7.845 7.845 0 0 0-4.26 4.26 7.836 7.836 0 0 0-.589 3.015v17.869a7.608 7.608 0 0 0 .656 2.931 7.636 7.636 0 0 0 1.734 2.46 7.903 7.903 0 0 0 5.558 2.223" fill="#3B61DE"/>
      <path opacity=".3" d="M75.455 230.138h163.677v-33.347H75.455a7.853 7.853 0 0 0-3.015.589 7.845 7.845 0 0 0-4.26 4.26 7.836 7.836 0 0 0-.589 3.015v17.869a7.608 7.608 0 0 0 .656 2.931 7.636 7.636 0 0 0 1.734 2.46 7.903 7.903 0 0 0 5.558 2.223" fill="#fff"/>
      <path d="M295.129 201.793h-75.308a2.779 2.779 0 0 0-2.779 2.779v17.868c0 .375.078.745.225 1.09.15.344.364.652.637.911a2.758 2.758 0 0 0 2 .834h75.308l-.083-23.482Z" fill="#fff"/>
      <path d="M217.684 196.794h82.923v5.002h-80.783a2.72 2.72 0 0 0-1.056 0 2.763 2.763 0 0 0-.939.561c-.27.251-.492.551-.645.884a2.72 2.72 0 0 0-.222 1.056v18.119c.003.361.078.719.222 1.055.145.339.361.642.639.89.275.236.598.414.945.528a2.33 2.33 0 0 0 1.056.139h80.783v5.002h-82.923a5.563 5.563 0 0 1-5.31-3.516 5.516 5.516 0 0 1-.386-2.209V202.38a5.557 5.557 0 0 1 .419-2.187 5.544 5.544 0 0 1 1.248-1.843 5.557 5.557 0 0 1 1.842-1.247 5.555 5.555 0 0 1 2.187-.42" fill="#3B61DE"/>
      <path opacity=".2" d="M217.684 196.794h82.923v5.002h-80.783a2.72 2.72 0 0 0-1.056 0 2.763 2.763 0 0 0-.939.561c-.27.251-.492.551-.645.884a2.72 2.72 0 0 0-.222 1.056v18.119c.003.361.078.719.222 1.055.145.339.361.642.639.89.275.236.598.414.945.528a2.33 2.33 0 0 0 1.056.139h80.783v5.002h-82.923a5.563 5.563 0 0 1-5.31-3.516 5.516 5.516 0 0 1-.386-2.209V202.38a5.557 5.557 0 0 1 .419-2.187 5.544 5.544 0 0 1 1.248-1.843 5.557 5.557 0 0 1 1.842-1.247 5.555 5.555 0 0 1 2.187-.42" fill="#D3DCF8"/>
      <path opacity=".1" d="m18.1 238.983 51.656.994-51.656.604v-1.598Z" fill="#000"/>
      <path d="m23.897 238.599 27.97 19.284a4.492 4.492 0 0 0 1.71.742 4.427 4.427 0 0 0 1.866-.013 4.568 4.568 0 0 0 1.698-.773 4.445 4.445 0 0 0 1.236-1.397 23.955 23.955 0 0 0 1.924-4.553 7.616 7.616 0 0 1 1.749-2.944l29.837-31.403 11.838-13.208-12.888-14.875-39.24 50.253a1.74 1.74 0 0 1-1.812.661l-19.63-5.145a8.55 8.55 0 0 0-5.276.239c-1.46.591-2.535 1.572-.982 3.132Z" fill="#FC9D9D"/>
      <path opacity=".15" d="M62.026 248.944a7.616 7.616 0 0 0-1.748 2.944 23.77 23.77 0 0 1-1.962 4.61c-.318.535-.744 1-1.25 1.365a4.533 4.533 0 0 1-1.695.748 4.58 4.58 0 0 1-1.85 0 4.42 4.42 0 0 1-1.696-.742l-27.95-19.271a1.333 1.333 0 0 1-.49-1.566l27.748 17.749c2.988 2.063 5.032-.912 6.145-3.308a26.01 26.01 0 0 1 3.774-5.931L99.65 199.71l4.025 4.642-11.836 13.208-29.813 31.384Z" fill="#000"/>
      <path d="m35.038 236.522-4.88-1.257a8.557 8.557 0 0 0-5.277.239c-1.46.572-2.516 1.54-.982 3.144l27.97 19.285a4.506 4.506 0 0 0 1.71.742 4.427 4.427 0 0 0 1.865-.013 4.563 4.563 0 0 0 1.698-.773c.506-.371.926-.85 1.236-1.397a24.06 24.06 0 0 0 1.925-4.553 7.544 7.544 0 0 1 .855-1.793c-27.956-8.561-26.12-13.624-26.12-13.624Z" fill="#4D61FC"/>
      <path opacity=".3" d="M23.17 236.914c-.113.465.07 1.025.73 1.686l27.97 19.283a4.584 4.584 0 0 0 1.99.786 4.624 4.624 0 0 0 2.13-.22l-32.82-21.535Z" fill="#000"/>
      <path d="M145.457 158.106H119.04c-5.27 0-9.843 2.831-15.66 10.774l-51.417 68.16 12.579 10.063 60.788-57.863 20.127-31.134Z" fill="#FFCE6B"/>
      <path d="m187.502 64.392-15.604 2.41c-4.296.836-8.353 6.59-9.435 10.83L143.469 120.4l13.837 36.479 69.185 1.258s.629-27.674 3.774-39.624l20.856-2.705L239.8 73.732a11.234 11.234 0 0 0-3.542-5.629 11.265 11.265 0 0 0-6.088-2.66l-20.038-2.277-22.63 1.226Z" fill="#CCADEB"/>
      <path opacity=".2" d="M166.508 91.469c.465-.796.641-1.731.49-2.642-.157-.843-.629-1.258-1.824 1.038-2.295 4.346-8.434 19.699-11.321 25.787 0 0 2.516 15.724 1.887 22.013h9.378s1.157-16.585-3.365-22.799a10.57 10.57 0 0 1-1.818-7.749c.466-3.164 3.145-9.516 6.573-15.648ZM138.318 158.106h-15.812s-14.831 4.403-12.68 20.372c.956 7.082 1.824 14.271-1.83 20.41l-46.6 45.071h.045l3.145 3.144 59.53-56.605 17.523-26.92-3.321-5.472Z" fill="#000"/>
      <path d="M217.767 13.868s21.611-.15 22.794 20.542c.081 1.453.044 2.912.163 4.365.44 5.396 1.887 16.088 6.29 20.328l-2.931.861s-4.403-5.478-4.573-10.195c0 0-1.585 6.824.541 11.371 0 0-6.208 4.604-21.56 5.12 0 0-6.051-10.497-.919-21.8.239-.534.522-1.05.812-1.566.761-1.358 2.893-6.063.918-13.623l-1.535-15.403Z" fill="#000"/>
      <path d="M223.635 22.76c.208-5.634-3.138-10.334-7.484-10.498-4.346-.164-8.044 4.27-8.258 9.904-.208 5.634 3.138 10.333 7.484 10.498 4.346.163 8.044-4.27 8.258-9.905Z" fill="#4D61FC"/>
      <path d="M203.075 50.202a19.247 19.247 0 0 0 14.793-14.24c3.245-11.95.987-17.868-2.076-24.623C213.49 6.25 203.955-1.491 193.301.25c-10.755 1.761-16.774 7.46-18.12 17.202-2.516 18.234 10.422 41.455 27.894 32.75Z" fill="#000"/>
      <path d="m131.242 283.67 31.92-11.629a4.494 4.494 0 0 0 2.66-2.497 4.477 4.477 0 0 0 .359-1.83 4.56 4.56 0 0 0-.422-1.818 24.791 24.791 0 0 0-2.597-4.208 7.566 7.566 0 0 1-1.384-3.144l-10.221-42.518-3.886-17.284-17.699 1.887 18.239 61.097c.114.327.126.686.032 1.019a1.76 1.76 0 0 1-.547.868l-15.567 13.026a8.564 8.564 0 0 0-2.855 4.44c-.378 1.51-.214 2.95 1.968 2.591Z" fill="#FC9D9D"/>
      <path opacity=".15" d="M161.772 258.561a7.645 7.645 0 0 0 1.39 3.145 23.85 23.85 0 0 1 2.641 4.264 4.499 4.499 0 0 1 .019 3.623 4.675 4.675 0 0 1-1.069 1.516c-.459.421-1 .748-1.591.95l-31.901 11.623c-.277.1-.585.107-.868.012a1.358 1.358 0 0 1-.692-.522l30.542-12.34c3.409-1.258 2.183-4.642.862-6.918a26.058 26.058 0 0 1-2.635-6.535l-15.007-58.008 4.226-.592 3.887 17.284 10.196 42.498Z" fill="#000"/>
      <path d="m207.132 34.957 5.339 28.146-2.119 1.434c-8.07 5.201-20.02 7.088-22.837-.139l.886-19.145 18.731-10.296Z" fill="#FC9D9D"/>
      <path opacity=".15" d="M188.03 52.264c2.459.968 2.189 1.446 4.787 1.138l1.786-.258c.214-.021.428-.074.629-.157h.126c.32-.113.629-.157 1.025-.29.126 0 .251-.1.377-.119a14.908 14.908 0 0 0 4.812-2.56.343.343 0 0 1 .144-.088.39.39 0 0 1 .17 0 .398.398 0 0 1 .157.044.326.326 0 0 1 .126.107c.076.09.113.204.113.321a.46.46 0 0 1-.151.302 16.534 16.534 0 0 1-6.289 3.145 12.814 12.814 0 0 1-7.22 3.774c-1.089.163.71.05-.39-.063l-.202-5.296Z" fill="#000"/>
      <path d="M183.69 152.402v.046-.046Z" fill="#fff" stroke="#000" strokeWidth="1.11" strokeMiterlimit="10"/>
      <path d="m199.59 141.042-6.145 1.491-10.031-1.095a4.486 4.486 0 0 0-2.931 1.635l-10.793 9.498c-.227.251-.346.58-.333.917.012.337.15.657.39.894a1.317 1.317 0 0 0 1.823 0l8.806-7.346-6.038 7.321a1.253 1.253 0 0 0-.157 1.179 1.2 1.2 0 0 0 .346.504c.157.136.345.233.547.279.226.052.465.041.685-.029.22-.071.415-.2.573-.373l6.063-7.334-4.604 5.585a1.17 1.17 0 0 0 .107 1.612c.151.134.327.229.522.275a1.162 1.162 0 0 0 1.126-.251l6.641-6.02-4.49 4.114c-.12.109-.214.245-.277.398a1.035 1.035 0 0 0 .453 1.268 1.073 1.073 0 0 0 1.019 0l6.251-3.408a2.431 2.431 0 0 1 1.982-.164l2.597.667c1.573.547 3.648.176 5.887-.057l5.114-.497c-1.34-3.339-5.133-11.063-5.133-11.063Z" fill="#FC9D9D"/>
      <path opacity=".15" d="M187.709 151.39c-1.478.944-3.949 2.516-4.811 3.145-1.208.868-1.673-.056-1.673-.056-.025.215.013.434.107.629.094.196.245.359.428.471a1.09 1.09 0 0 0 1.069 0l6.528-3.553a2.502 2.502 0 0 1 1.013-.312c.358-.03.717.019 1.05.142l-1.409-.629a2.48 2.48 0 0 0-1.169-.198 2.544 2.544 0 0 0-1.133.361ZM182.596 153.608a.119.119 0 0 0 .069-.011.166.166 0 0 0 .063-.045l4.315-3.931c.082-.101.113-.239 0-.321a.231.231 0 0 0-.327 0l-4.315 3.925a.23.23 0 0 0-.069.163c0 .062.025.12.069.164a.36.36 0 0 0 .195.056ZM178.609 153.721c.044 0 .138 0 .132-.057l4.522-5.478c.019-.023.031-.05.044-.079.006-.028.013-.058.006-.088a.195.195 0 0 0-.025-.085.177.177 0 0 0-.056-.069.305.305 0 0 0-.076-.042.231.231 0 0 0-.245.074l-4.529 5.49a.314.314 0 0 0-.05.073.208.208 0 0 0 0 .175.295.295 0 0 0 .05.073.344.344 0 0 0 .114.03.299.299 0 0 0 .113-.017Z" fill="#000"/>
      <path opacity=".2" d="m222.925 96.078-5.133 35.851-21.799 8.251 3.145 11.322 19.749-1.258.572 7.704 7.044-.195c.082-3.107.824-28.108 3.774-39.303l9.158-1.189.905-22.711-17.415 1.528Z" fill="#000"/>
      <path d="M176.854 39.48a3.46 3.46 0 0 0-1.459.812c-.415.393-.73.88-.919 1.421a3.652 3.652 0 0 0-.157 1.705c.082.57.302 1.112.629 1.585.327.462.755.84 1.258 1.1a3.365 3.365 0 0 0 3.227-.12 3.42 3.42 0 0 0 1.188-1.188" stroke="#fff" strokeWidth="1.762" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M181.854 49.894a14.47 14.47 0 0 0 5.007 2.766 14.5 14.5 0 0 0 5.685.618l1.107-.095a14.012 14.012 0 0 0 5.428-1.673 14.412 14.412 0 0 0 4.403-3.685 15.14 15.14 0 0 0 2.66-5.139c.535-1.885.711-3.855.516-5.805l-2.082-20.9a5.08 5.08 0 0 0-1.773-3.371 4.838 4.838 0 0 0-1.673-.915 4.833 4.833 0 0 0-1.9-.192l-19.038 1.842a6.243 6.243 0 0 0-2.353.74c-.729.401-1.371.943-1.886 1.594a6.57 6.57 0 0 0-1.372 4.742l1.969 19.39a15.355 15.355 0 0 0 5.302 10.083Z" fill="#FC9D9D"/>
      <path d="M226.497 158.106s4.717 14.825 3.277 27.555c-1.22 10.818-10.824 14.585-22.07 14.466l-14.931-.227-46.153-10.975 15.724 67.298-16.353 4.403-32.29-81.135a15.492 15.492 0 0 1-1.051-7.455 15.502 15.502 0 0 1 2.573-7.075 15.543 15.543 0 0 1 12.9-6.855h98.374Z" fill="#FFCE6B"/>
      <path d="M189.307 32.433c-.031-.308.025-.62.164-.896.138-.277.358-.505.629-.658a1.586 1.586 0 0 1 1.742.126 1.584 1.584 0 0 1 .088 2.428c-.233.207-.522.34-.83.384a1.587 1.587 0 0 1-1.183-.32 1.498 1.498 0 0 1-.409-.47 1.537 1.537 0 0 1-.201-.594Z" fill="#000"/>
      <path d="M189.829 32.548a.312.312 0 0 1 .006-.129.307.307 0 0 1 .145-.205.344.344 0 0 1 .315-.017.303.303 0 0 1 .144.13.29.29 0 0 1 .051.187.327.327 0 0 1-.221.3.315.315 0 0 1-.188.01.324.324 0 0 1-.17-.1.312.312 0 0 1-.082-.176Z" fill="#fff"/>
      <path d="M177.156 34.177c-.032-.31.025-.62.163-.897a1.58 1.58 0 0 1 2.931 1.12c-.075.3-.245.57-.478.776a1.55 1.55 0 0 1-1.446.342 1.579 1.579 0 0 1-.969-.749 1.531 1.531 0 0 1-.201-.592Z" fill="#000"/>
      <path d="M177.672 34.215a.399.399 0 0 1 0-.13.378.378 0 0 1 .05-.117.424.424 0 0 1 .101-.088.273.273 0 0 1 .119-.043.34.34 0 0 1 .195.023.363.363 0 0 1 .145.127.293.293 0 0 1 .05.188c0 .066-.025.13-.063.184a.317.317 0 0 1-.151.119.339.339 0 0 1-.365-.087.309.309 0 0 1-.081-.176Z" fill="#fff"/>
      <path opacity=".15" d="m207.213 35.063-2.207.315 3.101 4.176-.894-4.49ZM183.955 39.441c.088.238.226.453.409.63a1.687 1.687 0 0 0 1.352.484l2.113-.095a.54.54 0 0 0 .201 0 .4.4 0 0 0 .164-.094.565.565 0 0 0 .119-.157.54.54 0 0 0 .044-.202.586.586 0 0 0-.163-.358.407.407 0 0 0-.371-.094l-2.113.094a.773.773 0 0 1-.359-.063.927.927 0 0 1-.321-.201.876.876 0 0 1-.22-.308 1.082 1.082 0 0 1-.088-.371 1.026 1.026 0 0 1 .264-.711c.157-.192.384-.319.629-.359a.473.473 0 0 0 .264-.169.496.496 0 0 0 .095-.296.456.456 0 0 0-.095-.296.46.46 0 0 0-.264-.176c-.484.04-.937.265-1.258.63a1.945 1.945 0 0 0-.484 1.39c-.025.243 0 .49.082.722Z" fill="#000"/>
      <path d="m212.346 63.448 17.441 1.742a11.318 11.318 0 0 1 9.761 8.259l17.416 63.687a8.27 8.27 0 0 1-1.139 6.811 8.22 8.22 0 0 1-2.578 2.423 8.206 8.206 0 0 1-3.353 1.119l-50.756 5.9-2.831-12.265 37.738-10.692-21.699-66.984Z" fill="#CCADEB"/>
      <path d="M205.798 35.717s6.969.22 8.019-6.918c1.051-7.139-7.547-7.988-8.314-.786l-.466 7.843.761-.139Z" fill="#FC9D9D"/>
      <path d="M208.063 32.951a3.445 3.445 0 0 1 1.78.428 3.597 3.597 0 0 1 1.824 3.094 3.563 3.563 0 0 1-.453 1.805c-.296.542-.73.997-1.258 1.315a3.414 3.414 0 0 1-1.755.459 3.461 3.461 0 0 1-1.755-.528 3.677 3.677 0 0 1-1.258-1.359" stroke="#fff" strokeWidth="1.762" strokeMiterlimit="10" strokeLinecap="round"/>
      <path opacity=".15" d="M211.717 25.712a.515.515 0 0 0 0-.334.522.522 0 0 0-.201-.258 2.704 2.704 0 0 0-2.12-.447 2.448 2.448 0 0 0-.962.548 2.658 2.658 0 0 0-.629.912 5.256 5.256 0 0 0-.592 2.811v.749c0 .54.95.685.912 0v-.818a5.071 5.071 0 0 1 .466-2.314c.1-.234.245-.447.421-.63.183-.175.396-.314.629-.408a1.97 1.97 0 0 1 1.447.333.474.474 0 0 0 .629-.15v.006Z" fill="#000"/>
      <path d="M197.829 27.692c.478.835.755 1.77.818 2.73.031.458.006.919-.069 1.37a6.1 6.1 0 0 1-1.44 3.102 5.85 5.85 0 0 1-2.856 1.786 4.862 4.862 0 0 1-1.163.163 5.896 5.896 0 0 1-4.303-1.528 6.287 6.287 0 0 1-2.031-4.183v-.1c-.019-.636.05-1.27.207-1.887a4.864 4.864 0 0 0-2.924.346 6.274 6.274 0 0 1 1.063 3.094 6.167 6.167 0 0 1-1.453 4.403 5.864 5.864 0 0 1-4.063 2.057 5.552 5.552 0 0 1-1.73-.183 6.08 6.08 0 0 1-3.145-2.012 6.294 6.294 0 0 1-1.408-3.535 6.106 6.106 0 0 1 1.453-4.402 6.142 6.142 0 0 1 4.119-2.127 6.325 6.325 0 0 1 1.849.214 6.103 6.103 0 0 1 2.9 1.786 5.321 5.321 0 0 1 3.648-.465 6.073 6.073 0 0 1 1.321-1.969 5.772 5.772 0 0 1 1.962-1.258c.189-.078.383-.145.585-.2.39-.095.792-.151 1.195-.17a5.751 5.751 0 0 1 3.541.936 6.169 6.169 0 0 1 1.924 2.032Z" stroke="#fff" strokeWidth="1.75" strokeMiterlimit="10"/>
      <path d="M184.923 43.322a.324.324 0 0 0-.126.05.856.856 0 0 0-.088.107.364.364 0 0 0-.031.138c0 .048.013.095.031.139a4.664 4.664 0 0 0 1.925 1.654 4.398 4.398 0 0 0 4.742-.68 4.63 4.63 0 0 0 1.378-2.125c.031-.214-.057-.396-.321-.359l-7.51 1.076Z" fill="#fff"/>
      <path d="M205.987 18.968S209.943 2.05 184.81 8.106c-14.466 3.491-15.566 9.932-15.956 13.259-.39 3.327 7.547-2.277 7.547-2.277s0 6.692 2.648 4.736c3.245-2.396 6.988-7.698 6.988-7.698s8.22 8.76 16.447 6.817c4.673-1.1 3.503-3.975 3.503-3.975Z" fill="#000"/>
      <path d="M200.49 15.849s4.157 12.736 4.314 15.277c.214 3.466 5.221-8.176 1.007-18.466l-5.321 3.189Z" fill="#000"/>
      <path d="M123.89 159.728h90.971v-4.453H123.89l-.051.088a4.552 4.552 0 0 0-.534 2.183c.006.761.207 1.508.585 2.17v.012Z" fill="#21252B"/>
      <path opacity=".3" d="M123.89 159.728h90.971v-4.453H123.89a4.09 4.09 0 0 0 0 4.441v.012Z" fill="#000"/>
      <path d="M123.688 155.382h53.336l-19.869-39.568H103.82l19.868 39.568Z" fill="#21252B"/>
      <path opacity=".3" d="M123.688 155.382h53.336l-19.869-39.568H103.82l19.868 39.568Z" fill="#000"/>
      <path d="M214.666 159.596h-36.693a2.213 2.213 0 0 1-1.566-.651 2.204 2.204 0 0 1-.654-1.57c0-.588.233-1.153.654-1.569a2.213 2.213 0 0 1 1.566-.651h36.693v4.441Z" fill="#21252B"/>
      <path d="m176.785 155.866 1.742-.711-19.869-39.341h-1.987l20.114 40.052Z" fill="#21252B"/>
      <path opacity=".2" d="M141.582 140.293c1.422-1.287 1.113-3.937-.679-5.92-1.793-1.983-4.403-2.547-5.824-1.259-1.422 1.287-1.12 3.937.679 5.92 1.793 1.982 4.403 2.547 5.824 1.259Z" fill="#fff"/>
      <path opacity=".3" d="m176.476 155.244-52.662.031-.289-.566h52.681l.27.535Z" fill="#000"/>
      <path opacity=".2" d="M215.063 190.724a15.932 15.932 0 0 1-12.227 3.773l-60.933-7.773 4.755 2.364-.038-.163 46.153 11.007 14.931.226c11.246.113 20.85-3.654 22.07-14.466 1.44-12.73-3.277-27.554-3.277-27.554h-8.334s8.466 22.296-3.1 32.586Z" fill="#000"/>
      <path opacity=".2" d="m141.903 186.723 16.252 70.285 4.503-1.1-16.353-67.298-4.402-1.887Z" fill="#000"/>
      <path d="m136.003 273.387-3.874 3.239a8.564 8.564 0 0 0-2.855 4.44c-.378 1.522-.214 2.962 1.968 2.604l31.92-11.629a4.494 4.494 0 0 0 2.66-2.497 4.481 4.481 0 0 0 .359-1.831 4.555 4.555 0 0 0-.422-1.817 24.752 24.752 0 0 0-2.597-4.208 7.32 7.32 0 0 1-.969-1.736c-23.127 17.869-26.19 13.435-26.19 13.435Z" fill="#4D61FC"/>
      <path opacity=".3" d="M129.45 283.286c.314.364.874.534 1.792.383l31.92-11.629a4.535 4.535 0 0 0 2.849-3.038l-36.561 14.284Z" fill="#000"/>
      <path opacity=".2" d="M249.894 147.489a8.236 8.236 0 0 0 5.931-3.542 8.27 8.27 0 0 0 1.139-6.811l-1.61-5.906c-.397 11.183-9.045 11.466-9.045 11.466l-47.8 8.076.629 2.616 50.756-5.899Z" fill="#000"/>
      <path d="m189.251 113.159-1.416-.717-1.396.762.245-1.573-1.151-1.088 1.567-.252.679-1.434.723 1.416 1.579.201-1.12 1.126.29 1.559ZM213.78 111.273l-1.415-.717-1.397.761.246-1.573-1.151-1.088 1.566-.251.679-1.434.723 1.415 1.579.201-1.12 1.126.29 1.56ZM203.717 133.286l-1.416-.717-1.396.761.245-1.572-1.151-1.088 1.567-.252.679-1.434.723 1.416 1.579.201-1.12 1.126.29 1.559ZM232.648 137.689l-1.415-.717-1.396.761.245-1.573-1.151-1.088 1.566-.251.68-1.434.723 1.415 1.579.201-1.12 1.126.289 1.56ZM208.119 151.527l-1.415-.717-1.396.761.245-1.573-1.151-1.088 1.566-.251.679-1.434.724 1.415 1.578.201-1.119 1.126.289 1.56ZM154.029 112.532l-1.415-.717-1.396.761.245-1.573-1.151-1.088 1.566-.251.679-1.435.724 1.416 1.578.201-1.119 1.126.289 1.56ZM175.414 133.286l-1.416-.717-1.396.761.245-1.572-1.151-1.088 1.567-.252.679-1.434.723 1.416 1.579.201-1.12 1.126.29 1.559ZM228.61 125.388l-1.566.251 1.151 1.089-.245 1.572.629-.34c.157-1.258.327-2.421.503-3.56l-.472.988ZM223.843 93.034l-1.415-.718-1.396.761.245-1.572-1.151-1.088 1.566-.252.679-1.434.724 1.416 1.578.2-1.119 1.127.289 1.56ZM219.44 70.39l-1.415-.716-1.396.761.245-1.572-1.151-1.088 1.566-.252.68-1.434.723 1.415 1.578.201-1.119 1.126.289 1.56ZM193.024 91.147l-1.415-.717-1.396.76.245-1.572-1.151-1.088 1.566-.251.679-1.434.724 1.415 1.578.201-1.119 1.126.289 1.56ZM175.414 76.68l-1.416-.717-1.396.761.245-1.572-1.151-1.088 1.567-.252.679-1.434.723 1.415 1.579.201-1.12 1.126.29 1.56ZM174.156 101.21l-1.415-.717-1.397.761.246-1.572-1.151-1.088 1.566-.252.679-1.434.723 1.415 1.579.202-1.12 1.125.29 1.56Z" fill="#fff"/>
      <path d="M81.524 34.687h-22.55V67.94L70.4 56.518a3.469 3.469 0 0 1 4.904 4.904L57.958 78.769a3.47 3.47 0 0 1-4.905 0L35.708 61.422a3.47 3.47 0 0 1 4.904-4.904L52.036 67.94V34.687H29.487a12.155 12.155 0 0 0-12.142 12.141v45.099a12.156 12.156 0 0 0 12.142 12.142h52.037a12.158 12.158 0 0 0 12.142-12.142V46.828a12.155 12.155 0 0 0-12.142-12.142Z" fill="#7C96E9"/>
      <path d="M58.975 10.405a3.47 3.47 0 1 0-6.939 0v24.283h6.939V10.405Z" fill="#7C96E9"/>
      <path d="M361.59 180.23a45.389 45.389 0 0 0-19.41-6.68c-3.17-.33-6.64-.24-9.18 1.68-.3.164-.56.407-.73.703-.18.296-.27.633-.27.977.14.484.46.894.9 1.15 7.9 6 19.84 6.78 28.64 2.17M361.09 179.641a94.484 94.484 0 0 1-12.53-11 31.738 31.738 0 0 1-7.87-14.54c-.33-1.66-.44-3.55.63-4.87 10 7.81 17.84 17.91 19.77 30.44" fill="#4D61FC"/>
      <path d="M361.49 180.152c-.75-5.47-1-11.08.23-16.46 1.23-5.38 4.18-10.52 8.77-13.58 1.73 4 1 8.68-.23 12.9a62.86 62.86 0 0 1-8.7 18.31M371.29 197.711c-6 .72-11.13 3.86-15.57 7.88a29.538 29.538 0 0 0-8.91 15.28c5.84-.36 10.79-4.47 14.37-9.08s6.08-9.68 10-14M373.41 200.522a38.467 38.467 0 0 1-2.39-18.723 38.49 38.49 0 0 1 6.83-17.597c3.39 12.05 2.14 25.66-4.44 36.32ZM386.36 215.93a27.786 27.786 0 0 0-26.55 15.28c-.33.66-.62 1.49-.18 2.08.23.221.51.384.82.474.3.091.63.106.94.046 5.23-.34 9.87-3.49 13.7-7.07s7.16-7.73 11.39-10.83M385.95 215.091a49.428 49.428 0 0 1-.5-27.62c1-3.55 2.61-7.3 5.9-9a1.124 1.124 0 0 1 .9-.16c.27.138.47.375.57.66a16.7 16.7 0 0 1 1.39 7.92c-.2 2.696-.61 5.372-1.24 8-1 4.91-1.59 10.17-4.28 14.4a27.67 27.67 0 0 0-1.71 3.17c-.51 1.07-.51 1.45-1 2.3M420.21 220.201c-2.54-9.66.16-21.37 6.24-29.28.45-.59 1.05-1.21 1.78-1.14 1.12.1 1.41 1.59 1.36 2.72a52.864 52.864 0 0 1-9.38 27.7ZM423.3 222.311c11.73 3.05 25.19.08 35-7.11a28.273 28.273 0 0 0-18.92-2.32c-6.32 1.44-12.09 4.27-16 9.43M425.87 215.881c6.57-10.72 19.17-17.71 31.72-18.34-1.45 5.1-5.64 9.07-10.38 11.47-4.74 2.4-10 3.44-15.22 4.45-2 .39-4.81.83-6.12 2.42ZM420.87 184.642c1.78-6.1 3.57-12.25 4.06-18.58.49-6.33-.41-12.94-3.7-18.37-6.14 11.87-6.76 25.75-.54 37.58" fill="#4D61FC"/>
      <path opacity=".4" d="M420.87 184.642c1.78-6.1 3.57-12.25 4.06-18.58.49-6.33-.41-12.94-3.7-18.37-6.14 11.87-6.76 25.75-.54 37.58" fill="#fff"/>
      <path d="M441.53 174.231c7.18-13 21.82-23.46 36.64-24.92-5.72 14.23-21.31 25.23-36.64 24.92Z" fill="#4D61FC"/>
      <path opacity=".2" d="M441.53 174.231c7.18-13 21.82-23.46 36.64-24.92-5.72 14.23-21.31 25.23-36.64 24.92Z" fill="#000"/>
      <path d="M437.01 176.791c10.71 1.63 19.83 8.47 29.83 12.61a13.372 13.372 0 0 0 6.75 1.29c2.29-.3 4.56-1.84 5.07-4.1a5.83 5.83 0 0 0-.23-2.841 5.914 5.914 0 0 0-1.54-2.399 14.338 14.338 0 0 0-4.9-3c-10.89-4.53-22.58-4.65-34.06-1.9M430.87 184.3c2.42.69 3.25 2 5.17 3.58a55.643 55.643 0 0 0 22.62 11.62c.86.21 2 .3 2.42-.48.42-.78-.09-1.51-.58-2.12-3.48-4.35-8.44-7.32-13.66-9.27-5.22-1.95-9.74-4.28-16-3.78M423.87 138.951a42.818 42.818 0 0 0 15.23 9.059 42.748 42.748 0 0 0 17.59 2.111 2.2 2.2 0 0 0 1.49-.54c.83-.9-.16-2.28-1.13-3-9.64-7.52-21.33-10.64-33.18-7.59" fill="#4D61FC"/>
      <path d="M432.12 135.921a45.726 45.726 0 0 1 41-6.76c-5.16 5.08-12.31 7.82-19.52 8.57a61.73 61.73 0 0 1-21.43-1.81M428.87 115.082c-.94-9.63-.33-17.23-1-26.91 0-.77-.22-1.7-.94-2-1-.39-1.85.85-2.18 1.88a30.905 30.905 0 0 0-1.05 13.876 30.832 30.832 0 0 0 5.16 12.924" fill="#4D61FC"/>
      <path d="M428.13 116.152c6.53-4 11.42-9.31 16-15.51 3.15-4.31 5.81-9.48 5.09-14.77-4.42-.09-8.63 2.35-11.5 5.72-2.87 3.37-4.59 7.56-5.87 11.8-1.28 4.24-2.17 8.59-3.67 12.76" fill="#4D61FC"/>
      <path opacity=".2" d="M428.13 116.152c6.53-4 11.42-9.31 16-15.51 3.15-4.31 5.81-9.48 5.09-14.77-4.42-.09-8.63 2.35-11.5 5.72-2.87 3.37-4.59 7.56-5.87 11.8-1.28 4.24-2.17 8.59-3.67 12.76" fill="#000"/>
      <path d="M443.8 111.791a128.767 128.767 0 0 0 39.27-23.15c-4.69-1.89-10.06-.76-14.75 1.13-10.72 4.33-19.47 11.63-24.72 21.92M432.54 121.411c7.24.8 14.61 1.31 21.8.11 7.19-1.2 13.74-4 20.35-6.76a2.373 2.373 0 0 0 1.38-1c.11-.305.12-.636.03-.947a1.598 1.598 0 0 0-.52-.793 4.222 4.222 0 0 0-1.74-.82c-7.18-2-14.93-1.07-22 1.32a102.003 102.003 0 0 0-19.31 8.94M391.15 139.282c-4.94-10-4.62-21.63-.35-31.92 3 4.59 3.37 10.42 2.82 15.87-.55 5.45-2.21 11.07-2.31 16.55M371.4 129.321a44.513 44.513 0 0 0-18.03 3.553 44.54 44.54 0 0 0-15.05 10.537c6.16 1 12.51-.62 18.11-3.36 5.6-2.74 10.59-6.58 15.53-10.39" fill="#4D61FC"/>
      <path opacity=".2" d="M371.4 129.321a44.513 44.513 0 0 0-18.03 3.553 44.54 44.54 0 0 0-15.05 10.537c6.16 1 12.51-.62 18.11-3.36 5.6-2.74 10.59-6.58 15.53-10.39" fill="#000"/>
      <path d="M358.12 124.88c-6-.49-11.85-1.24-17.66.54-5.81 1.78-11.09 5.82-13.46 11.41 5.65.64 11.38-.78 16.56-3.13a95.17 95.17 0 0 0 14.56-8.82ZM377.78 125.082c-5.43-3.56-11-7.19-17.34-8.66-6.34-1.47-13.65-.391-18.07 4.379 5.12 3 11.34 3.361 17.28 3.361 5.94 0 13.21-.071 18.81 1.909" fill="#4D61FC"/>
      <path d="M377.81 125.371c-9.28-9.12-12.81-25.51-9.07-38a57.867 57.867 0 0 1 9.07 38ZM403.36 99.52c1.75-4.07 3-8.34 4.55-12.49a35.878 35.878 0 0 1 6.5-11.52c3-3.29 7.07-5.74 11.5-6a46.211 46.211 0 0 1-8.01 17.353 46.271 46.271 0 0 1-14.32 12.647" fill="#4D61FC"/>
      <path opacity=".2" d="M403.36 99.52c1.75-4.07 3-8.34 4.55-12.49a35.878 35.878 0 0 1 6.5-11.52c3-3.29 7.07-5.74 11.5-6a46.211 46.211 0 0 1-8.01 17.353 46.271 46.271 0 0 1-14.32 12.647" fill="#000"/>
      <path d="M398.73 104.91c-2.9-4-6.24-9.41-10.18-12.39-3.94-2.98-9-4.78-13.84-3.63a32.164 32.164 0 0 0 10.11 10.834 32.249 32.249 0 0 0 13.89 5.166M399.66 99.86c-1.9-4.83-3.82-9.71-4.58-14.84-.76-5.13-.31-10.62 2.32-15.09 5.1 9.63 6.74 20 2.52 30M403.4 69.93c2.89-9.56 11.64-16.59 21.08-19.82a7.908 7.908 0 0 1 3.7-.56 3.28 3.28 0 0 1 1.71.735c.48.404.84.942 1.02 1.546.34 1.62-1 3.08-2.27 4.16a71.765 71.765 0 0 1-25.24 13.94ZM403.51 62.11c.36-6.16.75-12.41 2.75-18.24 2-5.83 5.87-11.32 11.49-13.87.95 6.22-1.79 12.41-5.1 17.76-3.31 5.35-7.31 10.41-9.29 16.38M403.36 66.352c-2.39-7-2.64-15.81-6.81-21.87-2.91-4.24-7.58-7.13-10.09-11.62-1.67 4.19-1.16 9 .59 13.19 1.9 4.095 4.51 7.82 7.71 11 3.08 3.3 5.73 5.78 8.6 9.26" fill="#4D61FC"/>
      <path d="M403.4 271.42c-.18 0-.34-46.46-.36-103.74-.02-57.28.13-103.75.32-103.75s.34 46.44.35 103.75c.01 57.31-.12 103.74-.31 103.74Z" fill="#61656D"/>
      <path d="M444 132.271a9.2 9.2 0 0 1-1.75.661l-4.81 1.62c-4 1.38-9.62 3.379-15.66 5.849-6.04 2.47-11.63 4.65-14.84 7.44a10.946 10.946 0 0 0-3 3.94c-.46 1.09-.54 1.76-.6 1.75-.06-.01 0-.169 0-.489.07-.46.18-.913.34-1.35a10.497 10.497 0 0 1 2.91-4.21 26.393 26.393 0 0 1 6.43-4c2.58-1.2 5.43-2.451 8.47-3.701 6.06-2.48 11.67-4.419 15.77-5.679 2-.64 3.71-1.11 4.87-1.42a10 10 0 0 1 1.87-.411Z" fill="#61656D"/>
      <path d="M457.3 99.64s-.15.19-.49.521l-1.49 1.4-5.56 5.06c-4.81 4.27-11.32 10.08-18.52 16.49-7.2 6.41-13.48 12.58-17.81 17.21-2.17 2.31-3.88 4.23-5 5.57l-1.35 1.53c-.31.35-.48.53-.5.51.11-.213.24-.411.4-.59.28-.38.69-.93 1.25-1.62 1.1-1.39 2.76-3.36 4.89-5.72a232.3 232.3 0 0 1 17.71-17.39c7.25-6.42 13.86-12.19 18.65-16.35l5.71-4.9 1.57-1.3c.17-.16.35-.3.54-.42Z" fill="#61656D"/>
      <path d="M423.43 129.642c-.19-.08 1.32-3.57 2.83-8s2.23-8.13 2.42-8.1c.19.03-.25 3.85-1.79 8.31-.64 1.904-1.4 3.77-2.25 5.59-.29.789-.7 1.531-1.21 2.2ZM403.44 157.722c-.41-.4-.78-.835-1.11-1.3l-2.85-3.66c-2.4-3.1-5.72-7.37-9.43-12s-7.13-8.89-9.61-11.92c-1.16-1.45-2.14-2.66-2.91-3.61-.38-.428-.71-.89-1-1.38.44.36.85.765 1.21 1.21.74.8 1.79 2 3.08 3.47 2.57 3 6 7.12 9.77 11.81 3.77 4.69 7 9 9.28 12.19 1.15 1.59 2.06 2.88 2.68 3.79.35.432.65.901.89 1.4Z" fill="#61656D"/>
      <path d="M394.54 146.291c-.11.12-2.48-2.7-3.72-7-.7-2.265-.99-4.635-.85-7h.3c-.14.53-.23.82-.29.81-.06-.01 0-.31 0-.86h.3c.15 2.319.55 4.616 1.19 6.85 1.19 4.18 3.25 7.09 3.07 7.2Z" fill="#4D61FC"/>
      <path d="M383.05 131.841a11.84 11.84 0 0 0-2.6-1.52c-2.34-.696-4.81-.89-7.23-.57-2.45.19-4.89.581-7.28 1.17-.98.316-1.98.556-3 .72.89-.534 1.86-.928 2.87-1.17a32.56 32.56 0 0 1 7.35-1.39c2.52-.336 5.07-.062 7.46.8a4.61 4.61 0 0 1 2.43 1.96ZM380.33 128.332c0 .11-1.52-.61-4-1.45a40.044 40.044 0 0 0-20.1-1.53c-2.57.46-4.13.94-4.16.83-.03-.11.36-.2 1.06-.45.98-.349 1.98-.633 3-.85a35.46 35.46 0 0 1 20.35 1.55c.98.374 1.93.808 2.85 1.3.36.15.7.352 1 .6ZM403.36 113.521c-.18 0-1.49-4.48-2.91-10.11-1.42-5.63-2.44-10.22-2.26-10.26.18-.04 1.48 4.47 2.91 10.1 1.43 5.63 2.44 10.22 2.26 10.27Z" fill="#61656D"/>
      <path d="M403.54 113.521c-.2.06-1-3-3.22-6s-4.85-4.74-4.75-4.9c.68.183 1.31.526 1.83 1 1.35.982 2.52 2.186 3.47 3.56 2.27 3.12 2.83 6.33 2.67 6.34ZM403.4 246.64c-.07 0-.21-1.11-.73-3.09a44.117 44.117 0 0 0-3.1-8c-3.21-6.56-9-14.87-16.47-23.2-3.68-4.2-7.25-8.16-10.28-12a84.373 84.373 0 0 1-7.34-10.59 50.585 50.585 0 0 1-3.71-7.86c-.34-1-.59-1.73-.73-2.25a3.743 3.743 0 0 1-.19-.81c.06 0 .4 1.07 1.16 3 1.1 2.666 2.4 5.247 3.88 7.72a88.955 88.955 0 0 0 7.42 10.44c3 3.76 6.61 7.7 10.29 11.91 7.44 8.35 13.27 16.77 16.39 23.44a39.646 39.646 0 0 1 2.93 8.18c.18.769.32 1.546.41 2.33.06.256.08.518.07.78ZM440.08 205.221c-.61.406-1.25.767-1.92 1.08-1.74.98-3.41 2.082-5 3.3a57.337 57.337 0 0 0-6.54 6c-2.3 2.43-4.67 5.25-7.09 8.27-4.83 6.06-9.35 11.43-12.05 15.71-1.38 2.12-2.38 3.91-3 5.17-.31.668-.66 1.313-1.06 1.93.03-.188.09-.37.18-.54.14-.35.34-.87.65-1.51.84-1.828 1.8-3.601 2.86-5.31 2.62-4.38 7.11-9.82 11.93-15.87 2.42-3 4.82-5.85 7.16-8.28a53.525 53.525 0 0 1 6.71-5.92 34.96 34.96 0 0 1 5.15-3.15c.64-.32 1.16-.52 1.5-.66.16-.101.34-.175.52-.22Z" fill="#61656D"/>
      <path d="M440.8 218.64c-1.33.3-2.69.497-4.06.59-3.26.344-6.49.98-9.64 1.9a29.077 29.077 0 0 0-8.63 4.53c-2 1.54-2.92 2.81-3 2.73.15-.342.36-.657.61-.93.63-.792 1.34-1.519 2.11-2.17a26.234 26.234 0 0 1 8.73-4.8 43.933 43.933 0 0 1 9.79-1.73c1.36-.157 2.73-.197 4.09-.12Z" fill="#61656D"/>
      <path d="M416.64 226.941c-.21-.12 1.62-2.92 3-6.68s1.64-7.13 1.88-7.09c.11.724.09 1.462-.06 2.18-.2 1.753-.6 3.477-1.19 5.14a25.144 25.144 0 0 1-2.36 4.71c-.33.643-.76 1.23-1.27 1.74ZM451.96 169.141c-.2.142-.42.256-.65.34l-1.91.87c-.83.36-1.85.81-3 1.42-1.15.61-2.49 1.2-3.88 2l-2.22 1.26c-.77.44-1.53 1-2.34 1.47-1.63 1-3.27 2.21-5 3.46a86.364 86.364 0 0 0-19.33 20c-1.18 1.79-2.35 3.47-3.28 5.14-.48.82-1 1.6-1.39 2.38-.39.78-.81 1.54-1.19 2.26-.79 1.42-1.35 2.78-1.9 4-.55 1.22-1 2.19-1.32 3-.32.81-.59 1.43-.8 1.94-.08.232-.19.453-.32.66.03-.241.09-.476.19-.7.18-.53.41-1.19.69-2a31.47 31.47 0 0 1 1.21-3.1c.52-1.19 1.06-2.57 1.83-4l1.15-2.29c.41-.8.9-1.59 1.37-2.43.92-1.69 2.07-3.39 3.25-5.2a83.175 83.175 0 0 1 19.47-20.15c1.77-1.24 3.43-2.45 5.09-3.43.82-.5 1.6-1 2.38-1.45l2.25-1.23c1.42-.81 2.78-1.4 4-2 1.22-.6 2.2-1 3-1.32l2-.75c.21-.075.43-.125.65-.15Z" fill="#61656D"/>
      <path d="M422.93 188.641a4.68 4.68 0 0 1 1.6-2.38 10.096 10.096 0 0 1 6.43-2.469c2.32-.009 4.62.443 6.77 1.329.91.284 1.77.702 2.56 1.24-.93-.188-1.84-.456-2.72-.8a20.632 20.632 0 0 0-6.59-1.1c-2.23.051-4.38.808-6.14 2.16-.69.626-1.33 1.301-1.91 2.02Z" fill="#4D61FC"/>
      <path opacity=".2" d="M361.59 180.23a45.389 45.389 0 0 0-19.41-6.68c-3.17-.33-6.64-.24-9.18 1.68-.3.164-.56.407-.73.703-.18.296-.27.633-.27.977.14.484.46.894.9 1.15 7.9 6 19.84 6.78 28.64 2.17" fill="#000"/>
      <path opacity=".4" d="M361.49 180.152c-.75-5.47-1-11.08.23-16.46 1.23-5.38 4.18-10.52 8.77-13.58 1.73 4 1 8.68-.23 12.9a62.86 62.86 0 0 1-8.7 18.31" fill="#fff"/>
      <path opacity=".2" d="M373.41 200.522a38.467 38.467 0 0 1-2.39-18.723 38.49 38.49 0 0 1 6.83-17.597c3.39 12.05 2.14 25.66-4.44 36.32ZM386.36 215.93a27.786 27.786 0 0 0-26.55 15.28c-.33.66-.62 1.49-.18 2.08.23.221.51.384.82.474.3.091.63.106.94.046 5.23-.34 9.87-3.49 13.7-7.07s7.16-7.73 11.39-10.83" fill="#000"/>
      <path opacity=".4" d="M423.3 222.311c11.73 3.05 25.19.08 35-7.11a28.273 28.273 0 0 0-18.92-2.32c-6.32 1.44-12.09 4.27-16 9.43" fill="#fff"/>
      <path opacity=".2" d="M425.87 215.881c6.57-10.72 19.17-17.71 31.72-18.34-1.45 5.1-5.64 9.07-10.38 11.47-4.74 2.4-10 3.44-15.22 4.45-2 .39-4.81.83-6.12 2.42Z" fill="#000"/>
      <path opacity=".4" d="M437.01 176.791c10.71 1.63 19.83 8.47 29.83 12.61a13.372 13.372 0 0 0 6.75 1.29c2.29-.3 4.56-1.84 5.07-4.1a5.83 5.83 0 0 0-.23-2.841 5.914 5.914 0 0 0-1.54-2.399 14.338 14.338 0 0 0-4.9-3c-10.89-4.53-22.58-4.65-34.06-1.9" fill="#fff"/>
      <path opacity=".2" d="M432.12 135.921a45.726 45.726 0 0 1 41-6.76c-5.16 5.08-12.31 7.82-19.52 8.57a61.73 61.73 0 0 1-21.43-1.81" fill="#000"/>
      <path opacity=".4" d="M428.87 115.082c-.94-9.63-.33-17.23-1-26.91 0-.77-.22-1.7-.94-2-1-.39-1.85.85-2.18 1.88a30.905 30.905 0 0 0-1.05 13.876 30.832 30.832 0 0 0 5.16 12.924M443.8 111.791a128.767 128.767 0 0 0 39.27-23.15c-4.69-1.89-10.06-.76-14.75 1.13-10.72 4.33-19.47 11.63-24.72 21.92M391.15 139.282c-4.94-10-4.62-21.63-.35-31.92 3 4.59 3.37 10.42 2.82 15.87-.55 5.45-2.21 11.07-2.31 16.55M358.12 124.88c-6-.49-11.85-1.24-17.66.54-5.81 1.78-11.09 5.82-13.46 11.41 5.65.64 11.38-.78 16.56-3.13a95.17 95.17 0 0 0 14.56-8.82Z" fill="#fff"/>
      <path opacity=".2" d="M377.81 125.371c-9.28-9.12-12.81-25.51-9.07-38a57.867 57.867 0 0 1 9.07 38ZM398.73 104.91c-2.9-4-6.24-9.41-10.18-12.39-3.94-2.98-9-4.78-13.84-3.63a32.164 32.164 0 0 0 10.11 10.834 32.249 32.249 0 0 0 13.89 5.166" fill="#000"/>
      <path opacity=".4" d="M403.4 69.93c2.89-9.56 11.64-16.59 21.08-19.82a7.908 7.908 0 0 1 3.7-.56 3.28 3.28 0 0 1 1.71.735c.48.404.84.942 1.02 1.546.34 1.62-1 3.08-2.27 4.16a71.765 71.765 0 0 1-25.24 13.94Z" fill="#fff"/>
      <path opacity=".2" d="M403.36 66.352c-2.39-7-2.64-15.81-6.81-21.87-2.91-4.24-7.58-7.13-10.09-11.62-1.67 4.19-1.16 9 .59 13.19 1.9 4.095 4.51 7.82 7.71 11 3.08 3.3 5.73 5.78 8.6 9.26" fill="#000"/>
      <path opacity=".4" d="M394.54 146.291c-.11.12-2.48-2.7-3.72-7-.7-2.265-.99-4.635-.85-7h.3c-.14.53-.23.82-.29.81-.06-.01 0-.31 0-.86h.3c.15 2.319.55 4.616 1.19 6.85 1.19 4.18 3.25 7.09 3.07 7.2ZM422.93 188.641a4.68 4.68 0 0 1 1.6-2.38 10.096 10.096 0 0 1 6.43-2.469c2.32-.009 4.62.443 6.77 1.329.91.284 1.77.702 2.56 1.24-.93-.188-1.84-.456-2.72-.8a20.632 20.632 0 0 0-6.59-1.1c-2.23.051-4.38.808-6.14 2.16-.69.626-1.33 1.301-1.91 2.02Z" fill="#fff"/>
      <path d="M418.59 193.07c-.18-.11 1.46-2.51 1.83-5.95.37-3.44-.61-6.14-.41-6.2.39.508.64 1.101.75 1.73.4 1.483.51 3.026.33 4.55a12.35 12.35 0 0 1-1.36 4.35c-.57 1.03-1.07 1.56-1.14 1.52Z" fill="#61656D"/>
      <path d="M371.32 253.311c1 17.75 6.35 35.81 14.58 54.06h35.78c7.85-18.07 12.83-36.1 13.71-54.06h-64.07Z" fill="#B2B6BB"/>
      <path d="M438.37 247.651h-69.61v5.88h69.61v-5.88Z" fill="#B2B6BB"/>
      <path opacity=".2" d="M371.32 253.311c0 .9.12 1.8.19 2.7h50.19s.31 30.68-10.14 51.36h10.12c7.81-18 12.78-36 13.7-53.84l-64.06-.22ZM421.74 247.651v5.83l16.63.05v-5.88h-16.63Z" fill="#000"/>
    </>
  ),
});

export default SlideDownload;
