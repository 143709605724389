import { myOutlinesNavigationIds } from '@/containers/MyOutlines/myOutlinesNavitagionIds';

export const myOutlineReducer = (state = {}, action) => {
  switch (action.type) {
    case myOutlinesNavigationIds.makeReview:
      return {
        ...state,
        navigation: action.type,
        data: action.payload.data,
      };
    case myOutlinesNavigationIds.purchasedOutlines:
      return {
        ...state,
        navigation: action.type,
        data: null,
      };

    default:
      return state;
  }
};
