import React, { useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from '@chakra-ui/react';

import { DrawerHeader, DrawerBody } from '@/components/Drawer';
import UploadedOutlines from '@/components/UploadedOutlines';
import MessageComponent from '@/components/MessageComponent';
import LoadingSpinner from '@/components/LoadingSpinner';
import { NoOutlinesUploaded } from '@/components/illustrations';

import {
  getUploadedOutlines,
  getUploadedOutlinesHistory,
  getIsLoadingUploadedOutlines,
  clearUploadedOutlines,
} from '@/stores/uploadedOutlines';
import { getUser } from '@/stores/user';

import { pages } from '@/utils/pages';

const UploadedOutlinesContainer = ({ onBack, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const { id: userId } = useSelector(state => getUser(state));
  const uploadedOutlines = useSelector(state =>
    getUploadedOutlinesHistory(state),
  );
  const isLoading = useSelector(state => getIsLoadingUploadedOutlines(state));

  useEffect(() => {
    if (userId) {
      dispatch(getUploadedOutlines({ userId }));
    }

    return () => {
      dispatch(clearUploadedOutlines());
    };
  }, [dispatch, userId]);

  const onRedirect = () => {
    onClose();
    router.push(pages.uploadOutline);
  };

  const renderContent = () => {
    if (isLoading || !uploadedOutlines) return <LoadingSpinner mt={24} />;

    if (!uploadedOutlines.outlines.length) {
      return (
        <Box
          data-testid='empty-uploaded-outlines'
          display='flex'
          flexDirection='column'
          w='full'
          px={6}
          pt={{ base: 24, md: 44 }}
        >
          <MessageComponent
            title={t('uploadedOutlines:noOutlinesUploaded.title')}
            message={t('uploadedOutlines:noOutlinesUploaded.message')}
            onClick={onRedirect}
            buttonTitle={t('uploadedOutlines:noOutlinesUploaded.buttonTitle')}
            renderIllustration={() => (
              <NoOutlinesUploaded w={{ base: 307 }} h={{ base: 249 }} />
            )}
          />
        </Box>
      );
    }

    const { outlines, pageData } = uploadedOutlines;

    return <UploadedOutlines outlines={outlines} pageData={pageData} />;
  };

  return (
    <Flex
      data-testid='uploaded-outlines-container'
      flexDirection='column'
      bgGradient={{
        base: 'linear(318.94deg, gradientAlpha.0 -14.62%, gradientAlpha.100 84.21%)',
        md: 'none',
      }}
      minH='full'
    >
      <DrawerHeader onBack={onBack} title={t('uploadedOutlines:title')} />
      <DrawerBody pt={0} h='full'>
        {renderContent()}
      </DrawerBody>
    </Flex>
  );
};

UploadedOutlinesContainer.defaultProps = {
  onClose: () => {},
};

UploadedOutlinesContainer.propTypes = {
  onBack: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default UploadedOutlinesContainer;
