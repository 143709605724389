import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { PAYMENT_VIEW } from '@/utils/constants';

import SelectPaymentMethod from './SelectPaymentMethod';
import AddPaymentMethod from './AddPaymentMethod';
import AddBank from './AddBank';

const PaymentMethod = ({ onBack }) => {
  const { SELECT_PAYMENT_METHOD, ADD_PAYMENT_METHOD, ADD_BANK } = PAYMENT_VIEW;

  const [content, setContent] = useState(SELECT_PAYMENT_METHOD);

  const view = {
    [SELECT_PAYMENT_METHOD]: (
      <SelectPaymentMethod onBack={onBack} onViewChange={setContent} />
    ),
    [ADD_PAYMENT_METHOD]: (
      <AddPaymentMethod onBack={() => setContent(SELECT_PAYMENT_METHOD)} />
    ),
    [ADD_BANK]: <AddBank onBack={() => setContent(SELECT_PAYMENT_METHOD)} />,
  };

  return view[content];
};

PaymentMethod.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default PaymentMethod;
