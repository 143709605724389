import { useEffect } from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from '@chakra-ui/react';

import { getBalance } from '@/stores/balance';
import { getUser } from '@/stores/user';

import { BALANCE } from '@/utils/constants';

import { DrawerHeader, DrawerBody } from '@/components/Drawer';
import TotalBalance from './TotalBalance';
import History from './History';

const Balance = ({ onBack }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id: userId } = useSelector(state => getUser(state));

  useEffect(() => {
    dispatch(getBalance({ userId, type: BALANCE.ALL }));
  }, [dispatch, userId]);

  const handleFilterBalance = type => {
    dispatch(getBalance({ userId, type }));
  };

  return (
    <Flex flexDirection='column' h='full'>
      <DrawerHeader onBack={onBack} title={t('balance:title')} />
      <DrawerBody pt={0} h='full'>
        <Box
          bg='white'
          pt={2}
          mx={{ base: 6, md: 8 }}
          my={{ md: 4 }}
          borderRadius='xl'
        >
          <TotalBalance />
          <History filterBalance={handleFilterBalance} />
        </Box>
      </DrawerBody>
    </Flex>
  );
};

Balance.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default Balance;
