import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import { Box, Button } from '@chakra-ui/react';
import getConfig from 'next/config';
import { getIsBuying } from '@/stores/payment';
import {
  downloadOutline,
  clearDownload,
  getIsLoadingDownload,
} from '@/stores/outlines';
import {
  getPurchasedOutlineByUserItem,
  getIsLoadingPurchasedOutlineByUser,
  getPurchasedOutlineByUser,
  getPurchasedOutlineByUserErrors,
  clearPurchasedOutlineByUser,
} from '@/stores/purchasedOutlineByUser';
import LoadingSpinner from '@/components/LoadingSpinner';

const OutlineButton = ({ userId, outlineId, sellerId, handleCheckout }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { publicRuntimeConfig } = getConfig();
  const { PRICE_REMY_OUTLINES } = publicRuntimeConfig;

  const purchasedOutline = useSelector(state =>
    getPurchasedOutlineByUserItem(state),
  );
  const purchasedOutlineMessage = useSelector(state =>
    getPurchasedOutlineByUserErrors(state),
  );
  const isLoading = useSelector(state =>
    getIsLoadingPurchasedOutlineByUser(state),
  );
  const isDownloading = useSelector(state => getIsLoadingDownload(state));
  const isBuying = useSelector(state => getIsBuying(state));

  useEffect(() => {
    if (userId && outlineId) {
      dispatch(
        getPurchasedOutlineByUser({
          userId,
          outlineId,
        }),
      );
    }

    return () => {
      dispatch(clearPurchasedOutlineByUser());
    };
  }, [dispatch, userId, isBuying, outlineId, isDownloading]);

  const handleDownload = () => {
    dispatch(
      downloadOutline({
        userId,
        outlineId,
        purchaseId: purchasedOutline.id,
      }),
    );
  };

  useEffect(() => {
    dispatch(clearDownload());
  }, [dispatch, isDownloading]);

  const validateButton = () => {
    if (purchasedOutline) {
      return (
        <Button
          onClick={handleDownload}
          bg='alpha.500'
          color='white'
          w={{ base: 'full', md: '70%' }}
          my={{ base: 6, md: 12 }}
          _focus={{ boxShadow: 'none' }}
        >
          {t('purchasedOutlines:history.available.download')}
        </Button>
      );
    } else {
      return userId !== sellerId || purchasedOutlineMessage.length === 0 ? (
        <Button
          onClick={handleCheckout}
          bg='alpha.500'
          color='white'
          w={{ base: 'full', md: '70%' }}
          my={{ base: 6, md: 12 }}
          _focus={{ boxShadow: 'none' }}
        >
          {t('outlines:buyNow', { price: PRICE_REMY_OUTLINES })}
        </Button>
      ) : (
        <Box
          bg='gray.100'
          color='gray.500'
          fontWeight={600}
          w={{ base: 'full', md: '70%' }}
          my={{ base: 6, md: 12 }}
          textAlign='center'
          p={2}
          borderRadius={5}
          _focus={{ boxShadow: 'none' }}
        >
          {t('outlines:you_created_this_outline')}
        </Box>
      );
    }
  };

  return isLoading ? (
    <Box
      bg='alpha.500'
      color='white'
      w={{ base: 'full', md: '70%' }}
      my={{ base: 6, md: 12 }}
      _focus={{ boxShadow: 'none' }}
    >
      <LoadingSpinner />
    </Box>
  ) : (
    <>{validateButton()}</>
  );
};

export default OutlineButton;
