import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { Button, Text } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';

const ModalRemy = ({
  open,
  onCloseModal,
  title,
  children,
  confirmButton,
  stylescancelbutton,
  cancelButtonText,
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    onOpen(open);
  }, [open, onOpen]);

  const handleOnClose = () => {
    onCloseModal();
    onClose(true);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={{ base: 2 }}>
        <ModalHeader
          fontSize={{ base: 'xl' }}
          fontWeight={600}
          color='alpha.500'
        >
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          {confirmButton(handleOnClose)}
          <Button
            bg={stylescancelbutton.bg}
            color={stylescancelbutton.color}
            mr={3}
            onClick={handleOnClose}
          >
            {cancelButtonText ? cancelButtonText : t('common:cancel')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ModalRemy.defaultProps = {
  message: '',
  confirmButton: () => {},
  stylescancelbutton: {
    bg: 'alpha.500',
    color: 'white',
  },
};

ModalRemy.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  confirmButton: PropTypes.func,
  stylescancelbutton: PropTypes.shape({}),
};

export default ModalRemy;
