import React from 'react';
import PropTypes from 'prop-types';

import { Drawer } from '@/components/Drawer';
import { MakeSuggestion } from '@/components/MakeSuggestion';

const MakeSuggestionDrawerContainer = ({ isOpen, onClose }) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <MakeSuggestion onBack={onClose} />
    </Drawer>
  );
};

MakeSuggestionDrawerContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MakeSuggestionDrawerContainer;
