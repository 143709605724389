import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Box, Heading, Link } from '@chakra-ui/react';
import { ArrowBackIcon } from 'chakra-ui-ionicons';

const GoBackButton = ({ children }) => {
  const router = useRouter();
  const handleGoBack = () => {
    router.back();
  };

  return (
    <Box w='full' mb={6} display={{ md: 'none' }}>
      <Link
        data-testid='goback-button'
        position='absolute'
        onClick={handleGoBack}
      >
        <ArrowBackIcon w={5} h={5} color='black' />
      </Link>
      <Heading
        color='gray.900'
        fontWeight='600'
        fontSize={{ base: 'md' }}
        textAlign={{ base: 'center' }}
      >
        {children}
      </Heading>
    </Box>
  );
};

GoBackButton.defaultProps = {
  children: '',
};

GoBackButton.propTypes = {
  children: PropTypes.node,
};

export default GoBackButton;
