import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { Button, Box, List, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useSession } from 'next-auth/client';

import { getUser } from '@/stores/user';

import { pages } from '@/utils/pages';

import { DrawerHeader, DrawerBody } from '@/components/Drawer';
import SideMenuItem from './SideMenuItem';
import { navigationIds } from './navigationIds';

const SideMenu = ({ onClose, onSelectOption }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [session] = useSession();

  const { name, nickname, email } = useSelector(state => getUser(state));

  const navigation = session?.user?.roles?.includes('admin')
    ? Object.keys(navigationIds)
    : Object.keys(navigationIds).filter(id => id !== 'adminConsole');

  return (
    <Box data-testid='side-menu'>
      <DrawerHeader onBack={onClose} />
      <DrawerBody
        pt={0}
        p={6}
        px={{ base: 6, md: 12 }}
        pb={{ base: 12, md: 6 }}
        mb={{ base: 12, md: 6 }}
      >
        <Box mb={8}>
          <Text fontSize='2xl' color='alpha.400'>
            {t('sideMenu:hi', { name })}
          </Text>
          <Box color='gray.400' fontSize='sm'>
            <Text>{email}</Text>
            <Text>{nickname}</Text>
          </Box>
        </Box>
        <List>
          {navigation.map(el => (
            <SideMenuItem
              key={el}
              id={el}
              label={t(`sideMenu:${el}`)}
              onClick={onSelectOption}
            />
          ))}
        </List>
        <Button
          onClick={() => router.push(pages.uploadOutline)}
          w={{ base: 'full', md: '80%' }}
          bg='alpha.500'
          color='white'
          _focus={{ boxShadow: 'none' }}
          my={4}
          mb={{ base: 12, md: 4 }}
        >
          {t('sideMenu:upload')}
        </Button>
      </DrawerBody>
    </Box>
  );
};

SideMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSelectOption: PropTypes.func.isRequired,
};

export default SideMenu;
