import React, { useMemo, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import debounce from 'lodash.debounce';
import { Box } from '@chakra-ui/react';
import getConfig from 'next/config';

import { fetchByEntity } from '@/api/search';

import { OUTLINES_FILTERS } from '@/utils/constants';

import FilterInput from './FilterInput';
import FilterSelect from './FilterSelect';

const { publicRuntimeConfig } = getConfig();

const AdvancedFilters = ({
  handleAddFilter,
  handleRemoveFilter,
  handleSortBy,
}) => {
  const { t } = useTranslation();

  const { ADVANCED_FILTERS_SEARCH_LIMIT } = publicRuntimeConfig;

  const initialState = { professors: [], courses: [], schools: [] };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'professors':
        return { ...state, professors: action.data.professors };
      case 'courses':
        return { ...state, courses: action.data.courses };
      case 'schools':
        return { ...state, schools: action.data.schools };
      case 'clear':
        return initialState;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const debouncedFetch = useMemo(
    () =>
      debounce(async (value, type) => {
        const { data } = await fetchByEntity(
          value,
          type,
          ADVANCED_FILTERS_SEARCH_LIMIT,
        );

        dispatch({ type, data });
      }, 300),
    [ADVANCED_FILTERS_SEARCH_LIMIT],
  );

  const handleOnChange = (value, type) => {
    if (value.length >= 3) {
      debouncedFetch(value, type);
    } else {
      dispatch({ type: 'clear' });
    }
  };

  const sortByOptions = [
    {
      value: OUTLINES_FILTERS.SORTBY.COURSENAME_DESC,
      name: t('outlines:filters.sortby.nameDesc'),
    },
    {
      value: OUTLINES_FILTERS.SORTBY.COURSENAME_ASC,
      name: t('outlines:filters.sortby.nameAsc'),
    },
    {
      value: OUTLINES_FILTERS.SORTBY.GRADE_DESC,
      name: t('outlines:filters.sortby.gradeDesc'),
    },
    {
      value: OUTLINES_FILTERS.SORTBY.GRADE_ASC,
      name: t('outlines:filters.sortby.gradeAsc'),
    },
    {
      value: OUTLINES_FILTERS.SORTBY.RATING,
      name: t('outlines:filters.sortby.rating'),
    },
    {
      value: OUTLINES_FILTERS.SORTBY.CREATEDAT,
      name: t('outlines:filters.sortby.recent'),
    },
  ];

  const renderFilters = () => {
    const filters = [
      {
        id: 'schools',
        category: 'school',
        filter: 'schoolId',
        label: t('outlines:filters.school.label'),
        placeholder: t('outlines:filters.school.placeholder'),
      },
      {
        id: 'professors',
        category: 'professor',
        filter: 'professorId',
        label: t('outlines:filters.professor.label'),
        placeholder: t('outlines:filters.professor.placeholder'),
      },
      {
        id: 'courses',
        category: 'course',
        filter: 'courseId',
        label: t('outlines:filters.course.label'),
        placeholder: t('outlines:filters.course.placeholder'),
      },
    ];

    return filters.map(({ id, category, filter, label, placeholder }) => (
      <FilterInput
        key={id}
        id={id}
        category={category}
        options={state[id]}
        label={label}
        placeholder={placeholder}
        onChange={e => handleOnChange(e.target.value, id)}
        handleSelect={option => handleAddFilter(option.id, filter)}
        handleReset={() => handleRemoveFilter(filter)}
      />
    ));
  };

  return (
    <Box w={{ base: 'full', md: 'sm' }} mb={4} pr={{ base: 0, md: 4 }}>
      <FilterSelect
        id='sortBy'
        placeholder={t('outlines:filters.sortby.label')}
        handleSelect={handleSortBy}
        label={t('outlines:filters.sortby.label')}
        options={sortByOptions}
      />
      {renderFilters()}
    </Box>
  );
};

AdvancedFilters.propTypes = {
  handleAddFilter: PropTypes.func.isRequired,
  handleRemoveFilter: PropTypes.func.isRequired,
  handleSortBy: PropTypes.func.isRequired,
};

export default AdvancedFilters;
