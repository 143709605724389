import React from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { Text, List, ListItem, Box } from '@chakra-ui/react';
import OutlineInfo from '@/components/Outline/OutlineView/OutlineInfo';
import PurchasesInfo from '@/components/PurchasesInfo';

const UploadedOutlines = ({ outlines, pageData }) => {
  const { t } = useTranslation();
  const { total } = pageData;

  return (
    <List>
      <Text
        pt={{ base: 6, md: 2 }}
        pb={{ base: 4, md: 0 }}
        mx={{ base: 6, md: 8 }}
        fontSize={{ base: 'md' }}
        fontWeight={400}
        color='black'
      >
        {t('uploadedOutlines:totalOutlines', { total })}
      </Text>
      {outlines.map(outline => (
        <ListItem key={outline.id}>
          <Box
            bg='white'
            p={{ base: 4, md: 0 }}
            mx={{ base: 6, md: 8 }}
            my={{ base: 4 }}
            borderRadius='xl'
          >
            <OutlineInfo outline={outline} />
          </Box>
          <Box
            mx={{ base: 6, md: 8 }}
            mb={{ base: 6, md: 16 }}
            mt={{ base: 0, md: 6 }}
          >
            <PurchasesInfo outline={outline} />
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

UploadedOutlines.propTypes = {
  outlines: PropTypes.array.isRequired,
  pageData: PropTypes.shape({
    total: PropTypes.number,
    page: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
};

export default UploadedOutlines;
