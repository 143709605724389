import PropTypes from 'prop-types';
import { DocumentsOutlineIcon, DocumentOutlineIcon } from 'chakra-ui-ionicons';
import { Flex, Text } from '@chakra-ui/react';
import { ChevronForwardIcon } from 'chakra-ui-ionicons';

import { BALANCE } from '@/utils/constants';

import { formatTime } from '@/utils/dateUtils';

const HistoryItem = ({ transaction }) => {
  const {
    outline: { schoolName, courseName },
    purchase_date,
    type,
  } = transaction;

  const isIncome = type === BALANCE.INCOME.toUpperCase();
  const iconSize = { h: 5, w: 5 };

  return (
    <Flex
      alignItems='center'
      w='full'
      bg={isIncome ? 'alpha.50' : 'beta.50'}
      borderRadius='xl'
      p={4}
      mb={4}
      cursor='pointer'
      data-testid='balance-history-item'
    >
      <Flex
        justifyContent='center'
        alignItems='center'
        bg={isIncome ? 'alpha.100' : 'beta.100'}
        color={isIncome ? 'alpha.500' : 'beta.700'}
        borderRadius='full'
        p='1'
        mr={4}
      >
        {isIncome ? (
          <DocumentOutlineIcon {...iconSize} />
        ) : (
          <DocumentsOutlineIcon {...iconSize} />
        )}
      </Flex>
      <Flex w='full' flexDirection='column' fontSize='sm'>
        <Text fontWeight='bold' noOfLines={1}>
          {courseName} - {schoolName}
        </Text>
        <Text>{formatTime(purchase_date, 'PP')}</Text>
      </Flex>
      <ChevronForwardIcon />
    </Flex>
  );
};

HistoryItem.propTypes = {
  transaction: PropTypes.shape({}).isRequired,
};

export default HistoryItem;
