import React from 'react';
import PropTypes from 'prop-types';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';

const SearchForm = ({
  id,
  type,
  label,
  placeholder,
  errors,
  register,
  onChange,
  inValidSearchInput,
  children,
  isRequired,
  marginBottom = 4,
}) => {
  const customRegister = {
    ...register(id),
    onChange: onChange,
  };

  return (
    <FormControl
      isInvalid={errors[id] || inValidSearchInput}
      marginBottom={marginBottom}
      id={id}
      isRequired={isRequired}
    >
      <FormLabel
        htmlFor={id}
        color={
          errors[id] || inValidSearchInput ? 'customInput.error' : 'gray.800'
        }
        fontSize='xs'
      >
        {label}
      </FormLabel>
      <Input
        id={id}
        name={id}
        type={type}
        fontSize='14px'
        placeholder={placeholder}
        bg='gray.50'
        data-testid={`${id}-search-input`}
        _placeholder={
          (errors[id] || inValidSearchInput) && { color: 'customInput.error' }
        }
        {...customRegister}
      />
      {children}
      <FormErrorMessage fontSize='xs'>
        {errors[id] ? errors[id].message : 'Select a valid option'}
      </FormErrorMessage>
    </FormControl>
  );
};

SearchForm.defaultProps = {
  label: '',
  inValidSearchInput: false,
  placeholder: '',
  errors: {},
  type: 'text',
  isRequired: false,
};

SearchForm.propTypes = {
  id: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.shape({}),
  onChange: PropTypes.func,
  inValidSearchInput: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isRequired: PropTypes.bool,
};

export default SearchForm;
