import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Bank = createIcon({
  displayName: 'Bank',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.4377 0.110335C12.1645 -0.0367782 11.8356 -0.0367782 11.5624 0.110335L0.485448 6.07485C0.186453 6.23585 1.47231e-09 6.54801 1.47231e-09 6.88759V9.44382C1.47231e-09 9.95362 0.413277 10.3669 0.92308 10.3669H3.40831V18.7455H2.62723C2.27759 18.7455 1.95796 18.9431 1.8016 19.2558L0.0974531 22.6641C-0.0456189 22.9503 -0.030329 23.2901 0.137862 23.5622C0.306053 23.8344 0.603162 24 0.923081 24H23.077C23.3969 24 23.694 23.8344 23.8622 23.5622C24.0304 23.2901 24.0457 22.9503 23.9026 22.6641L22.1985 19.2558C22.0421 18.9431 21.7225 18.7455 21.3728 18.7455H20.5918V10.3669H23.077C23.5868 10.3669 24.0001 9.95362 24.0001 9.44382V6.88759C24.0001 6.54801 23.8136 6.23585 23.5146 6.07485L12.4377 0.110335ZM19.6916 8.52074C19.684 8.52055 19.6763 8.52046 19.6687 8.52046C19.661 8.52046 19.6534 8.52055 19.6458 8.52074H14.5792L14.5563 8.52046L14.5334 8.52074H9.46672L9.44382 8.52046L9.42092 8.52074H4.35429L4.33139 8.52046L4.30849 8.52074H1.84616V7.43894L12 1.97147L22.1539 7.43894V8.52074H19.6916ZM18.7456 10.3669V18.7455H15.4793V10.3669H18.7456ZM13.6332 10.3669V18.7455H10.3669V10.3669H13.6332ZM8.52074 10.3669V18.7455H5.25447V10.3669H8.52074ZM2.41665 22.1538L3.19772 20.5917H20.8024L21.5834 22.1538H2.41665Z'
      fill='currentColor'
    />
  ),
});

export default Bank;
