import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { useSelector } from 'react-redux';
import { DocumentsOutlineIcon, DocumentOutlineIcon } from 'chakra-ui-ionicons';
import {
  Flex,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';

import { getBalanceHistory, getIsLoadingBalance } from '@/stores/balance';

import { BALANCE } from '@/utils/constants';

import LoadingSpinner from '@/components/LoadingSpinner';
import HistoryItem from './HistoryItem';
import EmptyHistory from './EmptyHistory';

const History = ({ filterBalance }) => {
  const { t } = useTranslation();

  const balanceHistory = useSelector(state => getBalanceHistory(state));
  const loadingBalance = useSelector(state => getIsLoadingBalance(state));

  const { ALL, INCOME, OUTCOME } = BALANCE;

  const handleTabOnChange = idx => {
    const historyType = [ALL, INCOME, OUTCOME];

    filterBalance(historyType[idx]);
  };

  const tabPanelStyle = { p: 0, pt: 4 };

  const renderTabItem = (title, icon) => {
    const icons = {
      document: <DocumentOutlineIcon />,
      documents: <DocumentsOutlineIcon />,
    };

    return (
      <Tab
        px={2}
        _selected={{
          borderColor: 'alpha.400',
          fontWeight: 'bold',
        }}
        _active={{ bg: 'white' }}
        _focus={{ boxShadow: 'none' }}
      >
        {icon && (
          <Flex
            justifyContent='center'
            alignItems='center'
            bg='gray.100'
            borderRadius='full'
            p={1}
          >
            {icons[icon]}
          </Flex>
        )}
        <Text fontSize='sm' ml={2}>
          {title}
        </Text>
      </Tab>
    );
  };

  const renderTabPanel = () => {
    if (loadingBalance) return <LoadingSpinner mt={4} />;

    if (!balanceHistory?.buyersOutlines?.length) return <EmptyHistory />;

    return (
      <Flex flexDirection='column'>
        {balanceHistory.buyersOutlines.map(el => (
          <HistoryItem key={el.id} transaction={el} />
        ))}
      </Flex>
    );
  };

  return (
    <Tabs
      onChange={idx => handleTabOnChange(idx)}
      isFitted
      isLazy
      mt={2}
      data-testid='balance-history'
    >
      <TabList>
        {renderTabItem(t('balance:history.all'))}
        {renderTabItem(t('balance:history.mySales'), 'document')}
        {renderTabItem(t('balance:history.purchases'), 'documents')}
      </TabList>
      <TabPanels>
        <TabPanel {...tabPanelStyle}>{renderTabPanel()}</TabPanel>
        <TabPanel {...tabPanelStyle}>{renderTabPanel()}</TabPanel>
        <TabPanel {...tabPanelStyle}>{renderTabPanel()}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};

History.propTypes = { filterBalance: PropTypes.func.isRequired };

export default History;
