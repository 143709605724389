import { object, string } from 'yup';

const validationSchema = object({
  courses: string().required('Course name is required'),
  schools: string().required('School name is required'),
  professors: string().required('Professor name is required'),
  coursePeriod: string().required('Course period is required'),
  year: string().required('Year is required'),
  tableOfContents: string(),
}).required();

export default validationSchema;
