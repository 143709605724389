import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Cat = createIcon({
  displayName: 'Woman',
  viewBox: '0 0 292 414',
  path: (
    <>
      <path
        d='M259.944 368.23H86.9805V413.509H259.944C265.809 413.297 271.361 410.82 275.436 406.597C279.512 402.376 281.789 396.735 281.789 390.869C281.789 385.002 279.512 379.36 275.436 375.139C271.361 370.916 265.809 368.439 259.944 368.23Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M259.944 368.23H86.9805V413.509H259.944C265.809 413.297 271.361 410.82 275.436 406.597C279.512 402.376 281.789 396.735 281.789 390.869C281.789 385.002 279.512 379.36 275.436 375.139C271.361 370.916 265.809 368.439 259.944 368.23Z'
        fill='white'
      />
      <path
        d='M101.394 413.509H13.8535V407.04H40.9743V374.96H13.8535V368.23H101.394C107.259 368.439 112.814 370.916 116.886 375.139C120.962 379.36 123.239 385.002 123.239 390.869C123.239 396.735 120.962 402.376 116.886 406.597C112.814 410.82 107.259 413.297 101.394 413.509Z'
        fill='#4D61FC'
      />
      <path
        d='M20.3242 406.779H101.164C103.256 406.785 105.327 406.379 107.261 405.581C109.196 404.783 110.951 403.611 112.43 402.129C113.91 400.65 115.079 398.889 115.873 396.954C116.668 395.02 117.071 392.946 117.06 390.855C117.06 386.637 115.383 382.596 112.404 379.615C109.422 376.633 105.379 374.959 101.164 374.959H20.3242V406.779Z'
        fill='white'
      />
      <path
        d='M286.05 333.422H141.426V368.229H286.05C286.837 368.241 287.617 368.093 288.345 367.794C289.073 367.496 289.734 367.052 290.285 366.489C291.382 365.364 291.994 363.855 291.997 362.283V339.397C292.02 338.608 291.884 337.825 291.593 337.091C291.303 336.357 290.865 335.69 290.309 335.131C289.752 334.571 289.087 334.13 288.356 333.837C287.623 333.544 286.839 333.402 286.05 333.422Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M203.87 345.115H253.586C254.248 345.115 254.88 345.376 255.35 345.845C255.817 346.312 256.081 346.948 256.081 347.609V355.296C256.072 355.951 255.805 356.578 255.338 357.042C254.871 357.503 254.242 357.761 253.586 357.761H203.87C203.542 357.764 203.22 357.703 202.916 357.581C202.614 357.46 202.338 357.28 202.106 357.048C201.874 356.818 201.692 356.546 201.564 356.244C201.439 355.945 201.375 355.62 201.375 355.296V347.609C201.37 347.281 201.433 346.954 201.555 346.649C201.68 346.344 201.863 346.069 202.098 345.837C202.33 345.605 202.605 345.419 202.91 345.297C203.214 345.173 203.539 345.109 203.87 345.115Z'
        fill='black'
      />
      <path
        d='M85.4746 368.229H164.226C165.012 368.241 165.793 368.093 166.521 367.794C167.249 367.496 167.91 367.052 168.461 366.489C169.558 365.364 170.17 363.855 170.173 362.283V339.397C170.196 338.608 170.059 337.825 169.769 337.091C169.479 336.357 169.041 335.69 168.484 335.131C167.927 334.571 167.263 334.13 166.532 333.837C165.798 333.544 165.015 333.402 164.226 333.422H85.6197L85.4746 368.229Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M85.4746 368.229H164.226C165.012 368.241 165.793 368.093 166.521 367.794C167.249 367.496 167.91 367.052 168.461 366.489C169.558 365.364 170.17 363.855 170.173 362.283V339.397C170.196 338.608 170.059 337.825 169.769 337.091C169.479 336.357 169.041 335.69 168.484 335.131C167.927 334.571 167.263 334.13 166.532 333.837C165.798 333.544 165.015 333.402 164.226 333.422H85.6197L85.4746 368.229Z'
        fill='black'
      />
      <path
        d='M85.4746 363.011H161.848C162.616 363.011 163.353 362.704 163.899 362.161C164.441 361.616 164.748 360.879 164.748 360.111V341.344C164.74 340.566 164.441 339.818 163.907 339.255C163.632 338.974 163.301 338.751 162.936 338.603C162.573 338.452 162.181 338.376 161.79 338.385H85.4746V363.011Z'
        fill='white'
      />
      <path
        d='M40.9745 333.422H211.821V298.615H40.9745C39.8955 298.609 38.8251 298.821 37.8273 299.23C36.8295 299.642 35.9246 300.245 35.1617 301.008C34.3988 301.774 33.7926 302.679 33.3807 303.677C32.9717 304.674 32.7599 305.742 32.7657 306.824V325.475C32.7889 326.531 33.021 327.572 33.4503 328.535C33.8767 329.501 34.4917 330.374 35.2603 331.102C36.8121 332.604 38.8977 333.44 41.0615 333.422'
        fill='#4D61FC'
      />
      <path
        opacity='0.3'
        d='M40.9745 333.422H211.821V298.615H40.9745C39.8955 298.609 38.8251 298.821 37.8273 299.23C36.8295 299.642 35.9246 300.245 35.1617 301.008C34.3988 301.774 33.7926 302.679 33.3807 303.677C32.9717 304.674 32.7599 305.742 32.7657 306.824V325.475C32.7889 326.531 33.021 327.572 33.4503 328.535C33.8767 329.501 34.4917 330.374 35.2603 331.102C36.8121 332.604 38.8977 333.44 41.0615 333.422'
        fill='white'
      />
      <path
        d='M270.271 303.836H191.664C190.896 303.836 190.156 304.144 189.614 304.686C189.068 305.232 188.764 305.968 188.764 306.737V325.388C188.764 325.78 188.845 326.165 188.999 326.525C189.155 326.885 189.379 327.207 189.663 327.476C189.936 327.755 190.26 327.975 190.617 328.123C190.977 328.274 191.363 328.35 191.751 328.347H270.358L270.271 303.836Z'
        fill='white'
      />
      <path
        d='M189.434 298.618H275.988V303.839H191.667C191.302 303.767 190.928 303.767 190.565 303.839C190.202 303.967 189.869 304.167 189.585 304.425C189.303 304.686 189.071 304.999 188.912 305.347C188.761 305.696 188.682 306.07 188.68 306.45V325.362C188.682 325.739 188.761 326.113 188.912 326.464C189.062 326.818 189.289 327.134 189.579 327.392C189.866 327.639 190.202 327.824 190.565 327.943C190.916 328.077 191.293 328.126 191.667 328.088H275.988V333.309H189.434C188.645 333.33 187.859 333.188 187.128 332.895C186.394 332.599 185.73 332.161 185.173 331.601C184.616 331.041 184.181 330.374 183.891 329.64C183.601 328.906 183.461 328.12 183.487 327.334V304.448C183.479 303.665 183.627 302.891 183.925 302.166C184.224 301.443 184.668 300.788 185.228 300.242C185.773 299.683 186.429 299.242 187.151 298.94C187.873 298.641 188.651 298.493 189.434 298.502'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M189.434 298.618H275.988V303.839H191.667C191.302 303.767 190.928 303.767 190.565 303.839C190.202 303.967 189.869 304.167 189.585 304.425C189.303 304.686 189.071 304.999 188.912 305.347C188.761 305.696 188.682 306.07 188.68 306.45V325.362C188.682 325.739 188.761 326.113 188.912 326.464C189.062 326.818 189.289 327.134 189.579 327.392C189.866 327.639 190.202 327.824 190.565 327.943C190.916 328.077 191.293 328.126 191.667 328.088H275.988V333.309H189.434C188.645 333.33 187.859 333.188 187.128 332.895C186.394 332.599 185.73 332.161 185.173 331.601C184.616 331.041 184.181 330.374 183.891 329.64C183.601 328.906 183.461 328.12 183.487 327.334V304.448C183.479 303.665 183.627 302.891 183.925 302.166C184.224 301.443 184.668 300.788 185.228 300.242C185.773 299.683 186.429 299.242 187.151 298.94C187.873 298.641 188.651 298.493 189.434 298.502'
        fill='black'
      />
      <path
        d='M9.18956 348.703C9.18956 348.703 -1.98041 365.479 0.309437 368.387C2.59929 371.294 11.4794 368.741 12.5964 368.219C13.7134 367.697 18.4793 356.606 18.4793 356.084C18.4793 355.562 9.18956 348.703 9.18956 348.703Z'
        fill='#868A91'
      />
      <path
        opacity='0.3'
        d='M9.19263 348.703C9.19263 348.703 2.86297 355.712 5.11558 358.657C7.3682 361.602 9.84424 368.517 11.0171 368.517C14.9452 368.517 18.4637 356.606 18.4637 356.084C18.4637 355.562 9.19263 348.703 9.19263 348.703Z'
        fill='black'
      />
      <path
        d='M12.6025 368.219C12.6025 368.219 6.70099 405.704 26.4533 402.889C46.2055 400.075 33.7324 364.062 27.1421 353.997C27.1421 353.997 37.5302 333.493 37.8839 329.765C44.0535 334.384 51.353 337.256 59.0138 338.078C71.1332 339.122 88.8004 334.164 99.8773 300.798C99.8773 300.798 165.334 304.843 171.905 304.135C171.255 306.334 171.168 308.661 171.654 310.903C172.138 313.144 173.179 315.226 174.679 316.959C179.873 323.558 193.724 347.827 195.809 353.027C197.894 358.228 188.53 379.738 193.724 383.205C198.918 386.673 216.25 380.074 217.274 362.217C218.298 344.36 199.272 314.2 199.625 309.69C199.979 305.179 211.819 299.046 211.819 299.046L278.243 298.245C278.243 298.245 293.137 287.695 286.9 278.058C280.664 268.421 275.73 252.484 263.35 252.129L261.098 252.036V230.861C261.098 230.861 247.247 239.641 245.162 245.661C242.451 240.338 239.279 235.26 235.686 230.489C235.686 230.489 225.056 235.111 217.199 254.72C211.961 254.131 206.819 252.879 201.897 250.992C194.003 247.88 186.147 254.459 176.224 252.39C166.302 250.321 119.89 249.986 106.728 250.135C93.5663 250.284 55.3649 247.637 35.7244 260.704C18.5971 272.112 -1.89991 299.214 3.40583 328.087C3.4803 328.106 11.5599 353.307 12.6025 368.219Z'
        fill='#868A91'
      />
      <path
        d='M286.246 278.952C286.246 278.952 260.853 280.127 270.757 298.17H278.204C278.204 298.17 293.693 284.544 286.246 278.952Z'
        fill='#C3C5C8'
      />
      <path
        opacity='0.5'
        d='M46.891 282.68C43.1677 285.942 39.6119 289.279 36.1864 292.802C32.7144 296.243 29.5086 299.945 26.5988 303.874C25.8951 304.866 25.2435 305.891 24.6441 306.95C24.3648 307.472 24.0111 307.975 23.7877 308.515L23.0616 310.137C22.3281 312.424 21.9149 314.801 21.833 317.202C21.7399 318.395 21.833 319.625 21.7027 320.836C21.5723 322.048 21.7026 323.278 21.7957 324.564C21.8888 325.85 21.9074 327.006 22.0191 328.292C22.1308 329.578 22.2611 330.715 22.3915 331.927C22.8904 336.788 23.7877 341.601 25.0723 346.317C25.6922 348.664 26.4815 350.964 27.4366 353.195L28.2185 354.817L29.0562 356.457C29.5961 357.576 30.1174 358.694 30.6386 359.812C32.6827 364.32 34.3881 368.974 35.7396 373.736C36.3782 376.124 36.851 378.553 37.1545 381.006C37.497 383.48 37.497 385.989 37.1545 388.462C37.335 386.015 37.2159 383.554 36.8007 381.137C36.38 378.728 35.801 376.35 35.0694 374.016C33.5931 369.349 31.7631 364.799 29.5961 360.409C29.0562 359.309 28.5164 358.209 27.9392 357.128L27.0828 355.507L26.2451 353.81C25.2566 351.521 24.4226 349.167 23.7505 346.765C22.4027 341.993 21.4495 337.116 20.9022 332.188C20.7718 330.958 20.6415 329.728 20.567 328.46C20.4925 327.193 20.3995 326 20.3623 324.732C20.325 323.465 20.3623 322.253 20.3623 321.004C20.3623 319.755 20.3623 318.525 20.5484 317.276C20.6508 314.739 21.1069 312.23 21.9074 309.82C22.909 307.505 24.1973 305.326 25.7424 303.333C31.9232 295.611 39.0254 288.675 46.891 282.68Z'
        fill='black'
      />
      <path
        opacity='0.5'
        d='M104.718 288.625C101.869 296.08 98.965 303.536 96.0049 310.992C94.5472 314.758 92.6725 318.346 90.4199 321.692C88.1747 325.073 85.5033 328.15 82.4706 330.844C79.4175 333.573 75.8803 335.703 72.0453 337.126C68.1898 338.516 64.0848 339.068 60.0003 338.747C55.9586 338.406 52.0026 337.4 48.2905 335.765C44.6211 334.154 41.1379 332.147 37.9023 329.781C39.6337 330.788 41.3464 331.776 43.1336 332.671C44.9096 333.552 46.7304 334.343 48.5883 335.038C52.267 336.445 56.1411 337.275 60.0748 337.498C63.9787 337.67 67.8752 337.036 71.524 335.634C75.1673 334.227 78.5332 332.182 81.4653 329.595C84.4086 326.993 87.0224 324.038 89.2471 320.797C91.5165 317.57 93.4433 314.116 94.9996 310.489C98.1887 303.134 101.428 295.846 104.718 288.625Z'
        fill='black'
      />
      <path
        opacity='0.5'
        d='M180.056 281.879C178.995 285.607 177.803 289.447 176.556 293.175C175.309 296.903 173.987 300.631 172.646 304.359V304.452C172.552 304.61 172.477 304.78 172.423 304.955C172.349 305.168 172.293 305.386 172.256 305.608C172.159 306.06 172.109 306.525 172.107 306.987C172.103 307.947 172.209 308.905 172.423 309.839C172.862 311.775 173.531 313.654 174.415 315.431C176.256 319.053 178.386 322.518 180.782 325.795C179.348 324.328 177.999 322.777 176.742 321.153C175.474 319.524 174.337 317.798 173.335 315.99C172.3 314.156 171.507 312.195 170.971 310.156C170.72 309.106 170.588 308.031 170.58 306.95C170.58 306.387 170.638 305.826 170.748 305.272C170.79 304.987 170.859 304.705 170.952 304.433C171.057 304.127 171.194 303.835 171.362 303.557V303.725C172.74 299.997 174.117 296.269 175.588 292.69C177.059 289.111 178.38 285.458 180.056 281.879Z'
        fill='black'
      />
      <path
        d='M282.428 278.749C282.229 278.783 282.043 278.869 281.89 278.999C281.736 279.131 281.62 279.301 281.555 279.491C281.49 279.683 281.479 279.888 281.522 280.086C281.563 280.283 281.658 280.464 281.795 280.613C283.657 282.664 286.952 285.87 288.218 284.863C288.464 283.683 288.475 282.466 288.248 281.281C288.021 280.097 287.561 278.971 286.896 277.966L282.428 278.749Z'
        fill='black'
      />
      <path
        d='M253.404 273.176C254.441 272.902 255.499 272.721 256.569 272.635C257.563 272.54 258.569 272.596 259.548 272.803C260.525 272.986 261.469 273.327 262.34 273.81L262.675 273.977L263.011 274.182L263.681 274.592C264.146 274.872 264.556 275.226 265.058 275.506C264.816 274.991 264.53 274.497 264.202 274.033C263.852 273.578 263.454 273.165 263.011 272.803C262.104 272.028 261.015 271.502 259.846 271.275C258.677 271.04 257.468 271.097 256.327 271.442C255.218 271.754 254.21 272.352 253.404 273.176Z'
        fill='black'
      />
      <path
        d='M104.712 288.625C104.712 288.625 145.408 283.368 173.017 298.914L171.155 303.797L99.8535 300.722L104.712 288.625Z'
        fill='#C3C5C8'
      />
      <path
        opacity='0.4'
        d='M261.092 251.962V230.787C261.092 230.787 247.241 239.566 245.156 245.587L249.438 253.043L261.092 251.962Z'
        fill='black'
      />
      <path
        opacity='0.4'
        d='M236.207 234.479L247.377 253.119C243.805 254.439 240.015 255.073 236.207 254.983C236.207 254.983 232.41 248.814 236.207 234.479Z'
        fill='white'
      />
      <path
        opacity='0.3'
        d='M23.7894 308.424C23.7894 308.424 25.2042 328.071 31.8876 344.343L27.4382 352.955C27.4382 352.955 16.8826 321.23 23.7894 308.424Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M177.096 288.625C177.096 288.625 172.684 301.318 150.344 303.182L170.822 304.431L177.096 288.625Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M105.665 284.544C105.665 284.544 107.526 299.456 118.696 301.32L99.875 300.649L105.665 284.544Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M211.814 298.97L222.984 284.617C222.984 284.617 219.931 295.111 232.293 297.665L211.814 298.97Z'
        fill='black'
      />
      <path
        d='M217.268 254.832C217.268 254.832 213.097 282.063 228.27 298.243L220.935 298.86C212.157 285.607 208.73 269.509 211.347 253.826L217.268 254.832Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M217.268 254.832C217.268 254.832 213.097 282.063 228.27 298.243L220.935 298.86C212.157 285.607 208.73 269.509 211.347 253.826L217.268 254.832Z'
        fill='black'
      />
      <g filter='url(#filter0_dd_73_58028)'>
        <path
          d='M280.876 120.46C274.1 147.309 265.157 172.586 254.411 200.016C229.346 192.682 204.578 185.37 180.111 178.08C191.49 148.836 206.829 116.948 209.723 85.1454C230.992 91.377 250.318 97.2161 271.178 103.397L280.876 120.46Z'
          fill='white'
        />
        <g opacity='0.5'>
          <path
            opacity='0.2'
            d='M250.759 184.212C237.186 180.177 206.088 170.647 192.645 166.586L193.373 164.195C206.806 168.219 237.827 177.732 251.368 181.797L250.759 184.212Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M253.231 178.334C239.798 174.31 208.779 164.788 195.398 160.767L196.214 158.386C209.551 162.398 240.51 171.908 253.944 175.931C253.694 176.736 253.462 177.535 253.231 178.334Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M255.096 172.3C241.723 168.289 210.808 158.78 197.511 154.785C197.803 153.954 198.087 153.202 198.388 152.409C211.664 156.417 242.553 165.92 255.905 169.909L255.096 172.3Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M257.175 166.294C237.684 160.393 219.111 154.749 199.736 148.89L200.681 146.528C220.036 152.4 238.57 158.099 258.041 163.924L257.175 166.294Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M259.412 160.312C239.998 154.428 221.49 148.734 202.131 142.924L203.093 140.565C222.439 146.435 240.931 152.125 260.332 157.944L259.412 160.312Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M261.747 154.333C242.375 148.457 223.901 142.771 204.542 136.961L205.498 134.592C224.852 140.464 243.326 146.15 262.692 151.97L261.747 154.333Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M263.311 148.191C244.017 142.332 225.491 136.635 206.109 130.811L207.045 128.447C226.434 134.326 244.926 140.015 264.274 145.832L263.311 148.191Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M265.732 142.257C246.386 136.387 227.869 130.692 208.434 124.866L209.336 122.494C228.785 128.386 247.31 134.083 266.688 139.888L265.732 142.257Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M267.27 136.083C247.889 130.206 229.321 124.5 209.805 118.674C210.097 117.843 210.372 117.089 210.639 116.288C230.182 122.2 248.797 127.853 268.198 133.717L267.27 136.083Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M259.468 127.071C240.029 121.181 231.445 118.539 211.825 112.656L212.579 110.271C232.252 116.156 240.872 118.833 260.352 124.705L259.468 127.071Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M260.822 120.912C241.297 115.004 232.59 112.354 212.84 106.452L213.498 104.055C233.31 109.961 242.036 112.643 261.637 118.531C261.372 119.323 261.106 120.115 260.822 120.912Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M262.832 114.864C243.177 108.974 234.297 106.26 214.424 100.35C214.593 99.5463 214.763 98.743 214.934 97.9311C234.927 103.866 243.827 106.576 263.534 112.468L262.832 114.864Z'
            fill='#7C96E9'
          />
        </g>
        <path
          d='M280.869 120.494L267.879 116.823L271.165 103.457L280.869 120.494Z'
          fill='#D3DCF8'
        />
      </g>
      <g filter='url(#filter1_dd_73_58028)'>
        <path
          d='M28.251 39.311C35.386 67.5829 44.803 94.198 56.1185 123.082C82.5114 115.359 108.591 107.66 134.354 99.9831C122.372 69.1908 106.221 35.6131 103.174 2.12605C80.7779 8.68772 60.4285 14.8361 38.4631 21.3444L28.251 39.311Z'
          fill='white'
        />
        <g opacity='0.5'>
          <path
            opacity='0.2'
            d='M59.9662 106.441C74.259 102.191 107.004 92.1568 121.159 87.8802L120.393 85.3631C106.248 89.5999 73.5839 99.6174 59.3257 103.897L59.9662 106.441Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M57.3613 100.251C71.5063 96.0139 104.168 85.9875 118.257 81.7531L117.399 79.246C103.355 83.4709 70.7559 93.4841 56.6109 97.7209C56.8743 98.5676 57.1178 99.4092 57.3613 100.251Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M55.3977 93.8976C69.4797 89.6741 102.032 79.6614 116.033 75.4551C115.726 74.5799 115.427 73.7876 115.11 72.9523C101.13 77.1728 68.6053 87.1798 54.5465 91.3796L55.3977 93.8976Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M53.2088 87.5732C73.7325 81.3598 93.2886 75.4165 113.69 69.2478L112.695 66.7602C92.3152 72.9431 72.7993 78.9438 52.2965 85.0775L53.2088 87.5732Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M50.8535 81.2743C71.2953 75.0782 90.7842 69.0833 111.168 62.9652L110.156 60.4813C89.7852 66.6623 70.3134 72.6536 49.8844 78.7812L50.8535 81.2743Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M48.3952 74.9785C68.7929 68.7917 88.2458 62.8045 108.63 56.6864L107.624 54.1917C87.244 60.3747 67.7911 66.3619 47.4 72.4909L48.3952 74.9785Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M46.7492 68.5115C67.0659 62.3419 86.5728 56.3433 106.982 50.2105L105.996 47.7209C85.5799 53.9115 66.1091 59.9026 45.736 66.0278L46.7492 68.5115Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M44.1992 62.2632C64.5699 56.0821 84.0677 50.0854 104.533 43.9502L103.583 41.4531C83.1038 47.657 63.597 53.6556 43.193 59.7685L44.1992 62.2632Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M42.5804 55.7618C62.9871 49.5732 82.5389 43.565 103.089 37.4307C102.782 36.5556 102.492 35.7614 102.21 34.9184C81.6328 41.1432 62.0316 47.096 41.6031 53.2704L42.5804 55.7618Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M50.7951 46.2728C71.2648 40.0708 80.3027 37.2884 100.962 31.0934L100.168 28.582C79.4533 34.7794 70.3762 37.5983 49.8648 43.7809L50.7951 46.2728Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M49.3696 39.7873C69.9293 33.5663 79.0969 30.7753 99.893 24.5608L99.2004 22.0375C78.3395 28.2562 69.1506 31.08 48.5113 37.2802C48.7908 38.1141 49.0702 38.9481 49.3696 39.7873Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M47.2546 33.4191C67.9497 27.2166 77.3007 24.3586 98.2265 18.1355C98.0479 17.2897 97.8693 16.4438 97.6888 15.5889C76.637 21.8386 67.2662 24.6914 46.5152 30.8964L47.2546 33.4191Z'
            fill='#7C96E9'
          />
        </g>
        <path
          d='M28.2578 39.3472L41.936 35.4816L38.4756 21.4076L28.2578 39.3472Z'
          fill='#D3DCF8'
        />
      </g>
      <path
        d='M153 136.812C131.944 136.812 114.812 153.943 114.812 174.999C114.812 196.055 131.944 213.187 153 213.187C174.056 213.187 191.187 196.055 191.187 174.999C191.187 153.943 174.056 136.812 153 136.812ZM146.023 194.163L131.226 177.718L135.593 173.787L145.875 185.21L170.258 156.173L174.763 159.944L146.023 194.163Z'
        fill='#3B61DE'
      />
      <defs>
        <filter
          id='filter0_dd_73_58028'
          x='173.85'
          y='82.1265'
          width='116.129'
          height='128.844'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='erode'
            in='SourceAlpha'
            result='effect1_dropShadow_73_58028'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_73_58028'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='erode'
            in='SourceAlpha'
            result='effect2_dropShadow_73_58028'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_73_58028'
            result='effect2_dropShadow_73_58028'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_73_58028'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_dd_73_58028'
          x='18.9316'
          y='-1'
          width='121.752'
          height='135.14'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='erode'
            in='SourceAlpha'
            result='effect1_dropShadow_73_58028'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_73_58028'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='erode'
            in='SourceAlpha'
            result='effect2_dropShadow_73_58028'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_73_58028'
            result='effect2_dropShadow_73_58028'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_73_58028'
            result='shape'
          />
        </filter>
      </defs>
    </>
  ),
});

export default Cat;
