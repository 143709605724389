import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { signIn } from 'next-auth/client';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { InputForm } from '../Form';

import { coursesValidationSchema } from './validationSchema';
import { getUser } from '@/stores/user';
import { createCourseSuggestion } from '@/api/coursesSuggestions';

const SuggestCourseForm = ({ handleHasSuccess, handleHasError, focused }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const user = useSelector(state => getUser(state));

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    formState: { errors, isValid },
  } = useForm({ mode: 'all', resolver: yupResolver(coursesValidationSchema) });

  useEffect(() => {
    if (focused) {
      setFocus('courseSuggestion');
    }
  }, [focused, setFocus]);

  const onSubmit = async data => {
    if (!user?.id) {
      const currentUrl = window.location.href;

      history.pushState({}, '', currentUrl);

      return signIn('auth0', {
        callbackUrl: router.asPath,
      });
    }

    const { courseSuggestion } = data;

    setIsLoading(true);
    handleHasError(false);
    handleHasSuccess(false);

    try {
      await createCourseSuggestion({
        name: courseSuggestion,
        user_id: user.id,
      });

      handleHasSuccess(true);
    } catch (error) {
      handleHasError(true);
    }

    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
      <Box fontSize='sm' mb={{ base: 6, md: 8 }}>
        <Text fontWeight='bold'>{t('makeSuggestion:suggestCourse.title')}</Text>
        {t('makeSuggestion:suggestCourse.message')}
      </Box>

      <InputForm
        id='courseSuggestion'
        isRequired={true}
        label={t('makeSuggestion:suggestCourse.name')}
        placeholder='Criminal Law'
        register={register}
        onChange={e => setValue(e.target.value, 'courseSuggestion')}
        errors={errors}
      />

      <Box mt={{ base: 6 }} pb={6}>
        <Flex justifyContent='end'>
          <Button
            bg='alpha.500'
            color='white'
            type='submit'
            w={{ base: 'full', md: '50%' }}
            _focus={{ boxShadow: 'none' }}
            disabled={!isValid || isLoading}
            isLoading={isLoading}
            data-testid='suggest-course-button'
          >
            {t('common:send')}
          </Button>
        </Flex>
      </Box>
    </form>
  );
};

export default SuggestCourseForm;
