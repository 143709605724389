import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';
import { Box, Text, Flex, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { reviewSchema } from './validationSchemas';
import OutlineInfo from '@/components/Outline/OutlineView/OutlineInfo';
import { InputForm, TextAreaForm } from '@/components/Form';
import Rate from '@/components/ReviewOutline/Rate';

import { getUser } from '@/stores/user';
import {
  createReview,
  getIsLoadingReview,
  getOutlineReview,
  clearReview,
} from '@/stores/outlines';

const MakeReview = ({ outline, renderSuccessComponent }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(state => getUser(state));

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'all', resolver: yupResolver(reviewSchema) });

  const [rating, setRating] = useState(0);
  const isLoading = useSelector(state => getIsLoadingReview(state));
  const review = useSelector(state => getOutlineReview(state));

  useEffect(() => {
    return () => {
      dispatch(clearReview());
    };
  }, [dispatch]);

  const onSubmit = data => {
    if (!rating) return;

    const { title, content } = data;
    const { id } = user;
    const { id: outlineId } = outline;

    dispatch(
      createReview({
        outlineId,
        userId: id,
        rating,
        title,
        content,
      }),
    );
  };

  return (
    <Box data-testid='review-form'>
      {!review && (
        <>
          <Box
            border={{ base: '1px solid', md: 'none' }}
            borderColor={{ base: 'gray.100', md: 'none' }}
            borderRadius={{ base: 12, md: 'none' }}
            p={{ base: 3, md: 0 }}
          >
            <OutlineInfo outline={outline} fullInfo={false} />
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mt={{ base: 6, md: 12 }}>
              <Text
                fontSize={{ base: 'md' }}
                fontWeight={{ base: 400 }}
                color={{ base: 'gray.900' }}
              >
                {t('makeReview:rateOutline')}
              </Text>
              <Box my={{ base: 4 }}>
                <Rate rating={rating} onRating={rate => setRating(rate)} />
              </Box>
            </Box>
            <Box mt={{ base: 10 }}>
              <Text
                fontSize={{ base: 'md' }}
                fontWeight={{ base: 400 }}
                color={{ base: 'gray.900' }}
              >
                {t('makeReview:titleOfReview.label')}
              </Text>
              <InputForm
                id='title'
                placeholder={t('makeReview:titleOfReview.placeholder')}
                register={register}
                errors={errors}
              />
            </Box>
            <Box>
              <TextAreaForm
                id='content'
                label={t('makeReview:contentOfReview.label')}
                placeholder={t('makeReview:contentOfReview.placeholder')}
                register={register}
                errors={errors}
              />
            </Box>
            <Flex mt={{ base: 14, md: 6 }}>
              <Button
                w={{ base: 'full' }}
                type='submit'
                bg='alpha.400'
                color='white'
                disabled={!isValid || !rating}
                isLoading={isLoading}
              >
                {t('makeReview:buttonName')}
              </Button>
            </Flex>
          </form>
        </>
      )}
      {review && renderSuccessComponent()}
    </Box>
  );
};

MakeReview.propTypes = {
  renderSuccessComponent: PropTypes.func.isRequired,
  outline: PropTypes.shape({
    id: PropTypes.number,
    table_of_contents: PropTypes.string,
    outline_url: PropTypes.string,
    year: PropTypes.string,
    course: PropTypes.object,
    count_rating: PropTypes.number,
    average_rating: PropTypes.number,
    school: PropTypes.object,
    professor: PropTypes.object,
    user: PropTypes.object,
  }).isRequired,
};

export default MakeReview;
