import PropTypes from 'prop-types';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from '@chakra-ui/react';

const SelectForm = ({
  id,
  options,
  label,
  type,
  placeholder,
  errors,
  register,
  defaultValue,
  isRequired,
  ...rest
}) => {
  return (
    <FormControl
      id={id}
      isInvalid={errors[id]}
      isRequired={isRequired}
      {...rest}
    >
      <FormLabel
        htmlFor={id}
        color={errors[id] ? 'customInput.error' : 'gray.800'}
        fontSize='xs'
      >
        {label}
      </FormLabel>
      <Select
        id={id}
        defaultValue={defaultValue}
        fontSize='14px'
        type={type}
        placeholder={placeholder}
        bg='gray.50'
        _placeholder={errors[id] && { color: 'customInput.error' }}
        {...register(id)}
        data-testid={`${id}-select`}
      >
        {options.length &&
          options.map(({ value, name }) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
      </Select>
      <FormErrorMessage fontSize='xs'>
        {errors[id] && errors[id].message}
      </FormErrorMessage>
    </FormControl>
  );
};

SelectForm.defaultProps = {
  label: '',
  type: 'text',
  placeholder: '',
  errors: {},
  defaultValue: '',
};

SelectForm.propTypes = {
  id: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.shape({}),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isRequired: PropTypes.bool,
};

export default SelectForm;
