import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { signIn, useSession } from 'next-auth/client';
import getConfig from 'next/config';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useTranslation from 'next-translate/useTranslation';
import { Editor, EditorState, RichUtils, convertToRaw } from 'draft-js';
import debounce from 'lodash.debounce';

import {
  SelectForm,
  DropZoneOutline,
  RichTextForm,
  SearchInputForm,
  ListOptions,
} from '@/components/Form';
import Modal from '@/components/Modal';

import {
  Box,
  Grid,
  Button,
  Spinner,
  Text,
  Link,
  useDisclosure,
} from '@chakra-ui/react';
import { ListIcon } from 'chakra-ui-ionicons';

import validationSchema from './validationSchema';
import { constants } from '../../constants';
import { getPreviousYears } from '@/utils/dateUtils';
import { fetchByEntity } from '@/api/search';
import { fetchUser, getUser } from '@/stores/user';
import { createOutline } from '@/api/outlines';
import { pages } from '@/utils/pages';
import MakeSuggestionDrawerContainer from '@/containers/MakeSuggestionDrawerContainer';

const { publicRuntimeConfig } = getConfig();

const OutlineUploadForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const user = useSelector(state => getUser(state));
  const [session] = useSession();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const courseList = useRef();
  const professorList = useRef();
  const schoolList = useRef();

  const { UPLOAD_FORM_SEARCH_LIMIT } = publicRuntimeConfig;

  const coursePeriodOptions = [
    { value: constants.COURSE_PERIOD.FALL, name: constants.COURSE_PERIOD.FALL },
    {
      value: constants.COURSE_PERIOD.SPRING,
      name: constants.COURSE_PERIOD.SPRING,
    },
    {
      value: constants.COURSE_PERIOD.WINTER,
      name: constants.COURSE_PERIOD.WINTER,
    },
    {
      value: constants.COURSE_PERIOD.SUMMER,
      name: constants.COURSE_PERIOD.SUMMER,
    },
  ];

  const gradeOptions = Object.values(constants.COURSE_GRADE).map(grade => ({
    value: grade,
    name: grade,
  }));

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });

  const [entities, setEntities] = useState({
    courses: { id: null, inValid: false },
    professors: { id: null, inValid: false },
    schools: { id: null, inValid: false },
  });
  const [displayOptions, setDisplayOptions] = useState({
    courses: false,
    professors: false,
    schools: false,
  });
  const [dataOptions, setDataOptions] = useState({
    courses: [],
    professors: [],
    schools: [],
  });

  const [file, setFile] = useState({
    hasFile: false,
    name: '',
    content: null,
  });

  const [isDropzoneValid, setIsDropzoneValid] = useState({
    isValid: true,
    errorMessage: null,
  });
  const [isFormValid, setIsFormValid] = useState(false);

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );

  const handleFormValidation = useCallback(() => {
    if (isValid && isDropzoneValid && file.hasFile) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [isValid, isDropzoneValid, file]);

  useEffect(() => {
    handleFormValidation();
  }, [handleFormValidation]);

  const debouncedFetch = useMemo(() => {
    const fetchInputResults = async (name, entity) => {
      let result;

      try {
        const { data } = await fetchByEntity(
          name,
          entity,
          UPLOAD_FORM_SEARCH_LIMIT,
        );

        switch (entity) {
          case 'schools':
            result = data.schools;
            break;

          case 'professors':
            result = data.professors;
            break;

          case 'courses':
            result = data.courses;
            break;

          default:
            result = [];
            break;
        }
      } catch (error) {
        result = [];
      }

      setDataOptions({
        ...dataOptions,
        [entity]: result,
      });
    };

    return debounce((name, entity) => fetchInputResults(name, entity), 200);
  }, [dataOptions, UPLOAD_FORM_SEARCH_LIMIT]);

  const handleIsDropzoneValid = (isValid, message) => {
    setIsDropzoneValid({
      isValid: isValid,
      errorMessage: message,
    });
  };

  const handleFile = (acceptedFiles, fileRejections) => {
    if (acceptedFiles.length > 0) {
      setFile({
        name: acceptedFiles[0].path,
        hasFile: true,
        content: acceptedFiles[0],
      });
    }
    if (fileRejections.length > 0) {
      setFile({
        hasFile: false,
        name: '',
        content: null,
      });
    }
  };

  const onRemoveFile = () => {
    setFile({ hasFile: false, name: '', content: null });
  };

  const toggleList = type => {
    setEditorState(() => RichUtils.toggleBlockType(editorState, type));
  };

  const handleTab = event => {
    setEditorState(() =>
      RichUtils.onTab(event, editorState, constants.NESTED_LISTS),
    );
  };

  const handleOnChange = (value, type) => {
    setDataOptions({
      ...dataOptions,
      [type]: [],
    });
    setEntities({
      ...entities,
      [type]: {
        inValid: false,
        id: null,
      },
    });

    if (value.length >= 3) {
      debouncedFetch(value, type);

      setDisplayOptions({
        ...displayOptions,
        [type]: true,
      });
    } else {
      setDisplayOptions({
        ...displayOptions,
        [type]: false,
      });
    }
    setValue(type, value);
  };

  const onSelectOption = (option, type) => {
    setDisplayOptions({
      ...displayOptions,
      [type]: false,
    });
    setEntities({
      ...entities,
      [type]: {
        inValid: false,
        id: option.id,
      },
    });

    setValue(type, option.name);
  };

  const handleDisplayOption = input => {
    setDisplayOptions({
      ...displayOptions,
      [input]: false,
    });
  };

  const onSubmit = async data => {
    if (!user?.id) {
      const currentUrl = window.location.href;

      history.pushState({}, '', currentUrl);

      return signIn('auth0', {
        callbackUrl: router.asPath,
      });
    }

    const { content } = file;

    if (!content) {
      setIsDropzoneValid({
        isValid: false,
        errorMessage: 'Upload file is required',
      });

      return;
    }
    let isInvalidField = false;

    if (
      !entities?.professors?.id ||
      !entities?.courses?.id ||
      !entities?.schools?.id
    ) {
      isInvalidField = true;
      setEntities({
        ...entities,
        professors: {
          ...entities.professors,
          inValid: entities?.professors?.id ? false : true,
        },
        courses: {
          ...entities.courses,
          inValid: entities?.courses?.id ? false : true,
        },
        schools: {
          ...entities.schools,
          inValid: entities?.schools?.id ? false : true,
        },
      });
    }

    if (isInvalidField) return;

    let tableOfContents = convertToRaw(editorState.getCurrentContent());

    if (tableOfContents.blocks.length === 1) {
      tableOfContents = tableOfContents?.blocks[0]?.text.trim()
        ? tableOfContents
        : null;
    }

    const { year, coursePeriod, grade } = data;
    const { professors, courses, schools } = entities;

    let formData = new FormData();

    formData.append('file', content);
    formData.append('professorId', professors.id);
    formData.append('courseId', courses.id);
    formData.append('sellerId', user.id);
    formData.append('schoolId', schools.id);
    formData.append('year', year);
    formData.append('coursePeriod', coursePeriod);
    formData.append('grade', grade);
    if (tableOfContents)
      formData.append('tableOfContents', JSON.stringify(tableOfContents));

    try {
      const { stripe_account_id, auth_id } = user;

      setIsLoading(true);
      const { data: uploadedOutline } = await createOutline(formData);

      setHasError(false);
      setIsLoading(false);

      if (!stripe_account_id) {
        dispatch(fetchUser(auth_id));
      }

      router.push({
        pathname: pages.uploadSuccessfulPath(uploadedOutline.id),
      });
    } catch (error) {
      setHasError(true);
      setIsLoading(false);
    }
  };

  const handleAddSuggestion = suggestionType => {
    if (!session) {
      const currentUrl = window.location.href;

      history.pushState({}, '', currentUrl);

      return signIn('auth0', {
        callbackUrl: router.asPath,
      });
    }

    router.push({
      pathname: pages.asPath,
      query: { suggestionType },
    });
    onOpen();
  };

  const onCloseSuggestionDrawer = () => {
    router.replace({
      pathname: pages.asPath,
      query: {},
    });
    onClose();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
        <SearchInputForm
          id='courses'
          isRequired={true}
          label={t('outlines:outlineUpload.placeholder.course')}
          placeholder='Criminal Law'
          register={register}
          onChange={e => handleOnChange(e.target.value, 'courses')}
          errors={errors}
          inValidSearchInput={entities.courses.inValid}
          marginBottom={0}
        >
          {displayOptions.courses && (
            <ListOptions
              options={dataOptions.courses}
              onSelectOption={onSelectOption}
              input='courses'
              handleDisplayOption={handleDisplayOption}
              inputList={courseList}
            />
          )}
        </SearchInputForm>

        <Box fontSize='xs' mb={4} color='gray.500'>
          {t('outlines:outlineUpload.dontSeeCourse')}{' '}
          <Link
            textDecoration='underline'
            color='delta.500'
            onClick={() => handleAddSuggestion('course')}
            data-testid='suggest-professor-link'
          >
            {t('outlines:outlineUpload.suggestCourse')}
          </Link>
        </Box>

        <SearchInputForm
          id='schools'
          isRequired={true}
          label={t('outlines:outlineUpload.placeholder.school')}
          placeholder='Stanford'
          register={register}
          onChange={e => handleOnChange(e.target.value, 'schools')}
          errors={errors}
          inValidSearchInput={entities.schools.inValid}
        >
          {displayOptions.schools && (
            <ListOptions
              options={dataOptions.schools}
              onSelectOption={onSelectOption}
              input='schools'
              handleDisplayOption={handleDisplayOption}
              inputList={schoolList}
            />
          )}
        </SearchInputForm>

        <SearchInputForm
          id='professors'
          isRequired={true}
          label={t('outlines:outlineUpload.placeholder.professor')}
          placeholder='Kate Johnson'
          register={register}
          onChange={e => handleOnChange(e.target.value, 'professors')}
          errors={errors}
          inValidSearchInput={entities.professors.inValid}
          marginBottom={0}
        >
          {displayOptions.professors && (
            <ListOptions
              options={dataOptions.professors}
              onSelectOption={onSelectOption}
              input='professors'
              handleDisplayOption={handleDisplayOption}
              inputList={professorList}
            />
          )}
        </SearchInputForm>

        <Box fontSize='xs' mb={4} color='gray.500'>
          {t('outlines:outlineUpload.dontSeeProfessor')}{' '}
          <Link
            textDecoration='underline'
            color='delta.500'
            onClick={() => handleAddSuggestion('professor')}
          >
            {t('outlines:outlineUpload.suggestProfessor')}
          </Link>
        </Box>

        <Grid templateColumns='repeat(2, 1fr)' gap={6}>
          <SelectForm
            id='coursePeriod'
            isRequired={true}
            options={coursePeriodOptions}
            label={t('outlines:outlineUpload.placeholder.coursePeriod')}
            register={register}
            errors={errors}
            defaultValue={coursePeriodOptions[0].value}
            mb={4}
          />
          <SelectForm
            id='year'
            isRequired={true}
            options={getPreviousYears(constants.PREVIOUS_YEARS)}
            label={t('outlines:outlineUpload.placeholder.year')}
            register={register}
            errors={errors}
            defaultValue={new Date().getFullYear()}
            mb={4}
          />
        </Grid>

        <SelectForm
          id='grade'
          isRequired={true}
          options={gradeOptions}
          label={t('outlines:outlineUpload.placeholder.grade')}
          register={register}
          errors={errors}
          defaultValue={gradeOptions[0].value}
        />

        <Text mb={4} fontSize='xs' color='gray.500'>
          {t('outlines:outlineUpload.grade_note')}
        </Text>

        <DropZoneOutline
          id='dropzoneoutline'
          label={t('outlines:outlineUpload.placeholder.upload')}
          isValid={isDropzoneValid.isValid}
          errorMessage={isDropzoneValid.errorMessage}
          mb={4}
          file={file}
          onRemoveFile={onRemoveFile}
          handleFile={handleFile}
          handleIsDropzoneValid={handleIsDropzoneValid}
        />

        <RichTextForm label={t('outlines:tableOfContent')}>
          <Box pb={1}>
            <ListIcon
              mx='1'
              onClick={() => toggleList('ordered-list-item')}
              cursor='pointer'
            />
          </Box>
          <hr />
          <Box
            fontSize={{ base: 'xs' }}
            pt={2}
            minH={100}
            maxH={100}
            overflow='auto'
          >
            <Editor
              editorState={editorState}
              onChange={setEditorState}
              onTab={handleTab}
              placeholder={t(
                'outlines:outlineUpload.placeholder.tableOfContent',
              )}
            />
          </Box>
        </RichTextForm>

        <Box mt={{ base: 6 }} pb={6}>
          <Button
            bg='alpha.500'
            color='white'
            type='submit'
            w={{ base: 'full', md: '50%' }}
            _focus={{ boxShadow: 'none' }}
            disabled={!isFormValid || isLoading}
          >
            {isLoading && <Spinner size='sm' />}
            {!isLoading && t('common:done')}
          </Button>
          <Button
            mt={4}
            bg='white'
            color='alpha.500'
            w={{ base: 'full' }}
            display={{ md: 'none' }}
            _focus={{ boxShadow: 'none' }}
            onClick={() => router.back()}
            data-testid='cancel-button'
          >
            {t('common:cancel')}
          </Button>
        </Box>
      </form>
      <MakeSuggestionDrawerContainer
        isOpen={isOpen && router.query?.suggestionType !== undefined}
        onClose={onCloseSuggestionDrawer}
      />
      {hasError && (
        <Modal
          open={hasError}
          onCloseModal={() => setHasError(false)}
          title={t('outlines:outlineUpload.errors.title')}
        >
          <Text fontSize={{ base: 'sm' }} fontWeight={400} color='gray.500'>
            {t('outlines:outlineUpload.errors.errorMessage')}
          </Text>
        </Modal>
      )}
    </>
  );
};

export default OutlineUploadForm;
