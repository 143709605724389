import React, { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import { Box, Flex, Spacer, Heading, Link } from '@chakra-ui/react';
import OutlineInfo from './OutlineInfo';
import GoBackButton from '@/components/GoBackButton';
import Preview from '@/components/Preview';

const OutlineView = ({ children, outline, handleShowReviews, showReviews }) => {
  const { t } = useTranslation();

  const {
    table_of_contents,
    course,
    professor,
    course_period,
    year,
    outline_url,
  } = outline;

  const [tableOfContentState] = useState(() => {
    let tableOfContents;

    try {
      tableOfContents = table_of_contents
        ? EditorState.createWithContent(
            convertFromRaw(JSON.parse(table_of_contents)),
          )
        : null;
    } catch (error) {
      tableOfContents = null;
    }

    return tableOfContents;
  });

  return (
    <Box pb={6}>
      <Flex flexDirection={{ base: 'column', md: 'row' }}>
        <GoBackButton>{t('outlines:title')}</GoBackButton>
        <Box minW={{ base: 'auto', md: '40%' }}>
          <OutlineInfo outline={outline} />
          {children}
        </Box>

        <Spacer />
        <Box
          display={{ base: 'block', md: 'flex' }}
          justifyContent={{ base: 'normal', md: 'space-between' }}
          w={table_of_contents && tableOfContentState ? 'full' : ''}
        >
          <Flex
            mt={{ base: '16px', md: '6px' }}
            mb={{ base: 4, md: 0 }}
            data-testid='container-preview'
          >
            <Preview
              year={year}
              term={course_period}
              course={course.name}
              professor={professor.name}
              uuid={outline_url}
            />
          </Flex>
          {table_of_contents && tableOfContentState && (
            <Box ml={{ md: 4, lg: 2 }}>
              <Heading
                fontSize={{ base: 'md', md: 'xl' }}
                color='gray.600'
                fontWeight={600}
                alignSelf='stretch'
                m='6px 0'
              >
                {t('outlines:tableOfContent')}
              </Heading>

              <Box
                color='gray.500'
                alignSelf='stretch'
                fontWeight='normal'
                fontSize={{ base: 'xs' }}
              >
                <Editor
                  data-testid='rich-text'
                  editorState={tableOfContentState}
                  readOnly
                />
              </Box>
            </Box>
          )}
        </Box>
      </Flex>
      <Heading
        color='alpha.500'
        fontWeight={600}
        fontSize={{ base: 'sm' }}
        mt={4}
      >
        <Link onClick={handleShowReviews} data-testid='show-reviews-btn'>
          {showReviews ? t('outlines:hideReviews') : t('outlines:seeReviews')}
        </Link>
      </Heading>
    </Box>
  );
};

OutlineView.defaultProps = {
  outline: {},
};

OutlineView.propTypes = {
  outline: PropTypes.shape({
    id: PropTypes.number,
    table_of_contents: PropTypes.string,
    outline_url: PropTypes.string,
    course_period: PropTypes.string,
    year: PropTypes.string,
    course: PropTypes.object,
    count_rating: PropTypes.number,
    average_rating: PropTypes.number,
    school: PropTypes.object,
    professor: PropTypes.object,
    user: PropTypes.object,
  }),
  handleShowReviews: PropTypes.func.isRequired,
  showReviews: PropTypes.bool.isRequired,
};

export default OutlineView;
