import React from 'react';
import { createIcon } from '@chakra-ui/react';

const DownloadCat = createIcon({
  displayName: 'Success Cat',
  viewBox: '0 0 198 285',
  path: (
    <>
      <path
        d='M170.076 255.799H61.0532V284.34H170.076C173.773 284.206 177.273 282.645 179.842 279.983C182.41 277.322 183.846 273.766 183.846 270.069C183.846 266.371 182.41 262.815 179.842 260.154C177.273 257.492 173.773 255.931 170.076 255.799Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M170.076 255.799H61.0532V284.34H170.076C173.773 284.206 177.273 282.645 179.842 279.983C182.41 277.322 183.846 273.766 183.846 270.069C183.846 266.371 182.41 262.815 179.842 260.154C177.273 257.492 173.773 255.931 170.076 255.799Z'
        fill='white'
      />
      <path
        d='M70.1384 284.34H14.9595V280.262H32.0544V260.041H14.9595V255.799H70.1384C73.8353 255.931 77.3366 257.492 79.9035 260.154C82.4723 262.815 83.9076 266.371 83.9076 270.069C83.9076 273.766 82.4723 277.322 79.9035 279.983C77.3366 282.645 73.8353 284.206 70.1384 284.34Z'
        fill='#4D61FC'
      />
      <path
        d='M19.0386 280.097H69.9941C71.3123 280.101 72.6178 279.845 73.8373 279.342C75.0568 278.84 76.1629 278.101 77.0953 277.167C78.0278 276.234 78.7646 275.124 79.2656 273.905C79.7665 272.685 80.0207 271.378 80.0134 270.06C80.0134 267.402 78.9566 264.855 77.0789 262.975C75.1993 261.096 72.6507 260.041 69.9941 260.041H19.0386V280.097Z'
        fill='white'
      />
      <path
        d='M186.532 233.859H95.3711V255.799H186.532C187.027 255.806 187.519 255.713 187.978 255.525C188.437 255.336 188.854 255.056 189.201 254.702C189.892 253.992 190.278 253.042 190.28 252.051V237.625C190.294 237.128 190.208 236.634 190.025 236.172C189.843 235.709 189.567 235.289 189.216 234.936C188.865 234.583 188.446 234.305 187.985 234.12C187.523 233.936 187.029 233.846 186.532 233.859Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M134.731 241.229H166.069C166.486 241.229 166.884 241.393 167.18 241.69C167.475 241.984 167.641 242.384 167.641 242.801V247.646C167.636 248.06 167.468 248.455 167.173 248.747C166.879 249.038 166.482 249.2 166.069 249.2H134.731C134.525 249.202 134.322 249.164 134.13 249.087C133.94 249.01 133.766 248.897 133.62 248.751C133.473 248.606 133.358 248.434 133.278 248.244C133.199 248.056 133.159 247.851 133.159 247.646V242.801C133.155 242.595 133.196 242.388 133.272 242.196C133.351 242.004 133.466 241.83 133.614 241.684C133.76 241.538 133.934 241.421 134.126 241.344C134.318 241.266 134.523 241.225 134.731 241.229Z'
        fill='black'
      />
      <path
        d='M60.1045 255.799H109.744C110.239 255.806 110.731 255.713 111.19 255.525C111.649 255.336 112.066 255.056 112.413 254.702C113.104 253.992 113.49 253.042 113.492 252.051V237.625C113.506 237.128 113.42 236.634 113.238 236.172C113.055 235.709 112.779 235.289 112.428 234.936C112.077 234.583 111.658 234.305 111.197 234.12C110.735 233.936 110.241 233.846 109.744 233.859H60.1959L60.1045 255.799Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M60.1045 255.799H109.744C110.239 255.806 110.731 255.713 111.19 255.525C111.649 255.336 112.066 255.056 112.413 254.702C113.104 253.992 113.49 253.042 113.492 252.051V237.625C113.506 237.128 113.42 236.634 113.238 236.172C113.055 235.709 112.779 235.289 112.428 234.936C112.077 234.583 111.658 234.305 111.197 234.12C110.735 233.936 110.241 233.846 109.744 233.859H60.1959L60.1045 255.799Z'
        fill='black'
      />
      <path
        d='M60.1045 252.51H108.244C108.729 252.51 109.193 252.316 109.537 251.974C109.879 251.63 110.073 251.166 110.073 250.681V238.852C110.067 238.362 109.879 237.89 109.543 237.536C109.369 237.358 109.16 237.218 108.93 237.124C108.702 237.029 108.455 236.982 108.208 236.987H60.1045V252.51Z'
        fill='white'
      />
      <path
        d='M32.0546 233.859H139.743V211.919H32.0546C31.3745 211.916 30.6998 212.049 30.0709 212.307C29.4419 212.566 28.8715 212.947 28.3907 213.428C27.9098 213.91 27.5277 214.481 27.2681 215.11C27.0103 215.739 26.8768 216.411 26.8804 217.093V228.85C26.8951 229.515 27.0413 230.171 27.3119 230.778C27.5807 231.387 27.9683 231.938 28.4528 232.396C29.431 233.344 30.7456 233.87 32.1095 233.859'
        fill='#4D61FC'
      />
      <path
        opacity='0.3'
        d='M32.0546 233.859H139.743V211.919H32.0546C31.3745 211.916 30.6998 212.049 30.0709 212.307C29.4419 212.566 28.8715 212.947 28.3907 213.428C27.9098 213.91 27.5277 214.481 27.2681 215.11C27.0103 215.739 26.8768 216.411 26.8804 217.093V228.85C26.8951 229.515 27.0413 230.171 27.3119 230.778C27.5807 231.387 27.9683 231.938 28.4528 232.396C29.431 233.344 30.7456 233.87 32.1095 233.859'
        fill='white'
      />
      <path
        d='M176.585 215.21H127.037C126.553 215.21 126.087 215.404 125.745 215.746C125.401 216.09 125.209 216.554 125.209 217.039V228.795C125.209 229.042 125.26 229.285 125.357 229.511C125.456 229.738 125.597 229.941 125.776 230.111C125.948 230.287 126.152 230.426 126.377 230.519C126.604 230.614 126.847 230.661 127.092 230.66H176.64L176.585 215.21Z'
        fill='white'
      />
      <path
        d='M125.632 211.921H180.189V215.212H127.04C126.809 215.166 126.573 215.166 126.345 215.212C126.116 215.292 125.906 215.419 125.727 215.581C125.55 215.746 125.403 215.943 125.303 216.163C125.208 216.382 125.158 216.618 125.157 216.857V228.778C125.158 229.016 125.208 229.252 125.303 229.473C125.398 229.696 125.54 229.895 125.723 230.058C125.904 230.213 126.116 230.33 126.345 230.405C126.566 230.489 126.804 230.521 127.04 230.497H180.189V233.788H125.632C125.135 233.801 124.639 233.711 124.178 233.526C123.716 233.34 123.297 233.064 122.946 232.711C122.595 232.358 122.321 231.938 122.138 231.475C121.955 231.012 121.867 230.517 121.884 230.021V215.596C121.878 215.102 121.972 214.614 122.16 214.157C122.348 213.702 122.628 213.289 122.981 212.945C123.325 212.592 123.738 212.314 124.193 212.124C124.648 211.936 125.138 211.842 125.632 211.848'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M125.632 211.921H180.189V215.212H127.04C126.809 215.166 126.573 215.166 126.345 215.212C126.116 215.292 125.906 215.419 125.727 215.581C125.55 215.746 125.403 215.943 125.303 216.163C125.208 216.382 125.158 216.618 125.157 216.857V228.778C125.158 229.016 125.208 229.252 125.303 229.473C125.398 229.696 125.54 229.895 125.723 230.058C125.904 230.213 126.116 230.33 126.345 230.405C126.566 230.489 126.804 230.521 127.04 230.497H180.189V233.788H125.632C125.135 233.801 124.639 233.711 124.178 233.526C123.716 233.34 123.297 233.064 122.946 232.711C122.595 232.358 122.321 231.938 122.138 231.475C121.955 231.012 121.867 230.517 121.884 230.021V215.596C121.878 215.102 121.972 214.614 122.16 214.157C122.348 213.702 122.628 213.289 122.981 212.945C123.325 212.592 123.738 212.314 124.193 212.124C124.648 211.936 125.138 211.842 125.632 211.848'
        fill='black'
      />
      <path
        d='M12.0195 243.491C12.0195 243.491 4.97875 254.065 6.4221 255.898C7.86544 257.731 13.4628 256.121 14.1669 255.792C14.871 255.463 17.875 248.473 17.875 248.144C17.875 247.815 12.0195 243.491 12.0195 243.491Z'
        fill='#868A91'
      />
      <path
        opacity='0.3'
        d='M12.0219 243.491C12.0219 243.491 8.03214 247.909 9.45202 249.765C10.8719 251.621 12.4326 255.98 13.1719 255.98C15.6479 255.98 17.8657 248.473 17.8657 248.144C17.8657 247.815 12.0219 243.491 12.0219 243.491Z'
        fill='black'
      />
      <path
        d='M14.17 255.792C14.17 255.792 10.4502 279.42 22.9005 277.646C35.3509 275.872 27.4887 253.172 23.3347 246.828C23.3347 246.828 29.8826 233.904 30.1056 231.554C33.9944 234.465 38.5955 236.276 43.4242 236.794C51.0634 237.452 62.1995 234.327 69.1816 213.296C69.1816 213.296 110.44 215.845 114.582 215.399C114.173 216.785 114.118 218.251 114.424 219.665C114.729 221.077 115.385 222.389 116.331 223.482C119.605 227.641 128.335 242.939 129.65 246.217C130.964 249.495 125.061 263.053 128.335 265.239C131.609 267.424 142.534 263.265 143.18 252.009C143.825 240.753 131.832 221.743 132.055 218.9C132.278 216.057 139.741 212.191 139.741 212.191L181.61 211.686C181.61 211.686 190.998 205.036 187.067 198.962C183.136 192.887 180.026 182.842 172.223 182.618L170.803 182.56V169.213C170.803 169.213 162.072 174.746 160.758 178.541C159.049 175.186 157.05 171.985 154.785 168.978C154.785 168.978 148.085 171.891 143.133 184.252C139.83 183.88 136.589 183.091 133.487 181.902C128.511 179.94 123.559 184.087 117.305 182.783C111.05 181.479 81.7962 181.267 73.4999 181.361C65.2035 181.455 41.1243 179.787 28.7444 188.023C17.9486 195.214 5.02883 212.297 8.37318 230.496C8.42012 230.508 13.5129 246.393 14.17 255.792Z'
        fill='#868A91'
      />
      <path
        d='M186.655 199.525C186.655 199.525 170.65 200.266 176.892 211.639H181.586C181.586 211.639 191.349 203.05 186.655 199.525Z'
        fill='#C3C5C8'
      />
      <path
        opacity='0.5'
        d='M35.7839 201.875C33.437 203.931 31.1957 206.034 29.0365 208.255C26.8481 210.424 24.8274 212.757 22.9933 215.234C22.5497 215.859 22.139 216.505 21.7611 217.173C21.5851 217.502 21.3622 217.819 21.2213 218.16L20.7637 219.182C20.3013 220.623 20.0409 222.122 19.9892 223.635C19.9306 224.387 19.9892 225.162 19.9071 225.926C19.8249 226.69 19.9071 227.465 19.9657 228.276C20.0244 229.086 20.0361 229.815 20.1065 230.626C20.177 231.436 20.2591 232.153 20.3412 232.917C20.6557 235.981 21.2213 239.015 22.031 241.987C22.4218 243.466 22.9193 244.916 23.5213 246.323L24.0142 247.345L24.5422 248.379C24.8825 249.084 25.2111 249.789 25.5396 250.493C26.8281 253.334 27.903 256.268 28.7549 259.27C29.1574 260.775 29.4555 262.306 29.6467 263.852C29.8626 265.411 29.8626 266.993 29.6467 268.552C29.7606 267.009 29.6855 265.458 29.4238 263.935C29.1586 262.417 28.7936 260.917 28.3325 259.446C27.4019 256.504 26.2484 253.636 24.8825 250.869C24.5422 250.176 24.2019 249.483 23.8382 248.802L23.2983 247.779L22.7703 246.71C22.1472 245.267 21.6215 243.784 21.1979 242.269C20.3483 239.261 19.7475 236.188 19.4025 233.081C19.3204 232.306 19.2382 231.53 19.1913 230.731C19.1443 229.932 19.0857 229.18 19.0622 228.381C19.0387 227.583 19.0622 226.819 19.0622 226.032C19.0622 225.244 19.0622 224.469 19.1795 223.682C19.2441 222.083 19.5316 220.501 20.0361 218.982C20.6675 217.523 21.4795 216.149 22.4535 214.893C26.3493 210.026 30.8261 205.654 35.7839 201.875Z'
        fill='black'
      />
      <path
        opacity='0.5'
        d='M72.2329 205.622C70.4376 210.322 68.6069 215.021 66.7412 219.721C65.8223 222.094 64.6407 224.356 63.2208 226.465C61.8056 228.596 60.1217 230.536 58.2102 232.234C56.2857 233.954 54.0561 235.297 51.6388 236.193C49.2086 237.07 46.6211 237.418 44.0466 237.216C41.499 237.001 39.0054 236.366 36.6656 235.336C34.3527 234.321 32.1571 233.055 30.1177 231.564C31.209 232.199 32.2886 232.821 33.4151 233.385C34.5346 233.941 35.6822 234.439 36.8533 234.878C39.1721 235.765 41.614 236.287 44.0935 236.428C46.5542 236.537 49.0103 236.137 51.3102 235.254C53.6067 234.366 55.7283 233.078 57.5765 231.447C59.4317 229.807 61.0793 227.944 62.4816 225.901C63.912 223.867 65.1265 221.69 66.1075 219.404C68.1176 214.768 70.1595 210.174 72.2329 205.622Z'
        fill='black'
      />
      <path
        opacity='0.5'
        d='M119.721 201.37C119.053 203.72 118.302 206.14 117.515 208.49C116.729 210.84 115.896 213.19 115.051 215.54V215.598C114.991 215.698 114.944 215.805 114.91 215.916C114.863 216.05 114.828 216.187 114.805 216.327C114.744 216.612 114.712 216.905 114.711 217.196C114.708 217.801 114.775 218.405 114.91 218.994C115.187 220.215 115.608 221.399 116.166 222.519C117.326 224.802 118.669 226.986 120.179 229.051C119.275 228.127 118.425 227.149 117.633 226.126C116.834 225.099 116.117 224.011 115.485 222.871C114.833 221.715 114.333 220.479 113.995 219.194C113.837 218.532 113.753 217.854 113.749 217.173C113.749 216.818 113.785 216.464 113.854 216.115C113.881 215.936 113.925 215.758 113.983 215.587C114.049 215.394 114.136 215.21 114.241 215.034V215.14C115.11 212.79 115.978 210.44 116.905 208.185C117.832 205.929 118.665 203.626 119.721 201.37Z'
        fill='black'
      />
      <path
        d='M184.249 199.397C184.123 199.418 184.006 199.472 183.91 199.555C183.812 199.638 183.739 199.745 183.698 199.865C183.657 199.986 183.65 200.115 183.677 200.24C183.703 200.364 183.763 200.478 183.85 200.572C185.023 201.865 187.1 203.885 187.898 203.251C188.053 202.507 188.06 201.74 187.917 200.993C187.774 200.247 187.484 199.537 187.065 198.904L184.249 199.397Z'
        fill='black'
      />
      <path
        d='M165.954 195.884C166.608 195.712 167.274 195.598 167.949 195.544C168.576 195.484 169.209 195.519 169.827 195.649C170.443 195.765 171.038 195.98 171.587 196.284L171.798 196.39L172.009 196.519L172.432 196.777C172.725 196.954 172.983 197.177 173.3 197.353C173.147 197.029 172.967 196.717 172.76 196.425C172.54 196.138 172.288 195.877 172.009 195.649C171.438 195.161 170.751 194.829 170.014 194.686C169.277 194.538 168.516 194.574 167.796 194.792C167.097 194.988 166.462 195.365 165.954 195.884Z'
        fill='black'
      />
      <path
        d='M72.2292 205.622C72.2292 205.622 97.8809 202.309 115.283 212.108L114.11 215.186L69.1665 213.247L72.2292 205.622Z'
        fill='#C3C5C8'
      />
      <path
        opacity='0.4'
        d='M170.801 182.513V169.166C170.801 169.166 162.07 174.699 160.756 178.494L163.455 183.194L170.801 182.513Z'
        fill='black'
      />
      <path
        opacity='0.4'
        d='M155.114 171.493L162.155 183.242C159.903 184.074 157.514 184.474 155.114 184.417C155.114 184.417 152.72 180.528 155.114 171.493Z'
        fill='white'
      />
      <path
        opacity='0.3'
        d='M21.2217 218.102C21.2217 218.102 22.1135 230.486 26.3262 240.743L23.5216 246.171C23.5216 246.171 16.8681 226.174 21.2217 218.102Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M117.855 205.622C117.855 205.622 115.074 213.623 100.992 214.798L113.9 215.585L117.855 205.622Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M72.8301 203.05C72.8301 203.05 74.0036 212.45 81.0443 213.625L69.1807 213.202L72.8301 203.05Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M139.739 212.143L146.78 203.096C146.78 203.096 144.856 209.711 152.647 211.321L139.739 212.143Z'
        fill='black'
      />
      <path
        d='M143.176 184.322C143.176 184.322 140.548 201.486 150.111 211.685L145.488 212.074C139.955 203.72 137.795 193.573 139.445 183.688L143.176 184.322Z'
        fill='#4D61FC'
      />
      <path
        opacity='0.2'
        d='M143.176 184.322C143.176 184.322 140.548 201.486 150.111 211.685L145.488 212.074C139.955 203.72 137.795 193.573 139.445 183.688L143.176 184.322Z'
        fill='black'
      />
      <g filter='url(#filter0_dd_293_31439)'>
        <path
          d='M183.271 99.6252C179 116.549 173.363 132.481 166.59 149.772C150.79 145.149 135.179 140.54 119.757 135.944C126.929 117.512 136.597 97.4115 138.422 77.3657C151.828 81.2936 164.01 84.9741 177.158 88.8701L183.271 99.6252Z'
          fill='white'
        />
        <g opacity='0.5'>
          <path
            opacity='0.2'
            d='M164.286 139.81C155.731 137.266 136.129 131.26 127.655 128.7L128.114 127.193C136.582 129.729 156.135 135.726 164.67 138.287L164.286 139.81Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M165.845 136.105C157.378 133.568 137.826 127.567 129.392 125.032L129.906 123.531C138.313 126.06 157.827 132.054 166.295 134.59C166.137 135.097 165.991 135.601 165.845 136.105Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M167.021 132.302C158.591 129.773 139.105 123.78 130.724 121.262C130.908 120.738 131.086 120.264 131.276 119.763C139.645 122.29 159.115 128.28 167.531 130.794L167.021 132.302Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M168.331 128.516C156.045 124.796 144.339 121.238 132.126 117.546L132.722 116.057C144.921 119.758 156.604 123.35 168.877 127.022L168.331 128.516Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M169.74 124.745C157.504 121.036 145.837 117.447 133.635 113.785L134.241 112.298C146.435 115.998 158.091 119.585 170.321 123.253L169.74 124.745Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M171.212 120.976C159.002 117.273 147.357 113.689 135.155 110.026L135.757 108.533C147.957 112.234 159.601 115.818 171.808 119.487L171.212 120.976Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M172.198 117.105C160.036 113.412 148.359 109.821 136.142 106.15L136.733 104.66C148.954 108.365 160.609 111.952 172.805 115.618L172.198 117.105Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M173.725 113.365C161.531 109.665 149.859 106.075 137.608 102.402L138.177 100.908C150.436 104.621 162.113 108.212 174.327 111.871L173.725 113.365Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M174.693 109.473C162.477 105.768 150.773 102.172 138.472 98.4997C138.656 97.9758 138.829 97.5004 138.998 96.9958C151.316 100.722 163.049 104.285 175.278 107.982L174.693 109.473Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M169.776 103.793C157.523 100.08 152.112 98.4145 139.746 94.7061L140.221 93.2027C152.621 96.9125 158.055 98.6 170.333 102.301L169.776 103.793Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M170.63 99.9104C158.322 96.1864 152.835 94.5157 140.386 90.7956L140.8 89.2851C153.288 93.0077 158.789 94.6981 171.144 98.4096C170.976 98.9088 170.809 99.4081 170.63 99.9104Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M171.896 96.0983C159.508 92.3853 153.91 90.6745 141.384 86.9493C141.49 86.4429 141.597 85.9366 141.705 85.4248C154.307 89.166 159.917 90.8737 172.339 94.5881L171.896 96.0983Z'
            fill='#7C96E9'
          />
        </g>
        <path
          d='M183.267 99.647L175.079 97.333L177.15 88.9081L183.267 99.647Z'
          fill='#D3DCF8'
        />
      </g>
      <g filter='url(#filter1_dd_293_31439)'>
        <path
          d='M24.0338 48.472C28.5311 66.2925 34.4669 83.0686 41.5993 101.275C58.2354 96.4072 74.6738 91.5541 90.913 86.7151C83.3607 67.306 73.1803 46.1411 71.2595 25.0334C57.1428 29.1694 44.316 33.0449 30.4707 37.1473L24.0338 48.472Z'
          fill='white'
        />
        <g opacity='0.5'>
          <path
            opacity='0.2'
            d='M44.0243 90.7854C53.0334 88.107 73.6733 81.782 82.5958 79.0864L82.1127 77.4998C73.1968 80.1704 52.6079 86.4846 43.6206 89.1821L44.0243 90.7854Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M42.3829 86.8838C51.2989 84.2133 71.8866 77.8934 80.7673 75.2243L80.2262 73.6441C71.3739 76.3071 50.8259 82.6186 41.9099 85.2892C42.0759 85.8229 42.2294 86.3534 42.3829 86.8838Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M41.145 82.8795C50.0212 80.2173 70.5397 73.9061 79.3649 71.2547C79.1714 70.703 78.983 70.2037 78.7829 69.6771C69.9715 72.3374 49.4701 78.6451 40.6084 81.2923L41.145 82.8795Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M39.7653 78.8928C52.7019 74.9763 65.0286 71.2301 77.8881 67.3418L77.2608 65.7738C64.415 69.6711 52.1137 73.4534 39.1902 77.3197L39.7653 78.8928Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M38.2813 74.9226C51.1663 71.0171 63.4506 67.2383 76.2991 63.382L75.6611 61.8162C62.8209 65.7123 50.5474 69.4888 37.6704 73.3511L38.2813 74.9226Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M36.7318 70.954C49.589 67.0544 61.8506 63.2804 74.6992 59.4241L74.065 57.8516C61.2192 61.7489 48.9576 65.5228 36.1045 69.386L36.7318 70.954Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M35.6934 66.8779C48.4995 62.989 60.7952 59.2079 73.6595 55.3423L73.0379 53.7731C60.1694 57.6751 47.8964 61.4515 35.0547 65.3123L35.6934 66.8779Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M34.0862 62.9394C46.9263 59.0433 59.2163 55.2634 72.1159 51.3963L71.517 49.8223C58.6087 53.7327 46.3131 57.5138 33.452 61.3669L34.0862 62.9394Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M33.0662 58.8414C45.929 54.9406 58.253 51.1535 71.2061 47.2869C71.0126 46.7353 70.8299 46.2347 70.6525 45.7033C57.6819 49.627 45.3268 53.3791 32.4502 57.2711L33.0662 58.8414Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M38.2439 52.8604C51.1465 48.9511 56.8432 47.1973 69.8652 43.2924L69.3651 41.7094C56.3079 45.6158 50.5863 47.3926 37.6575 51.2897L38.2439 52.8604Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M37.345 48.7723C50.3043 44.8511 56.0828 43.0918 69.1911 39.1747L68.7546 37.5842C55.6054 41.504 49.8134 43.2839 36.804 47.192C36.9801 47.7177 37.1563 48.2434 37.345 48.7723Z'
            fill='#7C96E9'
          />
          <path
            opacity='0.2'
            d='M36.012 44.7583C49.0566 40.8487 54.9508 39.0472 68.1408 35.1247C68.0283 34.5915 67.9157 34.0583 67.8019 33.5195C54.5324 37.4588 48.6258 39.257 35.5459 43.1682L36.012 44.7583Z'
            fill='#7C96E9'
          />
        </g>
        <path
          d='M24.0386 48.4949L32.6603 46.0583L30.4791 37.1871L24.0386 48.4949Z'
          fill='#D3DCF8'
        />
      </g>
      <path
        d='M116.554 122.89H104.518V140.639L110.616 134.543C110.966 134.21 111.432 134.027 111.915 134.034C112.398 134.04 112.859 134.234 113.2 134.576C113.542 134.917 113.736 135.378 113.742 135.861C113.749 136.344 113.566 136.81 113.233 137.16L103.976 146.418C103.628 146.765 103.158 146.96 102.667 146.96C102.176 146.96 101.705 146.765 101.358 146.418L92.1 137.16C91.7674 136.81 91.5847 136.344 91.5909 135.861C91.5971 135.378 91.7916 134.917 92.133 134.576C92.4745 134.234 92.9358 134.04 93.4186 134.034C93.9014 134.027 94.3676 134.21 94.7176 134.543L100.815 140.639V122.89H88.7798C87.0617 122.892 85.4144 123.576 84.1994 124.791C82.9845 126.005 82.3012 127.653 82.2993 129.371V153.441C82.3012 155.16 82.9845 156.807 84.1994 158.022C85.4144 159.237 87.0617 159.92 88.7798 159.922H116.554C118.272 159.92 119.919 159.237 121.134 158.022C122.349 156.807 123.032 155.16 123.034 153.441V129.371C123.032 127.653 122.349 126.005 121.134 124.791C119.919 123.576 118.272 122.892 116.554 122.89Z'
        fill='#3B61DE'
      />
      <path
        d='M104.518 109.931C104.518 109.44 104.323 108.969 103.976 108.621C103.629 108.274 103.158 108.079 102.667 108.079C102.175 108.079 101.704 108.274 101.357 108.621C101.01 108.969 100.815 109.44 100.815 109.931V122.892H104.518V109.931Z'
        fill='#3B61DE'
      />
      <rect
        opacity='0.2'
        x='183.349'
        y='66.6885'
        width='9.45487'
        height='9.45487'
        transform='rotate(-28.7227 183.349 66.6885)'
        stroke='#3B61DE'
        strokeWidth='1.89097'
      />
      <rect
        opacity='0.2'
        x='65.8447'
        y='8.44312'
        width='9.45487'
        height='9.45487'
        transform='rotate(-42.9583 65.8447 8.44312)'
        stroke='#3B61DE'
        strokeWidth='1.89097'
      />
      <rect
        opacity='0.2'
        x='9.66113'
        y='122.933'
        width='9.45487'
        height='9.45487'
        transform='rotate(-88.1867 9.66113 122.933)'
        stroke='#3B61DE'
        strokeWidth='1.89097'
      />
      <rect
        opacity='0.2'
        x='1.81543'
        y='41.3391'
        width='9.45487'
        height='9.45487'
        transform='rotate(-88.1867 1.81543 41.3391)'
        stroke='#3B61DE'
        strokeWidth='1.89097'
      />
      <rect
        opacity='0.2'
        x='99.5156'
        y='61.5095'
        width='9.45487'
        height='9.45487'
        transform='rotate(-88.1867 99.5156 61.5095)'
        stroke='#3B61DE'
        strokeWidth='1.89097'
      />
      <defs>
        <filter
          id='filter0_dd_293_31439'
          x='113.96'
          y='75.093'
          width='76.896'
          height='84.9104'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='erode'
            in='SourceAlpha'
            result='effect1_dropShadow_293_31439'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_293_31439'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='erode'
            in='SourceAlpha'
            result='effect2_dropShadow_293_31439'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_293_31439'
            result='effect2_dropShadow_293_31439'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_293_31439'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_dd_293_31439'
          x='16.3115'
          y='22.6934'
          width='80.4399'
          height='88.8784'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='erode'
            in='SourceAlpha'
            result='effect1_dropShadow_293_31439'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_293_31439'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='erode'
            in='SourceAlpha'
            result='effect2_dropShadow_293_31439'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_293_31439'
            result='effect2_dropShadow_293_31439'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_293_31439'
            result='shape'
          />
        </filter>
      </defs>
    </>
  ),
});

export default DownloadCat;
