import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import { signIn, useSession } from 'next-auth/client';
import useTranslation from 'next-translate/useTranslation';
import { Box, Flex, Text, Button, useDisclosure } from '@chakra-ui/react';
import { MenuIcon, PersonCircleIcon } from 'chakra-ui-ionicons';

import { pages } from '@/utils/pages';

import { RemyLogoHorizontal } from '@/components/illustrations';
import SideMenuDrawerContainer from '@/containers/SideMenuDrawerContainer';

const Navbar = ({ router, isEmpty, showOffer, onOpenSideBar }) => {
  const { t } = useTranslation();
  const [session, loading] = useSession();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleLogin = () => {
    const currentUrl = window.location.href;

    history.pushState({}, '', currentUrl);

    signIn('auth0', {
      callbackUrl: router.asPath,
    });
  };

  const renderSignedOutUser = () => (
    <Button
      onClick={handleLogin}
      variant='link'
      fontSize='sm'
      fontWeight='400'
      color='white'
      _focus={{ boxShadow: 'none' }}
    >
      {t('common:login')}
    </Button>
  );

  const renderSignedInUser = () => (
    <Flex alignItems='center' cursor='pointer' onClick={onOpen}>
      <PersonCircleIcon w={5} h={5} />
      <Text
        display={{ base: 'none', md: 'inline-flex' }}
        fontSize='sm'
        fontWeight='400'
        color='white'
        ml={2}
      >
        {t('common:myAccount')}
      </Text>
    </Flex>
  );

  return (
    <Box
      position='fixed'
      bg='alpha.400'
      color='white'
      top='0'
      w='100%'
      zIndex={200}
    >
      {showOffer && (
        <Flex
          bg='white'
          color='alpha.400'
          h='45px'
          py={{ base: 0 }}
          px={{ base: 1, md: 4 }}
          align='center'
          justifyContent='center'
          margin='0 auto'
          position='relative'
        >
          <Text
            fontSize={{ base: '0.71rem', sm: 'md', md: 'xl' }}
            align='center'
          >
            {t('welcome:offer')}
          </Text>
        </Flex>
      )}
      <Flex
        h='56px'
        py={{ base: 2 }}
        px={{ base: 6, md: 4 }}
        align='center'
        maxWidth={{ md: '7xl' }}
        margin='0 auto'
        position='relative'
      >
        {onOpenSideBar && (
          <Flex
            display={{ base: 'block', md: 'none' }}
            cursor='pointer'
            onClick={onOpenSideBar}
          >
            <MenuIcon />
          </Flex>
        )}
        <Flex flex={1} justify={{ base: 'center', md: 'start' }}>
          <RemyLogoHorizontal
            onClick={() => router.push(pages.welcomePath)}
            display='inline-flex'
            cursor='pointer'
            mt={{ base: 2, md: 0 }}
            w={94}
            h={30}
          />
        </Flex>
        {!isEmpty && !loading && (
          <Flex
            position={{ base: 'absolute', md: 'relative' }}
            right={0}
            mr={{ base: 6, md: 0 }}
          >
            {session ? renderSignedInUser() : renderSignedOutUser()}
          </Flex>
        )}
      </Flex>
      <SideMenuDrawerContainer isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

Navbar.deafultProps = {
  isEmpty: false,
  router: {},
};

Navbar.propTypes = {
  router: PropTypes.shape({
    asPath: PropTypes.string,
  }),
};

export default withRouter(Navbar);
