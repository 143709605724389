import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { Text, Flex } from '@chakra-ui/react';
import { BanIcon } from 'chakra-ui-ionicons';

const Error = ({ clearError, clearText, showIcon, ...styles }) => {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      color='epsilon.500'
      data-testid='error-message'
      {...styles}
    >
      {showIcon && <BanIcon w={12} h={12} />}
      <Text fontSize='lg' mt={6}>
        {t('common:error')}
      </Text>
      {clearText && (
        <Text
          onClick={clearError}
          fontSize='sm'
          mt={2}
          color='gray.600'
          cursor='pointer'
        >
          {clearText}
        </Text>
      )}
    </Flex>
  );
};

Error.defaultProps = {
  styles: {},
  clearError: () => {},
  clearText: 'Try again',
  showIcon: true,
};

Error.propTypes = {
  styles: PropTypes.shape({}),
  clearError: PropTypes.func,
  clearText: PropTypes.string,
  showIcon: PropTypes.bool,
};

export default Error;
