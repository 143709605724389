import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import { StarIcon } from 'chakra-ui-ionicons';

const Rate = ({ count, rating, color, onRating }) => {
  const [hoverRating, setHoverRating] = useState(0);

  const starRating = useMemo(() => {
    const getColor = index => {
      const { filled, unfilled } = color;

      if (hoverRating >= index) {
        return filled;
      } else if (!hoverRating && rating >= index) {
        return filled;
      }

      return unfilled;
    };

    return Array(count)
      .fill(0)
      .map((_, i) => i + 1)
      .map(idx => (
        <StarIcon
          data-testid='star-icon-review'
          key={idx}
          w={{ base: '32.04px', md: '33.98px' }}
          h={{ base: '32.31px', md: '34.27px' }}
          color={getColor(idx)}
          cursor='pointer'
          onClick={() => onRating(idx)}
          onMouseEnter={() => setHoverRating(idx)}
          onMouseLeave={() => setHoverRating(0)}
        />
      ));
  }, [count, color, hoverRating, rating, onRating]);

  return <Box>{starRating}</Box>;
};

Rate.defaultProps = {
  count: 5,
  rating: 0,
  color: {
    filled: 'zeta.500',
    unfilled: 'zeta.100',
  },
};

Rate.propTypes = {
  count: PropTypes.number,
  rating: PropTypes.number,
  onChange: PropTypes.func,
  color: PropTypes.shape({
    filled: PropTypes.string,
    unfilled: PropTypes.string,
  }),
};

export default Rate;
