import PropTypes from 'prop-types';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ArrowBackIcon } from 'chakra-ui-ionicons';

const DrawerHeader = ({ title, onBack, ...styles }) => {
  return (
    <Box {...styles}>
      <Flex
        position='relative'
        alignItems='center'
        justifyContent={title && 'center'}
        py={4}
        px={6}
      >
        <ArrowBackIcon
          w={6}
          h={6}
          position={title && 'absolute'}
          left={6}
          onClick={onBack}
          cursor='pointer'
        />
        <Text fontSize={'lg'} fontWeight='bold'>
          {title}
        </Text>
      </Flex>
    </Box>
  );
};

DrawerHeader.defaultProps = {
  styles: {},
  title: '',
};

DrawerHeader.propTypes = {
  title: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  styles: PropTypes.shape({}),
};

export default DrawerHeader;
