const colors = {
  black: '#000000',
  white: '#FFFFFF',
  gray: {
    50: '#F9FAFB',
    100: '#E2E5E9',
    200: '#C4CCD4',
    300: '#A7B2BE',
    400: '#8A99A8',
    500: '#6C8093',
    600: '#576675',
    700: '#414D58',
    800: '#2B333B',
    900: '#161A1D',
  },
  alpha: {
    50: '#F6F8FE',
    100: '#D3DCF8',
    200: '#A8B9F0',
    300: '#7C96E9',
    400: '#5172E1',
    500: '#3B61DE',
    600: '#1E3FAE',
    700: '#163083',
    800: '#0F2057',
    900: '#07102C',
  },
  beta: {
    50: '#FAF7FD',
    100: '#E5D6F5',
    200: '#CCADEB',
    300: '#B285E0',
    400: '#995CD6',
    500: '#8033CC',
    600: '#6629A3',
    700: '#4D1F7A',
    800: '#331452',
    900: '#1A0A29',
  },
  gamma: {
    50: '#F7FCF9',
    100: '#D9F2E1',
    200: '#B2E5C3',
    300: '#8CD9A6',
    400: '#66CC88',
    500: '#40BF6A',
    600: '#339955',
    700: '#267340',
    800: '#1A4D2B',
    900: '#0D2615',
  },
  delta: {
    50: '#F7FAFD',
    100: '#D6E4F5',
    200: '#ADCAEB',
    300: '#85AFE0',
    400: '#5C95D6',
    500: '#337ACC',
    600: '#2962A3',
    700: '#1F497A',
    800: '#143152',
    900: '#0A1829',
  },
  epsilon: {
    50: '#FDF7F7',
    100: '#F5D6D6',
    200: '#EBADAD',
    300: '#E08585',
    400: '#D65C5C',
    500: '#CC3333',
    600: '#A32929',
    700: '#7A1F1F',
    800: '#521414',
    900: '#290A0A',
  },
  zeta: {
    50: '#FFF5E0',
    100: '#FFEBC2',
    200: '#FFE2A8',
    300: '#FFD88A',
    400: '#FFCE6B',
    500: '#FFC34D',
    600: '#FFB114',
    700: '#E09600',
    800: '#A87000',
    900: '#704B00',
  },
  gradientAlpha: {
    0: '#E4EBFF',
    100: '#F6F8FE',
  },
  customInput: { error: '#E53E3E', focus: '#3182ce' },
};

export default colors;
