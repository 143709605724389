import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { Button, Flex, Text } from '@chakra-ui/react';

import { BankAdded as BankAddedIllustration } from '@/components/illustrations';

const BankAdded = ({ onBack }) => {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      pt={{ base: 12, md: 20 }}
      px={{ base: 6, md: 8 }}
      textAlign='center'
    >
      <BankAddedIllustration h={254} w={204} />
      <Text
        fontSize={{ base: 'lg', md: '2xl' }}
        fontWeight='bold'
        color='gray.600'
        mt={10}
      >
        {t('payment:bank.bankAddedTitle')}
      </Text>
      <Text fontSize={{ md: 'md' }} color='gray.500' mt={8}>
        {t('payment:bank.bankAddedDesc')}
      </Text>
      <Button
        onClick={onBack}
        color='white'
        bg='alpha.500'
        mt={8}
        w='full'
        _focus={{ boxShadow: 'none' }}
      >
        {t('common:ok')}
      </Button>
    </Flex>
  );
};

BankAdded.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default BankAdded;
