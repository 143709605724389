import PropTypes from 'prop-types';
import { Flex, Spinner } from '@chakra-ui/react';

const LoadingSpinner = ({ size, ...styles }) => {
  return (
    <Flex
      data-testid='loading-spinner'
      justifyContent='center'
      alignItems='center'
      p={2}
      mt={12}
      color='alpha.100'
      {...styles}
    >
      <Spinner size={size} />
    </Flex>
  );
};

LoadingSpinner.defaultProps = {
  size: 'md',
  styles: {},
};

LoadingSpinner.propTypes = {
  size: PropTypes.string,
  styles: PropTypes.shape({}),
};

export default LoadingSpinner;
