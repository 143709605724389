import PropTypes from 'prop-types';
import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react';

import { Outline } from '@/components/Outline';

const Outlines = ({ outlines, outlinesTotalCount }) => (
  <Box w={{ base: 'full', md: '3xl' }}>
    <Text>{outlinesTotalCount} Outlines</Text>
    <Grid
      mt={4}
      templateColumns={{
        base: '1fr',
        md: 'repeat(2, 1fr)',
        lg: 'repeat(3, 1fr)',
      }}
      gap={4}
      rowGap={{ md: 10 }}
    >
      {outlines.map(outline => (
        <GridItem key={outline.id}>
          <Outline outline={outline} />
        </GridItem>
      ))}
    </Grid>
  </Box>
);

Outlines.propTypes = {
  outlines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      table_of_contents: PropTypes.string,
      outline_url: PropTypes.string,
      year: PropTypes.string,
      course: PropTypes.object,
      count_rating: PropTypes.number,
      average_rating: PropTypes.number,
      school: PropTypes.object,
      professor: PropTypes.object,
      user: PropTypes.object,
    }),
  ).isRequired,
};

export default Outlines;
