import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { Text, Flex } from '@chakra-ui/react';
import { CheckmarkCircleOutlineIcon } from 'chakra-ui-ionicons';

const Success = ({
  clearSuccess,
  clearText,
  showIcon,
  title,
  message,
  ...styles
}) => {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      color='gamma.500'
      data-testid='success-message'
      {...styles}
    >
      {showIcon && <CheckmarkCircleOutlineIcon w={12} h={12} />}
      <Text fontSize='lg' mt={6}>
        {title}
      </Text>
      {message && (
        <Text align='center' fontSize='sm' mt={2} mb={2} color='gray.600'>
          {message}
        </Text>
      )}
      {clearText && (
        <Text
          onClick={clearSuccess}
          fontSize='sm'
          mt={2}
          color='gray.400'
          cursor='pointer'
        >
          {clearText}
        </Text>
      )}
    </Flex>
  );
};

Success.defaultProps = {
  styles: {},
  clearSuccess: () => {},
  clearText: 'Try again',
  showIcon: true,
  title: 'Submited successfully!',
  message: '',
};

Success.propTypes = {
  styles: PropTypes.shape({}),
  clearSuccess: PropTypes.func,
  clearText: PropTypes.string,
  showIcon: PropTypes.bool,
};

export default Success;
