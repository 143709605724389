import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { signOut } from 'next-auth/client';

import { Drawer } from '@/components/Drawer';
import SideMenu from '@/components/SideMenu';
import { navigationIds } from '@/components/SideMenu/navigationIds';

import PaymentMethod from '@/components/PaymentMethod';
import Balance from '@/components/Balance';
import MyOutlinesContainer from '@/containers/MyOutlines';
import UploadedOutlinesContainer from '@/containers/UploadedOutlinesContainer';
import SupportContainer from '@/containers/SupportContainer';
import FAQContainer from '@/containers/FAQContainer';
import MakeSuggestion from '@/components/MakeSuggestion/MakeSuggestion';

import { pages } from '@/utils/pages';

const SideMenuDrawerContainer = ({ isOpen, onClose }) => {
  const router = useRouter();

  const [menuOption, setMenuOption] = useState(null);

  const handleOnClose = () => {
    setMenuOption(null);
    onClose();
  };

  const onBack = () => setMenuOption(null);

  let sideMenuContent = (
    <SideMenu onClose={handleOnClose} onSelectOption={setMenuOption} />
  );

  switch (menuOption) {
    case navigationIds.adminConsole:
      setMenuOption(null);
      handleOnClose();
      router.push(pages.adminOutlinesPath);
      break;

    case navigationIds.purchasedOutlines:
      sideMenuContent = <MyOutlinesContainer onBack={onBack} />;
      break;

    case navigationIds.paymentMethod:
      sideMenuContent = <PaymentMethod onBack={onBack} />;
      break;

    case navigationIds.makeSuggestion:
      sideMenuContent = <MakeSuggestion onBack={onBack} />;
      break;

    case navigationIds.myBalance:
      sideMenuContent = <Balance onBack={onBack} />;
      break;

    case navigationIds.uploadedOutlines:
      sideMenuContent = (
        <UploadedOutlinesContainer onBack={onBack} onClose={handleOnClose} />
      );
      break;

    case navigationIds.support:
      sideMenuContent = <SupportContainer onBack={onBack} />;
      break;

    case navigationIds.faq:
      sideMenuContent = <FAQContainer onBack={onBack} />;
      break;

    case navigationIds.tos:
      setMenuOption(null);
      handleOnClose();
      router.push({ pathname: pages.tosPath, query: { complete: true } });
      break;

    case navigationIds.logout:
      signOut({ callbackUrl: pages.welcomePath });
      break;
  }

  return (
    <Drawer isOpen={isOpen} onClose={handleOnClose}>
      {sideMenuContent}
    </Drawer>
  );
};

SideMenuDrawerContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SideMenuDrawerContainer;
