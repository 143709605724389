import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useTheme,
} from '@chakra-ui/react';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';

const StripeCard = ({ id, label, type, isValid, ...styles }) => {
  const {
    colors: { gray },
  } = useTheme();

  const [isFocus, setIsFocus] = useState(null);
  const [error, setError] = useState(null);

  let cardElement;

  const cardStyles = {
    base: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '14px',
      '::placeholder': {
        color: gray[300],
      },
    },
  };

  const onChange = e => {
    if (e.complete) {
      isValid(true);
    }

    if (e.error) {
      setError(e.error.message);
      isValid(false);

      return;
    }

    setError(null);
  };

  const baseCardProps = {
    options: { style: cardStyles },
    onChange,
    onFocus: () => setIsFocus(true),
    onBlur: () => setIsFocus(false),
  };

  switch (type) {
    case 'card':
      cardElement = <CardNumberElement {...baseCardProps} />;
      break;
    case 'cvc':
      cardElement = <CardCvcElement {...baseCardProps} />;
      break;
    case 'expiry':
      cardElement = <CardExpiryElement {...baseCardProps} />;
      break;

    default:
      break;
  }

  const outlineColor = () => {
    let color = 'gray.200';

    if (error) color = 'customInput.error';
    if (isFocus) color = 'customInput.focus';

    return color;
  };

  return (
    <FormControl isInvalid={error} id={id} mb={4}>
      <FormLabel
        htmlFor={id}
        fontSize='xs'
        color={error ? 'customInput.error' : 'gray.800'}
      >
        {label}
      </FormLabel>
      <Box
        p={3}
        bg='gray.50'
        borderRadius='md'
        outline={isFocus || error ? 'solid 2px' : 'solid 1px'}
        outlineColor={outlineColor()}
        transition='outline-color ease-in-out 200ms'
        _hover={
          !isFocus &&
          !error && {
            outlineColor: 'gray.300',
          }
        }
        {...styles}
      >
        {cardElement}
      </Box>
      <FormErrorMessage fontSize='xs'>{error}</FormErrorMessage>
    </FormControl>
  );
};

StripeCard.defaultProps = {
  styles: {},
  label: '',
  type: 'text',
};

StripeCard.propTypes = {
  id: PropTypes.string.isRequired,
  isValid: PropTypes.func.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  styles: PropTypes.shape({}),
};

export default StripeCard;
