import React, { useContext } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Box, Flex } from '@chakra-ui/react';
import { DrawerHeader, DrawerBody } from '@/components/Drawer';
import MakeReview from '@/components/ReviewOutline/MakeReview';
import MessageComponent from '@/components/MessageComponent';
import { Cat } from '@/components/illustrations';

import { MyOutlineContext } from '../../hooks/MyOutlineContext';
import { myOutlinesNavigationIds } from '@/containers/MyOutlines/myOutlinesNavitagionIds';

const ReviewOutline = () => {
  const { t } = useTranslation();
  const { page, dispatch: dispatchContext } = useContext(MyOutlineContext);

  const { data } = page;

  const onBack = () => {
    dispatchContext({
      type: myOutlinesNavigationIds.purchasedOutlines,
    });
  };

  return (
    <Flex flexDirection='column' h='full' data-testid='make-review-container'>
      <DrawerHeader onBack={onBack} title={t('makeReview:title')} />
      <DrawerBody pt={0} h='full'>
        <Box
          bg='white'
          pt={2}
          mx={{ base: 6, md: 8 }}
          my={{ md: 4 }}
          borderRadius='xl'
        >
          <Flex flexDirection='column'>
            <Box py={4} mb={4}>
              <MakeReview
                outline={data}
                renderSuccessComponent={() => (
                  <Box
                    display='flex'
                    flexDirection='column'
                    pt={{ base: 12 }}
                    w='full'
                  >
                    <MessageComponent
                      title={t('makeReview:successfulMessage.title')}
                      message={t('makeReview:successfulMessage.message')}
                      onClick={onBack}
                      buttonTitle='done'
                      renderIllustration={() => (
                        <Cat
                          w={{ base: 230, md: 230 }}
                          h={{ base: 230, md: 414 }}
                        />
                      )}
                    />
                  </Box>
                )}
              />
            </Box>
          </Flex>
        </Box>
      </DrawerBody>
    </Flex>
  );
};

export default ReviewOutline;
