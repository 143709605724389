import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { Box, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getPaymentMethods,
  getPaymentMethodsList,
  selectPaymentMethod,
  getIsLoadingPaymentsMethods,
  getBankAccount,
  getBankAccountsInfo,
  setDefaultBankAccount,
} from '@/stores/payment';
import { getUser } from '@/stores/user';

import { PAYMENT_VIEW } from '@/utils/constants';

import { DrawerHeader, DrawerBody } from '@/components/Drawer';
import LoadingSpinner from '@/components/LoadingSpinner';
import PaymentMethodCard from './PaymentMethodCard';
import AddButton from './AddButton';
import BankItem from './BankItem';
import { Stripe } from '../illustrations';

const PaymentMethod = ({ onBack, onViewChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { ADD_PAYMENT_METHOD, ADD_BANK } = PAYMENT_VIEW;

  const paymentMethodsList = useSelector(state => getPaymentMethodsList(state));
  const isLoading = useSelector(state => getIsLoadingPaymentsMethods(state));
  const bankAccounts = useSelector(state => getBankAccountsInfo(state));
  const {
    stripe_customer_id,
    stripe_account_id,
    id: userId,
  } = useSelector(state => getUser(state));

  useEffect(() => {
    if (stripe_customer_id) {
      dispatch(getPaymentMethods({ id: userId }));
    }
    if (stripe_account_id) {
      dispatch(getBankAccount({ userId }));
    }
  }, [dispatch, stripe_customer_id, stripe_account_id, userId]);

  const addInfo = isBank => {
    return (
      <Box fontSize='sm' my={4}>
        <Text fontWeight='bold'>
          {isBank ? t('payment:bank.add') : t('payment:newCard')}
        </Text>
        <Text>
          {isBank ? t('payment:bank.info') : t('payment:addPayment.info')}
        </Text>
      </Box>
    );
  };

  const renderPaymentMethod = () => {
    return (
      <>
        {addInfo(false)}
        {paymentMethodsList &&
          paymentMethodsList.map(paymentMethod => (
            <PaymentMethodCard
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              onSelect={() => dispatch(selectPaymentMethod(paymentMethod.id))}
              onBack={onBack}
              countPaymentMethod={paymentMethodsList.length}
            />
          ))}
        <AddButton onClick={() => onViewChange(ADD_PAYMENT_METHOD)} />
      </>
    );
  };

  const renderBankAccount = () => {
    if (!stripe_account_id) return;

    return (
      <>
        {addInfo(true)}
        {bankAccounts &&
          bankAccounts.map((bankAccount, index) => (
            <BankItem
              key={index}
              bankAccount={bankAccount}
              onSelect={() =>
                dispatch(
                  setDefaultBankAccount({
                    userId,
                    bankAccountId: bankAccount.id,
                  }),
                )
              }
            />
          ))}
        <AddButton onClick={() => onViewChange(ADD_BANK)} isBank />
      </>
    );
  };

  return (
    <Box data-testid='select-payment-method'>
      <DrawerHeader onBack={onBack} title={t('payment:method')} />
      <DrawerBody px={{ base: 6, md: 8 }} mt={{ base: 6, md: 0 }}>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {renderBankAccount()}
            {renderPaymentMethod()}

            <Box fontSize='sm' textAlign='center' mt={10}>
              {t('payment:powered_by_stripe')} <Stripe w={10} h={10} />
            </Box>
          </>
        )}
      </DrawerBody>
    </Box>
  );
};

PaymentMethod.propTypes = {
  onBack: PropTypes.func.isRequired,
  onViewChange: PropTypes.func.isRequired,
};

export default PaymentMethod;
