import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { Button, Flex, Text } from '@chakra-ui/react';

import { pages } from '@/utils/pages';

const EmptyHistory = () => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <Flex
      flexDirection='column'
      mt={10}
      alignItems='center'
      data-testid='balance-history-empty'
    >
      <Text fontWeight='bold' color='gray.400' mb={2}>
        {t('balance:history.nothing')}
      </Text>
      <Button
        onClick={() => router.push(pages.welcomePath)}
        w='full'
        bg='alpha.500'
        color='white'
        _focus={{ boxShadow: 'none' }}
        my={4}
      >
        {t('balance:history.buy')}
      </Button>
      <Button
        onClick={() => router.push(pages.uploadOutline)}
        w='full'
        variant='outline'
        color='alpha.500'
        border='2px'
        borderColor='alpha.500'
        _focus={{ boxShadow: 'none' }}
      >
        {t('balance:history.sell')}
      </Button>
    </Flex>
  );
};

export default EmptyHistory;
