import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from '@chakra-ui/react';

import { getDownloaded, clearDownload, getRefreshTab } from '@/stores/outlines';
import { getPurchasedOutlines } from '@/stores/purchasedOutlines';
import { getUser } from '@/stores/user';

import { DrawerHeader, DrawerBody } from '@/components/Drawer';
import History from './History';

const PurchasedOutlines = ({ onBack }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id: userId } = useSelector(state => getUser(state));
  const downloaded = useSelector(state => getDownloaded(state));
  const refreshTab = useSelector(state => getRefreshTab(state));

  const handleGetPurchasedOutlines = useCallback(() => {
    dispatch(getPurchasedOutlines({ userId }));
  }, [dispatch, userId]);

  useEffect(() => {
    handleGetPurchasedOutlines();
  }, [handleGetPurchasedOutlines]);

  useEffect(() => {
    if (downloaded && refreshTab) {
      handleGetPurchasedOutlines();
    }
  }, [downloaded, refreshTab, handleGetPurchasedOutlines]);

  useEffect(() => {
    return () => {
      dispatch(clearDownload());
    };
  }, [dispatch]);

  return (
    <Flex
      flexDirection='column'
      h='full'
      data-testid='purchased-outline-container'
    >
      <DrawerHeader onBack={onBack} title={t('purchasedOutlines:title')} />
      <DrawerBody pt={0} h='full'>
        <Box
          bg='white'
          pt={2}
          mx={{ base: 6, md: 8 }}
          my={{ md: 4 }}
          borderRadius='xl'
        >
          <History />
        </Box>
      </DrawerBody>
    </Flex>
  );
};

PurchasedOutlines.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default PurchasedOutlines;
