import { useState } from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { Box, Divider, Flex, Text, Spinner, Button } from '@chakra-ui/react';
import { CardIcon, CheckmarkCircleIcon, TrashIcon } from 'chakra-ui-ionicons';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@/components/Modal';

import {
  getSelectedPaymentMethod,
  detachPaymentMethod,
  getIsRemoving,
} from '@/stores/payment';
import { getUser } from '@/stores/user';

const PaymentMethodCard = ({
  paymentMethod,
  onSelect,
  onBack,
  countPaymentMethod,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id: userId } = useSelector(state => getUser(state));
  const isRemoving = useSelector(state => getIsRemoving(state));
  const selectedPaymentMethod = useSelector(state =>
    getSelectedPaymentMethod(state),
  );

  const [paymentMethodToRemove, setPaymentMethodToRemove] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const {
    id,
    card: { brand, last4, expMonth, expYear },
  } = paymentMethod;

  const removePaymentMethod = handleOnClose => {
    setPaymentMethodToRemove(id);
    dispatch(
      detachPaymentMethod({
        userId,
        paymentMethodId: id,
      }),
    );
    setModalIsOpen(false);
    handleOnClose();
  };

  const renderIcons = () => {
    if (isRemoving && paymentMethodToRemove === id)
      return <Spinner size='sm' color='alpha.100' />;

    if (selectedPaymentMethod === id && countPaymentMethod > 1)
      return <CheckmarkCircleIcon h={6} w={6} color='gamma.500' />;

    return (
      <TrashIcon
        w={6}
        h={6}
        cursor='pointer'
        color='epsilon.500'
        onClick={() => setModalIsOpen(true)}
        data-testid='remove-payment-method'
      />
    );
  };

  return (
    <Box data-testid='add-new-payment-method-card'>
      <Flex py={4} alignItems='flex-start'>
        <Flex
          cursor='pointer'
          w='full'
          data-testid='card-paymentmethod'
          onClick={() => {
            onSelect();
            onBack();
          }}
        >
          <CardIcon h={6} w={6} color='gray.900' cursor='pointer' mr={4} />
          <Box w='full'>
            <Text fontSize={{ base: 'md', md: 'sm' }} color='gray.900'>
              {`${brand.toUpperCase()} ${t(
                'payment:paymentMethod.ending',
              )} ${last4}`}
            </Text>
            <Text fontSize={{ base: 'md', md: 'sm' }} color='gray.400'>
              {`${t('payment:paymentMethod.expires')} ${expMonth}/${expYear}`}
            </Text>
          </Box>
        </Flex>
        <Flex alignSelf='center'>{renderIcons()}</Flex>
      </Flex>
      <Divider borderColor='alpha.200' />
      {modalIsOpen && (
        <Modal
          open={modalIsOpen}
          onCloseModal={() => setModalIsOpen(false)}
          title={t('payment:removePaymentMethod.modalTitle')}
          stylescancelbutton={{ bg: 'white', color: 'alpha.500' }}
          confirmButton={handleOnClose => (
            <Button
              data-testid='confirm-button'
              bg='epsilon.500'
              color='white'
              mr={3}
              onClick={() => removePaymentMethod(handleOnClose)}
            >
              {t('common:remove')}
            </Button>
          )}
        >
          <Text fontSize={{ base: 'sm' }} fontWeight={400} color='gray.500'>
            {t('payment:removePaymentMethod.modalMessage')}
          </Text>
        </Modal>
      )}
    </Box>
  );
};

PaymentMethodCard.propTypes = {
  paymentMethod: PropTypes.shape({}).isRequired,
  onSelect: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  countPaymentMethod: PropTypes.number.isRequired,
};

export default PaymentMethodCard;
