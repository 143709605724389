import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from '@chakra-ui/react';

const RichTextForm = ({ children, label }) => {
  return (
    <Box data-testid='richtext-container'>
      <Text
        color='gray.800'
        fontSize={{ base: 'xs' }}
        fontWeight={{ base: 'md' }}
        textAlign='start'
        wordBreak='break-word'
        mb={3}
      >
        {label}
      </Text>
      <Box
        p={3}
        bg='gray.50'
        borderRadius='md'
        outline='solid 1px'
        outlineColor='gray.200'
        transition='outline-color ease-in-out 200ms'
      >
        {children}
      </Box>
    </Box>
  );
};

RichTextForm.defaultProps = {
  label: '',
};

RichTextForm.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
};

export default RichTextForm;
