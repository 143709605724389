const typography = {
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
  },
  fontWeights: {
    normal: 400,
    bold: 600,
  },
};

export default typography;
