import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const validatePurchaseDate = purchase_date => {
  const { DAYS_LIMITS_FOR_DOWNLOAD } = publicRuntimeConfig;

  const currentDate = new Date();
  const limitDate = new Date(purchase_date);

  limitDate.setDate(limitDate.getDate() + Number(DAYS_LIMITS_FOR_DOWNLOAD));

  return limitDate > currentDate;
};

export default validatePurchaseDate;
