import useTranslation from 'next-translate/useTranslation';
import { Box } from '@chakra-ui/react';

import { DrawerHeader, DrawerBody } from '@/components/Drawer';
import FAQ from '@/components/FAQ';

const FAQContainer = ({ onBack }) => {
  const { t } = useTranslation();

  return (
    <Box data-testid='FAQ'>
      <DrawerHeader onBack={onBack} title={t('faq:title')} />
      <DrawerBody pt={0} h='full'>
        <FAQ />
      </DrawerBody>
    </Box>
  );
};

export default FAQContainer;
