import React from 'react';
import { createIcon } from '@chakra-ui/react';

const SlideUpload = createIcon({
  displayName: 'SlideUpload',
  viewBox: '0 0 372 229',
  defaultProps: {
    w: '372px',
    h: '229px',
    fill: 'transparent',
  },
  path: (
    <>
      <path d="M330.679 171.954H112.762v57.047h217.917a28.555 28.555 0 0 0 19.519-8.709 28.532 28.532 0 0 0 0-39.633 28.534 28.534 0 0 0-19.519-8.705Z" fill="#3B61DE"/>
      <path opacity=".2" d="M330.679 171.954H112.762v57.047h217.917a28.555 28.555 0 0 0 19.519-8.709 28.532 28.532 0 0 0 0-39.633 28.534 28.534 0 0 0-19.519-8.705Z" fill="#fff"/>
      <path d="M130.921 229.001H20.629v-8.15h34.17v-40.418h-34.17v-8.479h110.292a28.524 28.524 0 0 1 19.519 8.705 28.534 28.534 0 0 1 0 39.633 28.546 28.546 0 0 1-19.519 8.709Z" fill="#3B61DE"/>
      <path d="M28.782 220.522h101.85a19.987 19.987 0 0 0 7.682-1.509 19.92 19.92 0 0 0 6.512-4.349 19.918 19.918 0 0 0 4.338-6.52 19.914 19.914 0 0 0 1.495-7.685 20.024 20.024 0 0 0-20.027-20.026H28.782v40.089Z" fill="#fff"/>
      <path d="M363.569 128.098H181.357v43.854h182.212a7.327 7.327 0 0 0 5.336-2.193 7.612 7.612 0 0 0 2.156-5.299v-28.834a7.314 7.314 0 0 0-2.127-5.375 7.304 7.304 0 0 0-5.365-2.153Z" fill="#3B61DE"/>
      <path opacity=".2" d="M260.031 142.831h62.637a3.144 3.144 0 0 1 3.143 3.142v9.685a3.144 3.144 0 0 1-3.143 3.106h-62.637a3.14 3.14 0 0 1-1.203-.227 3.132 3.132 0 0 1-1.703-1.684 3.118 3.118 0 0 1-.237-1.195v-9.685a3.074 3.074 0 0 1 .91-2.233 3.046 3.046 0 0 1 1.023-.679 3.037 3.037 0 0 1 1.21-.23Z" fill="#D3DCF8"/>
      <path d="M110.865 171.952h99.22a7.326 7.326 0 0 0 5.335-2.193 7.608 7.608 0 0 0 2.156-5.299v-28.834a7.302 7.302 0 0 0-2.127-5.375 7.284 7.284 0 0 0-5.364-2.153h-99.037l-.183 43.854Z" fill="#3B61DE"/>
      <path opacity=".2" d="M110.865 171.952h99.22a7.326 7.326 0 0 0 5.335-2.193 7.608 7.608 0 0 0 2.156-5.299v-28.834a7.302 7.302 0 0 0-2.127-5.375 7.284 7.284 0 0 0-5.364-2.153h-99.037l-.183 43.854Z" fill="#D3DCF8"/>
      <path d="M110.865 165.379h96.223a3.67 3.67 0 0 0 2.584-1.071 3.667 3.667 0 0 0 1.07-2.584V138.08a3.883 3.883 0 0 0-1.06-2.631 3.593 3.593 0 0 0-1.224-.822 3.561 3.561 0 0 0-1.443-.275h-96.15v31.027Z" fill="#fff"/>
      <path d="M54.799 128.099h215.249V84.245H54.798c-1.359-.007-2.707.26-3.965.775a10.31 10.31 0 0 0-6.377 9.567v23.499c.03 1.33.322 2.642.863 3.855a10.034 10.034 0 0 0 2.28 3.234 10.393 10.393 0 0 0 7.31 2.924" fill="#3B61DE"/>
      <path opacity=".3" d="M54.799 128.099h215.249V84.245H54.798c-1.359-.007-2.707.26-3.965.775a10.31 10.31 0 0 0-6.377 9.567v23.499c.03 1.33.322 2.642.863 3.855a10.034 10.034 0 0 0 2.28 3.234 10.393 10.393 0 0 0 7.31 2.924" fill="#fff"/>
      <path d="M343.688 90.823h-99.036a3.652 3.652 0 0 0-3.655 3.654v23.499c0 .493.103.98.296 1.433.198.453.479.858.837 1.198.344.351.753.629 1.203.815.453.19.939.285 1.429.282h99.036l-.11-30.88Z" fill="#fff"/>
      <path d="M241.842 84.25h109.05v6.577H244.656a3.556 3.556 0 0 0-1.389 0c-.457.16-.877.413-1.235.738a3.7 3.7 0 0 0-.848 1.163c-.19.438-.288.91-.292 1.388v23.828c.004.475.102.946.292 1.388.19.446.475.844.841 1.17.362.31.785.544 1.242.694.442.168.918.23 1.389.183h106.236v6.578h-109.05a7.323 7.323 0 0 1-5.368-2.153 7.316 7.316 0 0 1-2.124-5.375V91.595a7.272 7.272 0 0 1 2.193-5.299 7.313 7.313 0 0 1 2.423-1.641 7.312 7.312 0 0 1 2.876-.552" fill="#3B61DE"/>
      <path opacity=".2" d="M241.842 84.25h109.05v6.577H244.656a3.556 3.556 0 0 0-1.389 0c-.457.16-.877.413-1.235.738a3.7 3.7 0 0 0-.848 1.163c-.19.438-.288.91-.292 1.388v23.828c.004.475.102.946.292 1.388.19.446.475.844.841 1.17.362.31.785.544 1.242.694.442.168.918.23 1.389.183h106.236v6.578h-109.05a7.323 7.323 0 0 1-5.368-2.153 7.316 7.316 0 0 1-2.124-5.375V91.595a7.272 7.272 0 0 1 2.193-5.299 7.313 7.313 0 0 1 2.423-1.641 7.312 7.312 0 0 1 2.876-.552" fill="#D3DCF8"/>
      <path d="M11.578 148.938S-2.495 170.074.39 173.738c2.885 3.664 14.073.446 15.48-.211 1.408-.658 7.412-14.631 7.412-15.289 0-.657-11.704-9.3-11.704-9.3Z" fill="#868A91"/>
      <path opacity=".3" d="M11.583 148.938s-7.975 8.831-5.137 12.541c2.838 3.711 5.957 12.423 7.435 12.423 4.95 0 9.382-15.006 9.382-15.664 0-.657-11.68-9.3-11.68-9.3Z" fill="#000"/>
      <path d="M15.877 173.527s-7.435 47.227 17.45 43.681c24.887-3.547 9.172-48.918.868-61.6 0 0 13.088-25.833 13.534-30.53a54.041 54.041 0 0 0 26.622 10.474c15.269 1.315 37.528-4.932 51.484-46.969 0 0 82.468 5.097 90.747 4.204a17.228 17.228 0 0 0 3.495 16.157c6.544 8.314 23.995 38.891 26.622 45.443 2.627 6.552-9.171 33.653-2.627 38.021 6.544 4.368 28.38-3.945 29.671-26.443 1.29-22.499-22.682-60.496-22.236-66.18.446-5.683 15.363-13.41 15.363-13.41l83.688-1.01s18.764-13.291 10.907-25.433c-7.858-12.141-14.073-32.22-29.671-32.667l-2.838-.117V.47s-17.451 11.06-20.078 18.646A129.44 129.44 0 0 0 296.94 0s-13.393 5.824-23.291 30.53a78.32 78.32 0 0 1-19.28-4.697c-9.945-3.922-19.843 4.368-32.345 1.761-12.502-2.607-70.975-3.03-87.558-2.841-16.583.187-64.713-3.147-89.458 13.315C23.43 52.441-2.395 86.588 4.29 122.965c.094.023 10.273 31.774 11.587 50.562Z" fill="#868A91"/>
      <path d="M360.642 61.06s-31.993 1.48-19.515 24.212h9.382s19.515-17.167 10.133-24.212Z" fill="#C3C5C8"/>
      <path opacity=".5" d="M59.079 65.757c-4.691 4.11-9.171 8.313-13.487 12.752a121.15 121.15 0 0 0-12.08 13.95 45.034 45.034 0 0 0-2.462 3.875c-.352.657-.797 1.291-1.079 1.972l-.915 2.043a32.809 32.809 0 0 0-1.548 8.901c-.117 1.503 0 3.053-.164 4.579-.164 1.527 0 3.077.117 4.697.118 1.621.141 3.077.282 4.697.14 1.621.305 3.053.469 4.58a112.837 112.837 0 0 0 3.378 18.13 62.832 62.832 0 0 0 2.978 8.666l.985 2.043 1.056 2.066c.68 1.409 1.337 2.818 1.994 4.227a125.329 125.329 0 0 1 6.426 17.543 67.604 67.604 0 0 1 1.783 9.159 34.247 34.247 0 0 1 0 9.394 38.008 38.008 0 0 0-.446-9.229 70.568 70.568 0 0 0-2.181-8.971 121.529 121.529 0 0 0-6.896-17.144c-.68-1.386-1.36-2.771-2.087-4.133l-1.08-2.043-1.055-2.138a69.742 69.742 0 0 1-3.143-8.877 113.767 113.767 0 0 1-3.588-18.364c-.165-1.55-.329-3.1-.423-4.697-.093-1.597-.21-3.1-.258-4.697-.047-1.597 0-3.124 0-4.697 0-1.574 0-3.124.235-4.697a34.167 34.167 0 0 1 1.712-9.394 37.879 37.879 0 0 1 4.832-8.172 152.628 152.628 0 0 1 26.645-26.021Z" fill="#000"/>
      <path opacity=".5" d="M131.933 73.246a2334.557 2334.557 0 0 1-10.977 28.181 68.29 68.29 0 0 1-7.037 13.48 59.202 59.202 0 0 1-10.015 11.531 41.228 41.228 0 0 1-13.135 7.915 36.354 36.354 0 0 1-15.175 2.043 46.247 46.247 0 0 1-14.753-3.758 69.531 69.531 0 0 1-13.088-7.538c2.18 1.268 4.339 2.512 6.59 3.64a72.948 72.948 0 0 0 6.873 2.982 48.158 48.158 0 0 0 14.472 3.1 35.835 35.835 0 0 0 14.424-2.348 41.591 41.591 0 0 0 12.526-7.609 60.496 60.496 0 0 0 9.804-11.085 71.695 71.695 0 0 0 7.247-12.987 1640.837 1640.837 0 0 1 12.244-27.547ZM226.854 64.746a329.684 329.684 0 0 1-4.41 14.232c-1.571 4.697-3.237 9.393-4.925 14.09v.118c-.12.2-.214.413-.282.634a4.967 4.967 0 0 0-.211.822 8.541 8.541 0 0 0-.188 1.738 15.84 15.84 0 0 0 .399 3.593 31.428 31.428 0 0 0 2.51 7.045 95.23 95.23 0 0 0 8.021 13.057 67.368 67.368 0 0 1-5.089-5.847 50.15 50.15 0 0 1-4.293-6.505 30.97 30.97 0 0 1-2.978-7.351 17.831 17.831 0 0 1-.493-4.04c0-.708.073-1.415.211-2.113.054-.36.141-.714.258-1.057a5.68 5.68 0 0 1 .516-1.104v.212c1.736-4.697 3.472-9.394 5.324-13.903 1.853-4.509 3.519-9.112 5.63-13.62Z" fill="#000"/>
      <path d="M355.831 60.803a1.403 1.403 0 0 0-1.142 1.684c.051.248.171.476.345.664 2.345 2.583 6.497 6.623 8.092 5.355a11.48 11.48 0 0 0-1.666-8.69l-5.629.987ZM319.264 53.782a22.695 22.695 0 0 1 3.988-.681c1.252-.12 2.519-.05 3.753.211 1.231.23 2.42.66 3.518 1.269l.422.21.422.26.845.516c.586.352 1.102.798 1.735 1.15a12.284 12.284 0 0 0-1.079-1.855 9.395 9.395 0 0 0-1.501-1.55 8.678 8.678 0 0 0-3.987-1.925 9.114 9.114 0 0 0-4.433.211 8.286 8.286 0 0 0-3.683 2.184Z" fill="#000"/>
      <path d="M131.926 73.245s51.273-6.623 86.057 12.963l-2.346 6.153-89.833-3.875 6.122-15.241Z" fill="#C3C5C8"/>
      <path opacity=".4" d="M328.951 27.053V.375S311.5 11.436 308.873 19.022l5.395 9.393 14.683-1.362Z" fill="#000"/>
      <path opacity=".4" d="m297.597 5.028 14.073 23.485a38.04 38.04 0 0 1-14.073 2.348s-4.785-7.773 0-25.833Z" fill="#fff"/>
      <path opacity=".3" d="M29.971 98.19s1.783 24.753 10.203 45.255l-5.605 10.85s-13.3-39.971-4.598-56.105ZM223.122 73.246s-5.559 15.993-33.705 18.341l25.801 1.574 7.904-19.915ZM133.127 68.105s2.345 18.787 16.419 21.135l-23.714-.845 7.295-20.29ZM266.865 86.28l14.073-18.083s-3.846 13.222 11.728 16.44l-25.801 1.643Z" fill="#000"/>
      <path d="M273.736 30.671s-5.254 34.309 13.862 54.693l-9.242.778a79.164 79.164 0 0 1-12.079-56.739l7.459 1.268Z" fill="#4D61FC"/>
      <path opacity=".2" d="M273.736 30.671s-5.254 34.309 13.862 54.693l-9.242.778a79.164 79.164 0 0 1-12.079-56.739l7.459 1.268Z" fill="#000"/>
    </>
  )
});

export default SlideUpload;