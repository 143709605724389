import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import PurchasedOutlines from '@/components/PurchasedOutlines';
import ReviewOutline from '@/components/ReviewOutline';
import { myOutlinesNavigationIds } from '@/containers/MyOutlines/myOutlinesNavitagionIds';

import { MyOutlineContext } from '../../hooks/MyOutlineContext';
import { myOutlineReducer } from '../../hooks/myOutlineReducer';

const MyOutlinesContainer = ({ onBack }) => {
  const [page, dispatch] = useReducer(myOutlineReducer, {
    navigation: myOutlinesNavigationIds.purchasedOutlines,
    data: {},
  });

  const renderMyOutlineContent = page => {
    const { navigation } = page;

    switch (navigation) {
      case myOutlinesNavigationIds.purchasedOutlines:
        return <PurchasedOutlines onBack={onBack} />;

      case myOutlinesNavigationIds.makeReview:
        return <ReviewOutline />;

      default:
        return <PurchasedOutlines onBack={onBack} />;
    }
  };

  return (
    <Box data-testid='my-outlines-container'>
      <MyOutlineContext.Provider value={{ page, dispatch }}>
        {renderMyOutlineContent(page)}
      </MyOutlineContext.Provider>
    </Box>
  );
};

MyOutlinesContainer.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default MyOutlinesContainer;
