import React, { useState } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import { Box } from '@chakra-ui/react';
import { DrawerBody, DrawerHeader } from '../Drawer';
import SuggestProfessorForm from './SuggestProfessorForm';
import Error from '@/components/Error';
import Success from '../Success';
import SuggestCourseForm from './SuggestCourseForm';

const MakeSuggestion = ({ onBack }) => {
  const { t } = useTranslation();
  const router = useRouter();

  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);

  return (
    <Box data-testid='select-payment-method'>
      <DrawerHeader onBack={onBack} title={t('makeSuggestion:title')} />
      <DrawerBody px={{ base: 6, md: 8 }} mt={{ base: 6, md: 0 }}>
        {hasSuccess && (
          <Success
            clearSuccess={() => {
              setHasSuccess(false);
            }}
            title={t('makeSuggestion:success.title')}
            message={t('makeSuggestion:success.message')}
            clearText={t('makeSuggestion:success.clearText')}
          />
        )}

        {hasError && (
          <Error
            clearError={() => {
              setHasError(false);
            }}
          />
        )}

        {!hasSuccess && !hasError ? (
          <>
            <Box fontSize='sm' mb={{ base: 6, md: 12 }}>
              {t('makeSuggestion:mainMessage')}
            </Box>

            <Box mb={{ base: 6, md: 12 }}>
              <SuggestProfessorForm
                handleHasSuccess={setHasSuccess}
                handleHasError={setHasError}
                focused={router.query.suggestionType === 'professor'}
              />
            </Box>

            <Box mb={{ base: 6, md: 12 }}>
              <SuggestCourseForm
                handleHasSuccess={setHasSuccess}
                handleHasError={setHasError}
                focused={router.query.suggestionType === 'course'}
              />
            </Box>
          </>
        ) : (
          ''
        )}
      </DrawerBody>
    </Box>
  );
};

export default MakeSuggestion;
