import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Box, Text, Divider } from '@chakra-ui/react';

import { downloadOutline, tabDownloadOutline } from '@/stores/outlines';
import { getUser } from '@/stores/user';

import { formatTime } from '@/utils/dateUtils';
import { PURCHASED } from '@/utils/constants';

import OutlineInfo from '@/components/Outline/OutlineView/OutlineInfo';
import ReviewCard from '@/components/Reviews/ReviewCard';
import { MyOutlineContext } from '../../../hooks/MyOutlineContext';
import { myOutlinesNavigationIds } from '@/containers/MyOutlines/myOutlinesNavitagionIds';
import validatePurchaseDate from '../../../hooks/validatePurchaseDate';

const HistoryItem = ({ purchasedOutline }) => {
  const { dispatch: dispatchContext } = useContext(MyOutlineContext);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id: userId, nickname } = useSelector(state => getUser(state));

  const {
    outline,
    purchase_date,
    userReview,
    downloaded,
    id: purchaseId,
  } = purchasedOutline;

  const purchasedDate = formatTime(purchase_date, 'MM/dd/yyyy');
  const validateDate = validatePurchaseDate(purchase_date);

  const onDownloadOutline = () => {
    dispatch(downloadOutline({ userId, outlineId: outline.id, purchaseId }));
    dispatch(tabDownloadOutline(true));
  };

  const onMakeReview = () => {
    dispatchContext({
      type: myOutlinesNavigationIds.makeReview,
      payload: {
        data: outline,
      },
    });
  };

  const buttonValidation = () => {
    if (downloaded) {
      return (
        <Box
          bg='gray.100'
          color='gray.500'
          fontWeight={600}
          mt={4}
          w='full'
          textAlign='center'
          p={2}
          borderRadius={5}
          _focus={{ boxShadow: 'none' }}
        >
          {t('purchasedOutlines:history.notAvailable.downloaded')}
        </Box>
      );
    } else if (validateDate) {
      return (
        <Button
          onClick={onDownloadOutline}
          mt={4}
          w='full'
          _focus={{ boxShadow: 'none' }}
          bg='alpha.500'
          color='white'
        >
          {t('purchasedOutlines:history.available.download')}
        </Button>
      );
    } else {
      return (
        <Box
          bg='gray.100'
          color='gray.500'
          fontWeight={600}
          mt={4}
          w='full'
          textAlign='center'
          p={2}
          borderRadius={5}
          _focus={{ boxShadow: 'none' }}
        >
          {t('purchasedOutlines:history.notAvailable.expiredDate')}
        </Box>
      );
    }
  };

  return (
    <>
      <Box py={4} mb={4} data-testid='purchased-outlines-history-item'>
        <OutlineInfo outline={outline} fullInfo={false} />
        <Box fontSize='sm' w='full'>
          <Text color='gray.600' mt={4}>
            {t('purchasedOutlines:purchasedDate', { date: purchasedDate })}
          </Text>
          {userReview && (
            <Box mt={2} p={4} bg='gray.50' borderRadius='md'>
              <ReviewCard review={{ ...userReview, user: { nickname } }} />
            </Box>
          )}
          <Box>
            {buttonValidation()}
            {downloaded && !userReview && (
              <Button
                onClick={onMakeReview}
                mt={4}
                w='full'
                _focus={{ boxShadow: 'none' }}
                variant='outline'
                color='alpha.500'
                border='2px'
                borderColor='alpha.500'
              >
                {t('purchasedOutlines:history.downloads.review')}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

HistoryItem.propTypes = {
  purchasedOutline: PropTypes.shape({}).isRequired,
};

export default HistoryItem;
