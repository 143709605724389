import PropTypes from 'prop-types';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';

const InputForm = ({
  id,
  type,
  label,
  placeholder,
  errors,
  register,
  isRequired,
  defaultValue,
}) => {
  return (
    <FormControl isInvalid={errors[id]} mb={4} id={id} isRequired={isRequired}>
      <FormLabel
        htmlFor={id}
        color={errors[id] ? 'customInput.error' : 'gray.800'}
        fontSize='xs'
      >
        {label}
      </FormLabel>
      <Input
        id={id}
        type={type}
        fontSize='14px'
        placeholder={placeholder}
        bg='gray.50'
        _placeholder={errors[id] && { color: 'customInput.error' }}
        {...register(id)}
        data-testid={`${id}-input`}
        defaultValue={defaultValue}
      />
      <FormErrorMessage fontSize='xs'>
        {errors[id] && errors[id].message}
      </FormErrorMessage>
    </FormControl>
  );
};

InputForm.defaultProps = {
  label: '',
  placeholder: '',
  errors: {},
  type: 'text',
};

InputForm.propTypes = {
  id: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.shape({}),
};

export default InputForm;
