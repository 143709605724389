export const navigationIds = {
  adminConsole: 'adminConsole',
  purchasedOutlines: 'purchasedOutlines',
  uploadedOutlines: 'uploadedOutlines',
  paymentMethod: 'paymentMethod',
  makeSuggestion: 'makeSuggestion',
  myBalance: 'myBalance',
  faq: 'faq',
  support: 'support',
  tos: 'tos',
  logout: 'logout',
};
