import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { SearchIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  List,
  ListItem,
  useOutsideClick,
} from '@chakra-ui/react';

import { getSelectedOption } from '@/stores/search';

const FilterInput = ({
  id,
  category,
  label,
  options,
  value,
  placeholder,
  onChange,
  handleSelect,
  handleReset,
  type,
}) => {
  const inputList = useRef();

  const selectedOption = useSelector(state => getSelectedOption(state));

  const [displayOptions, setDisplayOptions] = useState(false);
  const [inputValue, setInputValue] = useState(value || '');

  useOutsideClick({
    ref: inputList,
    handler: () => setDisplayOptions(false),
  });

  useEffect(() => {
    if (selectedOption) {
      if (selectedOption.category === category) {
        setInputValue(selectedOption.name);
      } else {
        setInputValue('');
      }
    }
  }, [selectedOption, category]);

  useEffect(() => {
    if (options) {
      setDisplayOptions(true);
    } else {
      setDisplayOptions(false);
    }
  }, [options]);

  const handleOnChange = e => {
    setInputValue(e.target.value);

    onChange(e);
  };

  const handleSelectOption = option => {
    handleSelect(option);
    setInputValue(option.name);
    setDisplayOptions(false);
  };

  const handleClear = () => {
    setInputValue('');
    setDisplayOptions(false);
    handleReset();
  };

  return (
    <FormControl id={id} mb={4}>
      <FormLabel htmlFor={id} color='gray.600' fontSize='sm' fontWeight='bold'>
        {label}
      </FormLabel>
      <InputGroup>
        <InputLeftAddon bg='white' px={3}>
          <SearchIcon color='alpha.400' h='12px' w='12px' />
        </InputLeftAddon>
        <Input
          id={id}
          name={id}
          type={type}
          fontSize='14px'
          value={inputValue}
          placeholder={placeholder}
          bg='white'
          onChange={handleOnChange}
          autoComplete='off'
          _focus={{ boxShadow: 'none' }}
        />
        {inputValue?.length && (
          <InputRightAddon
            onClick={handleClear}
            data-testid='clear-icon'
            bg='white'
            cursor='pointer'
            px={3}
          >
            <CloseIcon color='gray.500' h='10px' w='10px' />
          </InputRightAddon>
        )}
      </InputGroup>
      {displayOptions && !!options.length && (
        <List
          position='absolute'
          bg='white'
          w='100%'
          borderRadius='md'
          boxShadow='md'
          mt={2}
          px={6}
          pt={4}
          pb={2}
          ref={inputList}
          zIndex={1}
        >
          <Box mb={2} textAlign='left'>
            {options.map(item => (
              <ListItem
                onClick={() => handleSelectOption(item)}
                fontSize='sm'
                key={item.id}
                cursor='pointer'
                borderRadius='md'
                mb={1}
                _hover={{ color: 'alpha.400' }}
              >
                {item.name}
              </ListItem>
            ))}
          </Box>
        </List>
      )}
    </FormControl>
  );
};

FilterInput.defaultProps = {
  label: '',
  options: [],
  value: '',
  placeholder: '',
  type: 'text',
  onChange: () => {},
  handleSelect: () => {},
  handleReset: () => {},
};

FilterInput.propTypes = {
  id: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  handleSelect: PropTypes.func,
  handleReset: PropTypes.func,
};

export default FilterInput;
