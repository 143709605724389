import useTranslation from 'next-translate/useTranslation';
import { Flex, Text } from '@chakra-ui/react';

import { DressedAndPlant } from '@/components/illustrations';

const EmptyHistory = () => {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection='column'
      mt={{ base: 4, md: 32 }}
      alignItems='center'
      data-testid='purchased-outlines-history-empty'
    >
      <DressedAndPlant w={298} h={211} />
      <Text fontSize='2xl' fontWeight='bold' color='gray.600' mt={6}>
        {t(`purchasedOutlines:empty`)}
      </Text>
    </Flex>
  );
};

export default EmptyHistory;
