import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';
import { Box, Text, Flex, Spacer, Heading, Badge } from '@chakra-ui/react';
import { useSession } from 'next-auth/client';

import StarRating from '@/components/StarRating';
import { SchoolInformation } from '@/components/Outline';

import { getDateDifference } from '@/utils/dateUtils';

const OutlineInfo = ({ outline, fullInfo }) => {
  const { t } = useTranslation();

  const [session] = useSession();

  const {
    course,
    count_rating,
    average_rating,
    school,
    professor,
    course_period,
    year,
    user,
    approval_status,
    grade,
  } = outline;

  const rating = average_rating.toFixed(1);

  return (
    <Box w='full'>
      <Heading color='gray.900' fontSize='2xl' my={fullInfo && '6px'}>
        {course.name}
      </Heading>
      {fullInfo && (
        <Text color='gray.500' fontSize='xs' my='6px'>
          {t('outlines:published', {
            date: getDateDifference(outline.created_at),
          })}
        </Text>
      )}
      <Box my={fullInfo && '6px'}>
        {fullInfo && <Text fontSize='sm'>{t('outlines:studentReview')}</Text>}
        <Flex>
          <Box m='4px 0'>
            <Flex alignItems='baseline'>
              <StarRating average={average_rating} />
              {fullInfo && (
                <Text fontSize='xs' mx={11}>
                  {rating}
                </Text>
              )}
            </Flex>
          </Box>
          <Spacer />
        </Flex>
        <Text color='gray.500' fontSize='10px' my='4px'>
          {t('outlines:countReview', {
            count: count_rating,
          })}
        </Text>
      </Box>
      <SchoolInformation
        professorName={professor.name}
        schoolName={school.name}
        year={year}
        grade={grade}
        term={course_period}
        nickname={user.nickname}
      />
      {session?.user?.roles.includes('admin') && (
        <Badge colorScheme={approval_status ? 'green' : 'yellow'}>
          {t(`outlines:approval_status.${approval_status}`)}
        </Badge>
      )}
    </Box>
  );
};

OutlineInfo.defaultProps = { fullInfo: true };

OutlineInfo.propTypes = {
  fullInfo: PropTypes.bool,
  outline: PropTypes.shape({
    id: PropTypes.number,
    table_of_contents: PropTypes.string,
    outline_url: PropTypes.string,
    year: PropTypes.string,
    course: PropTypes.object,
    course_period: PropTypes.string,
    count_rating: PropTypes.number,
    average_rating: PropTypes.number,
    school: PropTypes.object,
    professor: PropTypes.object,
    user: PropTypes.object,
  }).isRequired,
};

export default OutlineInfo;
