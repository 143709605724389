import React from 'react';
import PropTypes from 'prop-types';
import {
  Drawer as ChakraDrawer,
  DrawerOverlay,
  DrawerContent,
  useBreakpointValue,
  DrawerBody,
} from '@chakra-ui/react';

const Drawer = ({ children, isOpen, onClose, placement = 'right' }) => {
  const drawerSize = useBreakpointValue({ base: 'full', md: 'md' });

  return (
    <ChakraDrawer
      placement={placement}
      size={drawerSize}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent bg='white'>
        <DrawerBody p={0}>{children}</DrawerBody>
      </DrawerContent>
    </ChakraDrawer>
  );
};

Drawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Drawer;
