import useTranslation from 'next-translate/useTranslation';
import { useSelector } from 'react-redux';
import { Box, Flex, Text } from '@chakra-ui/react';

import { getTotalBalance } from '@/stores/balance';

const TotalBalance = () => {
  const { t } = useTranslation();

  const totalBalance = useSelector(state => getTotalBalance(state));

  return (
    <Box data-testid='total-balance'>
      <Flex justifyContent='space-between' mb={4}>
        <Text fontSize='lg' fontWeight='bold' color='alpha.400'>
          {t('balance:totalBalance.title')}
        </Text>
        {/* Filter */}
      </Flex>
      <Text fontSize='5xl' fontWeight='bold' color='gray.500'>
        {t('balance:totalBalance.total', { total: totalBalance })}
      </Text>
    </Box>
  );
};

export default TotalBalance;
