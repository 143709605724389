import {
  format,
  formatDistanceToNowStrict,
  addDays,
  differenceInDays,
} from 'date-fns';

/**
 * Format a date with the given format string
 * Patterns accepted: https://date-fns.org/v2.27.0/docs/format
 *
 * @param {string} date string to format
 *
 * @returns {string} the date given formatted with the given format pattern.
 */
export const formatTime = (dateString, formatType) =>
  format(new Date(dateString), formatType);

/**
 *
 * @param {string} dateString  string for the date we need to know the difference of
 * @returns {string} a formatted string with the date difference or an empty value
 */
export const getDateDifference = dateString => {
  if (!dateString) {
    return '-';
  }

  return formatDistanceToNowStrict(new Date(dateString));
};

export const getRemainingDays = (dateString, daysLimit) => {
  const date = new Date(dateString);

  return differenceInDays(addDays(date, daysLimit), date);
};

export const getPreviousYears = previousYears => {
  let currentYear = new Date().getFullYear();
  let yearsOptions = [{ value: currentYear, name: currentYear }];

  for (let index = 0; index < previousYears; index++) {
    currentYear = currentYear - 1;
    yearsOptions.push({ value: currentYear, name: currentYear });
  }

  return yearsOptions;
};
