import PropTypes from 'prop-types';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
} from '@chakra-ui/react';

const TextAreaForm = ({ id, type, label, placeholder, errors, register }) => {
  return (
    <FormControl isInvalid={errors[id]} mb={4} id={id}>
      <FormLabel
        htmlFor={id}
        color={errors[id] ? 'customInput.error' : 'gray.800'}
        fontSize='xs'
      >
        {label}
      </FormLabel>
      <Textarea
        id={id}
        type={type}
        fontSize='14px'
        placeholder={placeholder}
        bg='gray.50'
        _placeholder={errors[id] && { color: 'customInput.error' }}
        {...register(id)}
        data-testid={`${id}-input`}
      />
      <FormErrorMessage fontSize='xs'>
        {errors[id] && errors[id].message}
      </FormErrorMessage>
    </FormControl>
  );
};

TextAreaForm.defaultProps = {
  label: '',
  placeholder: '',
  errors: {},
  type: 'text',
};

TextAreaForm.propTypes = {
  id: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.shape({}),
};

export default TextAreaForm;
